import React, { useContext, useState } from 'react';

// import Modal from 'react-bootstrap/Modal';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Modal from 'react-bootstrap/Modal';
import { globalData } from '../CommonModal';
import '../Modal.css';
import {returnFname} from '../../control/Constant'
// import konsole from '../../Network/konsole';

const IllnessWarning = () => {
    const { handleClose, setmodalcontantshow, steptwomodaldata,primaryAgents,setCertifyAgent } = useContext(globalData)
    let primaryAgentnames = primaryAgents.length == 0 ? "" : primaryAgents.map((ele,index)=>{return (ele.length == 1 ? returnFname(ele.fullName) : `${" "+returnFname(ele.fullName)}${(primaryAgents.length - 1 === index) ? '' : ' '}`)})
    let primaryAgentname = primaryAgents.length == 0 ? "" : primaryAgents.map((ele,index)=>{return (ele.length == 1 ? ele.fullName : `${" "+ele.fullName}${(primaryAgents.length - 1 === index) ? '' : ' '}`)})
     const [showDocumentView, setShowDocumentView] = useState(false)
     const continueUser = (data)=>{
        data == "step4illness" ? setCertifyAgent("warning") : setCertifyAgent("")
        setmodalcontantshow(data)
     }
    return (
        <>

<Modal.Header style={{ backgroundColor: "#871a27" }}>
<Modal.Title className='mt-2' id="example-modal-sizes-title-lg">
                    <h3 className='heading text-center' >
                        Warning !
                    </h3>
                </Modal.Title>
                {/* closeButton */}

                <button className='closeButton' style={{ border: "1px solid #981b29" }} onClick={handleClose}>x</button>

        </Modal.Header>
            <Modal.Body>
                <h3 className='SelectOptionsh4Tag  mb-0 mt-3'>
                    
                   It appears you are NOT the primary agent to act on behalf of {returnFname(steptwomodaldata?.memberFullName)}. This means you,
                    do not have the authority to act on {returnFname(steptwomodaldata?.memberFullName)}'s behalf.<br/><br/>&nbsp;
                     {primaryAgentname != "" ? primaryAgentname + " is the primary agent. ":""}  You can see
                     what {primaryAgentnames != "" ? (primaryAgentnames) + " as the primary agent can do, ":""}
                      {/* will need to do in order to fulfill {primaryAgentnames != "" ? primaryAgentnames : ""}'s role <br/> */}
                    or you can assume {primaryAgentnames != "" ? primaryAgentnames : "primary agent"}'s duty by certifying under penalty of perjury that all agents ahead of you are unable or unwilling to act. <br/><br/>
                     {/* Click continue to acknowledge that you understand you do not
                    have the authority to act as of now. */}
                     </h3>
            


                <div className='buttonDiv'>
                    <button className='profesionalBtnTool fw-bold mb-2' style={{fontSize:'15px'}} onClick={()=>continueUser('step4illness')}>See Role of Primary Agent</button>
                    {/* <button className='profesionalBtnTool fw-bold mb-2 me-2' onClick={()=>continueUser('elaNotify')}>Certify</button> */}
                    <button className='profesionalBtnTool fw-bold mb-2' style={{fontSize:'15px'}} onClick={()=>continueUser('illnessMemberWarning')}>Assume Primary Agent Role</button>
                   </div>
                <div className='warnigbackbuttonDiv '>
                    <button className='backButton' onClick={()=>setmodalcontantshow('step3selectoption')}>Back</button>
                </div>
            </Modal.Body>
            <Modal centered={true} size="lg" show={showDocumentView} backdrop="static" >
                <Modal.Header style={{ backgroundColor: "#871a27" }}>
                    <Row>
                        <Col sm={11}>
                            <h3 className='heading'>
                                Document View
                            </h3>
                        </Col>
                        <Col sm={1} >
                            <button className='closeButton' style={{ border: "1px solid #981b29" }} onClick={() => setShowDocumentView(false)}>x</button>
                        </Col>
                    </Row>
                </Modal.Header>
                <Modal.Body>      
                    <h4>Coming soon.............</h4>               
                </Modal.Body>
            </Modal>
        </>
    );
}

export default IllnessWarning;