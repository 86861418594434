import React, { useState, useContext } from 'react';
import Modal from 'react-bootstrap/Modal';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { globalData } from '../CommonModal';

import '../Modal.css'
import "../Illness/Warning.css";
const DeathHome = (props) => {
    const { handleClose, setmodalcontantshow, steptwomodaldata } = useContext(globalData)


  return (
    <>
    
      <Modal.Header style={{ backgroundColor: "#871a27" }}>

        <Modal.Title className='heading' id="example-modal-sizes-title-lg" style={{ color: 'white', fontFamily: "Inter",height: 30 }}>

        </Modal.Title>
        {/* closeButton */}

        <button className='closeButton' style={{ border: "1px solid #981b29" }} onClick={handleClose}>x</button>

      </Modal.Header>
      <Modal.Body>
 
{
(props.deathhome == 'deathhome') ?
<h4 className='SelectOptionsh4Tag text-center mb-4'>
  
        You are required by law to notify authorities of any death at home. Please call 911.
        </h4>
: (props.deathhome == 'carefacility') ?
<h4 className='SelectOptionsh4Tag text-center mb-4'>
You are required by law to notify authorities. Please inform Officials
  </h4>
  :""
}

        <div className='stepsevenbuttonsDiv'>
          <button className='backButton' onClick={()=>setmodalcontantshow('deathstep5')}>
            Back</button>
          {/* <button className='backButton' style={{backgroundColor:"#d79b48"}} onClick={()=>setmodalcontantshow('deathgoalstep7')}> */}
          <button className='backButton' style={{backgroundColor:"#d79b48"}} onClick={()=>setmodalcontantshow('deathaskagent')}>
            Next</button>
        </div>  

        

      </Modal.Body>

   
    </>
  );
}

export default DeathHome;