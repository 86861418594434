import React, { useState, useEffect, forwardRef, useImperativeHandle, useContext } from 'react'
import commonLib from "./control/commonLib";
import { globalData } from './Execute/CommonModal';
import konsole from "./Network/konsole";
import Services from "./Network/Services";
import { isNotValidNullUndefinedfile } from './control/Constant';

const OccuranceCom = forwardRef((props, ref) => {
    const {steptwomodaldata, fullNameofGcm, setsuccessalert,setwarninngMsg,setWarningText,setloader,selectoptionId,agentData} = useContext(globalData)
    

    useImperativeHandle(ref, () => ({
    calloccuranceIdfun, calloccuranceIdfunOnlyMailOrText
    }));
    //-----------------------------------------------------------------------------------------
    let subtenantId = sessionStorage.getItem('subtenantId')
    let loggedUserId = JSON.parse(sessionStorage.getItem('userLoggedInDetail'))
    let memberName = JSON.parse(sessionStorage.getItem('userLoggedInDetail')).loginUserName
    //-----------------------------------------------------------------------------------------
    const calloccuranceIdfun = (occurrenceIdData, subtenantIdData, allData) => {
      
        let jsonobj = {
            "occurrenceId": occurrenceIdData,
            "isActive": true,
            "subtenantId": subtenantIdData
        }
        konsole.log("getCommMediumPath", JSON.stringify(jsonobj))
        let result = Services.getCommMediumPath(jsonobj)
        result.then((res) => {
            konsole.log("getCommMediumPath", res)
            const commChannelIdId = res.data?.data[0]?.commChannelId
            const applicableEmailTempIdId = res.data?.data[0]?.applicableEmailTempId
            const applicableTextTempIdId = res.data?.data[0]?.applicableTextTempId

            if (commChannelIdId == 3) {
                callBothTextAndEmailApiFunc(applicableEmailTempIdId, applicableTextTempIdId, occurrenceIdData, allData)
            } else if (commChannelIdId == 2) {
                callTextApiFunc(applicableTextTempIdId, allData)
            } else if (commChannelIdId == 1) {
                callEmailApiFunc(applicableEmailTempIdId, allData)
            }
        }).catch((err) => {
            setloader(false)
            konsole.log("getCommMediumPath", err)
            if (err.response.status == 404) {
                konsole.log("getCommMediumPathab")
                let subtenantId = commonLib.DEFAULT_SUBTENANTID_FOR_OCCURRENCE;
                calloccuranceIdfun(occurrenceIdData, subtenantId, allData)
            }
        }).finally(() => {
            konsole.log("getCommMediumPathfinally")
            setloader(false)
        })
    }

//    To Send Only Mail or Text
const calloccuranceIdfunOnlyMailOrText = (occurrenceIdData, subtenantIdData, allData, type) => {
    let jsonobj = {
        "occurrenceId": occurrenceIdData,
        "isActive": true,
        "subtenantId": subtenantIdData
    }
    konsole.log("getCommMediumPath", JSON.stringify(jsonobj))
    let result = Services.getCommMediumPath(jsonobj)
    result.then((res) => {
        const commChannelIdId = res.data?.data[0]?.commChannelId
        const applicableEmailTempIdId = res.data?.data[0]?.applicableEmailTempId
        const applicableTextTempIdId = res.data?.data[0]?.applicableTextTempId
        const subtenantName = res.data?.data[0]?.subtenantName
        sessionStorage.setItem("SubtenantName", subtenantName)
        if (type == "Text" && isNotValidNullUndefinedfile(applicableEmailTempIdId)) {
            callTextApiFunc(applicableTextTempIdId, allData)
        } else if (type == "Email" && isNotValidNullUndefinedfile(applicableEmailTempIdId)) {
            callEmailApiFunc(applicableEmailTempIdId, allData)
        }
    }).catch((err) => {
        setloader(false)
        konsole.log("getCommMediumPath", err)
        if (err.response.status == 404) {
            konsole.log("getCommMediumPathab")
            let subtenantId = commonLib.DEFAULT_SUBTENANTID_FOR_OCCURRENCE;
            calloccuranceIdfun(occurrenceIdData, subtenantId, allData)
        }
    }).finally(() => {
        setloader(false)
        konsole.log("getCommMediumPathfinally")
    })
}


    const callBothTextAndEmailApiFunc = (emailTempalteId, textTempalteId, occurrenceIdDataValue, allData) => {
        callTextApiFunc(textTempalteId, allData)
        callEmailApiFunc(emailTempalteId, allData)
    }


    //------------------------------Text Call & Send ---------------------------------------

    const callTextApiFunc = (TempalteId, allData,) => {
      
        let isActive = true;
        konsole.log("TempalteIdisActiv", TempalteId, isActive)
        let result = Services.getTextTemplate(TempalteId, isActive)
        result.then((res) => {
            konsole.log("getTextTemplate", res)
            let responseData = res.data.data[0]
            if(allData?.type === "financeUser"){
                sendOnlyTextAPI(responseData, allData)
            }else if(allData?.type == "healthUser"){
                sendOnlyTextAPI(responseData,allData)  
            }else{
                sendTextAPIFunc(responseData, allData)
            }
        }).catch((err) => {
            setloader(false)
            konsole.log("getTextTemplate", err)
        }).finally(() => {
            konsole.log("getTextTemplatefinally")
            setloader(false)
        })
    }

    const sendOnlyTextAPI = (responseData, allData) => {
        let templateData = templateReplacer(responseData?.textTemplateContent, allData, 'TEXT')
       
        
         let jsonobj = {
            "smsType": responseData?.textTemplateType,
            "textTo": allData?.userPhoneNo,
            "textContent": templateData,
            "smsTemplateId": responseData?.textTemplateId,
            "smsStatusId": 1,
            "smsMappingTable": "tblUserLinks",
            "smsMappingTablePKId": allData?.usersUserId,
            "createdBy": loggedUserId.loggedUserId
        }
            sendTextApi(jsonobj)
    }

    const sendTextAPIFunc = (responseData, allData) => {
        konsole.log("sendTextAPIFunc", allData)
        let templateData = templateReplacer(responseData?.textTemplateContent, allData, 'TEXT')
        let paralegalDetails = allData.paralegalDetails
        let textToNo = allData.primaryclientData.contact.mobiles

        let jsonobj = {
            "smsType": responseData?.textTemplateType,
            "textTo": "",
            "textContent": templateData,
            "smsTemplateId": responseData?.textTemplateId,
            "smsStatusId": 1,
            "smsMappingTable": "tblUserLinks",
            "smsMappingTablePKId": allData.clientData.agentId,
            "createdBy": loggedUserId.loggedUserId
        }
        konsole.log("jsobobj", JSON.stringify(jsonobj))
        for (let [index, item] of paralegalDetails.entries()) {
            konsole.log("indexindexindextext", item, item.primaryMobile, index, paralegalDetails.length)
            jsonobj.textTo = item.primaryMobile
            sendTextApi(jsonobj)

        }
        konsole.log("textToNo", textToNo)
        for (let [index, item] of textToNo.entries()) {
            jsonobj.textTo = item.mobileNo
            sendTextApi(jsonobj)
        }
    }

    const sendTextApi = (jsonobj) => {
        if(jsonobj?.textTo != null && jsonobj?.textTo != undefined && jsonobj?.textTo != ""){
            let result = Services.postSendTextPath(jsonobj)
            result.then((res) => {
                setloader(false)
                konsole.log("postSendTextPath", res)
                setsuccessalert(true);
                setTimeout(() => {
                    setsuccessalert(false)
                  }, 3000)
            }).catch((err) => {
                setloader(false)
                konsole.log("postSendTextPath".err)
            }).finally(() => {
                konsole.log("postSendTextPathfinally")
                setloader(false)
            })
        }else{
            setloader(false)
            setwarninngMsg(true)
            setWarningText("Contact number not available")
            setTimeout(() => {
                setwarninngMsg(false)
              }, 3000)
        }
       
    }
    //------------------------------EMail Call & Send ---------------------------------------
    const callEmailApiFunc = (TempalteId, allData) => {
        let isActive = true;
        let result = Services.getEmailTemplate(TempalteId, isActive)
        result.then((res) => {
            let responseData = res.data.data[0]
            
            if(allData.type === "financeUser"){
                sendOnlyEmailAPI(responseData,allData)
            }else if(allData?.type == "healthUser"){
                sendOnlyEmailAPI(responseData,allData)  
            }
            else{
                sendEmailAPIFunc(responseData, allData)
            }
        }).catch((err) => {
            setloader(false)
            konsole.log("getEmailTemplate", err)
        }).finally(() => {
            konsole.log("getEmailTemplatefinally")
            setloader(false)
        })
    }

    const sendOnlyEmailAPI = (responseData, allData,) => {
        
        konsole.log("responseData", responseData, allData)
        let paralegalDetails = allData.paralegalDetails
        let templateData = templateReplacer(responseData?.templateContent, allData, 'EMAIL')
        let emailto = allData?.primaryclientData?.contact?.emails
        let jsonobj = {
            "emailType": responseData?.templateType,
            "emailTo": allData.userEmail,
            "emailSubject": responseData?.emailSubject.replace("@@MEMBERNAME",steptwomodaldata?.memberFullName),
            "emailContent": templateData,
            "emailTemplateId": responseData?.templateId,
            "emailStatusId": 1,
            "emailMappingTable": "tblUserLinks",
            "emailMappingTablePKId": allData?.usersUserId,
            "createdBy": loggedUserId?.loggedUserId

        }
        
        sendEmailApi(jsonobj)
    }

    const sendEmailAPIFunc = (responseData, allData,) => {
        konsole.log("responseData", responseData, allData)
        let paralegalDetails = allData.paralegalDetails
        let templateData = templateReplacer(responseData?.templateContent, allData, 'EMAIL')
        let emailto = allData?.primaryclientData?.contact?.emails
        let jsonobj = {
            "emailType": responseData?.templateType,
            "emailTo": 'hiiemailto@mailinator.com',
            "emailSubject": responseData?.emailSubject,
            "emailContent": templateData,
            "emailTemplateId": responseData?.templateId,
            "emailStatusId": 1,
            "emailMappingTable": "tblUserLinks",
            "emailMappingTablePKId": allData?.clientData?.agentId,
            "createdBy": loggedUserId?.loggedUserId

        }
        konsole.log("jsonobjemail", JSON.stringify(jsonobj))
        for (let [index, item] of paralegalDetails?.entries()) {
            konsole.log("indexindexindex", item.primaryEmail, index, paralegalDetails.length)
            jsonobj.emailTo = item.primaryEmail
            sendEmailApi(jsonobj)
        }
        for (let [index, item] of emailto.entries()) {
            jsonobj.emailTo = item.emailId
            sendEmailApi(jsonobj)
        }
    }

    const sendEmailApi = (jsonobj) => {
        if(jsonobj?.emailTo != null && jsonobj?.emailTo != undefined && jsonobj?.emailTo != ""){
            let result = Services.PostEmailCom(jsonobj)
            result.then((res) => {
                setloader(false)
                konsole.log("PostEmailCom", res)
                setsuccessalert(true);
                setTimeout(() => {
                    setsuccessalert(false)
                  }, 3000)
            }).catch((err) => {
                setloader(false)
                konsole.log("PostEmailCom", err)
            }).finally(() => {
                setloader(false)
                konsole.log("PostEmailCom")
            })
        }else{
            setloader(false)
            setwarninngMsg(true)
            setWarningText("Email not available")
            setTimeout(() => {
                setwarninngMsg(false)
              }, 3000)
        }
       
    }
    const numberFormate = (number) => {
        const lastTenDigitArray = array => array.slice(-10);
        return commonLib.convertToUSFormat(lastTenDigitArray(number), "1")
          }

    const templateReplacer = (templateData, allData, type) => {
       

        let clientdetails = allData?.clientData

        let updatedTemplateData = templateData;
        const nameOfSubtenant = sessionStorage.getItem("SubtenantName")
        updatedTemplateData = updatedTemplateData?.replace('@@SUBTENANTNAME',nameOfSubtenant).replace('@@AGENTNAME', clientdetails?.memberFullName).replace('@@RANK', clientdetails?.agentRank).
        replace('@@ROLE', clientdetails?.agentRole).replace('@@DOCUMENTNAME', clientdetails?.legalDocName).replace('@@AGENTFULLNAME',allData?.agentName).replace("@@RELATIVENAME",(selectoptionId == 5 ? (agentData.length > 0 && agentData.length < 2 ?  agentData.length > 0 && agentData.map(ele => ele.fullName) : "all") : (allData?.memberFullName))).replace("@@NOTIFYTEXT",allData?.notifyText).
        
        replace("@@EMAILTEXT", allData?.contentForMail).replace("@@MEMBERNAME ", memberName).replace("@USERNAME", fullNameofGcm).replace("@@SMSTEXT", allData?.contentForMail).replace("@@SUCCESSORNAME",allData?.memberFullName).replace("@@PRIMARYMEMBERNAME",allData?.primaryMembername).replace("@@AGENTNUMBER",allData?.agentMobileNo != undefined && allData?.agentMobileNo != null &&  allData?.agentMobileNo != "" ? numberFormate(allData?.agentMobileNo):"not available").replace("@@AGENTEMAIL",allData?.agentEmail)                                                                                                                                                                                
        // updatedTemplateData = updatedTemplateData?.replace('@@SUBTENANTNAME','Sample')
     
        return updatedTemplateData


    }

    //---------------------------------COMPONENENT--------------------------------------------------------
    return <></>
});
export default OccuranceCom