import React, { useState, useContext } from 'react';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { globalData } from '../CommonModal';
import '../Modal.css'
import {returnFname} from '../../control/Constant'

const EOLYesNo = () => {
    const { handleClose, setmodalcontantshow, steptwomodaldata,memberRankIdforPrimary } = useContext(globalData)

    const btnCLickYes = () => {
        let id = memberRankIdforPrimary
        if (id == 1 || id == 2 || id == 3 || id == 4 || id == 5 || id == 6) {
            setmodalcontantshow('eolnotifysuccessorstep4')
        } else {
           setmodalcontantshow('eolwarning')
        }

    }
    return (
        <>

            <Modal.Header style={{ backgroundColor: "#871a27" }}>
                <Row >
                    <Col sm={11}>
                        <h3 className='heading'>
                            <span style={{ color: "#871a27" }}>S</span>
                        </h3>
                    </Col>
                    <Col sm={1} >
                        <button className='closeButton' style={{ border: "1px solid #981b29" }} onClick={handleClose}>x</button>
                    </Col>
                </Row>



            </Modal.Header>
            <Modal.Body>

                <h4 className='SelectOptionsh4Tag text-center'>
                    Has {returnFname(steptwomodaldata.memberFullName)}  been diagnosed terminally ill?

                </h4>
                <h4 className='SelectOptionsh4Tag text-center'>

                    (Meaning {returnFname(steptwomodaldata.memberFullName)} is unlikely to live beyond 6 months)
                </h4>


                <div className='buttonDiv'>
                    <button className='yesButton' onClick={() => btnCLickYes()} >Yes</button>
                    <button className='noButton' onClick={() => setmodalcontantshow('step3selectoption')} >No</button>
                </div>
                <div className='backbuttonDiv'>
                    <button className='backButton' onClick={() => setmodalcontantshow('step3selectoption')}>Back</button>
                </div>
            </Modal.Body>



        </>
    );
}

export default EOLYesNo