import React, { useContext, useEffect, useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";
import { globalData } from "../CommonModal";
import '../Modal.css'
import { click } from "@testing-library/user-event/dist/click";

const EOLGcmStep8 = (props) => {

    const { handleClose, setmodalcontantshow,setFlowWithoutProff,steptwomodaldata,isGCM,fullNameofGcm,setEOlStep8Toallcall, setNotifyTypeId,setWaytoconnect } = useContext(globalData)
    // alert(props.step7illnesshomenext)
  useEffect(()=>{
    setEOlStep8Toallcall('Email')
  },[])
     
  
  const setContactType = (typeid, pageName,type) => {
    setNotifyTypeId(typeid);
    setWaytoconnect(type)
    setmodalcontantshow(pageName);
  }

  const backButton = (text) => {
    if (isGCM === 'true') {
      switch (text) {
        case 'IllnessHome':
          return 'illnesshomegoalstep7';
        case 'eolstep7':
          return 'eolgoalstep7';
        case 'NeedHelp':
          return 'eolneedhelpstep7';
        default:
          return 'illnesshospitalstep7';
      }
    }else{
      switch(text){
        case 'IllnessHospital':
        return 'illnesshospitalstep7'
        case 'IllnessHome':
          return 'illnesshomegoalstep7'
      }
      setFlowWithoutProff("")
    }
    
  };
  
  return (
    <>
      <Modal.Header style={{ backgroundColor: "#871a27" }}>
        <Row>
          <Col sm={11} md={10} lg={11}>
            <h3 className="heading">
              
              {(isGCM=='true') ? <>Step 8:  How would you like to contact {fullNameofGcm}, GCM?</>
            :<>Step 8: How would you like to contact Care Managar, GCM?  </>
            }

            </h3>
          </Col>
          <Col sm={1} md={2} lg={1}>
            <button
              className="closeButton"
              style={{ border: "1px solid #981b29" }}
              onClick={handleClose}
            >
              x
            </button>
          </Col>
        </Row>
      </Modal.Header>
      <Modal.Body>
        <h4 className="SelectOptionsh4Tag">Select option</h4>

        <div className="buttonDiv mt-4">
          <button className="emailButton" onClick={()=>setContactType(1, 'illnessemailtextpreview',"Email")}>Email</button>
          <button className="textButton"  onClick={()=>setContactType(2, 'illnessemailtextpreview',"Text")}>Text</button>
          {isGCM=='true' && 
          <button className="callButton"onClick={() =>setmodalcontantshow('eolgcmcontact')}>Call</button>}
          
        </div>
        <div>
          { (isGCM=='false') ? <h4 className='SelectOptionsh4Tag pt-4'>If {steptwomodaldata.memberFullName}'s GCM is not available, please click above to send email or text to your GCM.</h4>:''}
                    
    </div>
        <div className="backButtonDiv">

          {
            (isGCM =='true') ?<>
              <button className="backButton"  onClick={()=>setmodalcontantshow(backButton(props.step7illnesshomenext))}>Back</button>
          </>:(isGCM=='false') ?
          <>
          {(props.withOutGCMNeedHelpNext=='NeedHelp')?
          <button className="backButton"  onClick={()=>setmodalcontantshow('eolneedhelpstep7')}>Back</button>:
           (props.step7illnesshomenext =='IllnessHospital' || props.step7illnesshomenext =='IllnessHome') ? 
           <button className="backButton"  onClick={()=>setmodalcontantshow(backButton(props.step7illnesshomenext))}>Back</button>
         
         :<button className="backButton"  onClick={()=>setmodalcontantshow('eolgoalstep7')}>Back</button>
        }
          
          </> : ""        }
        
        </div>
      </Modal.Body>
     
    </>
  );
};

export default EOLGcmStep8;
