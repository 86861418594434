import React, { useState, useContext } from 'react';
import Modal from 'react-bootstrap/Modal';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { globalData } from '../CommonModal';

import '../Modal.css'
import "./Warning.css";
const IllnessHomeMedicalHelp = () => {
    const { handleClose, setmodalcontantshow, steptwomodaldata,getGCMprofessionalbyUserid,addManageStepToFollow,setGoBack} = useContext(globalData)
const sendNext = ()=>{
  // setmodalcontantshow('illnesshomegoalstep7')
  // addManageStepToFollow("illnessnotifysuccessor", "Home");
  setmodalcontantshow('illnessnotifysuccessor')
  // getGCMprofessionalbyUserid(7,true)
  setGoBack("HOME")
}

  return (
    <>
    
      <Modal.Header style={{ backgroundColor: "#871a27" }}>
      <Row   style={{height:"30px"}}>
          <Col sm={11}>
            <h3 className="heading">Step 5a: Next Step</h3>
          </Col>
          <Col sm={1}>
            <button
              className="closeButton"
              style={{ border: "1px solid #981b29" }}
              onClick={handleClose}
            >
              x
            </button>
          </Col>
        </Row>
        {/* <Modal.Title className='heading' id="example-modal-sizes-title-lg" style={{ color: 'white', fontFamily: "Inter",height: 30 }}>

        </Modal.Title> */}
        {/* closeButton */}

        {/* <button className='closeButton' style={{ border: "1px solid #981b29" }} onClick={handleClose}>x</button> */}

      </Modal.Header>
      <Modal.Body>

        <h4 className='SelectOptionsh4Tag text-center mb-4'>
          If medical help has not already been requested, please call 911.
        </h4>

        <div className='stepsevenbuttonsDiv'>
          <button className='backButton' onClick={()=>setmodalcontantshow('illnesshomemedical')}>
            Back</button>
          <button className='backButton' style={{backgroundColor:"#d79b48"}} onClick={()=>sendNext()}>
            Next</button>
        </div>  
      </Modal.Body>

   
    </>
  );
}

export default IllnessHomeMedicalHelp;