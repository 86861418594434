import React, { useContext, useState,useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { globalData } from '../CommonModal';
import Container from "react-bootstrap/Container"
import commonLib from '../../control/commonLib';
import {returnFname,returnRoleChangeOfAgents} from '../../control/Constant'
import "./Warning.css"
import "./Execute.css";
const Step4illness = () => {
  const { setillushospitalstep6help, setIsGCM, handleClose, setmodalcontantshow,primaryAgents, steptwomodaldata, addManageStepToFollow,setHospitalDetails,goNext,setGoNext,setGoBack,certifyAgent,allAgents,memberRankIdforPrimary} = useContext(globalData)
  const selectoption = (e) => {
    if (e == 'Hospital') {
      setmodalcontantshow("hospitalDetails")
      setGoNext("Hospital")
      addManageStepToFollow("setillushospitalstep6help", "Hospital");
    } else if (e == 'Home') {
      // setillushospitalstep6help('Home')
      setGoNext("Home")
      setmodalcontantshow('illnesshomemedical')
      addManageStepToFollow("setillushospitalstep6help", "Home");
    } else if (e == 'Other') {
      setmodalcontantshow("OtherSetting")
      // setillushospitalstep6help('Other')
      setGoNext("Other")
      addManageStepToFollow("setillushospitalstep6help", "Other");
    }

  }
  


  const clickbackbutton = () => {
    setIsGCM('false')
    let id = memberRankIdforPrimary
    if(certifyAgent == ""){
     setmodalcontantshow('elaNotify')
    }else if(certifyAgent == "illnessNo"){
      setmodalcontantshow('illnessMemberWarning')
    }else if(certifyAgent == "warning"){
       if(id == 1 || id == 2 || id == 3 || id == 4 || id == 5 || id == 6){
        setmodalcontantshow('step3selectoption')
      }else{
        setmodalcontantshow('illnesswarning')
      }
      
     
     
  }
}


  const customSelectOptions = (options) => {
    selectoption(options);
    setGoBack("step4illness")

  }
  

  return (
    <>
      <Modal.Header style={{backgroundColor: "#871a27" }}>
        <Row >
          <Col sm={11}>
            <h3 className='heading locateTitle'>
              Step 4: Where is {returnFname(steptwomodaldata.memberFullName)} located at this time?
            </h3>
          </Col>
          <Col sm={1} >
            <button className='closeButton' style={{ border: "1px solid #981b29" }} onClick={handleClose}>x</button>
          </Col>
        </Row>


        {/* closeButton */}

        <button className='closeButton' onClick={handleClose}>x</button>

      </Modal.Header>
      <Modal.Body>

        <Container>
          <h4 className='SelectOptionsh4Tag mb-4 mt-2'>

            Select option
          </h4>
          <div className='container'>
            <div className='row '>
              <div className='col-md-4 col-xs-12 col-md-12 col-lg-4 '>
                {/* <Card className="card" onClick={() => setmodalcontantshow("hospitalDetails")}> */}
                <Card className="card" onClick={() => customSelectOptions('Hospital')}>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <Card.Img className='mt-3' src="/hospital.png" style={{ height: 100, width: 120, }} ></Card.Img>
                  </div>
                  <Card.Body className='text-center mt-2 mt-2 mb-0 pb-0'>
                    <Card.Title style={{ opacity: "85%", fontSize: "24px", fontFamily: "Inter"}}>Hospital</Card.Title>

                  </Card.Body>


                </Card>
              </div><br></br>
              <div className='col-md-4 col-xs-12 col-md-12 col-lg-4 justify-content-around'>
                <Card onClick={() => customSelectOptions('Home')} >
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <Card.Img className='mt-3' variant="top" src="/home.png" style={{ height: 100, width: 120, }} />
                  </div>
                  <Card.Body className='text-center mt-2 mt-2 mb-0 pb-0'>
                    <Card.Title style={{ opacity: "85%", fontSize: "24px", fontFamily: "Inter"}}>Home</Card.Title>

                  </Card.Body>

                </Card>
              </div><br></br>
              <div className='col-md-4 col-xs-12 col-md-12 col-lg-4 justify-content-around'>
                {/* <Card onClick={() => setmodalcontantshow("OtherSetting")} > */}
                <Card onClick={() => customSelectOptions("Other")} >
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <Card.Img className='mt-3' variant="top" src="/Others.png" style={{ height: 100, width: 120, }} />
                  </div>
                  <Card.Body className='text-center mt-2 mt-2 mb-0 pb-0'>
                    <Card.Title style={{ opacity: "85%", fontSize: "24px", fontFamily: "Inter"}} >Other Setting</Card.Title>

                  </Card.Body>

                </Card>
              </div>
            </div>
          </div>
        </Container>

        <div className='backbuttonDiv'>
          <button className='backButton' onClick={clickbackbutton} >Back</button>
        </div>
      </Modal.Body>
     </>
  );
}

export default Step4illness;