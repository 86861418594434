import React, { useContext, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import '../Modal.css';
import { globalData } from '../CommonModal';
import {returnFname} from '../../control/Constant'

const EOLNotifySuccessorStep4 = () => {

    const { NotifyContinueCallApi, handleClose, setmodalcontantshow, steptwomodaldata, getGCMprofessionalbyUserid, memberRankIdforPrimary } = useContext(globalData)

    const backBtnWarning = () => {
        let id = memberRankIdforPrimary
        if (id == 1 || id == 2 || id == 3 || id == 4 || id == 5 || id == 6) {
            setmodalcontantshow('eolyesno')
        } else {

            setmodalcontantshow('eolwarning')
        }
    }


    return (
        <>





            <Modal.Header style={{ backgroundColor: "#871a27" }}>

                <Modal.Title className='mt-2' id="example-modal-sizes-title-lg">
                    <h3 className='heading text-center' >
                        Step 4: Notify Agents
                    </h3>
                </Modal.Title>
                {/* closeButton */}

                <button className='closeButton' style={{ border: "1px solid #981b29" }} onClick={handleClose}>x</button>

            </Modal.Header>
            <Modal.Body>
                <h3 className='SelectOptionsh4Tag mb-2 mt-3'>
                    You are required to notify all agents that you are taking over&nbsp;
                    {returnFname(steptwomodaldata.memberFullName)}'s financial affairs, health care affairs, or both.
                </h3>
                <h3 className='SelectOptionsh4Tag mb-2 mt-1'>
                    By clicking continue, a notification will be automatically sent to agents.
                </h3>


                <div className='buttonDiv '>
                    <button className='understandButton fw-bold' onClick={() => setmodalcontantshow("eolemailtextpreview")}>Continue</button>

                </div>
                <div className='backbuttonDiv'>
                    <button className='backButton' onClick={() => backBtnWarning()} >Back</button>
                </div>
            </Modal.Body>

        </>
    );
}

export default EOLNotifySuccessorStep4;