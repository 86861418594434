import React, { useState, useContext, useEffect,useRef } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { saveAs } from 'file-saver';
import Modal from "react-bootstrap/Modal";
import { globalData } from "../CommonModal";
import '../Modal.css'
import OccuranceCom from "../../OccuranceCom";
import commonLib from "../../control/commonLib";
import {returnFname,returnRoleChangeOfAgents,getBase64FromFile,returnAttorny,returnMemberEmail,loginUserEmail,isNotValidNullUndefinedfile,returnFileFormFileCabinate,returnBase64File} from '../../control/Constant'
import "./Warning.css";
import { removeDuplicateValue } from "../../control/Constant";
import Emailcreate from "./Emailcreate";
import Services from "../../Network/Services";
import CommonInput from "../../CommonInput";
import PdfViwer from "../../FileCabinet/PdfViwer";

const ElaNotify = () => {
    const {setnewAlert,primaryAgents,setSelectoptionId,handleClose,legalDoc, setmodalcontantshow,steptwomodaldata, NotifyContinueCallApi, setSelectoptionmessage,getGCMprofessionalbyUseridforLocate,agentData,agentRoleid,agentRole,setloader,certifyAgent,allAgents,lawFirmMail,elderlawAttorney,setCertifyAgent} = useContext(globalData)
    let primaryAgentnames = primaryAgents.length == 0 ? "" : primaryAgents.map((ele,index)=>{return (ele.length == 1 ? ele.fullName : `${" "+ele.fullName}${(primaryAgents.length - 1 === index) ? '' : ' '}`)})
    const [textareavalue, settextareavalue] = useState()
    const stateObj = commonLib.getObjFromStorage("stateObj")
    const [files, setFiles] = useState([]);
    const [viewBaseFile, setviewBaseFile] = useState()
    const [showDocumentView, setShowDocumentView] = useState(false)
    const [doeFiles, setDoeFiles] = useState()
    const [isActive, setIsActive] = useState(false)
    const [sendbuttondisabled, setbuttondesabled] = useState()
    const [uploadedFile, setUploadedFile] = useState(null)
    let userLoggedInDetails =JSON.parse(sessionStorage.getItem('userLoggedInDetail'))
    const loggedInUserId = userLoggedInDetails?.loggedUserId
    

    let users =  allAgents.length > 0 && allAgents.filter((ele)=>{
      return ele?.agentRoleId == 1 
       && stateObj?.userId == ele?.agentUserId
     })
     const userRankId = removeDuplicateValue(users).length > 0 &&removeDuplicateValue(users)[0].agentRankId

    let occurranceRef = useRef(null)
    useEffect(() => {
        callfunction(certifyAgent)
        getGCMprofessionalbyUseridforLocate(13, true)
    }, [certifyAgent,allAgents])

     const roleChange = async(users)=>{
        let primaryAgentsData = primaryAgents[0]
        
        let loginUserData = users[0]
        let date = new Date()
        let jsonobj = [
          {
            "agentId": primaryAgentsData.agentId,
            "agentUserId": primaryAgentsData?.agentUserId,
            "memberUserId": primaryAgentsData?.memberUserId,
            "agentRoleId": primaryAgentsData?.agentRoleId,
            "agentRankId": loginUserData?.agentRankId,
            "agentAcceptanceStatus": true,
            "agentActiveStatus": true,
            "agentEffectiveDate": date,
            "fileId": primaryAgentsData?.fileId,
            "legalDocId": primaryAgentsData?.legalDocId,
            "testDocId": primaryAgentsData?.testDocId,
            "testSupportDocId": primaryAgentsData?.testSupportDocId,
            "statusId": primaryAgentsData?.statusId,
            "upsertedBy": loginUserData?.updatedBy
          },
          {
            "agentId": loginUserData.agentId,
            "agentUserId": loginUserData?.agentUserId,
            "memberUserId": loginUserData?.memberUserId,
            "agentRoleId": loginUserData?.agentRoleId,
            "agentRankId": primaryAgentsData?.agentRankId,
            "agentAcceptanceStatus":true,
            "agentActiveStatus": true,
            "agentEffectiveDate": date,
            "fileId": loginUserData?.fileId,
            "legalDocId": loginUserData?.legalDocId,
            "testDocId": loginUserData?.testDocId,
            "testSupportDocId": loginUserData?.testSupportDocId,
            "statusId": loginUserData?.statusId,
            "upsertedBy": loginUserData?.updatedBy
          }
        ]
        const result = await returnRoleChangeOfAgents(jsonobj)
        window.location.reload()
        if (result == 'err')return;
      }

    const returnAlertmsg=()=>{
      
        if(userRankId == 7){
          return "primary agent ahead of @@NEWPRIMARYAGENT is"
        }else if(userRankId == 8){
         return "primary agent and first successor ahead of @@NEWPRIMARYAGENT are"
        }else{
          return "all agents ahead of @@NEWPRIMARYAGENT are"
        }


    }
     
    const callfunction = async(certifyAgent) => {
          if(isNotValidNullUndefinedfile(certifyAgent)){
              
           let texts = `This is an alert that @@NEWPRIMARYAGENTFULLNAME has certified that @@CLIENTFULLNAME is ill and unable to act on ${steptwomodaldata?.memberGenderId == 1 ? "his":"her"} own behalf. @@NEWPRIMARYAGENT has further certified that ${returnAlertmsg()} unable or unwilling to act as agent, for which reason @@NEWPRIMARYAGENT must take over the responsibilities. If you agree with this action nothing more is needed. If you disagree that @@AllAGENTSNAME cannot act as agents for @@CLIENTNAME, please notify the attorney of your concern, or reach out to @@NEWPRIMARYAGENT to let @@NEWPRIMARYAGENT know of your position. Thank you.`
           let text = texts.replaceAll("@@NEWPRIMARYAGENTFULLNAME",userLoggedInDetails.loginUserName).replaceAll("@@NEWPRIMARYAGENT",returnFname(userLoggedInDetails.loginUserName)).replaceAll("@@CLIENTFULLNAME",steptwomodaldata?.memberFullName).replaceAll("@@CLIENTNAME",returnFname(steptwomodaldata?.memberFullName)).replaceAll("@@AllAGENTSNAME",agentData?.length === 0 ? "(agents not available)" : (agentData.length > 0 && agentData.filter(ele=> ele?.statusId == 2)?.map((ele,index) => ele?.length == 1 ? ele?.fullName : `${""+ele.fullName}${(agentData?.length - 1 == index) ? '' : ''}` )))
            settextareavalue(text)
            setSelectoptionId(5)
            setSelectoptionmessage(text)
          }else{
              let text = `The current primary agent${primaryAgentnames != "" ? primaryAgentnames : "(not available)"} is not in a position to fulfill ${primaryAgents.length > 0 ?  (primaryAgents[0]?.agentUserGenderId == 1 ? "his":"her") :"his/her"} role for client, therefore I ${userLoggedInDetails.loginUserName} taking over the role of agent for ${steptwomodaldata?.memberFullName}. Please find the reason why the current Primary agent and the successor agents are not able to take over the role.`
              settextareavalue(text)
              setSelectoptionmessage(text)
             
              let fileData = await returnFileFormFileCabinate(loggedInUserId,setloader)
             
              let doeFile = fileData.length > 0 && fileData.filter(ele => ele?.fileTypeId == 40 && ele?.fileStatusId == 2 && ele?.belongsTo == steptwomodaldata?.memberUserId)
            
              if (doeFile.length > 0) {
                setDoeFiles(doeFile[0])
                let fileBase64Data = await returnBase64File(doeFile[0]?.fileId, setloader)
                const blob = await fetch(fileBase64Data).then((res) => res.blob());
                const file = new File([blob], `${"Durable Power of Attorney for Healthcare"}.pdf`, { type: "pdf" });
                setUploadedFile(file)
              } else {
                setIsActive(true)
  
              }
           
          }
          
     }
     const textarea = () => {
        return (
          <textarea className="form-control pt-0 ps-3 fs-4  text-dark  shadow-none border-0" rows="4" value={textareavalue} onChange={handleChange}>
          </textarea>
        )
    }
    const handleChange = (e) => {
        const enteredText = e.target.value;
        let updatedText = enteredText;
    
        if (enteredText.length > 0) {
          updatedText = enteredText.replace(/_(.)/g, '$1');
        }
    
        // setText(updatedText);
        settextareavalue(updatedText)
       
      
        
        setSelectoptionmessage(e.target.value)
    }
      const clicksend = () => {
        const stateObj = commonLib.getObjFromStorage("stateObj");
        let subtenantId = sessionStorage.getItem('subtenantId')
        let userLoggedInDetail =JSON.parse(sessionStorage.getItem('userLoggedInDetail'))
         if(agentRoleid == 2 || agentRole == 2){
          if(!isNotValidNullUndefinedfile(agentData)){
          }else{
            for(let i=0;i<=agentData.length;i++){
    
              const professUserData = {
                notifyText : textareavalue,
                userPhoneNo : agentData[i].agentMobileNo,
                usersUserId : agentData[i]?.memberUserId,
                userEmail :  agentData[i]?.agentEmailId,
                memberFullName :agentData[i]?.fullName,
                agentName : userLoggedInDetail?.loginUserName,
                agentEmail : loginUserEmail(),
                agentMobileNo : userLoggedInDetail?.primaryPhoneNumber,
                primaryMembername :steptwomodaldata?.memberFullName,
                type : "financeUser",
                
               } 
               if(stateObj.roleId == "5"){
                
                setloader(true)
                occurranceRef?.current?.calloccuranceIdfun(31, subtenantId,professUserData);
                setmodalcontantshow("financeOptions")
               }else{
                setmodalcontantshow("financeOptions")
               }
          
             
          }
          }
         
         }
          
         else{
             if(isNotValidNullUndefinedfile(certifyAgent)){
                if(stateObj.roleId == "5"){                
                    NotifyContinueCallApi()
                    setCertifyAgent("")
                }else{
                    setmodalcontantshow('illnessMemberWarning')
                }
                
            }else{
                if(stateObj.roleId == "5"){
                    if(!isNotValidNullUndefinedfile(uploadedFile)){
                      setnewAlert({type: "warning", text: "Please attach your document"});
                      setTimeout(() => {
                          setnewAlert({type: "", text: ""})
                        }, 3000)
                    }else{
                      mailToElderLawAttorny()
                    }
                    
                }else{
                    setmodalcontantshow('step4illness')
                }
                
              
            }
          
          
           }
        setbuttondesabled(true)
        setTimeout(() => {
          setbuttondesabled(false)
        }, 5000)
        // setmodalcontantshow('step5illness')
    
    
      }
      const clicksendlater = () =>{
        if(isNotValidNullUndefinedfile(certifyAgent)){
            setCertifyAgent("")
        }else{
             setmodalcontantshow('step4illness')
        }
        
     }
      const clicknotifysend = () => {
        NotifyContinueCallApi()
        setbuttondesabled(true)
        setTimeout(() => {
          setbuttondesabled(false)
        }, 5000)
        // setmodalcontantshow('eolneedhelpstep9')
      }
      
      const backLatter =()=>{

         if(isNotValidNullUndefinedfile(certifyAgent)){
            setmodalcontantshow("illnessMemberWarning")
         }else{
            setCertifyAgent("illnessYes")
         }
       
     }
      const FileUpload =(e)=>{
        const files = e.target.files[0];
        setUploadedFile(files);
       }
        

      const mailToElderLawAttorny = async()=>{
        setloader(true) 
        let membererId = await returnMemberEmail(steptwomodaldata?.memberUserId)
        setloader(false) 
        let text = `Dear Team<br/>\n${textareavalue}<br/><br/>\nRegards<br/> ${returnFname(userLoggedInDetails.loginUserName)}`
        let subtenantId = sessionStorage.getItem('subtenantId')
        const ettornyId = await returnAttorny(subtenantId,true,13)
        let formdata = new FormData();
       
        formdata.append("CreatedBy",loggedInUserId)
        formdata.append("Emailcc", `${userLoggedInDetails?.primaryEmailId},${membererId[0]?.emailId}`)
        formdata.append("files" , legalDoc)
        formdata.append("files" , uploadedFile)
        formdata.append("EmailType" , "SavePdfData")
        formdata.append("EmailTo" ,elderlawAttorney.length > 0 && isNotValidNullUndefinedfile(elderlawAttorney) ? elderlawAttorney[0]?.emaidIds : ettornyId.join(','))
        formdata.append("EmailSubject" ,"Email for agent certification")
        formdata.append("EmailContent", text)
        formdata.append("EmailTemplateId" , 1)
        formdata.append("EmailStatusId" , 1)
        formdata.append("EmailMappingTable" , "tblUsers")
        formdata.append("EmailMappingTablePKId" , stateObj?.loggenInId)
        setloader(true) 
      
      let result = Services.PostEmailMultiFiles(formdata)
       result.then((res) => {
        const stateObj = commonLib.getObjFromStorage("userLoggedInDetail");
        let users =  allAgents.length > 0 && allAgents.filter((ele)=>{
            return ele?.agentRoleId == 1  && stateObj?.loggedUserId == ele?.agentUserId
           })
           roleChange(users)   
           setloader(false)
 
        setnewAlert({type: "success", text: "Email sent successfully"});
        setTimeout(() => {
            setnewAlert({type: "", text: ""})
          }, 3000)
       
    }).catch((err) => {
        setloader(false)
    }).finally(() => {
        setloader(false)
    })
      }   

   const toggleInputType=()=>{
    setUploadedFile("")
    setIsActive(true)
    
   }   
   const inputStyle = {
    width: isActive ? '90%' : '100%', // Ternary operator to toggle width based on isActive state
  };
    
  const view = async(text)=>{ 
    if(text == "view"){
      let fileBase64Data = await returnBase64File(doeFiles?.fileId,setloader)
      setviewBaseFile(fileBase64Data)
      setShowDocumentView(true)
    }else if(text == "downloads"){
       let fileBase64Data = await returnBase64File(doeFiles?.fileId,setloader)
       if(isNotValidNullUndefinedfile(fileBase64Data)){
          const printWindow = window.open('', '_blank');
        printWindow.document.write('<iframe src="' + fileBase64Data + '" width="100%" height="100%"></iframe>');
        printWindow.document.close();
        setTimeout(() => {
          setloader(false)
         
        }, 2000); // Adjust the delay as needed 
        }
    
    }else{
      saveAs(legalDoc, 'Agent Certification-Details');
    }
    
   
    
    
   
  }
  const truncateFileName = (fileName,maxLength) => {
    if (fileName.length > maxLength) {
      return fileName.substring(0, 20) + '...';
    }
    return fileName;
  };
    return (
    <>
      <Modal.Header style={{ backgroundColor: "#871a27" }}>
        <Row style={{ height: "30px" }}>
          <Col sm={11}>
            <h3 className="heading">{isNotValidNullUndefinedfile(certifyAgent) ? "Alert Message" : "Successor Agent Preview"}</h3>
          </Col>
          <Col sm={1}>
            <button className="closeButton"style={{ border: "1px solid #981b29" }}onClick={handleClose}> x</button>
          </Col>
        </Row>
      </Modal.Header>
      <Modal.Body>
        <div className="form-outline border border-2 rounded fs-4">
            {isNotValidNullUndefinedfile(certifyAgent) && certifyAgent === "illnessYes"  ? <Emailcreate/> : ""}
            
            <label className="mb-0 ps-3  text-secondary">{isNotValidNullUndefinedfile(certifyAgent) ? `${agentData.length > 0 && agentData.length < 2 ? agentData.map(ele => `Dear ${ele.fullName}`) : "Dear all"}` : "Dear Team"}</label>
           {textarea()}
           <div className="d-flex justify-content-between">
           <h6 className="ps-3 textData mb-1">{returnFname(userLoggedInDetails.loginUserName)}</h6>
           {isNotValidNullUndefinedfile(certifyAgent) ? "" : 
           <>
           <div className="d-flex justify-content-end mb-2 me-1" style={{width:"70%"}}>
            <input className="me-1" type="text"defaultValue={legalDoc?.name}style={{width:"50%"}}disabled="true"/>
            <div style={{ position: 'relative'}}>
             <a style={{display:"flex",cursor:"pointer",position: 'absolute', top: '7px',right: '8px',borderRadius: '50%',height: '20px',fontSize: "medium",alignItems:"baseline"}} onClick={()=>view("download")} ><img title='Download & Print' src="/icons/printIcon.svg"className='smallIconSize' alt="Download & Print"/> </a>
               </div>
         
           {isActive == true ? <>
            <input className=""type="file"style={{width:"50%"}}onChange={(e)=>FileUpload(e,"2")}/>
           </>:<>
            <div style={{ position: 'relative', width: '50%' }}>
            <input className ="" type='text'defaultValue={isNotValidNullUndefinedfile(doeFiles?.fileTypeName) ? truncateFileName(doeFiles?.fileTypeName,20) : ""} disabled="true"style={{ ...inputStyle }}onChange={(e) => FileUpload(e, '2')}/>
            <div style={{display:"flex",cursor:"pointer",position: 'absolute', top: '7px',right: '8px',borderRadius: '50%',height: '20px',fontSize: "medium",alignItems:"baseline"}} >
            <a className="me-1" style={{cursor:"pointer",borderRadius: '50%',height: '20px',fontSize: "medium"}} onClick={()=>view("downloads")} ><img title='Download & Print' src="/icons/printIcon.svg"className='smallIconSize' alt="Download & Print"/> </a> 

            <a className="me-1" onClick={()=>view("view")} ><img title='View' src="/icons/viewIcon.svg"className='smallIconSize' alt="View"/> </a>
            <button  className="ms-1" title="Remove"  onClick={toggleInputType} style={{borderRadius: '50%',width: '19px', height: '19px',backgroundColor: '#76272B',color: 'white',border: 'none', cursor: 'pointer',fontSize: "12px"}} > X</button>
            
            </div>
            
            </div>
           </> }
           </div>
           
           </>
           
                
           }
           </div>
           
       

        </div>




        
            <div className='stepsevenbuttonsDiv'>
              <button className="backButton" onClick={() => backLatter()} >
                Back
              </button>
              {stateObj.roleId !== "5" && 
              <button className='laterButton' onClick={clicksendlater} disabled={sendbuttondisabled} >
                Send Later & Proceed</button>}
              <button className='backButton' onClick={clicksend} disabled={sendbuttondisabled} >
                Send</button>
            </div>
        


      </Modal.Body>
           <Modal centered={true} size="lg" show={showDocumentView} backdrop="static" >
                <Modal.Header style={{ backgroundColor: "#871a27" }}>
                    <Row>
                        <Col sm={11}>
                            <h3 className='heading'>
                                Document View
                            </h3>
                        </Col>
                        <Col sm={1} >
                            <button className='closeButton' style={{ border: "1px solid #981b29" }} onClick={() => setShowDocumentView(false)}>x</button>
                        </Col>
                    </Row>
                </Modal.Header>
                <Modal.Body>
                <PdfViwer base64File={viewBaseFile} />
                </Modal.Body>
               
            </Modal>
    </>
  )
}

export default ElaNotify