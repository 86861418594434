import React, { useState, useContext, useEffect } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Card from 'react-bootstrap/Card';
import "../components/ShowProfess.css"
import Container from "react-bootstrap/Container"
import Modal from "react-bootstrap/Modal";
import { globalData } from "./Execute/CommonModal";
import { aorgLinkFunc, isNotValidNullUndefinedfile } from "./control/Constant";
import Confirmation from "./AgentInvitation/Confirmation";
import commonLib from "./control/commonLib";

const ShowPreofessionlDetails = ({ showGcmModal, setShowGcmModal, professData, Confirmations, professType, isProfess, singleUserData }) => {
    const {setallProfessionals,steptwomodaldata,setWarningText,setwarninngMsg, modalcontantshow, addManageStepToFollow, stepsToFollow, getGCMprofessionalbyUserid, setloader } = useContext(globalData)
    const usersPerPage = 2;
    const totalUsers = professData?.length || 0;
    const [showConfrimation, setshowConfrimation] = useState(false)
    const totalPages = Math.ceil(totalUsers / usersPerPage);
    const [currentPage, setCurrentPage] = useState(0);
    const [userData, setUserData] = useState(null)
    const [selectedCards, setSelectedCards] = useState([]);
    const nextPage = () => { setCurrentPage(prevPage => (prevPage < totalPages - 1 ? prevPage + 1 : prevPage)); };
    const prevPage = () => { setCurrentPage(prevPage => (prevPage > 0 ? prevPage - 1 : prevPage)); };
    const displayedUsers = professData?.slice(currentPage * usersPerPage, (currentPage + 1) * usersPerPage) || [];

    const openWindow = () => {
        localStorage.setItem("keypoints", JSON.stringify(stepsToFollow));
        const serviceType = professType === "Elder law attorney" ? "Legal" : "health";
        window.location.replace(aorgLinkFunc(serviceType, modalcontantshow, professType === "Elder law attorney" ? 13 : 7, steptwomodaldata));
    };
    const clicknextcontactbutton = () => {
        let nextText = `IllnessHospital${professType == "Elder law attorney" ? "ELA":"GCM"}`
        if(selectedCards.length > 0){
            Confirmations(nextText)
            setallProfessionals(selectedCards)
        }else{
            setwarninngMsg(true)
            setWarningText("Please select at least one professional")
           setTimeout(() => {
                setwarninngMsg(false)
              }, 3000)
        }
        
      }
    // const addProfessionals = async (data) => {
    //     setUserData(data)
    //     setshowConfrimation(true)
    // }
    // const addUserProffess = (text) => {
    //     if (text == "No") {
    //         setshowConfrimation(false)
    //     } else {
    //         // Confirmations(userData, setshowConfrimation)
    //     }

    // }
    const addProfessionals = (selectedProfessional) => {
        const isSelected = selectedCards.some(card => card.proCatId === selectedProfessional.proCatId);
       if (isSelected) {
          setSelectedCards(prevSelected => prevSelected.filter(card => card.proCatId !== selectedProfessional.proCatId
            ));
        } else {
          setSelectedCards(prevSelected => [...prevSelected, selectedProfessional]);
        }
      };

    const numberFormate = (number) => {
        const lastTenDigitArray = array => array.slice(-10);
        return commonLib.convertToUSFormat(lastTenDigitArray(number), "1")
    }
    return (
        <>
            <Modal centered={true} size="lg" show={showGcmModal} backdrop="static" >
                <Modal.Header style={{ backgroundColor: "#871a27" }}>
                    <Row>
                        <Col sm={11}> <h3 className='heading'>{professType}</h3> </Col>
                        <Col sm={1} ><button className='closeButton' style={{ border: "1px solid #981b29" }} onClick={() => setShowGcmModal(false)}>x</button></Col>
                    </Row>
                </Modal.Header>
                <Modal.Body>
                    {isProfess == "true" || isProfess == true ? <>
                        <Container style={{ marginBottom: "5rem", marginTop: "5rem" }}>
                            <div className='d-flex justify-content-center'>
                                <div className='me-3' >
                                    <Card className="card" style={{ width: "19rem", height: "15rem" }}>
                                        <div style={{ display: "flex", justifyContent: "center" }}>
                                            <Card.Img className='mt-3' src="/Person.png" style={{ width: "50px" }} ></Card.Img>
                                        </div>
                                        <Card.Body className='text-center mt-2 mt-2 mb-0 pb-0'>
                                            <Card.Title style={{ opacity: "85%", fontSize: "24px", fontFamily: "Inter", }}>{commonLib.showFullName(singleUserData)}</Card.Title>
                                            <div className="dataField">Email: {singleUserData?.emaidIds ?? "Not available"}</div>
                                            <div className="dataField">Cell No: {isNotValidNullUndefinedfile(singleUserData?.mobileNumbers) ? (numberFormate(singleUserData?.mobileNumbers)) : "Not available"}</div>
                                            {/* <div className="dataField">Address: {ele?.addresses.find(ele => ele?.addressTypeId === 1)?.addressLine1 ?? "Not available"}</div> */}
                                        </Card.Body>
                                    </Card>
                                </div>
                            </div>

                        </Container>
                    </> : <>
                        <Container style={{ marginBottom: "1.5rem", marginTop: "5rem" }}>
                            <div className="mainContainer justify-content-center">
                                {totalPages > 1 && currentPage > 0 ? (
                                    <button className="nextButton me-3 mt-auto mb-auto" onClick={prevPage}>{"<"}</button>
                                ) : <button className="nextButton me-2 mt-auto mb-auto" style={{ visibility: "hidden" }}>{"<"}</button>}
                                {displayedUsers.length > 0 && displayedUsers.map(ele => (
                                    <>
                                        <div className="justify-content-center" onClick={() => addProfessionals(ele)}>
                                            <div className='me-3' >
                                                
                                                <Card className="card" style={{ width: "19rem", height: "17rem" }}>
                                                {selectedCards.some(card => card.proCatId === ele.proCatId) ? <div className="d-flex justify-content-end"><img src="/Select.png"style={{ width: "20px" }}/></div>:""}
                                                    <div style={{ display: "flex", justifyContent: "center" }}>
                                                        <Card.Img className='mt-3' src="/Person.png" style={{ width: "50px" }} ></Card.Img>
                                                    </div>
                                                    <Card.Body className='text-center mt-2 mt-2 mb-0 pb-0'>
                                                        <Card.Title style={{ opacity: "85%", fontSize: "24px", fontFamily: "Inter", }}>{ele.proFullName}</Card.Title>
                                                        <div className="dataField">Email: {ele?.proPrimaryEmail ?? "Not available"}</div>
                                                        <div className="dataField">Cell No: {isNotValidNullUndefinedfile(ele?.proPrimaryContact) ? numberFormate(ele?.proPrimaryContact) : "Not available"}</div>
                                                        <div className="dataField">Address: {ele?.addresses.find(ele => ele?.addressTypeId === 1)?.addressLine1 ?? "Not available"}</div>
                                                    </Card.Body>
                                                </Card>
                                            </div>
                                        </div>
                                    </>
                                ))}
                                {totalPages > 1 && currentPage < totalPages - 1 ? (
                                    <button className="nextButton mt-auto mb-auto" onClick={nextPage}>{">"}</button>
                                ) : (
                                    <button className="nextButton mt-auto mb-auto" style={{ visibility: "hidden" }} onClick={openWindow}>Show more</button>
                                )}
                            </div>
                            {totalPages > 1 && currentPage < totalPages - 1 ? "" :
                                <div className="container d-flex justify-content-end"><button className="nextButton" style={{ width: "auto", padding: "7px", marginRight: "3.8rem" }} onClick={openWindow}>Show more</button></div>
                            }
                            {isProfess == "true" && isProfess == true ? '' : <>
                            <div className="d-flex justify-content-center"><button className="healthButton"onClick={()=>clicknextcontactbutton()}>Contact {professType}</button>
                           </div>
                            </>}
                         
                        </Container>
                        {/* {showConfrimation == true && <Confirmation handleClickStatus={addUserProffess} text={`Are you sure that you want to add ${userData?.proFullName} as a ${professType}?`} />} */}

                    </>}

                </Modal.Body>
            </Modal>
        </>
    )
}

export default ShowPreofessionlDetails