import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom';
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import konsole from '../Network/konsole';
import Services from '../Network/Services';
import HeaderInvitation from './HeaderInvitation';
import Confirmation from './Confirmation';
import config from '../config.json';
import { Button } from 'react-bootstrap';
import { AoLoginBaseUrl } from '../Network/url';
import { isNotValidNullUndefinedfile } from '../control/Constant';

const AgentList = () => {
    const { state } = useLocation();
    const logoUrl = state?.response[0]?.fileURL
    const responseData = state?.response[0]
    const subtenant = state?.response[0]
    const [showmessage, setShowmessage] = useState('Thank you for accepting the role so that you have access to the documents and the information you will need to fulfill your responsibilities as the agent you need to set up your account. Please Activate your account using the Activate Button.')
    //state---------------------------------------------------------------------------------------------------------------------
    const [isLoading, setisLoading] = useState(false)
    const [userAgentData, setuserAgentData] = useState([])
    const [checkallisSelectorNot, setcheckallisSelectorNot] = useState(true)
    const [checkAccept, setcheckAccept] = useState(false)
    const [subtanantId, setsubtanantId] = useState('')
    const [showactivatebtn, setshowactivatebtn] = useState(true)
    const [checkifoneselect, setcheckifoneselect] = useState(true)
    const [declinepoput,setdeclinepopup] = useState(false)
    const [declinerolecheck,setdeclinerolecheck]=useState(false)

    //predefinefunction---------------------------------------------------------------------------------------------------------------------
    useEffect(() => {
        setcheckAccept(state?.checkAccept)
        if (isNotValidNullUndefinedfile(subtenant?.subtenantId)) {
            getAgentDetails(subtenant?.subtenantId)
            setsubtanantId(subtenant?.subtenantId)
        }
    }, [subtenant])

    //fun---------------------------------------------------------------------------------------------------------------------
    const getAgentDetails = (subtenant) => {
        setisLoading(true)
        let jsonobj = { "agentUserId": responseData?.userId, "agentActiveStatus": true}
        //  statusId: '1' };
        konsole.log("JSONOBJ",JSON.stringify(jsonobj))
        let result = Services.getUserAgent(jsonobj)
        result.then((res) => {
            konsole.log("getUserAgent", res?.data?.data)
            setisLoading(false)
            let dataresult=res.data?.data.filter(item => item.statusId !==2)
            konsole.log("dataresultdataresult",dataresult)
            let responseData = dataresult
            for (let [index, item] of responseData.entries()) {
                responseData[index]['isSelected'] = true
            }
            setuserAgentData(responseData)
            let checkallisSelectorNot = responseData?.every(obj => obj.isSelected === true);
            let checkifoneselect = responseData?.every(obj => obj.isSelected == false);
            setcheckallisSelectorNot(checkallisSelectorNot)
            setcheckifoneselect(checkifoneselect)
        }).catch((err) => {
            konsole.log("getUserAgent", err)
            setisLoading(false)
        }).finally(() => {
            konsole.log("getUserAgent")
        })
    }
    const isSelectAdd = (value, type, index) => {
        let data = userAgentData
        konsole.log("datadatadatadatadata", data)
        if (type === 'ALLSELECT') {
            for (let [index, item] of data.entries()) {
                data[index].isSelected = value
            }
        } else if (type == 'SEPRATE') {
            data[index].isSelected = value
        }
        setuserAgentData(data)
        let checkallisSelectorNot = data?.every(obj => obj.isSelected === true);
        let checkifoneselect = data?.every(obj => obj.isSelected == false);
        setcheckallisSelectorNot(checkallisSelectorNot)
        setcheckifoneselect(checkifoneselect)
    }

    const createjsonforupsert = (item, type) => {
        let { agentId, agentUserId, memberUserId, agentRoleId, agentRankId, fileId, legalDocId, testDocId, testSupportDocId } = item;
        let agentEffectDate = new Date()
        return {
            "agentId": agentId,
            "agentUserId": agentUserId,
            "memberUserId": memberUserId,
            "agentRoleId": agentRoleId,
            "agentRankId": agentRankId,
            "agentAcceptanceStatus": (type == 'Accept') ? true : false,
            "agentActiveStatus": true,
            "agentEffectiveDate": agentEffectDate,
            "fileId": fileId,
            "legalDocId": legalDocId,
            "testDocId": testDocId,
            "testSupportDocId": testSupportDocId,
            "statusId": (type == 'Accept') ? 2 : 3,
            "upsertedBy": responseData?.userId
        }
    }
    const handleClickDeclineOrAccept = (type) => {
        setshowactivatebtn(false)
        setisLoading(true)
        let data = userAgentData.filter(item => item.isSelected == true)
        let agentSaveObj = [];
        for (let [index, item] of data.entries()) {
            agentSaveObj.push(createjsonforupsert(item, type))
        }
        setisLoading(true)
        let result = Services.upsertUserAgent(agentSaveObj)
        result.then((res) => {
            konsole.log("upsertUserAgent", res)
            getAgentDetails(state?.response[0]?.subtenant)
            setisLoading(true)
            if (type !== 'Accept') {
                setdeclinepopup(true)
                setShowmessage(`Thank you for letting us know you will not be able to perform the role we will notify ${userAgentData[0]?.memberFullName} Thank you for your consideration.`)
            } else {
                setShowmessage('You will now receive one time password(OTP) on your cellphone. Please enter the OTP(one time password) on the following screen.')

            }
            setisLoading(true)
            if (type === 'Accept' && userAgentData[0].isAgentActiveUser === false) {
                setTimeout(() => {
                    setisLoading(true)
                    agentActivationUserByfun()
                }, 4000)
            }
            else if (type === 'Accept' && userAgentData[0].isAgentActiveUser === true) {
                window.location.replace(`${AoLoginBaseUrl}account/Signin?subtenantId=${subtanantId}`);
            }else{
                setdeclinerolecheck(true)
                setTimeout(()=>{      
                   window.location.replace(`${AoLoginBaseUrl}account/Signin?subtenantId=${subtanantId}`);
                },5000)
                // setisLoading(false)

            }
        }).catch((err) => {
            setisLoading(false)
            konsole.log("upsertUserAgent", err)
        }).finally(() => {
            konsole.log("upsertUserAgent")
            // setisLoading(false)
        })
    }

    const agentActivationUserByfun = () => {
        setisLoading(true)
        let jsonobj = {
            "subtenantId": subtanantId,
            "signUpPlateform": 10,
            "userId": responseData?.userId,
            "roleId": 5,
            "activateUser": false,
            "createdBy": responseData?.userId
        }
        let results = Services.agentActivationUserByUserId(jsonobj)
        results.then((res) => {
            konsole.log("agentActivationUserByUserId", res)
            let responseData = res.data.data
            setisLoading(true)
            window.location.replace(responseData.activationLink);
            // setisLoading(false)
        }).catch((err) => {
            konsole.log("agentActivationUserByUserId", err)
            setisLoading(false)
        }).finally(() => {
            konsole.log("agentActivationUserByUserId")
            // setisLoading(false)
        })
    }
    //---------------------------------------------------------------------------------------------------------------------
    const handleClickStatus = (msg) => {
        setcheckAccept(false)
        if (msg == 'Yes') {
            handleClickDeclineOrAccept('Decline')
        }
    }


    return (
        <>
            <div >
                {(isLoading == true)? <div className=" w-100 h-100 d-flex align-items-center justify-content-center position-absolute overLayLoader" style={{ backgroundColor: "rgba(0,0,0,0.5)" }}>
                    <div className="spinner-border" role="status">
                    </div>
                </div>:null}
            </div>
            {(isNotValidNullUndefinedfile(logoUrl)) &&
                <>
                    {(checkAccept && userAgentData.length > 0) && <Confirmation handleClickStatus={handleClickStatus} text="Are you sure you want to decline the roles/role assigned to you?" />}
                    <HeaderInvitation logourl={logoUrl} />
                    <div className={`${checkAccept == true && "d-none"} container mt-5`}>
                        {/* <p><b>{userAgentData[0]?.memberFullName}</b>  has choosen you as an agent for various Roles below.</p> */}
                        {(userAgentData.length == 1  && declinerolecheck == false ) ?
                            <><h2>Hi, <b>{responseData?.memberName}</b></h2>
                                <p>Thank you for playing the role of fiduciary, {userAgentData[0]?.memberFullName} has named you  please make your selection below.</p>
                                <p className='text-danger'> <b>Note*:</b> As an agent whatever role/roles you check will be accepted & unchecked would not be accepted.</p>
                            </> : (userAgentData.length > 1) ? <>
                                <h2>Hi, <b>{responseData?.memberName}</b></h2>
                                <p>Thank you for playing the role of fiduciary, {userAgentData[0]?.memberFullName} has named you in more than one position, please make your selection below.</p>
                                <p className='text-danger'> <b>Note*:</b> As an agent whatever role/roles you check will be accepted & unchecked would not be accepted.</p>
                            </> : null}

                        {(userAgentData.length > 0 && declinerolecheck == false) ?
                            <>
                                <Table bordered responsive="sm md lg xl" className="text-center" >
                                    <thead className=''>
                                        <tr>
                                            <th><Form.Check inline type='checkbox' checked={checkallisSelectorNot} value="checked" onChange={(e) => isSelectAdd(e.target.checked, "ALLSELECT")}></Form.Check>Select All</th>
                                            <th>Legal Document</th>
                                            <th>Role</th>
                                            <th>Order</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {(userAgentData.length > 0 && userAgentData.map((item, index) => {
                                            let { agentRank, agentRole, legalDocName, testDocName, testSupportDocName, isSelected } = item;
                                            let documentName = (testDocName == null && testSupportDocName == null) ? legalDocName : (testDocName == null && testSupportDocName !== null) ? testSupportDocName : testDocName;
                                            konsole.log("isSelectisSelect", isSelected
                                            )
                                            return (
                                                <tr>
                                                    <td><Form.Check inline type='checkbox' checked={isSelected} onChange={(e) => isSelectAdd(e.target.checked, "SEPRATE", index)} /></td>
                                                    <td>{documentName}</td>
                                                    <td>{agentRole}</td>
                                                    <td>{agentRank}</td>
                                                </tr>
                                            )
                                        }))}
                                    </tbody>
                                </Table>
                                {(checkifoneselect == false) ?
                                    <div className='d-flex justify-content-end mt-2'>
                                        {/* <button type="button" className="btn me-2 " style={{ color: "#720C20", border: "2px solid #720C20" }} onClick={() => handleClickDeclineOrAccept('Decline')}>Decline {(checkallisSelectorNot) && 'All'} </button> */}
                                        <button type="button" className="btn" style={{ backgroundColor: "#720C20", color: "white" }} onClick={() => handleClickDeclineOrAccept('Accept')}>Accept {(checkallisSelectorNot) && 'All'} </button>
                                    </div> : null}

                            </>
                            : null}


                    </div>
                    {((userAgentData.length == 0 || declinepoput==true )  && isLoading==false) &&
                        <div className='row no-gutters m-0 p-0 justify-content-center align-items-center flex-column' style={{ height: "85vh" }}  >
                            <div className='col-5' style={{ padding: "10px", boxShadow: "0 10px 15px -3px rgba(0,0,0,0.1), 0 4px 6px -4px rgba(0,0,0,0.1)", border: "2px solid #ECF0F1" }}>
                                <h4 style={{ fontFamily: "Inter", color: "#7B7D7D" }}>{showmessage}
                                    {/* Thank you for  accepting the role so that you have access to the documents and the information you  will need to fulfill your responsibilities as   the agent you need to set up your account. Please Activate your account using the Activate Button. */}
                                </h4>

                            </div>
                            {(showactivatebtn == true) ?
                                <div className='col-5 mt-3 d-flex justify-content-center align-items-center'>
                                    <button style={{ backgroundColor: "#720c20", color: "white", border: "none", padding: "10px", borderRadius: "5px", fontWeight: "500" }} onClick={() => agentActivationUserByfun()}>Activate Account</button>
                                </div> : null}
                        </div>
                    }
                </>
            }
        </>
    )
}
export default AgentList