import React, { useState, useContext, useEffect, useRef } from "react";
import { Form, Button, InputGroup, DropdownButton, Dropdown } from 'react-bootstrap';
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Modal from "react-bootstrap/Modal";
import { globalData } from "../CommonModal";
import '../Modal.css'
import OccuranceCom from "../../OccuranceCom";
import commonLib from "../../control/commonLib";
import { returnFname,loginUserEmail,isNotValidNullUndefinedfile} from '../../control/Constant'
import "./Warning.css";
import Emailcreate from "./Emailcreate";
import Services from "../../Network/Services";
import EOLNeedYesUnanimous from "../EndOfLife/EOLNeedYesUnanimous";
const IllnessEmailTextPreview = (props) => {
  const { illushospitalstep6help,flowWithoutProff,postsendnotifytoGCMApi,setFlowWithoutProff,step7illnesshomenext,isGCM, handleClose, setmodalcontantshow, steptwomodaldata, NotifyContinueCallApi, setSelectoptionmessage, selectoptionId, getGCMprofessionalbyUserid, getGCMprofessionalbyUseridforLocate, gcmDetails, agentData, agentRoleid, wayToconnect, agentRole, setIsGCM, setloader, hospitalDetails, settingDetails, goBack, goNext, setillushospitalstep6help,primaryAgents,certifyAgent} = useContext(globalData)
  const [textareavalue, settextareavalue] = useState()
  const [sendbuttondisabled, setbuttondesabled] = useState()
  const [emails, setEmails] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const stateObj = commonLib.getObjFromStorage("stateObj");
  let primaryAgentname = primaryAgents.length == 0 ? "" : primaryAgents.map((ele,index)=>{return (ele.length == 1 ? ele.fullName : `${" "+ele.fullName}${(primaryAgents.length - 1 === index) ? '' : ' '}`)})
  let primaryAgentData = primaryAgents.length > 0 ? primaryAgents[0] : ""
  

  let userLoggedInDetails = JSON.parse(sessionStorage.getItem('userLoggedInDetail'))
  let occurranceRef = useRef(null)
  useEffect(() => {
    const string = "We are sorry to report that {{userName}}'s health condition is not well now. We join you in your prayers for a quick recovery of {{userName}} health."
    // const stringDischarne = "We are sorry to report that {{userName}}'s health condition is not well currently which required hospitalization. {{userName}} requires help with discharge from hospital. Kindly visit the hospital in order to make appropriate arrangements."
    const nonProfessionalHospital = "My name is {{agentName}}. I need assistance with helping {{CLINTNAME}}, discharged from the hospital back home instead of a care facility. I understand you help with these types of issues. Or help me locate a good housing option so {{CLINTNAMES}} does not experience repeated moves. If you are able to help please call or text me at {{agentnumber}} or email me at {{agentEmail}}. I look forward to hearing back from you." 
    const stringDischarnes = "My name is {{userName}}. I am the agent of your client, {{CLINTNAME}}. I have been directed to contact you for help in getting {{HIM}} discharged back home, if at all possible. {{gender}} is at {{HOSPITALNAME}} {{ROOMNUMBER}} {{CITY}}. Please contact the hospital immediately and start the discharge process. If you have questions you can call or text me at {{agentnumber}} or email me at {{agentEmail}}, Thank you for your assistance."
    const stringDischarne = "My name is {{userName}}. I am the agent of your client, {{CLINTFULLNAME}}. I have been directed to contact you for help in getting {{CLINTNAME}} discharged back home, if possible. {{CLINTNAME}} is at {{HOSPITALNAME}} {{ROOMNUMBER}} {{CITY}}. Please contact the hospital immediately and start the discharge process. If you have questions, you can call or text me at {{agentnumber}} or email me at {{agentEmail}}. Thank you for your assistance"
    const stringCare = "My name is {{userName}}. I am the agent of your client, {{CLINTNAME}}. I have been directed to contact you for care with the hospital to get the best suitable care possible. {{gender}} is at {{HOSPITALNAME}} {{ROOMNUMBER}} {{CITY}}. Please contact the hospital immediately and start the care process. If you have questions you can call or text me at {{agentnumber}} or email me at {{agentEmail}}, Thank you for your assistance."
    // const stringCare = "We are sorry to report that {{userName}}'s health condition is not well currently and had required hospitalization. Sunil will require help for his care in the hospital. Kindly visit the hospital in order to make appropriate arrangements."
    const Home = "We are sorry to report that {{userName}}'s health condition is not well currently and is at home. Request your help organizing appropriate care for {{userNames}}."
    const Other = !isNotValidNullUndefinedfile(settingDetails?.settingName) ? "My name is {{userName}}. I am the agent of your client, {{CLINTNAME}}.I have been directed to provide the required care and help if at all possible.If you have questions you can call or text me at {{agentnumber}} or email me at {{agentEmail}}, Thank you for your assistance" :
    "My name is {{userName}}. I am the agent of your client, {{CLINTNAME}}.I have been directed to provide the required care and help if at all possible. {{CLINTNAMES}} is at (othersettingname),which provided (Typesoffacility).Please contact the (othersettingname) by calling on (Phonenumberprovided) immediately and assist George getting the required support and treatment.If you have questions you can call or text me at {{agentnumber}} or email me at {{agentEmail}}, Thank you for your assistance." 

    // const Other = "We are sorry to report that {{userName}}'s health condition is not well currently and is staying at Other setting. Request your help to organize appropriate care for {{userName}}."
    const EndOfLife = "We are sorry to be the bearers of sad news, {{userName}}'s health condition is very serious. This is an intimation of this condition to obtain a second opinion as there is no unanimous consent from the family and successors regarding the fulfillment . "
    const finance = "We are writing to let you know that {{userName}} is not in a condition to manage {{HIM}} finances. We will keep you updated."
    const manageHealth = "We are writing to let you know that {{userName}} is dealing with a mental illness and hence unable to make a rational decision. We are praying for a quick recovery. We will keep you updated.";
    // "My name is Buzz Allen. I am the agent of your client, Barry. I have been directed to contact you for help in getting Barry discharged back home, if at all possible. If you have questions you can call or text me at +1 (620) 441-3269 or email me at buzzallen@mailinator.com, Thank you for your assistance"
 
    if (illushospitalstep6help == "Discharge" || illushospitalstep6help == "Care" || illushospitalstep6help == "Home" || illushospitalstep6help == "Other" || illushospitalstep6help == "EndOfLife" || illushospitalstep6help == "finance") {
      callfunction(string, stringDischarne, stringCare, Home, Other, EndOfLife, finance, manageHealth,nonProfessionalHospital)
         
     }
    else {
      firstApi(string, stringDischarne, stringCare, Home, Other, EndOfLife, finance, manageHealth)

    }

  }, [])


  async function firstApi(string, stringDischarne, stringCare, Home, Other, EndOfLife, finance, manageHealth) {

    const responseEmail = await getNotifyMessageapi(1, 1, selectoptionId,Other,Home);
    const responseText = await getNotifyMessageapi(2, 1, selectoptionId,Other,Home);
    if (responseText !== "resolve" && responseEmail !== "resolve") {
      callfunction(string, stringDischarne, stringCare, Home, Other, EndOfLife, finance, manageHealth)
    }
  }

  function getCountryCode(mobileNumber) {
    const digitsOnly = mobileNumber.replace(/\D/g, '');

    if (digitsOnly.startsWith('1')) {

      return '1';
    }
    if (digitsOnly.startsWith('91')) {
      return '91';
    }
    return null;
  }
  const getNotifyMessageapi = (notifyTypeId, contactNatureId, notifyConditionId, Other, Home) => {
    setloader(true);
    return new Promise((resolve, reject) => {
      const userId = steptwomodaldata.memberUserId;
      Services.getNotifyMessageapi(userId, notifyTypeId, contactNatureId, notifyConditionId)
        .then((res) => {
          setloader(false);
          if (res.data.data.length > 0) {
            let text = "";
            const name = res.data.data[0].notifyText;
            if (!["Discharge", "Care", "Home", "Other"].includes(illushospitalstep6help)) {
              switch (goNext) {
                case "Hospital":
                  text = `Letting you know that ${returnFname(steptwomodaldata.memberFullName)} is unwell. ${steptwomodaldata?.memberGenderId === 1 ? "He" : "She"} is at ${hospitalDetails?.hospitalName ? hospitalDetails.hospitalName : "the hospital"}${hospitalDetails?.roomNumber ? " in room " + hospitalDetails.roomNumber : ""}${hospitalDetails?.county ? " in " + hospitalDetails.county : ""}. Will keep you posted about ${returnFname(steptwomodaldata.memberFullName)}'s progress as I know of it. No need to come now. I will let you know if your presence would be helpful. You are always welcome to stop anytime.`;
                  break;
                case "Other":
                  text = Other.replaceAll('{{userName}}', userLoggedInDetails?.loginUserName)
                    .replaceAll("{{CLINTNAME}}", steptwomodaldata.memberFullName)
                    .replaceAll("{{agentnumber}}",certifyAgent === "illnessNo" ? userLoggedInDetails?.primaryPhoneNumber ? numberFormate(userLoggedInDetails.primaryPhoneNumber) : "(Mobile number not available)" : (isNotValidNullUndefinedfile(primaryAgentData?.agentMobileNo) == true ? primaryAgentData?.agentMobileNo : "(Mobile number not available)"))
                    .replaceAll("{{agentEmail}}",certifyAgent === "illnessNo" ? loginUserEmail() : primaryAgentData?.agentEmailId)
                    .replaceAll("{{gender}}", steptwomodaldata.memberFullName)
                    .replaceAll('{{CLINTNAMES}}', returnFname(steptwomodaldata.memberFullName))
                    .replaceAll("{{GENDER}}", steptwomodaldata.memberFullName)
                    .replaceAll("{{HIM}}", steptwomodaldata.memberFullName)
                    .replaceAll("(othersettingname)", !settingDetails?.settingName ? "Setting name not available" : settingDetails.settingName)
                    .replaceAll("(Phonenumberprovided)", !settingDetails?.phoneNumber ? "(Mobile number not available)" : numberFormatee(settingDetails.phoneNumber))
                    .replaceAll("(Typesoffacility)", !settingDetails?.facilityType ? 'facility type not available' : (settingDetails.facilityType === "other" && settingDetails.other ? settingDetails.other : settingDetails.facilityType));
                  break;
                case "Home":
                  text = Home.replaceAll('{{userName}}', steptwomodaldata.memberFullName).replaceAll('{{userNames}}', returnFname(steptwomodaldata.memberFullName));
                  break;
                default:
                  text = name;
              }
            }
            settextareavalue(text);
            setSelectoptionmessage(text);
            resolve("resolve");
          }
        })
        .catch((err) => {
          resolve("reject");
          let text = "";
          if (!["Discharge", "Care", "Home", "Other"].includes(illushospitalstep6help) && goNext === "Hospital") {
            text = `Letting you know that ${returnFname(steptwomodaldata.memberFullName)} is unwell. ${steptwomodaldata?.memberGenderId === 1 ? "He" : "She"} is at ${hospitalDetails?.hospitalName ? hospitalDetails.hospitalName : "the hospital"}${hospitalDetails?.roomNumber ? " in room " + hospitalDetails.roomNumber : ""}${hospitalDetails?.county ? " in " + hospitalDetails.county : ""}. Will keep you posted about ${returnFname(steptwomodaldata.memberFullName)}'s progress as I know of it. No need to come now. I will let you know if your presence would be helpful. You are always welcome to stop anytime.`;
          } else if (!["Discharge", "Care", "Home", "Other"].includes(illushospitalstep6help) && goNext === "Other") {
            text = Other.replaceAll('{{userName}}', userLoggedInDetails?.loginUserName)
              .replaceAll("{{CLINTNAME}}", steptwomodaldata.memberFullName)
              .replaceAll("{{agentnumber}}",certifyAgent === "illnessNo" ? userLoggedInDetails?.primaryPhoneNumber ? numberFormate(userLoggedInDetails.primaryPhoneNumber) : "(Mobile number not available)" :(isNotValidNullUndefinedfile(primaryAgentData?.agentMobileNo) == true ? primaryAgentData?.agentMobileNo : "(Mobile number not available)"))
              .replaceAll("{{agentEmail}}", certifyAgent === "illnessNo" ? loginUserEmail() : primaryAgentData?.agentEmailId)
              .replaceAll("{{gender}}", steptwomodaldata.memberFullName)
              .replaceAll('{{CLINTNAMES}}', returnFname(steptwomodaldata.memberFullName))
              .replaceAll("{{GENDER}}", steptwomodaldata.memberFullName)
              .replaceAll("{{HIM}}", steptwomodaldata.memberFullName)
              .replaceAll("(othersettingname)", !settingDetails?.settingName ? "Setting name not available" : settingDetails.settingName)
              .replaceAll("(Phonenumberprovided)", !settingDetails?.phoneNumber ? "(Mobile number not available)" : numberFormatee(settingDetails.phoneNumber))
              .replaceAll("(Typesoffacility)", !settingDetails?.facilityType ? 'facility type not available' : (settingDetails.facilityType === "other" && settingDetails.other ? settingDetails.other : settingDetails.facilityType));
          } else if (!["Discharge", "Care", "Home", "Other"].includes(illushospitalstep6help) && goNext === "Home") {
            text = Home.replaceAll('{{userName}}', steptwomodaldata.memberFullName).replaceAll('{{userNames}}', returnFname(steptwomodaldata.memberFullName));
          }
          settextareavalue(text);
          setSelectoptionmessage(text);
          setloader(false);
        });
    });
  }

const numberFormate = (number) => {
    const lastTenDigitArray = array => array.slice(-10);
    return commonLib.convertToUSFormat(lastTenDigitArray(number), '1')


  }
  const numberFormatee = (number) => {
    const lastTenDigitArray = array => array.slice(-10);
    let countryCode = getCountryCode(number)
    if (countryCode == '1') {
      return commonLib.convertToUSFormat(lastTenDigitArray(number), countryCode)
    } else {
      return commonLib.convertToIndiaFormat(lastTenDigitArray(number), countryCode);
    }

  }

  const callfunction = (string, stringDischarne, stringCare, Home, Other, EndOfLife, finance, manageHealth,nonProfessionalHospital) => {
    let userLoggedInDetail = JSON.parse(sessionStorage.getItem('userLoggedInDetail'))

    if (illushospitalstep6help == "Discharge" || "Care") {
        if(isGCM == 'true'){
      let newstr = stringDischarne.replaceAll('{{userName}}',userLoggedInDetail?.loginUserName).replaceAll("{{CLINTFULLNAME}}",steptwomodaldata.memberFullName).replaceAll("{{CLINTNAME}}", returnFname(steptwomodaldata.memberFullName)).
        replaceAll("{{agentnumber}}",certifyAgent === "illnessNo" ? userLoggedInDetail?.primaryPhoneNumber ? (numberFormate(userLoggedInDetail?.primaryPhoneNumber)):"(Mobile number not available)" :(isNotValidNullUndefinedfile(primaryAgentData?.agentMobileNo) == true ? primaryAgentData?.agentMobileNo : "(Mobile number not available)")).replaceAll("{{agentEmail}}",certifyAgent === "illnessNo" ? loginUserEmail() : primaryAgentData?.agentEmailId).
        replaceAll("{{gender}}", returnFname(steptwomodaldata.memberFullName)

          // steptwomodaldata?.memberGenderId == 1 ? "He" :steptwomodaldata?.memberGenderId == 2 ? "She":"He/She"
        ).replaceAll('{{HIM}}', returnFname(steptwomodaldata.memberFullName)).replaceAll("{{GENDER}}", returnFname(steptwomodaldata.memberFullName)
          // steptwomodaldata?.memberGenderId == 1 ? "he" :steptwomodaldata?.memberGenderId == 2 ? "she":"He/She"
        ).
        // replaceAll("{{HOSPITALNAME}} {{ROOMNUMBER}} {{CITY}}",hospitalDetails?.hospitalName == "" ? "[Details Not Available]" : `${hospitalDetails?.hospitalName ?? `${hospitalDetails?.hospitalName}`}${hospitalDetails?.roomNumber ?? `/${hospitalDetails?.roomNumber}`}${hospitalDetails?.county ?? `/${hospitalDetails?.county}`}`)
        replaceAll("{{HOSPITALNAME}}", !isNotValidNullUndefinedfile(hospitalDetails?.hospitalName) ? "the hospital" : (hospitalDetails?.hospitalName)).
        replaceAll("{{ROOMNUMBER}} ", !isNotValidNullUndefinedfile(hospitalDetails?.hospitalName) ? "" : !isNotValidNullUndefinedfile(hospitalDetails?.roomNumber) ? "" : `,in room ${hospitalDetails?.roomNumber}`).
        replaceAll("{{CITY}}", !isNotValidNullUndefinedfile(hospitalDetails?.hospitalName) ? "" : !isNotValidNullUndefinedfile(hospitalDetails?.county) ? " " : `, in ${hospitalDetails?.county}`)
      settextareavalue(newstr)
      setSelectoptionmessage(newstr)
    }else{
      let newstr = nonProfessionalHospital.replaceAll("{{agentName}}",userLoggedInDetails.loginUserName).replaceAll("{{CLINTNAME}}",steptwomodaldata.memberFullName).replaceAll("{{agentnumber}}",certifyAgent === "illnessNo" ? (userLoggedInDetail?.primaryPhoneNumber ? (numberFormate(userLoggedInDetail?.primaryPhoneNumber)):"(Mobile number not available)") :(isNotValidNullUndefinedfile(primaryAgentData?.agentMobileNo) == true ? primaryAgentData?.agentMobileNo : "(Mobile number not available)")).replaceAll("{{agentEmail}}", certifyAgent === "illnessNo" ? loginUserEmail() : primaryAgentData?.agentEmailId).replaceAll("{{CLINTNAMES}}",returnFname(steptwomodaldata.memberFullName))
      settextareavalue(newstr)
      setSelectoptionmessage(newstr)
    }

    // }
    //  else if (illushospitalstep6help == "Care") {
    //   if(isGCM == 'true'){
    //     let newstr = stringCare.replaceAll('{{userName}}', userLoggedInDetail?.loginUserName).replaceAll("{{CLINTNAME}}", steptwomodaldata.memberFullName).
    //     replaceAll("{{agentnumber}}", userLoggedInDetail?.primaryPhoneNumber ? (numberFormate(userLoggedInDetail?.primaryPhoneNumber)) : "not available").replaceAll("{{agentEmail}}", loginUserEmail()).
    //     replaceAll("{{gender}}", returnFname(steptwomodaldata.memberFullName)
    //       // steptwomodaldata?.memberGenderId == 1 ? "He" :steptwomodaldata?.memberGenderId == 2 ? "She":"He/She"
    //     ).
    //     replaceAll("{{GENDER}}", returnFname(steptwomodaldata.memberFullName)
    //       // steptwomodaldata?.memberGenderId == 1 ? "his" :steptwomodaldata?.memberGenderId == 2 ? "her":"his/her"
    //     ).
    //     replaceAll("{{HOSPITALNAME}}",!isNotValidNullUndefinedfile(hospitalDetails?.hospitalName) ? "[Details Not Available]" : (hospitalDetails?.hospitalName)).
    //     replaceAll("{{ROOMNUMBER}} ", !isNotValidNullUndefinedfile(hospitalDetails?.hospitalName) ? "" : !isNotValidNullUndefinedfile(hospitalDetails?.roomNumber) ? "" : `, Room Number-${hospitalDetails?.roomNumber}`).
    //     replaceAll("{{CITY}}",        !isNotValidNullUndefinedfile(hospitalDetails?.hospitalName) ? "" : !isNotValidNullUndefinedfile(hospitalDetails?.county) ? "" : `, ${hospitalDetails?.county}`)
    //   settextareavalue(newstr)
    //   setSelectoptionmessage(newstr)
    //   }
    //   else{
    //     let newstr = nonProfessionalHospital.replaceAll("{{agentName}}",userLoggedInDetails.loginUserName).replaceAll("{{CLINTNAME}}",steptwomodaldata.memberFullName).replaceAll("{{agentnumber}}",userLoggedInDetail?.primaryPhoneNumber ? (numberFormate(userLoggedInDetail?.primaryPhoneNumber)):"not available").replaceAll("{{agentEmail}}",loginUserEmail()).replaceAll("{{CLINTNAMES}}",returnFname(steptwomodaldata.memberFullName))
    //     settextareavalue(newstr)
    //     setSelectoptionmessage(newstr)
    //   }
      

    } else if (illushospitalstep6help == "Home" || goNext == "Home") {
      if(isGCM == 'true'){
        let newstr = Home.replaceAll('{{userName}}',steptwomodaldata.memberFullName).replaceAll('{{userNames}}',returnFname(steptwomodaldata.memberFullName))
        settextareavalue(newstr)
        setSelectoptionmessage(newstr)
      }else{
        let newstr = nonProfessionalHospital.replaceAll("{{agentName}}",userLoggedInDetails.loginUserName).replaceAll("{{CLINTNAME}}",steptwomodaldata.memberFullName).replaceAll("{{agentnumber}}",certifyAgent === "illnessNo" ? (userLoggedInDetail?.primaryPhoneNumber ? (numberFormate(userLoggedInDetail?.primaryPhoneNumber)):"(Mobile number not available)"):(isNotValidNullUndefinedfile(primaryAgentData?.agentMobileNo) == true ? primaryAgentData?.agentMobileNo : "(Mobile number not available)")).replaceAll("{{agentEmail}}",certifyAgent === "illnessNo" ? loginUserEmail() : primaryAgentData?.agentEmailId).replaceAll("{{CLINTNAMES}}",returnFname(steptwomodaldata.memberFullName))
        settextareavalue(newstr)
        setSelectoptionmessage(newstr)
      }
      
    } else if (illushospitalstep6help == "Other" || goNext == "Other"){
      if(isGCM == 'true'){
        let newstr = Other.replaceAll('{{userName}}', userLoggedInDetail?.loginUserName).replaceAll("{{CLINTNAME}}", steptwomodaldata.memberFullName).
        replaceAll("{{agentnumber}}", certifyAgent === "illnessNo" ? (userLoggedInDetail?.primaryPhoneNumber ? (numberFormate(userLoggedInDetail?.primaryPhoneNumber)) : "(Mobile number not available)") : (isNotValidNullUndefinedfile(primaryAgentData?.agentMobileNo) == true ? primaryAgentData?.agentMobileNo : "(Mobile number not available)")).replaceAll("{{agentEmail}}",certifyAgent === "illnessNo" ? loginUserEmail() : primaryAgentData?.agentEmailId).
        replaceAll("{{gender}}", steptwomodaldata.memberFullName).replaceAll('{{CLINTNAMES}}',returnFname(steptwomodaldata.memberFullName)
          // steptwomodaldata?.memberGenderId == 1 ? "He" :steptwomodaldata?.memberGenderId == 2 ? "She":"He/She"
        ).replaceAll("{{GENDER}}", steptwomodaldata.memberFullName
          // steptwomodaldata?.memberGenderId == 1 ? "his" :steptwomodaldata?.memberGenderId == 2 ? "her":"his/her"
        ).replaceAll("{{HIM}}", steptwomodaldata.memberFullName).
        replaceAll("(othersettingname)", !isNotValidNullUndefinedfile(settingDetails?.settingName) ? "Setting name not available" : settingDetails?.settingName).replaceAll("(Phonenumberprovided)", !isNotValidNullUndefinedfile(settingDetails?.phoneNumber) ? "(Mobile number not available)" : (numberFormatee(settingDetails?.phoneNumber))).
        replaceAll("(Typesoffacility)", isNotValidNullUndefinedfile(settingDetails?.facilityType) ?
          (settingDetails?.facilityType == "other" && isNotValidNullUndefinedfile(settingDetails?.other) ? settingDetails?.other : settingDetails?.facilityType == "other" && !isNotValidNullUndefinedfile(settingDetails?.other) ? "facility type not available" : settingDetails?.facilityType) : 'facility type not available')
 
      settextareavalue(newstr)
      setSelectoptionmessage(newstr)
      }else{
          let newstr = nonProfessionalHospital.replaceAll("{{agentName}}",userLoggedInDetails.loginUserName).replaceAll("{{CLINTNAME}}",steptwomodaldata.memberFullName).replaceAll("{{agentnumber}}",certifyAgent === "illnessNo" ? (userLoggedInDetail?.primaryPhoneNumber ? (numberFormate(userLoggedInDetail?.primaryPhoneNumber)):"(Mobile number not available)"):(isNotValidNullUndefinedfile(primaryAgentData?.agentMobileNo) == true ? primaryAgentData?.agentMobileNo : "(Mobile number not available)")).replaceAll("{{agentEmail}}",certifyAgent === "illnessNo" ? loginUserEmail() : primaryAgentData?.agentEmailId).replaceAll("{{CLINTNAMES}}",returnFname(steptwomodaldata.memberFullName))
          settextareavalue(newstr)
          setSelectoptionmessage(newstr)
        
      }
    
    } else if (illushospitalstep6help == "EndOfLife") {
      let newstr = EndOfLife.replaceAll('{{userName}}', steptwomodaldata.memberFullName)
      settextareavalue(newstr)
      setSelectoptionmessage(newstr)
    } else if (illushospitalstep6help == "finance") {
      let replaceStr = finance.replaceAll('{{userName}}', returnFname(steptwomodaldata.memberFullName)).
        replaceAll("{{HIM}}", returnFname(steptwomodaldata.memberFullName)
          // steptwomodaldata?.memberGenderId == 1 ? "his" :steptwomodaldata?.memberGenderId == 2 ? "her":"his/her"
        )
      settextareavalue(replaceStr)
      setSelectoptionmessage(replaceStr)


    } else if (illushospitalstep6help == "MentalHealth") {
      let replaceStr = manageHealth.replaceAll('{{userName}}', returnFname(steptwomodaldata.memberFullName))
      settextareavalue(replaceStr)
      setSelectoptionmessage(replaceStr)
    } else {
      let newstr = string.replaceAll('{{userName}}', returnFname(steptwomodaldata.memberFullName))
      if (illushospitalstep6help != "Discharge" && illushospitalstep6help != "Care" && illushospitalstep6help != "Home" && illushospitalstep6help != "Other") {
        if (goNext == "Hospital") {
          let text = `Letting you know that ${returnFname(steptwomodaldata.memberFullName)} is unwell. ${steptwomodaldata?.memberGenderId == 1 ? "He":"She"} is at ${!isNotValidNullUndefinedfile(hospitalDetails?.hospitalName) ? "the hospital" : "" + hospitalDetails?.hospitalName}${ !isNotValidNullUndefinedfile(hospitalDetails?.hospitalName) ? "" : !isNotValidNullUndefinedfile(hospitalDetails?.roomNumber) ? "" : " in room " + hospitalDetails?.roomNumber}${!isNotValidNullUndefinedfile(hospitalDetails?.hospitalName) ? "" : !isNotValidNullUndefinedfile(hospitalDetails?.county) ? "" : " in " + hospitalDetails?.county}. Will keep you posted about ${returnFname(steptwomodaldata.memberFullName)}'s progress as I know of it. No need to come now. I will let you know if your presence would be helpful. You are always welcome to stop anytime.`

          // const hospitalText = `${newstr} ${returnFname(steptwomodaldata.memberFullName)} is at ${!isNotValidNullUndefinedfile(hospitalDetails?.hospitalName) ? "[Details Not Available]" : "Hospital Name-" + hospitalDetails?.hospitalName}${!isNotValidNullUndefinedfile(hospitalDetails?.hospitalName) ? "" :!isNotValidNullUndefinedfile(hospitalDetails?.roomNumber) ? "" : ", Room Number-" + hospitalDetails?.roomNumber}${!isNotValidNullUndefinedfile(hospitalDetails?.hospitalName) ? "" : !isNotValidNullUndefinedfile(hospitalDetails?.county) ? "" : ", Address-" + hospitalDetails?.county}`;
          settextareavalue(text)
          setSelectoptionmessage(text)
        } else {
          settextareavalue(newstr)
          setSelectoptionmessage(newstr)
        }

      }else{
      settextareavalue(newstr)
      setSelectoptionmessage(newstr)
      }

     

    }

  }




  const handleChange = (e) => {
    const enteredText = e.target.value;
    let updatedText = enteredText;

    if (enteredText.length > 0) {
      updatedText = enteredText.replace(/_(.)/g, '$1');
    }
    settextareavalue(updatedText)



    setSelectoptionmessage(e.target.value)
  }
  const textarea = () => {
    return (
      <textarea className="form-control pt-0 ps-3 fs-4  text-dark  shadow-none border-0" rows="4" value={textareavalue} onChange={handleChange}>
      </textarea>
    )
  }





  const clicksend = async() => {
    const stateObj = commonLib.getObjFromStorage("stateObj");
    let subtenantId = sessionStorage.getItem('subtenantId')
    let userLoggedInDetail = JSON.parse(sessionStorage.getItem('userLoggedInDetail'))
    if (agentRoleid == 2 || agentRole == 2) {
      if (!isNotValidNullUndefinedfile(agentData)) {
        setmodalcontantshow("financeOptions")
      } else {
        for (let i = 0; i <= agentData.length; i++) {

          const professUserData = {
            notifyText: textareavalue,
            userPhoneNo: agentData[i].agentMobileNo,
            usersUserId: agentData[i]?.memberUserId,
            userEmail: agentData[i]?.agentEmailId,
            memberFullName: agentData[i]?.fullName,
            agentName: userLoggedInDetail?.loginUserName,
            agentEmail: certifyAgent === "illnessNo" ? loginUserEmail() : primaryAgentData?.agentEmailId,
            agentMobileNo: certifyAgent === "illnessNo" ? (userLoggedInDetail?.primaryPhoneNumber ): (isNotValidNullUndefinedfile(primaryAgentData?.agentMobileNo) == true ? primaryAgentData?.agentMobileNo : "(Mobile number not available)"),
            primaryMembername: steptwomodaldata?.memberFullName,
            type: "financeUser",

          }
          if (stateObj.roleId == "5") {
            setloader(true)
            occurranceRef?.current?.calloccuranceIdfun(31, subtenantId, professUserData);
            setmodalcontantshow("financeOptions")
          } else {
            setmodalcontantshow("financeOptions")
          }


        }
      }

    }

    else {
      if (isGCM == "true") {
        if (illushospitalstep6help == "MentalHealth") {
          NotifyContinueCallApi()
        } else {
          postsendnotifytoGCMApi()
        }

      } else {
        if(flowWithoutProff == "IllnessHospitalGCM"){
          postsendnotifytoGCMApi()
        }else{
          setloader(true)
         await getGCMprofessionalbyUserid(7, true)
        await NotifyContinueCallApi()
        }
        
      }

    }
    setbuttondesabled(true)
    setTimeout(() => {
      setbuttondesabled(false)
    }, 5000)
    // setmodalcontantshow('step5illness')


  }
  const clicksendlater = async() => {
    if (isGCM == 'true') {
      if (illushospitalstep6help == "MentalHealth") {
        setmodalcontantshow("mentalHealthYesOrNo")
      } else if (agentRoleid == 2 || agentRole == 2) {
        setmodalcontantshow("financeOptions")
      }
      else {
        setloader(true)
      await getGCMprofessionalbyUserid(7, true)
     await getGCMprofessionalbyUseridforLocate(13, true);
        setmodalcontantshow('eolneedhelpstep9')
      }

    } else {
      if (agentRoleid == 2 || agentRole == 2) {
        setmodalcontantshow("financeOptions")
      } else {
        if (illushospitalstep6help == "MentalHealth") {
          setmodalcontantshow("mentalHealthYesOrNo")
        } else {
          if(flowWithoutProff == "IllnessHospitalGCM" ||  flowWithoutProff == "IllnessHospitalELA"){
            setmodalcontantshow('eolneedhelpstep9')
          }else{
            setloader(true)
           await getGCMprofessionalbyUserid(7, true)
           await getGCMprofessionalbyUseridforLocate(13, true);
            setillushospitalstep6help(goNext)
            if (goNext == "Home" || goNext == "Other") {
            setmodalcontantshow('illnesshomegoalstep7')
            } else if (goNext == "Hospital") {
            setmodalcontantshow('illnesshospitalstep6')
          }
          }
         
        }

        // }

      }
    }
  }
  const clicknotifysend = () => {
    NotifyContinueCallApi()
    setbuttondesabled(true)
    setTimeout(() => {
      setbuttondesabled(false)
    }, 5000)
    // setmodalcontantshow('eolneedhelpstep9')
  }
  const sendBack = () => {
    if (agentRoleid == 2 || agentRole == 2) {
      setmodalcontantshow("financenotifysuccessor")
    }
    else {
      if (illushospitalstep6help == "MentalHealth") {
        setmodalcontantshow('mentalHealthNotifySuccessor')
      } else {
        setmodalcontantshow('eolgcmstep8')
      }
    }

  }
  const backLatter = () => {
    if (goBack === "illnessnotifysuccessor" && illushospitalstep6help !== "Discharge" && illushospitalstep6help !== "Care" && illushospitalstep6help !== "Home" && illushospitalstep6help !== "Other" ) {
      setmodalcontantshow("illnessnotifysuccessor");
    } else if (agentRoleid == 2 || agentRole == 2) {
      setmodalcontantshow("financenotifysuccessor");
    } else if (illushospitalstep6help === "Discharge" || illushospitalstep6help === "Care" || illushospitalstep6help === "Home" || illushospitalstep6help === "Other") {
      setFlowWithoutProff("");
      setmodalcontantshow('eolgcmstep8');
    } else {
      setmodalcontantshow('illnessnotifysuccessor');
    }
  };
  const handleChangeEmail = (e) => {
    const enteredText = e.target.value;
    let updatedText = enteredText;

    if (enteredText.length > 0) {
      updatedText = enteredText.replace(/_(.)/g, '$1');
    }

    // setText(updatedText);
    setEmails(updatedText)



    // setSelectoptionmessage(e.target.value)

  }


  return (
    <>
      <Modal.Header style={{ backgroundColor: "#871a27" }}>
        <Row style={{ height: "30px" }}>
          <Col sm={11}>
            <h3 className="heading">Successor Agent Preview</h3>
          </Col>
          <Col sm={1}>
            <button
              className="closeButton"
              style={{ border: "1px solid #981b29" }}
              onClick={handleClose}
            >
              x
            </button>
          </Col>
        </Row>
      </Modal.Header>
      <Modal.Body>
        <div className="form-outline border border-2 rounded fs-4">
          {illushospitalstep6help == "Illness" || !isNotValidNullUndefinedfile(illushospitalstep6help) || goNext !== "Hospital" && goNext !== "Home" && goNext !== "Other" ? <><Emailcreate/></> :""}
         <label className="mb-0 ps-3  text-secondary">Dear User</label>
          {textarea()}
          
{/*          
          <div className="d-flex" style={{border:"1px dotted"}}>
          <div  style={{ display: 'flex', flexWrap: 'wrap',width:"100%" }}>
        {emails.map((email, index) => (
          <div key={index} style={{ display: 'flex', alignItems: 'center', margin: '5px', padding: '2px', background: '#f0f0f0', borderRadius: '5px',height: "max-content",fontFamily:"initial"}}>
            <span  className="ms-1 me-2">{email}</span>
            <span className="align-self-center">
            <img   src= 'close.png' className='' style={{cursor:"pointer",height:"0.9rem",marginTop:"-29px"}}onClick={() => setEmails(emails.filter((_, i) => i !== index))}></img>
            </span>
          </div>
        ))}
        <textarea className="form-control pt-0 ps-3 fs-4  text-dark  shadow-none border-0" rows="4"
              type="text"
              value={inputValue}
              onChange={handleInputChange}
              onKeyDown={handleKeyDown}
              placeholder={emails.length == 0 ? "Enter an email separated by commas" : ""}
              style={{ width: '100%', padding: '8px', border: '1px solid #ccc', borderRadius: '4px', fontSize: '16px' }}
            />
      </div>
            
          </div> */}
          <h6 className="ps-3 textData mb-1">{certifyAgent === "illnessNo" ? returnFname(userLoggedInDetails.loginUserName) : primaryAgentname}</h6>
          {/* <h6 className="ps-3 textData">{userLoggedInDetails?.primaryEmailId}</h6>
          <h6 className="ps-3 textData mb-1">{numberFormatee(userLoggedInDetails?.primaryPhoneNumber)}</h6> */}



        </div>




        {
          (isGCM == 'true') ?
            <div className='stepsevenbuttonsDiv'>
              <button className="backButton" onClick={() => sendBack()} >
                Back
              </button>
              {stateObj.roleId !== "5" && 
              <button className='laterButton' onClick={clicksendlater} disabled={sendbuttondisabled} >
                Send Later & Proceed</button>}
              <button className='backButton' onClick={clicksend} disabled={sendbuttondisabled} >
                Send</button>
            </div>

            :
            <div className='stepsevenbuttonsDiv'>
              <button className="backButton" onClick={() => backLatter()} >
                Back
              </button>
              {stateObj.roleId !== "5" && 
              <button className='laterButton' onClick={clicksendlater} disabled={sendbuttondisabled} >
                Send Later & Proceed</button>
                } 
              <button className='backButton' onClick={clicksend} disabled={sendbuttondisabled} >
                Send</button>
            </div>
        }


      </Modal.Body>
      <OccuranceCom ref={occurranceRef} />
    </>
  )
}
export default IllnessEmailTextPreview;