import React, { useContext, useEffect, useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";
import { globalData } from "../Execute/CommonModal";
import { aorgLink, aorgLinkFunc } from "../control/Constant";

const MentalHealthGCM = (props) => {

    const { handleClose, setmodalcontantshow, steptwomodaldata, isGCM, fullNameofGcm, setEOlStep8Toallcall, setNotifyTypeId, stepsToFollow, modalcontantshow } = useContext(globalData)
  

    useEffect(() => {
        setEOlStep8Toallcall('Email')
    }, [])


    const setContactType = (typeid, pageName) => {
        setNotifyTypeId(typeid);
        setmodalcontantshow(pageName);
    }

    const  openWindow = () => {
        localStorage.setItem("keypoints", JSON.stringify(stepsToFollow));
        window.location.replace(aorgLinkFunc("health", modalcontantshow, 7, steptwomodaldata));
      }

    return (
        <>
            <Modal.Header style={{ backgroundColor: "#871a27" }}>
                <Row>
                    <Col sm={11} md={10} lg={11}>
                        <h3 className="heading">
                            Goal and Steps
                        </h3>
                    </Col>
                    <Col sm={1} md={2} lg={1}>
                        <button className="closeButton" style={{ border: "1px solid #981b29" }} onClick={handleClose}
                        >
                            x
                        </button>
                    </Col>
                </Row>
            </Modal.Header>
            <Modal.Body>
                <>
                    <div className="d-flex justify-content-center flex-column" style={{ height: "100px" }}>
                        <h4 className="SelectOptionsh4Tag mb-4 mt-2">{steptwomodaldata.memberFullName} does not have any geriatric care manager. Please click below to search for geriatric care manager.</h4>
                        <div className="text-center">
                            <a onClick={openWindow} target="_blank">
                                <button className="healthButton">
                                    Find a GCM
                                </button>
                            </a>
                        </div>
                    </div>
                </>
                <div className='stepsevenbuttonsDiv'>
                    <button className='backButton' onClick={() => setmodalcontantshow('MentalHealthSafe')}>
                        Back</button>
                </div>
            </Modal.Body >

        </>
    );
};

export default MentalHealthGCM;
