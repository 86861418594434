import React, { useContext, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import "../Execute/Modal.css"
import { globalData } from '../Execute/CommonModal';

import {returnFname} from '../control/Constant'
import FinancelRole from '../Execute/FinancialRole';




const FinanceOptions = ({ setSelectoptionId, setSelectedFinancePage }) => {
    const [show, setShow] = useState(false)
    const { setSelectoptionmessage, handleClose, setmodalcontantshow, getGCMprofessionalbyUserid, setillushospitalstep6help, addManageStepToFollow,steptwomodaldata } = useContext(globalData)


    const selectoption = async(data) => {
        if(data == "paybills"){
            setSelectoptionId(4);
            await getGCMprofessionalbyUserid(2, true);
            setSelectedFinancePage(data);
            setmodalcontantshow('paybills')
            addManageStepToFollow("setSelectoptionId", 4);
            addManageStepToFollow("setSelectedFinancePage", data);
        }
        else if (data == "filetaxes") {
            setSelectoptionId(5)
            await getGCMprofessionalbyUserid(3, true);
            setSelectedFinancePage(data);
            setmodalcontantshow('filetaxes')
            addManageStepToFollow("setSelectoptionId", 5);
            addManageStepToFollow("setSelectedFinancePage", data);
        }
        else if (data == "manageInverstment") {
            setSelectoptionId(6)
            setSelectedFinancePage(data);
            setmodalcontantshow('manageInverstment')
            addManageStepToFollow("setSelectoptionId", 6);
            addManageStepToFollow("setSelectedFinancePage", data);
        }
        else if (data == "managerealproperty") {
            setSelectoptionId(7)
            setSelectedFinancePage(data);
            setmodalcontantshow('managerealproperty')
            addManageStepToFollow("setSelectoptionId", 7);
            addManageStepToFollow("setSelectedFinancePage", data);
        }
        else if (data == "passwordsLoginId") {
            setSelectoptionId(8)
            setSelectedFinancePage(data);
            // setShow(true)                               
            setmodalcontantshow('financialRole')
            addManageStepToFollow("setSelectoptionId", 8);
            addManageStepToFollow("setSelectedFinancePage", data);
        }
        else if (data == "otherServices") {
            setSelectoptionId(9)
            setSelectedFinancePage(data);
            // setShow(true)                              
            setmodalcontantshow('financialRole')
            addManageStepToFollow("setSelectoptionId", 9);
            addManageStepToFollow("setSelectedFinancePage", data);
        }
    }

   


    return (
        <>
            <Modal.Header style={{ backgroundColor: "#871a27" }}>
                <Row >
                    <Col sm={11}>
                        <h3 className='heading'>
                            Step 3: What {returnFname(steptwomodaldata?.memberFullName)} is  dealing with ?
                        </h3>
                    </Col>
                    <Col sm={1} >
                        <button className='closeButton' style={{ border: "1px solid #981b29" }} onClick={handleClose}>x</button>
                    </Col>
                </Row>


            </Modal.Header>
            <Modal.Body>
                {/* <Container> */}

                <h4 className='SelectOptionsh4Tag  mt-2'>Select Option </h4>

                {/* <div className='container'> */}
                <div className='row justify-content-around' >
                    <div className='col-md-4  col-xs-12 col-md-12 col-lg-4 p-0' style={{ display: "flex", justifyContent: "center" }}>
                        <img src="/icons/financeScreens/paybills.svg" className='cursor-pointer card' onClick={()=>selectoption("paybills")}/>
                    </div>
                    <div className='col-md-4  col-xs-12 col-md-12 col-lg-4 p-0' style={{ display: "flex", justifyContent: "center" }}>
                        <img src="/icons/financeScreens/filetaxes.svg" className='cursor-pointer card' onClick={() => selectoption("filetaxes")} />
                    </div>
                    <div className='col-md-4  col-xs-12 col-md-12 col-lg-4 p-0' style={{ display: "flex", justifyContent: "center" }}>
                        <img src="/icons/financeScreens/manageInverstment.svg" className='cursor-pointer card' onClick={() => selectoption("manageInverstment")} />
                    </div>
                    <div className='col-md-4  col-xs-12 col-md-12 col-lg-4 p-0' style={{ display: "flex", justifyContent: "center" }}>
                        <img src="/icons/financeScreens/managerealproperty.svg" className='cursor-pointer card' onClick={() => selectoption("managerealproperty")} />
                    </div>
                    <div className='col-md-4  col-xs-12 col-md-12 col-lg-4 p-0' style={{ display: "flex", justifyContent: "center" }}>
                        <img src="/icons/financeScreens/passwordsLoginId.svg" className='cursor-pointer card' onClick={() => selectoption("passwordsLoginId")} />
                    </div>
                    <div className='col-md-4  col-xs-12 col-md-12 col-lg-4 p-0' style={{ display: "flex", justifyContent: "center" }}>
                        <img src="/icons/financeScreens/otherServices.svg" className='cursor-pointer card' onClick={() => selectoption("otherServices")} />
                    </div>
                </div>
                {/* </div>
        </Container> */}

                <div className='mt-2 mx-3'>
                    <button className='backButton' onClick={() => setmodalcontantshow('illnessemailtextpreview')}>Back</button>
                </div>
            </Modal.Body>

        </>
    );
}

export default FinanceOptions 