import React, { useContext, useEffect, useState, useRef } from 'react'
import Services from '../Network/Services';
import konsole from '../Network/konsole';
import commonLib from '../control/commonLib';
import { Modal, Row, Table, Col, Alert } from 'react-bootstrap';
import { globalData } from '../Execute/CommonModal';
import PdfViwer from '../FileCabinet/PdfViwer';
import OccuranceCom from '../OccuranceCom';
import { isNotValidNullUndefinedfile,returnHealthPolicyCount,returnBase64File} from '../control/Constant';


export default function AccessDocuments(props) {

    const { setloader, setnewAlert,stepName,steptwomodaldata,setStepName} = useContext(globalData)
    const userLoggedInDetail = commonLib.getObjFromStorage("userLoggedInDetail");
    const stateObj = commonLib.getObjFromStorage("stateObj")
    const loggedInUserId = userLoggedInDetail?.loggedUserId
    const [base64File, setbase64File] = useState('');
    const [handelChange, setHandelChange] = useState(false)
    const [userAgentData, setuserAgentData] = useState([]);
    const [showDocumentView, setDocumentView] = useState(false);
    const [documentViewObj, setDocumentViewObj] = useState({});
    const [sharedFileShatusList, setSharedFileShatusList] = useState([])
    const [documentUploadedViewtype, setUploadedDocumentViewtype] = useState('')
    const [allDocument, setAllDocument] = useState([])
    const [print, setPrint] = useState(false)
    const [baseFile, setbaseFile] = useState()
    const [showMail, setShowMail] = useState(false)
    const [fileName, setFileName] = useState("")
    const [downLoadSend, setDownLoadSend] = useState(false)
    const [memberName, setMemberName] = useState("")
    const [errors, setErrors] = useState({});
    const [showField, setShowField] = useState(false)
    const [memberFullName1, setMemberFullName1] = useState('');
    let occurranceRef = useRef(null)
    const [formData, setFormData] = useState({
        to: '',
        Subject: '',
        Compose: '',
        bcc: '',
        cc: `${userLoggedInDetail.primaryEmailId}`,
    });
    const fileCablinetId = 6 // file cablinet for legal
  
    const apiCallGetSharedFileStatus = (primaryMemberUserId, legalDocumentsMapped) => {

        setloader(true)
        Services.getSharedFileStatus({ primaryMemberUserId: primaryMemberUserId }).then((res) => {
            setloader(false)
            konsole.log('res of ger shared file status', res)
            let responseData = res?.data?.data.filter(({ isRead }) => isRead == true)
            if (responseData.length > 0) {
                apiCallgetFileFromCabinet(responseData, legalDocumentsMapped)
            }
        }).catch((err) => {
            setloader(false)
            konsole.log(' err in get shared file status', err)
        })
    }

    const apiCallgetFileFromCabinet = async(shareFileList, legalDocumentsMapped) => {
        const result = stepName == "Illness" ? await returnHealthPolicyCount(steptwomodaldata?.memberUserId) : "";
        setloader(true)
        let primaryMemberUserId = shareFileList[0].primaryUserId
        Services.getFileFromCabinet(primaryMemberUserId).then((res) => {
            setloader(false)
            konsole.log('res of file from cabinet',res)
            let responseData = res?.data?.data?.filter((item) => item?.fileStatusName != "Archived")
            konsole.log('responseDataresponseData', responseData)
            let newArr = []

            for (let [index, item] of shareFileList.entries()) {

                for (let i = 0; i < responseData?.length; i++) {

                    if (shareFileList[index].fileId == responseData[i].fileId) {
                        newArr.push({ ...shareFileList[index], ...responseData[i] })
                    }
                }
            }

          const  newFilteredFiles = newArr?.length > 0 && stepName == "Illness" ? newArr?.filter(ele => (ele?.fileTypeId == 40 || ele.fileTypeId == 49) && (ele?.belongsTo == steptwomodaldata?.memberUserId)) : newArr
           
           if(stepName == "Illness" && result?.length > 0){
            for(let i = 0;i < result.length;i++){
                if(result[i].longTermInsDocs.length > 0){
                    const newArrayinfo = ({memberFullName:steptwomodaldata?.memberFullName,fileId:result[i].longTermInsDocs[0]?.fileId,testDocName:"",testSupportDocName:"",fileTypeName:"Long Trem Care Policy",belongsToMemberName:steptwomodaldata?.memberFullName,isLongTerm:true})
                    newFilteredFiles.push(newArrayinfo)       
                }
            }
            
        }
        
            setSharedFileShatusList(newFilteredFiles)
            setAllDocument(newFilteredFiles)
        }).catch((err) => {
            setloader(false)
            konsole.log('err in getting file ', err, err?.response)
        })
    }

    const filtTypeEnum = [
        { legalDocId: 1, value: 20, label: "Last Will and Testament" },
        { legalDocId: 2, value: 21, label: "Revocable Living Trust" },
        { legalDocId: 3, value: 26, label: "Pour-Over Will" },
        { legalDocId: 4, value: 38, label: "Stand-Alone Safe Harbor Trust" },
        { legalDocId: 6, value: 39, label: "Durable Power of Attorney for Finances" },
        { legalDocId: 7, value: 40, label: "Durable Power of Attorney for Healthcare" },
        { legalDocId: 8, value: 42, label: "Limited Power of Attorney" },
        { legalDocId: 10, value: 48, label: "Handling of Remains" },
        { legalDocId: 11, value: 49, label: "HIPAA Release" },
        { legalDocId: 12, value: 52, label: "In Case of Emergency Cards" },
        { legalDocId: 999999, value: 999999, label: "Others" },
        { legalDocId: 15, value: 36, label: "Community Property Agreement" },
        { legalDocId: 16, value: 37, label: "Revocation of Community Property Agreement" },
        { legalDocId: 17, value: 41, label: "Directive to Attorney" },
    ]

    useEffect(() => {
        getUserAgent();
    }, [stepName])

    const getUserAgent = () => {
        let jsonobj = {
            "agentUserId": loggedInUserId,
            "agentRoleId": null,
            "agentActiveStatus": true,
            "isDummyAgent": (stateObj.roleId !== "5") ? true : undefined,
        };
        konsole.log("jsonobj", JSON.stringify(jsonobj))
        setloader(true)
        let result = Services.getUserAgent(jsonobj)
        result.then((res) => {
            konsole.log("UserUser", res)
            setloader(false)
            let responseData = res.data.data
            let arryData = []

            for (let item of responseData) {
                if (item.statusId === 2) {
                    arryData.push(item)
                }
            }

            const legalDocumentsMapped = arryData.length > 0 && arryData.map(legalDoc => {
                const legalDocumentFileIndex = filtTypeEnum.findIndex((data) => legalDoc.legalDocId === data.legalDocId);
                return { fileTypeId: (legalDocumentFileIndex >= 0) ? filtTypeEnum[legalDocumentFileIndex].value : null, ...legalDoc };
            });

            setuserAgentData(legalDocumentsMapped)
            apiCallGetSharedFileStatus(userLoggedInDetail.loggedUserId, legalDocumentsMapped)
        }).catch((err) => {
            setloader(false)
            konsole.log("User", err)
        })
    }

    const handleDocumentView = async(userObj, type, documentName,text,memberFullName1) => {
        if(userObj?.isLongTerm == true){
            setFileName(documentName);
            setDocumentViewObj(userObj);
           let  results = await returnBase64File(userObj?.fileId,setloader);
            setbase64File(results)
        //    if(data == true || data2 == true){setbaseFile(results)}
           if(text == "download" || text == "forward"){setbaseFile(results)}
        }
        // if (data == true) {
        //     setDownLoadSend(true);
        //     setShowMail(false);
        // }
        // if (data2 == true) {
        //    let  results = await returnBase64File(userObj?.fileId,setloader);
        //     setbaseFile(results)
        //     setMemberName(memberFullName1)
        //     setDownLoadSend(false);
        //     setShowMail(true);

        // }
        // if (data == true && data2 !== true) {
        //     setHandelChange(!handelChange);
        // }
        // if (data !== true && data2 !== true) {
        //     setDocumentView(true);
        // }

        if (text == "download") {
            setDownLoadSend(true);
            setShowMail(false);
            setHandelChange(!handelChange);
        }else if(text == "view"){
            setDocumentView(true);
        }else if(text == "forward"){
            let  results = await returnBase64File(userObj?.fileId,setloader);
            setbaseFile(results)
            setMemberName(memberFullName1)
            setDownLoadSend(false);
            setShowMail(true);
        }
        setMemberFullName1(memberFullName1);
        setDocumentViewObj(userObj);
        setUploadedDocumentViewtype(type);
        setFileName(documentName);
        setUploadedDocumentViewtype(type)
    }
   
    const handleModalHide = () => {
        setbaseFile("")
        props.hideView(false)
    }

    const handleClose = () => {
        setFileName('')
        setShowField(false)
        setShowMail(false)
    }
    const handleSubmit = async (e) => {
        e.preventDefault();
        // Basic validations
        const newErrors = {};
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!formData.to) {
            newErrors.to = 'To field is mandatory';
        }
        if (showField) {
            if (formData.to) {
                const toEmails = formData.to.split(',').map((email) => email.trim());
                for (const toEmail of toEmails) {
                    if (!emailRegex.test(toEmail)) {
                        newErrors.to = 'Please enter a valid email';
                        // return;
                        break;
                    }
                }
            }
            if (formData.cc) {
                let ccEmails = formData.cc.trim();
                if (ccEmails.endsWith(',')) {
                    ccEmails = ccEmails.slice(0, -1);
                }
                ccEmails = ccEmails.split(',').map((email) => email.trim());
                for (const ccEmail of ccEmails) {
                    if (!emailRegex.test(ccEmail)) {
                        newErrors.cc = 'Please enter a valid email';
                        break;
                    }
                }
            }
            
            if (formData.bcc) {
                const bccEmails = formData.bcc.split(',').map((email) => email.trim());
                for (const bccEmail of bccEmails) {
                    if (!emailRegex.test(bccEmail)) {
                        newErrors.bcc = 'Please enter a valid email';
                        break;
                    }
                }
            }
        }


        // Check if there are any errors
        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
            return;
        }
      
        if (stateObj.roleId == "5") {
            const blob = await fetch(baseFile).then((res) => res.blob());
            const file = new File([blob], `${fileName}.pdf`, { type: "pdf" });
            let formdata = new FormData();

            formdata.append("CreatedBy", userLoggedInDetail?.loggedUserId)
            formdata.append("Emailcc", showField == true ? formData?.cc : "")
            formdata.append("Emailbcc", formData?.bcc)
            formdata.append("File", file)
            formdata.append("EmailType", "SavePdfData")
            formdata.append("EmailTo", formData?.to)
            formdata.append("EmailSubject", formData?.Subject == "" ? (`${fileName} - ${userLoggedInDetail.loginUserName}`) : formData?.Subject)
            formdata.append("EmailContent", formData?.Compose == "" ? "At your required find the " + fileName + " for " + memberFullName1 + ".<br />Do email me " + userLoggedInDetail.primaryEmailId + " or call me at " + (userLoggedInDetail?.primaryPhoneNumber ? (numberFormatee(userLoggedInDetail?.primaryPhoneNumber)) : "not avalable") + ".<br />If you have questions.<br /> " + userLoggedInDetail.loginUserName : formData?.Compose)
            formdata.append("EmailTemplateId", 1)
            formdata.append("EmailStatusId", 1)
            formdata.append("EmailMappingTable", "EmailMappingTable")
            formdata.append("EmailMappingTablePKId", stateObj?.loggenInId)
            setloader(true)
            let result = Services.PostEmail(formdata)
            result.then((res) => {
                setloader(false)
                setShowField(false)
                setShowMail(false)

                setFormData({
                    ...formData,
                    to: "", Subject: "", Compose: "", cc: "", bcc: ""
                });
                konsole.log("PostEmailCom", res)

                setnewAlert({ type: "success", text: "Email sent successfully" });
                setTimeout(() => {
                    setnewAlert({ type: "", text: "" })
                }, 3000)
            }).catch((err) => {
                setloader(false)
                konsole.log("PostEmailCom", err)
            }).finally(() => {
                setloader(false)
                konsole.log("PostEmailCom")
            })
        } else {
            setShowMail(false)
        }


    }
    const handleChanges = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
        setErrors({
            ...errors,
            [name]: ''
        });
    }

    const toggleCc = () => {
        setShowField(!showField);
    }


    const numberFormatee = (number) => {

        const lastTenDigitArray = array => array.slice(-10);
        let countryCode = getCountryCode(number)
        if (countryCode == '1') {
            return commonLib.convertToUSFormat(lastTenDigitArray(number), countryCode)
        } else {
            return commonLib.convertToIndiaFormat(lastTenDigitArray(number), countryCode);
        }

    }

    function getCountryCode(mobileNumber) {
        const digitsOnly = isNotValidNullUndefinedfile(mobileNumber) && mobileNumber?.replace(/\D/g, '');

        if (digitsOnly.startsWith('1')) {

            return '1';
        }
        if (digitsOnly.startsWith('91')) {
            return '91';
        }
        return null;
    }
    const removeDuplicateValue = (array) => {
        return array.filter((obj, index, self) =>
            index === self.findIndex((o) => o.fileId === obj.fileId)
        );
    }
    const handleView =()=>{
        setbaseFile("")
        setDocumentViewObj({})
        setbase64File("")
        setDocumentView(false)
        
    }
    const viewAllDoc=()=>{
        if(stepName == "Illness"){
            setStepName("doc")
        }else{
            setStepName("Illness")
        }
      
    }

    return (
        <>
            <Modal centered={true} size="lg" show={props.showDocument} backdrop="static" >
                <Modal.Header style={{ backgroundColor: "#871a27" }}>
                    <Row>
                        <Col sm={11}>
                            <h3 className='heading'>
                                Access Documents
                            </h3>
                        </Col>
                        <Col sm={1} >
                            <button className='closeButton' style={{ border: "1px solid #981b29" }} onClick={handleModalHide}>x</button>
                        </Col>
                    </Row>


                </Modal.Header>
                <Modal.Body>
                    <>
                        <div style={{ maxHeight: "80vh", height: "80vh", overflowY: "auto" }} className='p-2 pt-0 mt-0'>
                            <Table striped bordered hover >
                                <thead className='sticky-top bg-white' style={{ top: "-2px" }}>
                                    <tr>
                                        {/* <th>Sr. No.</th> */}
                                        <th>Document Name</th>
                                        {/* <th>Rank</th> */}
                                        {/* <th>Role</th> */}
                                        <th>Belongs To</th>
                                        <th>Document Action</th>
                                    </tr>
                                </thead>
                                <tbody>

                                    {removeDuplicateValue(allDocument)?.length > 0 &&
                                        removeDuplicateValue(allDocument)?.map((user, index) => {const { memberFullName, agentRole, agentRank, fileId, testDocName, testSupportDocName, legalDocName, fileTypeName, isRead, belongsToMemberName } = user
                                            const docName = `${legalDocName} ${(testDocName !== null) ? `- ${testDocName}` : ``} ${(testSupportDocName !== null) ? ` - ${testSupportDocName}` : ``}`
                                            const documentName = legalDocName == undefined ? fileTypeName : docName;
                                            const forFileViewParameter = legalDocName == undefined ? 'UserShare' : 'ParalegalUploaded'
                                            const agentRank1 = agentRank == undefined ? '-' : agentRank
                                            const agentRole1 = agentRole == undefined ? '-' : agentRole
                                            const memberFullName1 = legalDocName == undefined ? belongsToMemberName : memberFullName
                                           
                                            return (
                                                <tr>
                                                    {/* <td>{index + 1}</td> */}
                                                    <td>{documentName}</td>
                                                    {/* <td>{agentRank1}</td> */}
                                                    {/* <td>{agentRole1}</td> */}
                                                    <td>{memberFullName1}</td>
                                                    <td className='colWidth'>{(fileId !== null) ?
                                                        <div>
                                                            <a style={{ cursor: "pointer" }} onClick={() => handleDocumentView(user, forFileViewParameter, documentName,"view")}>
                                                                <img
                                                                    title='View'
                                                                    src="/icons/viewIcon.svg"
                                                                    className='smallIconSize'
                                                                    alt="View"
                                                                />
                                                            </a>
                                                            <a style={{ cursor: "pointer" }} onClick={() => handleDocumentView(user, forFileViewParameter, documentName, "download")} >
                                                                <img
                                                                    title='Download & Print'
                                                                    src="/icons/printIcon.svg"
                                                                    className='printIconSize'
                                                                    alt="Download & Print"
                                                                />
                                                            </a>
                                                            <a style={{ cursor: "pointer" }} onClick={() => handleDocumentView(user, forFileViewParameter, documentName,"forward", memberFullName1)} >
                                                                <img
                                                                    title='Forward'
                                                                    src="/icons/forwardIcon.svg"
                                                                    className='smallIconSize'
                                                                    alt="Forward"
                                                                />
                                                            </a>
                                                        </div> : <>File need to be upload</>}</td>

                                                </tr>
                                            )
                                        })}
                                        
                                    {/* {userAgentData.length > 0 &&
                                    userAgentData?.map((user, index) => {
                                        const { memberFullName, agentRole, agentRank, fileId, testDocName, testSupportDocName, legalDocName } = user;
                                        const documentName = `${legalDocName} ${(testDocName !== null) ? `- ${testDocName}` : ``} ${(testSupportDocName !== null) ? ` - ${testSupportDocName}` : ``}`
                                        return (
                                            <tr>
                                                <td>{index + 1}</td>
                                                <td>{documentName}</td>
                                                <td>{agentRank}</td>
                                                <td>{agentRole}</td>
                                                <td>{memberFullName}</td>
                                                <td>{(fileId !== null) ? <a className='viewFileText' style={{ cursor: "pointer" }} onClick={() => handleDocumentView(user, 'ParalegalUploaded')}>View</a> : <>File need to be upload</>}</td>
                                            </tr>
                                        )
                                    })
                                } */}
                                    {/* {sharedFileShatusList.length > 0 &&
                                    sharedFileShatusList.filter(item => (item.isRead == true)).map((user, index) => {
                                        const { primaryUserName, fileTypeName, belongsToMemberName, fileUploadedByName, userFileName, fileId, testDocName, testSupportDocName, legalDocName, isEdit, isRead, isDelete } = user;
                                        konsole.log('useruseruser', user.isRead)
                                        return (
                                            <tr>
                                                <td>{userAgentData.length + index + 1}</td>
                                                <td>{fileTypeName}</td>
                                                <td>-{isRead}</td>
                                                <td>-</td>
                                                <td>{belongsToMemberName}</td>

                                                <td>{(fileId !== null) ? <a className='viewFileText' style={{ cursor: "pointer" }} onClick={() => handleDocumentView(user, 'UserShare')}>View</a> : <>File need to be upload</>}</td>
                                            </tr>
                                        )
                                    })
                                }  */}
                                </tbody>
                            </Table>
                              {stepName !== "" && 
                    <div className='container d-flex justify-content-center'><button className='profesionalBtnTool fw-bold mb-2 me-2' onClick={viewAllDoc}>{stepName == "Illness" ? "View all documents" : `View own documents`}</button></div>
                        }
                            
                        </div>
                        {downLoadSend == true &&
                            <PdfViwer setDownLoadSend={setDownLoadSend} key={downLoadSend} documentViewObj={documentViewObj} documentUploadedViewtype={documentUploadedViewtype} fileCabinetId={fileCablinetId} handelChange={handelChange} setHandelChange={setHandelChange} print={print} setPrint={setPrint}base64File={base64File} setbaseFile={setbaseFile} downLoadSend={downLoadSend} />}

                    </>
                  
                </Modal.Body>
         
            </Modal>

            <Modal centered={true} size="lg" show={showDocumentView} backdrop="static" >
                <Modal.Header style={{ backgroundColor: "#871a27" }}>
                    <Row>
                        <Col sm={11}>
                            <h3 className='heading'>
                                Document View
                            </h3>
                        </Col>
                        <Col sm={1} >
                            <button className='closeButton' style={{ border: "1px solid #981b29" }} onClick={() => handleView()}>x</button>
                        </Col>
                    </Row>
                </Modal.Header>
                <Modal.Body>
                    <PdfViwer documentViewObj={documentViewObj}base64File={base64File} documentUploadedViewtype={documentUploadedViewtype} key={documentViewObj} fileCabinetId={fileCablinetId} handelChange={handelChange} setHandelChange={setHandelChange} print={print} setPrint={setPrint} setbaseFile={setbaseFile} />
                </Modal.Body>
                <OccuranceCom ref={occurranceRef} />
            </Modal>
            <Modal centered={true} size="md" show={showMail} backdrop="static" style={{ opacity: "0.1 !importtant", zIndex: "9999", background: "rgba(0,0,0,0.5)" }}>
                <Modal.Header style={{ backgroundColor: "#871a27" }}>
                    <div>
                        <h5 style={{ color: "aliceblue", marginBottom: "auto" }}><span>Forward Document</span></h5>
                        <button className='closeButton' style={{ border: "1px solid #981b29" }} onClick={() => handleClose()}>x</button>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <>
                        <form className="compose-container" onSubmit={handleSubmit}>
                            <div className="form-group">
                                <div className='position-relative'>
                                    <input type="text" id="to" name="to" className="recipient-input" placeholder="To" onChange={handleChanges} />
                                    {/* <button className='position-absolute top-0 end-0 mt-2 me-4 border-0 bg-white' onClick={toggleCc}>Cc / Bcc</button> */}
                                    <h6 className='position-absolute fw-normal top-0 end-0 mt-2 me-4 border-0 bg-white cursor-pointer' onClick={toggleCc}>Cc / Bcc</h6>
                                    {errors.to && <p className='text-danger'> <div className="error">{errors.to}
                                    </div></p>}
                                </div>
                                {showField && <input type="text" id="cc" name="cc" className="recipient-input" placeholder="Cc" defaultValue={(`${userLoggedInDetail.primaryEmailId},`)} onChange={handleChanges} />}
                                {errors.cc && <p className='text-danger'> <div className="error">{errors.cc}
                                </div></p>}
                                {showField && <input type="text" id="bcc" name="bcc" className="recipient-input" placeholder="Bcc" onChange={handleChanges} />}
                                {errors.bcc && <p className='text-danger'> <div className="error">{errors.bcc}
                                </div></p>}

                            </div>
                            <input type="text" id="Subject" name="Subject" className="subject-input" defaultValue={(`${fileName} - ${memberName}`)} onChange={handleChanges} />
                            <textarea className="message-input" id="Compose" name="Compose" placeholder="Compose your message..." defaultValue={`At your required find the ${fileName} for ${memberFullName1}.\nDo email me ${(userLoggedInDetail.primaryEmailId)} or call me at ${userLoggedInDetail?.primaryPhoneNumber ? (numberFormatee(userLoggedInDetail?.primaryPhoneNumber)) : "not avalable"}.\nIf you have question.\n${userLoggedInDetail.loginUserName}`} onChange={handleChanges} />
                            <div className='d-flex' style={{ width: "fit-content" }}>
                                <button type="submit" style={{ height: "46px" }} className="send-button me-2">Send</button>
                                {/* {baseFile && <div className="attachment-preview" onClick={() => handelChanges()}>{fileName}</div>} */}
                            </div>
                        </form>
                    </>
                </Modal.Body >

            </Modal >

        </>
    )
}