import React, { useState, useContext, useEffect } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Modal from "react-bootstrap/Modal";
import { globalData } from "../Execute/CommonModal";
import '../Execute/Modal.css'
import {returnFname} from '../control/Constant'
import "../Execute/Illness/Warning.css";
import FinanceGoalNStep2 from "./financeGoalNStep2";
import Services from "../Network/Services";
import konsole from "../Network/konsole";
import { Card } from "react-bootstrap";
import commonLib from "../control/commonLib";
const ManageReal = (props) => {
    const { handleClose, setmodalcontantshow, steptwomodaldata, setGcmDetails, setgcmidforcontactno, isGCM, setIsGCM, setFullNameOfGCM,setloader} = useContext(globalData)
    const [showNoProessional, setNoProessional] = useState();
    const [jsonWholeArray, setJsonWholeArray] = useState([])
    useEffect(() => {
        getGCMprofessionalbyUserid();
    }, [])

    const getGCMprofessionalbyUserid = async (LPOStatus = true) => {


        let array = [];
        let arrayValues = [14, 4];
        for (let [index, professId] of arrayValues.entries()) {
            try {
                setloader(true)
                const responsefirst = await Services.getprofessionalusergetmembergcmbyuserid(steptwomodaldata.memberUserId, professId, LPOStatus);
                konsole.log("responsefiesr", responsefirst);
                if (responsefirst) {
                    setloader(false)
                    array.push(responsefirst.data.data.professionalUser[0]);
                    if (index === array.length - 1) {
                        setNoProessional(false);
                    }
                }
            }
            catch (error) { 
                setloader(false)
                konsole.log("error in catch", error);
                if (index === array.length - 1) {
                    setNoProessional(false);
                }
            }
        }

        if (array.length === 0) {
            setNoProessional(true);
        }
        else{
            setJsonWholeArray(array);
        }
    };

    const handleGcmSave = (professionalUser) => {
        setIsGCM('true')
        setGcmDetails(professionalUser);
        setgcmidforcontactno(professionalUser.professionalUserId);

        let fullName = commonLib.showFullName(professionalUser);
        konsole.log("fullname professional", fullName)
        setFullNameOfGCM(fullName);
        setmodalcontantshow("manageRealContactpage");
        props.setRolesName(professionalUser.proType);
    }

    konsole.log("error in catch here", jsonWholeArray);
    return (
        <>
            {
                (showNoProessional === false) ?
                    <>
                        <Modal.Header style={{ backgroundColor: "#871a27" }}>
                            <Row>
                                <Col sm={11}>
                                    <h3 className="heading">Goal and Steps</h3>
                                </Col>
                                <Col sm={1}>
                                    <button
                                        className="closeButton"
                                        style={{ border: "1px solid #981b29" }}
                                        onClick={handleClose}
                                    >
                                        x
                                    </button>
                                </Col>
                            </Row>
                        </Modal.Header>
                        <Modal.Body>
                            <h4 className="SelectOptionsh4Tag mb-4 mt-2">
                                {returnFname(steptwomodaldata.memberFullName)} did not want to burden you to be sorting out with managing their Real Properties. Please contact professional team and they will be able to help you with it.<br/>
                                Please contact any one of the professional based on the situation.
                            </h4>
                            <div className="d-flex gap-2 p-4 justify-content-center">
                                {
                                    (jsonWholeArray.length > 0) ?
                                        jsonWholeArray.map((items, index) => {
                                            let fullName = commonLib.showFullName(items)
                                            return (
                                                <div className='col-md-4' >
                                                    <Card className="card h-100" onClick={() => handleGcmSave(items)} >
                                                        <div style={{ display: "flex", justifyContent: "center" }}>
                                                            <Card.Img className='mt-3' src="/icons/financeScreens/carbon_user-avatar.png" style={{ width: "50px" }} ></Card.Img>
                                                        </div>
                                                        <Card.Body className='text-center mt-2 mt-2 mb-0 pb-0'>
                                                            <Card.Title style={{ opacity: "85%", fontSize: "24px", fontFamily: "Inter"}}>{fullName}</Card.Title>
                                                            <p>{items.proType}</p>
                                                        </Card.Body>
                                                    </Card>
                                                </div>
                                            );
                                        })
                                        :
                                        <>Loading...</>
                                }
                            </div>


                            <div className='buttonsDiv'>
                                <button className='backButton' onClick={() => setmodalcontantshow('financeOptions')}>Back</button>
                            </div>
                        </Modal.Body>
                    </>
                    :
                    (showNoProessional === true) ?
                        <FinanceGoalNStep2 message="7" showButton={true} goBack="financeOptions" /> :
                        <></>
            }
        </>
    )
}
export default ManageReal;