import { useEffect, useState } from 'react'
import { Modal, ModalBody } from 'react-bootstrap'
import { useIdleTimer } from 'react-idle-timer'
import commonLib from './control/commonLib'
import  config  from './config.json';
import { AoLoginBaseUrl } from './Network/url';

export default function CustomeIdleTimer({timeout, promptBeforeIdle}) {
    const [state, setState] = useState ('Active')
    const [remaining, setRemaining] = useState(timeout)
    const [open, setOpen] = useState(false)
    // console.log("idle state", state);
    const onIdle = () => {
        setState('Idle')
        setOpen(false)
        // const stateObj = commonLib.getObjFromStorage('stateObj');
        // let params = `appState=${stateObj?.appState}&userId=${stateObj?.userId}&roleId=${stateObj?.roleId}&loggenInId=${stateObj?.loggenInId}&success=${true}`;
        // sessionStorage.clear();
        // window.location.replace(`https://aologin.azurewebsites.net/Account/Signout?token=` + btoa(params));

        const stateObj = commonLib.getObjFromStorage('stateObj');
        let params = `appState=${stateObj.appState}&userId=${stateObj.userId}&roleId=${stateObj.roleId}&loggenInId=${stateObj.loggenInId}&success=${true}`;
        const paramsdec=window.btoa(params)
        if(stateObj.roleId==5){
            sessionStorage.clear()
            window.location.replace(`${AoLoginBaseUrl}Account/Signout?` +params);
        }else{
            commonLib.closeParentModal()
        }
        
    }

    const onActive = () => {
        setState('Active')
        setOpen(false)
    }

    const onPrompt = () => {
        setState('Prompted')
        setOpen(true)
    }

    const { getRemainingTime, activate } = useIdleTimer({
        onIdle,
        onActive,
        onPrompt,
        timeout,
        promptBeforeIdle,
        throttle: 500
    })

    useEffect(() => {
        const interval = setInterval(() => {
            setRemaining(Math.ceil(getRemainingTime() / 1000))
        }, 500)

        return () => {
            clearInterval(interval)
        }
    })

    const handleStillHere = () => {
        activate()
    }

    const timeTillPrompt = Math.max(remaining - promptBeforeIdle / 1000, 0)
    // const seconds = timeTillPrompt > 1 ? 'seconds' : 'second'

    return (
        <>
            {/* <h1>React Idle Timer</h1>
            <h2>Confirm Prompt</h2>
            <br />
            <p>Current State: {state}</p>
            {timeTillPrompt > 0 && (
                <p>
                    {timeTillPrompt} {seconds} until prompt
                </p>
            )} */}


            <Modal show={open} onHide={handleStillHere} className="customModal">
                <Modal.Header closeButton className="d-flex justify-content-between"></Modal.Header>
                <Modal.Body className='text-center'>
                    <h3>We have noticed that you have been inactive for a while.</h3>
                    <p>To ensure security, we will log you out.</p>
                    <p>Logging out in {remaining} seconds</p>
                    <div className='d-flex align-items-center justify-content-center'>
                        <button className='theme-btn' onClick={handleStillHere}>Click here to get back!</button>
                    </div>
                    
                </Modal.Body>
            </Modal>
        </>
    )
}
