import React, { useContext, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { globalData } from '../CommonModal';

import '../Modal.css';


const EOLNeedYesUnanimous = () => {
    const { handleClose, setmodalcontantshow, steptwomodaldata } = useContext(globalData)
  


    return (
        <>

            <Modal.Header style={{ backgroundColor: "#871a27" }}>

                <Modal.Title className='heading' id="example-modal-sizes-title-lg" style={{ color: 'white', fontFamily: "Inter"}}>
                    <span style={{ color: "#871a27" }}>S</span>
                </Modal.Title>
                {/* closeButton */}

                <button className='closeButton' onClick={handleClose}>x</button>

            </Modal.Header>
            <Modal.Body>
                <h4 className='SelectOptionsh4Tag mt-3'>

                    Is there unanimous consent?
                </h4>


                <div className='buttonDiv'>
                    <button className='yesButton' onClick={()=>setmodalcontantshow('eolaccessdocument')} >Yes</button>
                    <button className='noButton' onClick={()=>setmodalcontantshow('eolgoalstep7')} >No</button>
                </div>
                <div className='backbuttonDiv'>
                    <button className='backButton' onClick={()=>setmodalcontantshow('eolneedyes')}>Back</button>
                </div>
            </Modal.Body>

            {/* </Modal> */}
        </>
    );
}

export default EOLNeedYesUnanimous