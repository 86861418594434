import React, { useContext, useEffect, useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";
import { globalData } from "../Execute/CommonModal";
import '../Execute/Modal.css'

const FinanceContactPage = (props) => {

    const { handleClose, setmodalcontantshow, steptwomodaldata, isGCM, fullNameofGcm, setEOlStep8Toallcall, setSendEmailOrText } = useContext(globalData)
  
    const showModalAndSend = () =>{
        setmodalcontantshow(props.forward)
        setSendEmailOrText("Email")
    }

    const showModalAndSendText = () =>{
        setmodalcontantshow(props.forward)
        setSendEmailOrText("Text")
    }

    useEffect(() => {
        setEOlStep8Toallcall('Email')
    }, [])



    return (
        <>
            <Modal.Header style={{ backgroundColor: "#871a27" }}>
                <Row>
                    <Col sm={11} md={10} lg={11}>
                        <h4 className="heading">
                             How would you like to contact {fullNameofGcm}, {props.roleName}?
                        </h4>
                    </Col>
                    <Col sm={1} md={2} lg={1}>
                        <button
                            className="closeButton"
                            style={{ border: "1px solid #981b29" }}
                            onClick={handleClose}
                        >
                            x
                        </button>
                    </Col>
                </Row>
            </Modal.Header>
            <Modal.Body>
                <h4 className="SelectOptionsh4Tag">Select option</h4>

                <div className="buttonDiv mt-4">
                    <button
                        className="emailButton"
                        // onClick={() =>(isGCM=='true')?setmodalcontantshow('eolneedhelpstep9'):''}
                        onClick={() => showModalAndSend()}
                    >
                        Email
                    </button>
                    <button
                        className="textButton"
                        // onClick={() =>(isGCM=='true')?setmodalcontantshow('eolneedhelpstep9'):''}
                        onClick={() => showModalAndSendText()}
                    >
                        Text
                    </button>
                    <button
                        className="callButton"
                        onClick={() => (isGCM == 'true') ? setmodalcontantshow(props.goCallPage) : ''}
                    >
                        Call
                    </button>

                </div>
                <div>
                </div>
                <div className="backButtonDiv">
                    <button className="backButton" onClick={() => setmodalcontantshow(props.goBack)}>Back</button>
                </div>
            </Modal.Body>

        </>
    );
};

export default FinanceContactPage;
