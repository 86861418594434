import React from 'react'
import './Agent.css'

const Confirmation = ({ handleClickStatus,text }) => {
 
  return (
    <div className="overlay">
      <div className="alert-container">
        <div className="alert alert-dismissible fade show border" style={{ backgroundColor: "white" }}>
          <p>{text}</p>
          <div className="d-flex justify-content-end">
            <button className="btn me-3" style={{ background: "#720C20", color: "white" }} onClick={() => handleClickStatus('Yes')}>Yes</button>
            <button className="btn" style={{ outline: "2px solid #720C20" }} onClick={() => handleClickStatus('No')}>No</button>
          </div>

        </div>
      </div>
    </div>
  )
}

export default Confirmation