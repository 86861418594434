import React, { useContext, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Container from "react-bootstrap/Container"
import { globalData } from '../components/Execute/CommonModal';

const HospitalDetails = () => {
  const { setillushospitalstep6help, setIsGCM, handleClose, setmodalcontantshow, steptwomodaldata, addManageStepToFollow, setHospitalDetails,setGoBack} = useContext(globalData)
  const [formData, setFormData] = useState({
    hospitalName: '',
    roomNumber: '',
    county: ''
  });

  const [errors, setErrors] = useState({});
  const selectoption = (e) => {
    if (e == 'Hospital') {
      // setmodalcontantshow('illnesshospitalstep6')
      setmodalcontantshow('illnessnotifysuccessor')
      addManageStepToFollow("setillushospitalstep6help", "Hospital");
      // addManageStepToFollow("illnessnotifysuccessor", "Hospital");
    } else if (e == 'Home') {
      // setillushospitalstep6help('Home')
      setmodalcontantshow('illnesshomemedical')
      addManageStepToFollow("setillushospitalstep6help", "Home");
    } else if (e == 'Other') {
      // setillushospitalstep6help('Other')
      setmodalcontantshow('illnesshomemedical')

      addManageStepToFollow("setillushospitalstep6help", "Other");
    }

  }





  const customSelectOptions = (options) => {
    selectoption(options);
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    const newErrors = {};
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      // customSelectOptions('Other')
      
      setHospitalDetails(formData)
      customSelectOptions('Hospital')
    }
  }
  const handleChange = (e) => { const { name, value } = e.target;
  const capitalized = value.charAt(0).toUpperCase() + e.target.value.slice(1)
      setFormData({
        ...formData,
        [name]: capitalized
      });
  }

  return (
    <>
      <Modal.Header style={{ backgroundColor: "#871a27" }}>
        <Row >
          <Col sm={11}>
            <h3 className='heading locateTitle'>
              Step 4(a) : Please Fill Hospital Details
            </h3>
          </Col>
          <Col sm={1} >
            <button className='closeButton' style={{ border: "1px solid #981b29" }} onClick={handleClose}>x</button>
          </Col>
        </Row>

        <button className='closeButton' onClick={handleClose}>x</button>

      </Modal.Header>
      <Modal.Body>

        {/* <Container>
          <div className=''>
            <div className='row mb-2'style={{border: "0.5px solid lightgray"}}>
            <textarea className="form-control pt-0 ps-3 fs-4  text-dark  shadow-none border-0" rows="4"placeholder="Enter Hospital Name / Room Number / Address "onChange={(e)=>setHospitalDetails(e.target.value)}>
              </textarea>
            </div>
          </div>
        </Container> */}
        <Container>
          <div className="container-other">

            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <label htmlFor="hospitalName">Hospital Name:</label>
                <input type="text" id="hospitalName" name="hospitalName" value={formData.hospitalName} onChange={handleChange} />
                <p className='text-danger'>{errors.hospitalName && <div className="error">{errors.hospitalName}
                </div>}</p>
              </div>
              <div className="form-group">
                <label htmlFor="roomNumber">Room Number:</label>
                <input type="text" id="roomNumber" name="roomNumber" value={formData.roomNumber} onChange={handleChange} />
                <p className='text-danger'>{errors.roomNumber && <div className="error">{errors.roomNumber}
                </div>}</p>
              </div>
              <div className="form-group">
                <label htmlFor="county">City:</label>
                <input type="text" id="county" name="county" value={formData.county} onChange={handleChange} />
                <p className='text-danger'>{errors.county && <div className="error">{errors.county}
                </div>}</p>
              </div>


              <div className='d-flex justify-content-between'>
                <button className='backButton' onClick={() => setmodalcontantshow('step4illness')}>Back</button>
                <button className='backButton'type="submit">Next</button>
              </div>

            </form>
          </div>
        </Container>


      </Modal.Body>
    </>

  )
}

export default HospitalDetails