import React, { useState, useContext } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Modal from "react-bootstrap/Modal";
import {returnFname} from '../../control/Constant'
import { globalData } from "../CommonModal";
import '../Modal.css'

import "./Warning.css";
const IllnessHomeMedical = () => {
  const { setillushospitalstep6help,handleClose, setmodalcontantshow, steptwomodaldata, addManageStepToFollow,manageStepToFollow,illushospitalstep6help,goNext } = useContext(globalData)

  const clickbackbutton=()=>{
    if(goNext == 'Home'){
      setmodalcontantshow('step4illness')
    }else{
      setmodalcontantshow('OtherSetting')
    }
    
    setillushospitalstep6help('')
    addManageStepToFollow("setillushospitalstep6help", "");

  }


  return (
    <>
      <Modal.Header style={{ backgroundColor: "#871a27" }}>
        <Row   style={{height:"30px"}}>
          <Col sm={11}>
            <h3 className="heading">Step 5: {returnFname(steptwomodaldata.memberFullName)}'s medical condition</h3>
          </Col>
          <Col sm={1}>
            <button
              className="closeButton"
              style={{ border: "1px solid #981b29" }}
              onClick={handleClose}
            >
              x
            </button>
          </Col>
        </Row>
      </Modal.Header>
      <Modal.Body>
     <h4 className="SelectOptionsh4Tag mb-4 mt-2"> What is {returnFname(steptwomodaldata.memberFullName)}'s current condition?</h4> 
        
          

        

     <div className="d-flex justify-content-center">
     <button  className="healthButton" onClick={()=>setmodalcontantshow('illnesshomemedicalhelp')}>
               Medical Emergency 
              </button>
          
        </div>



        <div className="backButtonDiv">
          <button className="backButton" onClick={clickbackbutton}>
            Back
          </button>
        </div>
      </Modal.Body>
    </>
  )
}
export default IllnessHomeMedical