import React, { useState, useContext, useEffect } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Modal from "react-bootstrap/Modal";
import { globalData } from "../CommonModal";
import '../Modal.css'
import { returnFname } from "../../control/Constant";
import { aorgLink, aorgLinkFunc } from "../../control/Constant";

import "../Illness/Warning.css";
const EOLGoalStep7 = ({setEolneedhelpsetp7to8}) => {
  const { handleClose, setmodalcontantshow, steptwomodaldata,isGCM,fullNameofGcm ,setstep7illnesshomenext,setwithOutGCMNeedHelpNext, addManageStepToFollow, stepsToFollow, modalcontantshow} = useContext(globalData)

  const EolstepTo8=()=>{
    setstep7illnesshomenext('NeedHelp')
    addManageStepToFollow("setstep7illnesshomenext", "NeedHelp");
    setEolneedhelpsetp7to8('EolNeedHelpStep7')
    addManageStepToFollow("setEolneedhelpsetp7to8", "EolNeedHelpStep7");
    setmodalcontantshow('eolgcmstep8')
  }


  useEffect(()=>{
    setwithOutGCMNeedHelpNext('NeedHelp')
  },[])


  const  openWindow = () => {
    localStorage.setItem("keypoints", JSON.stringify(stepsToFollow));
    window.location.replace(aorgLinkFunc("health", modalcontantshow, 7, steptwomodaldata));
  }

  return (
    <>
      <Modal.Header style={{ backgroundColor: "#871a27" }}>
        <Row>
          <Col sm={11}>
            <h3 className="heading">Step 7:Goal and Steps</h3>
          </Col>
          <Col sm={1}>
            <button
              className="closeButton"
              style={{ border: "1px solid #981b29" }}
              onClick={handleClose}
            >
              x
            </button>
          </Col>
        </Row>
      </Modal.Header>
      <Modal.Body>
      
<h4 className="SelectOptionsh4Tag mb-4 mt-2">{returnFname(steptwomodaldata.memberFullName)} has not made any arrangements for this situation. <br />
{
  (isGCM=='true')?<> Please contact {fullNameofGcm } for guidance.</>:
  <>
  If {returnFname(steptwomodaldata.memberFullName)} does not have a Geriatric Care Manager, please click below to find one.
  </>
}
       </h4>
      
      
       <div className="d-flex justify-content-center">
            {
              (isGCM=='true')?
              <button  className="healthButton" onClick={EolstepTo8}>
              Contact {fullNameofGcm}
            </button>
            : <a onClick={openWindow} target="_blank">
            <button  className="healthButton">
              Find a GCM
            </button>
          </a>
            }
       </div>

    
        <div className='buttonsDiv'>
                    <button className='backButton' onClick={()=>setmodalcontantshow('eolyesneedno')} >Back</button>
                    {/* <button className='continueButton' onClick={EolstepTo8}>Next</button> */}
                </div>
      </Modal.Body>
    </>
  )
}
export default EOLGoalStep7;