import React, { useContext, useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import './Modal.css'
import { globalData } from './CommonModal';
import Services from '../Network/Services';
import konsole from '../Network/konsole';
import Container from "react-bootstrap/Container"
import commonLib from '../control/commonLib';

const Stept2SelectOne = ({ setsteptwomodaldata }) => {
    const { handleClose, setmodalcontantshow, setmemberRankIdforPrimary,modalcontantshow, setSelectedAgentRolesList, addManageStepToFollow,setAgentrole ,setAllagents,setPrimaryagents,setAgentdata,setAlljsondata,setloader} = useContext(globalData)
    const [healthRole, setHealthrole] = useState([])
    const [jsonWholeArray, setJsonWholeArray] = useState([]);
    const stateObj = commonLib.getObjFromStorage("stateObj");

    konsole.log("healthRolehealthRole",healthRole)

    useEffect(() => {
        const userId = commonLib.getObjFromStorage("userLoggedInDetail").loggedUserId;
        getuserbyagentid(userId);
    },[])


    const getuserbyagentid = async(userId) => {
        let jsonobj={
            "agentUserId": userId,
            // "agentRoleId": 1,
            "agentActiveStatus": true,
            "statusId": 2,
            "isDummyAgent": (stateObj.roleId !== "5")? true: undefined,
          }

          let array = [];
          try{
            setloader(true)
            let promise = await Services.postgetUserAgentUserByAgentId(jsonobj);
            if(promise){
                setloader(false)
                let filterJson = promise.data.data.filter((ele)=>{
                    return ele?.agentRoleId == 1 || ele?.agentRoleId == 2
                })
                array.push(...filterJson);
            }
        }
        catch(error){
            konsole.log("error in catch");
        }

        konsole.log("username show here", commonLib.getUniqueArrayFromAgentUsr(array));
        setHealthrole(commonLib.getUniqueArrayFromAgentUsr(array));
        setJsonWholeArray(array);
    }



    const getCardClickData = (e) => {
        setloader(true)
        const stateJson = commonLib.getObjFromStorage('stateObj')?.userId;
        const userId = e?.memberUserId;
         Services.getUseragentsapi("true",userId).then((res) => {
            setloader(false)
              let response = res?.data?.data
              setAllagents(response)
              let agentRankId=e?.agentRankId 
              sessionStorage.setItem('memberUserId',e)
              setmemberRankIdforPrimary(agentRankId)  
      
              const uniqueData = jsonWholeArray.length > 0 && jsonWholeArray.filter((fil) => fil.memberUserId === e.memberUserId && ( fil.agentRoleId === 1 || fil.agentRoleId == 2));
              const jsonFind  = uniqueData.length > 0 &&  uniqueData.filter((obj, index, self) => index === self.findIndex((o) => o.agentId === obj.agentId));
         
              setAlljsondata(jsonFind.length)
              
              if(jsonFind.length === 2){
                  setmodalcontantshow('');
                  setSelectedAgentRolesList(jsonFind);
              }
              else if(jsonFind.length === 1){
                  setAgentrole(jsonFind[0]?.agentRoleId)
                  let  filteredJson = response.filter((ele)=>{return ele?.agentRoleId == jsonFind[0]?.agentRoleId && ele?.agentUserId != stateJson})
                  setAgentdata(filteredJson)
                  let  filteredData = response.filter((ele)=>{return (ele?.agentRoleId == jsonFind[0]?.agentRoleId && ele?.agentRankId == 1)})
                   
                   if(filteredData.length === 0){
                    let  filteredDatas = response.filter((ele)=>{return (ele?.agentRoleId == jsonFind[0]?.agentRoleId && (ele?.agentRankId == 2 || ele?.agentRankId == 3 || ele?.agentRankId == 4 || ele?.agentRankId == 5 || ele?.agentRankId == 6))})
                    setPrimaryagents(filteredDatas)
                    
                   }else{
                    setPrimaryagents(filteredData)
                   }
                  
                 
                  if(jsonFind[0]?.agentRoleId == 1){
                      setmodalcontantshow("step3selectoption");
                      setmemberRankIdforPrimary(jsonFind[0].agentRankId);
                      addManageStepToFollow("pageName", "health");
                       
                  }
                  else if(jsonFind[0].agentRoleId == 2){

                      setmemberRankIdforPrimary(jsonFind[0].agentRankId);
                      addManageStepToFollow("pageName", "finance");
                      let id = jsonFind[0].agentRankId
                      if (id == 1 || id == 2 || id == 3 || id == 4 || id == 5 || id == 6) {
                          setmodalcontantshow('financenotifysuccessor')
                      } else {
                          setmodalcontantshow('financeWarning');
                      }
                      //  setmodalcontantshow("financialRole");
                  }

              }   
          
           }).catch((err) => {})
        setsteptwomodaldata(e)
        addManageStepToFollow("setsteptwomodaldata", e);
    }
    const showRoles = (item)=>{
          const jsonFind = jsonWholeArray.length > 0 &&  jsonWholeArray.filter((fil) => fil.memberUserId === item.memberUserId && ( fil.agentRoleId === 1 || fil.agentRoleId == 2));
          const uniqueData = jsonFind.length > 0 &&  jsonFind.filter((obj, index, self) => index === self.findIndex((o) => o.agentId === obj.agentId));
          
             if(uniqueData.length === 2){
                return ["selected you for ",<br key="lineBreak" />,"Multiple Responsibilities"] 
             }else{
                return [
                    "selected you as: ",<br key="lineBreak" />,uniqueData[0]?.agentRank + " " + uniqueData[0]?.agentRole + " For " + (uniqueData[0]?.agentRoleId == 1 ? "Health" : "Finance")
                  ];}
             }
    return (
        <>

            <Modal.Header style={{ backgroundColor: "#871a27" }}>
                <Row >
                    <Col sm={11}>
                        <h3 className='heading'>
                            Step 1: {healthRole.length == 1 ? "Please click to proceed further" : "Identify whom you are helping!"}
                        </h3>
                    </Col>
                    <Col sm={1} >
                        <button className='closeButton' style={{ border: "1px solid #981b29" }} onClick={handleClose}>x</button>
                    </Col>
                </Row>
            </Modal.Header>
            <Modal.Body>

                <Container>
                    <h4 className='SelectOptionsh4Tag pt-1'>{healthRole.length == 1 ? "" : "Select one"}</h4>

                    <div className='row justify-content-center'>
                        {healthRole.length > 0 && healthRole.map((items, index) => {
                            return (
                                <>
                                    <div className='col-md-4' >
                                        <Card className="card h-100" onClick={() => getCardClickData(items)}>
                                            <div style={{ display: "flex", justifyContent: "center" }}>
                                                <Card.Img className='mt-3' src="/Person.png" style={{ width: "50px" }} ></Card.Img>
                                            </div>
                                            <Card.Body className='text-center mt-2 mt-2 mb-0 pb-0'>
                                                <Card.Title style={{ opacity: "85%", fontSize: "24px", fontFamily: "Inter",}}>{items.memberFullName}</Card.Title>
                                                <div>{showRoles(items)}</div>
                                            </Card.Body>
                                        </Card>
                                    </div>
                                </>
                            )

                        })}
                    </div>

                </Container>
                <div className='backButtonDiv'>
                    <button className='backButton' onClick={() => handleClose()}>Back</button>
                </div>
            </Modal.Body>
        </>
    )
}
export default Stept2SelectOne