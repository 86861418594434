
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import '../Execute/Modal.css';
import { globalData } from '../Execute/CommonModal';
import { useContext } from 'react';
import commonLib from '../control/commonLib';
import Services from '../Network/Services';
import {returnFname} from '../control/Constant'
const FinessNotifySuccessor = (props) => {
    const { NotifyContinueCallApi, handleClose, setmodalcontantshow, steptwomodaldata, getGCMprofessionalbyUserid,memberRankIdforPrimary,alljsonData } = useContext(globalData)
   
   const backBtn=()=>{
    let id = memberRankIdforPrimary
    if(props.pageName == "financenotifysuccessor"){
        if(alljsonData == 1){
            if(id == 1 || id == 2 || id == 3 || id == 4 || id == 5 || id == 6){
                // setmodalcontantshow('')
                setmodalcontantshow("step2selectone");
            }else{
                setmodalcontantshow('financeWarning')
            }
        }else{
            if(id == 1 || id == 2 || id == 3 || id == 4 || id == 5 || id == 6){
                setmodalcontantshow('')
             }else{
                 setmodalcontantshow('financeWarning')
             }
        }
       
    }
    else if(props.pageName == "mentalHealthNotifySuccessor")
    if(id == 1 || id == 2 || id == 3 || id == 4 || id == 5 || id == 6){
            setmodalcontantshow('step3selectoption')
        }else{
            setmodalcontantshow('mentalHealthWarning')
        }
   }
  



    return (
        <>
          <Modal.Header style={{ backgroundColor: "#871a27" }}>

                <Modal.Title className='mt-2' id="example-modal-sizes-title-lg">
                    <h3 className='heading text-center' >
                        Notify Agents
                    </h3>
                </Modal.Title>
                {/* closeButton */}

                <button className='closeButton' style={{ border: "1px solid #981b29" }} onClick={handleClose}>x</button>

            </Modal.Header>
            <Modal.Body>
                <h3 className='SelectOptionsh4Tag mb-2 mt-3'>
                   You are required to notify all agents that you are taking over&nbsp;
                    {returnFname(steptwomodaldata.memberFullName)}'s financial affairs, health care affairs, or both.
                </h3>
                <h3 className='SelectOptionsh4Tag mb-2 mt-1'>
                    By clicking continue, you can send the default notification or create your own to send.
                </h3>


                <div className='buttonDiv '>
                    <button className='understandButton fw-bold' onClick={() => setmodalcontantshow("illnessemailtextpreview")}>Continue</button>

                </div>
                <div className='backbuttonDiv'>
                    <button className='backButton' onClick={() => backBtn()} >Back</button>
                </div>
            </Modal.Body>

        </>
    );
}

export default FinessNotifySuccessor;