import React, { useState,useContext } from 'react';
import Modal from 'react-bootstrap/Modal';
import { globalData } from '../CommonModal';
import {returnFname} from '../../control/Constant'

import '../Modal.css';
const EOLYesNeedNo = () => {
    const {setIsGCM, handleClose, setmodalcontantshow, steptwomodaldata } = useContext(globalData)

   
    const   clickbackbutton=()=>{
        setIsGCM('false')
        setmodalcontantshow('eolemailtextpreview')
      }

    return (
        <>
           
                <Modal.Header style={{ backgroundColor: "#871a27" }}>

                    <Modal.Title className='heading' id="example-modal-sizes-title-lg" style={{ color: 'white', fontFamily: "Inter"}}>
                        <span style={{color:"#871a27"}}>S</span>
                    </Modal.Title>
                    {/* closeButton */}

                    <button className='closeButton' style={{ border: "1px solid #981b29" }} onClick={handleClose}>x</button>

                </Modal.Header>
                <Modal.Body>
                    <h4 className='SelectOptionsh4Tag mt-1'>
                    Have you been asked to decide about continuing {returnFname(steptwomodaldata.memberFullName)}'s life support?
                       
                    </h4>
                  
                    <div className='buttonDiv mt-5'>
                        <button className='yesButton' onClick={()=>setmodalcontantshow('eolneedyes')}>Yes</button>
                        <button className='needButton'onClick={()=>setmodalcontantshow('eolneedhelpstep7')} >Need help</button>
                        <button className='noButton' onClick={()=>setmodalcontantshow('step3selectoption')}>No</button>
                    </div>
                    <div className='needbackDiv pt-3 '>
                        <button className='backButton needhelpbackbutton' onClick={clickbackbutton} >Back</button>
                        {/* disabled={continuebuttondisable} */}
                    </div>
                </Modal.Body>
                {/* <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button>
        </Modal.Footer> */}
            {/* </Modal> */}
        </>
    );
}

export default EOLYesNeedNo