import React, { useState, useContext } from 'react';
import Modal from 'react-bootstrap/Modal';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { globalData } from './CommonModal';
import './Modal.css'

const FinancelRole = () => {
  const { handleClose,setmodalcontantshow} = useContext(globalData);
  
  return (
    <>
     
      <Modal.Header style={{ backgroundColor: "#871a27" }}>

        <Modal.Title className='heading' id="example-modal-sizes-title-lg" style={{ color: 'white', fontFamily: "Inter",height:25 }}>

        </Modal.Title>
  <button className='closeButton' style={{ border: "1px solid #981b29" }} onClick={handleClose}>x</button>
      </Modal.Header>
      <Modal.Body>
    
          <h4 className='h4Tag text-center pb-2 pt-4'>
          COMING SOON...
          </h4>

       
        <Container>
          <Row>
            <Col md={4}>  <button className='backButton ms-5' onClick={()=>setmodalcontantshow('financeOptions')}  >Back</button></Col>
            
          </Row>

        </Container>

      </Modal.Body>

      {/* </Modal> */}
    </>
  );
}

export default FinancelRole;