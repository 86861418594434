import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import commonLib from '../control/commonLib';
import konsole from '../Network/konsole';
import HeaderInvitation from './HeaderInvitation';
import Services from '../Network/Services';
import AgentList from './AgentList';
import { Alert } from 'react-bootstrap';


const InvitationList = () => {
  const navigate = useNavigate();

  //state
  const [checkAccept, setcheckAccept] = useState(false)
  const [linkvalidate, setLinkValidate] = useState(false)
  const [alldatashowcheck, setalldatashowcheck] = useState(true)

  //react predefine functions
  useEffect(() => {
    const queryString = window.location.search;
    // let checkAccept = queryString.includes('&&DECLINE')
    // setcheckAccept(checkAccept)
    let alldatashow = queryString.includes('&&SHOW_LIST_ALL')
    setalldatashowcheck(alldatashow)

    const UID = commonLib.getQueryValuefromUrl(queryString, 'UID')
    const Stage = commonLib.getQueryValuefromUrl(queryString, 'Stage')
    const Linktype = commonLib.getQueryValuefromUrl(queryString, 'Linktype')


    if (UID !== "" && Stage !== "" && Linktype !== "null") {
      let result = Services.userValidateLinks(1, UID, Linktype)
      result.then((res) => {
        let response = res?.data?.data
        konsole.log("userValidateLinks", response)
        // navigate('/AgentList', { state: { response: response, checkAccept: checkAccept } });
        if (alldatashow == true) {navigate('/AgentAllList', { state: { response: response, checkAccept: checkAccept } }); }
      }).catch((err) => {
        setLinkValidate(true)
        konsole.log("userValidateLinks", err)
      }).finally(() => {
        konsole.log("userValidateLinks")
      })
    }
    return () => {
      konsole.log("Component unmounted");
    };
  }, []);



  return (
    <>

      {(linkvalidate == true || alldatashowcheck == false) ?
        <div className="container d-flex justify-content-center align-items-center" style={{ minHeight: '100vh' }}>
        <div className="col-lg-6 col-md-8 col-sm-10">
          <Alert variant="danger">
            <Alert.Heading>Invalid Link!</Alert.Heading>
            <p>Please provide the correct link.</p>
          </Alert>
        </div>
      </div>:
        <AgentList />
      }


    </>

  )
}

export default InvitationList
