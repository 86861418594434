import React,{useState,useEffect} from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import commonLib from './control/commonLib';
import  config  from './config.json';
import './Execute/Modal.css'
// import Services from './control/services';
import Services from './Network/Services';
import konsole from './Network/konsole';
import { AoLoginBaseUrl } from './Network/url';

const Header = ({openmodalagentfun,notificationlength}) => {
  const [subtenantImage, setsubtenantImage] = useState("");
  const userLoggedInDetail = commonLib.getObjFromStorage("userLoggedInDetail");

  konsole.log("subtenantImage",subtenantImage)

  const handleClick = () => {
    const stateObj = commonLib.getObjFromStorage('stateObj');
    let params = `appState=${stateObj.appState}&userId=${stateObj.userId}&roleId=${stateObj.roleId}&loggenInId=${stateObj.loggenInId}&success=${true}`;
    const paramsdec=window.btoa(params)
    // window.location.replace(`${config.Ao_LOGIN}Signout?token=` +params);

    if (stateObj.roleId == 5) {
      sessionStorage.clear()
      window.location.replace(`${AoLoginBaseUrl}Account/Signout?` + params);
    } else {
      commonLib?.closeParentModal()
    }
    // window.location.replace(config.Ao_LOGIN+"Signout?token="+params);
  }
  useEffect(() => {
    const subtenantId = commonLib.getObjFromStorage("subtenantId");
    Services.getSubtenantDetail(subtenantId, true)
      .then((res) => {
        konsole.log("responseresponse", res)
        setsubtenantImage(res.data?.data[0]?.subtenantLogoUrl);
      })
      .catch((err) => {
        konsole.log("ErrorData", err);
      });
  }, []);



  return (
    <div className='container-fluid   border-0 border-bottom'>

      <div className='row'>
        <div className='col-sm-4 col-md-9 col-lg-9   ' >
          <img id="subtenantImageLogo" src={subtenantImage} style={{width:"140px"}} ></img>
        </div>
        <div className='col-sm-6 col-md-3 col-lg-3  justify-content-end mt-2 logosumit '>

          <div style={{ position: 'relative', width: '40px', height: '40px' }}>
            <div onClick={()=>openmodalagentfun()}   style={{
              position: 'absolute',
              top: '-2px',
              right: '6px',
              borderRadius: '50%',
              width: '20px',
              height: '20px',
              backgroundColor: '#720C20',
              color: 'white',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              fontSize: '12px',
              cursor:'pointer'
            }}> {notificationlength} </div>
            {( notificationlength !==0) ?<img src= 'BellGIF.gif' className='mt-2 ' style={{cursor:"pointer",height:"2.2rem"}} onClick={()=>openmodalagentfun()} ></img>:
              <img src= 'BeLLIcon2.png' className='mt-2 ' style={{cursor:"pointer",height:"2.2rem"}} onClick={()=>openmodalagentfun()} ></img>}
          </div>
          <div className='mt-2 ms-2'><p >Hi, {userLoggedInDetail.loginUserName}</p></div>
          <div>
            <img onClick={handleClick} className='admin ms-2 pt-2' style={{cursor: 'pointer'}} src='icons/logoutcircle.svg' width={25}/> </div>
        </div>
      </div>
    </div>
  )
}

export default Header