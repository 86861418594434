import "./App.css";
import Login from "./components/Login";
import PrivateRoute from "./components/Routes/PrivateRoute";
import CommonModal from "./components/Execute/CommonModal";
import AboutSection from "./components/About/AboutSection";
import FileCabinet from "./components/FileCabinet/Filecabinet";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Modalfilecabinet from "./components/FileCabinet/Modalfilecabine";
import IntitationList from "./components/AgentInvitation/InvitationList";
import AgentList from "./components/AgentInvitation/AgentList";
import AgentAllList from "./components/AgentInvitation/AgentAllList";
import AccessDocuments from "./components/AccessDocument/AccessDocuments";
function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" exact element={<Login />} />
          <Route
            path="/index"
            exact
            element={
              <PrivateRoute>
                <CommonModal />
              </PrivateRoute>
            }
          />

          <Route
            path="/myroles"
            exact
            element={
              <PrivateRoute>
                <AboutSection />
              </PrivateRoute>
            }
          />
            <Route
            path="/InvitationList"
            exact
            element={
             <IntitationList />
            }
          />
              <Route
            path="/AgentList"
            exact
            element={
             <AgentList />
            }
          />
             <Route
            path="/AgentAllList"
            exact
            element={
             <AgentAllList />
            }
          />
          
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
