import React, { useState, useContext } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Modal from "react-bootstrap/Modal";
import { globalData } from "../Execute/CommonModal";
import '../Execute/Modal.css'
import { aorgLink, aorgLinkFunc } from "../control/Constant";
import { returnFname } from "../control/Constant";

import "../Execute/Illness/Warning.css";
import konsole from "../Network/konsole";
const FinanceGoalNStep2 = (props) => {
    const { handleClose, setmodalcontantshow, steptwomodaldata, modalcontantshow, stepsToFollow } = useContext(globalData)


    const openWindow = () => {
        let role = null;
        let pageName = "";

        if (props.message == "4") {
            role = 2;
            pageName = "paybills";
        }

        if (props.message == "5") {
            role = 3;
            pageName = 'filetaxes';
        }

        if (props.message === "6") {
            role = 3
            pageName = modalcontantshow;
        }

        if (props.message === "7") {
            role = 14
            pageName = modalcontantshow;
        }
        openWindows(role, pageName)
    }


    const openWindows = (role, pageName) => {
        localStorage.setItem("keypoints", JSON.stringify(stepsToFollow));
        konsole.log("stepterter", stepsToFollow, steptwomodaldata)
        window.location.replace(aorgLinkFunc("Finance", pageName, role, steptwomodaldata));
      }


    return (
        <>
            <Modal.Header style={{ backgroundColor: "#871a27" }}>
                <Row>
                    <Col sm={11}>
                        <h3 className="heading">Goal and Steps</h3>
                    </Col>
                    <Col sm={1}>
                        <button
                            className="closeButton"
                            style={{ border: "1px solid #981b29" }}
                            onClick={handleClose}
                        >
                            x
                        </button>
                    </Col>
                </Row>
            </Modal.Header>
            <Modal.Body>

                <h4 className="SelectOptionsh4Tag mb-4 mt-2">
                    {
                        (props.message == "4") ?
                            `Choose a professional or a service provider to pay the bills for ${returnFname(steptwomodaldata.memberFullName)}.`
                            : (props.message == "5") ?
                                `Choose a professional who could help you for filing the taxes for ${returnFname(steptwomodaldata.memberFullName)}.`
                                :
                                (props.message === "6") ?
                                    `${returnFname(steptwomodaldata.memberFullName)} did not choose any of the financial professional team for managing their investments. Please choose a professional to help you manage finances.`
                                    :
                                    (props.message === "7") ?
                                        `${returnFname(steptwomodaldata.memberFullName)} did not choose any of the financial professional team for managing their real property. Please choose a professional to help you manage finances.`
                                        :
                                        <></>
                    }
                </h4>



                <div className="d-flex flex-column align-items-center gap-3 justify-content-around">
                    <button className="healthButton" onClick={() => openWindow()} >
                        Choose a professional
                    </button>
                    {
                        props.message === "4" ?
                            <button className="healthButton" onClick={() => window.open("https://silverbills.com/pricing/", "_blank")}>
                                Pay using silver bills service
                            </button>
                            :
                            <></>
                    }

                </div>


                <div className='buttonsDiv'>
                    <button className='backButton' onClick={() => setmodalcontantshow(props.goBack)}>Back</button>
                </div>
            </Modal.Body>
        </>
    )
}
export default FinanceGoalNStep2;    