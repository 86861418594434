import React, { useContext, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Container from "react-bootstrap/Container"
import { globalData } from '../components/Execute/CommonModal';
import '../components/otherSetting.css'
import PhoneInput from 'react-phone-number-input';

import 'react-phone-number-input/style.css';

const OtherSetting = () => {
    const { setillushospitalstep6help, setIsGCM, handleClose, setmodalcontantshow, steptwomodaldata, addManageStepToFollow, setHospitalDetails,setSettingDetails} = useContext(globalData)
    const [formData, setFormData] = useState({
        settingName: '',
        phoneNumber: '',
        facilityType: '',
        other :''
      });
    
      const [errors, setErrors] = useState({});
    
    const handleChange = (e) => {
       if((e?.target == undefined || e?.target == null)){
        setFormData({
            ...formData,
            phoneNumber: e
          });
       }else{
        const { name, value } = e.target;
        const capitalized = value.charAt(0).toUpperCase() + e.target.value.slice(1)
            setFormData({
          ...formData,
           [name]: capitalized
        });
       }
        
        // if()
       
      }
    
      const handleSubmit = (e) => {
        e.preventDefault();
        const newErrors = {};
       
        // if (formData.settingName.trim() === '') {
        
        //   newErrors.settingName = 'Setting name is required';
        // }
         
        // if (formData.mobileNumber.trim() === '') {
        //   newErrors.mobileNumber = 'mobileNumber is required';
        // }
        // if (formData.facilityType === '') {
        //   newErrors.facilityType = 'Facility type is required';
        // }
        // if (formData.other === '') {
        //   newErrors.other = 'Other type is required';
        // }
     
        if (Object.keys(newErrors).length > 0) {
          setErrors(newErrors);
        } else {
            customSelectOptions('Other')
            setSettingDetails(formData)
        }
      }
    
    
    const selectoption = (e) => {
        if (e == 'Hospital') {
          setmodalcontantshow('illnesshospitalstep6')
          addManageStepToFollow("setillushospitalstep6help", "Hospital");
        } else if (e == 'Home') {
          // setillushospitalstep6help('Home')
          setmodalcontantshow('illnesshomemedical')
          addManageStepToFollow("setillushospitalstep6help", "Home");
        } else if (e == 'Other') {
          // setillushospitalstep6help('Other')
          setmodalcontantshow('illnesshomemedical')
          addManageStepToFollow("setillushospitalstep6help", "Other");
        }
    
      }
    
      const customSelectOptions = (options) => {
        selectoption(options);
      }
    return (
        <>
            <Modal.Header style={{ backgroundColor: "#871a27" }}>
                <Row >
                    <Col sm={11}>
                        <h3 className='heading locateTitle'>
                            Step 4(b) :Setting Details
                        </h3>
                    </Col>
                    <Col sm={1} >
                        <button className='closeButton' style={{ border: "1px solid #981b29" }} onClick={handleClose}>x</button>
                    </Col>
                </Row>

                <button className='closeButton' onClick={handleClose}>x</button>

            </Modal.Header>
            <Modal.Body>

                <Container>
                    <div className="container-other">

                        <form onSubmit={handleSubmit}>
                            <div className="form-group">
                                <label htmlFor="settingName">Name of Setting:</label>
                                <input type="text" id="settingName" name="settingName" value={formData.settingName} onChange={handleChange}  />
                               <p className='text-danger'>{errors.settingName && <div className="error">{errors.settingName}
                                </div>}</p> 
                            </div>
                            
                            {/* <div className="form-group">
                                <label htmlFor="mobileNumber">Phone Number:</label>
                                <input type="number"id="mobileNumber" name="mobileNumber" value={formData.mobileNumber} onChange={handleChange}/>
                              <p className='text-danger'>{errors.mobileNumber && <div className="error">{errors.mobileNumber}</div>}</p>
                            </div> */}
                            <div className="form-group">
                                <label htmlFor="phoneNumber">Phone Number:</label>
                                <PhoneInput
                                    international
                                    defaultCountry="US"
                                    className='county'
                                    name="mobileNumber"
                                    value={formData.phoneNumber}
                                    onChange={handleChange}
                                    maxLength={15}
                                    countryCodeEditable={false}
                                    
                                    
                                />
                                {errors.phoneNumber && <div className="error">{errors.phoneNumber}</div>}
                            </div>
                            <div className="form-group">
                                <label htmlFor="facilityType">Type of Facility:</label>
                                <select id="facilityType" name="facilityType" className="custom-select" value={formData.facilityType} onChange={handleChange}>
                                    <option value="" disabled>Select Facility Type</option>
                                    <option value="Group Home">Group Home/Adult Family Home</option>
                                    <option value="Assistant Level">Assisted Living</option>
                                    <option value="Memory care">Memory care</option>
                                    <option value="Nursing Care">Nursing Care</option>
                                    <option value="other">Other</option>
                                </select>
                             <p className='text-danger'>{errors.facilityType && <div className="error">{errors.facilityType}</div>}</p>
                            </div>
                            {formData.facilityType == 'other' && 
                            <>
                              <div className="form-group">
                                <label htmlFor="roomNumber">Description:</label>
                                <input type="text" id="other" name="other" value={formData.other} onChange={handleChange}/>
                              <p className='text-danger'>{errors.other && <div className="error">{errors.other}</div>}</p>
                            </div>
                            </>}
                          
                            <div className='d-flex justify-content-between'>
                                <button className='backButton' onClick={() => setmodalcontantshow('step4illness')}>Back</button>
                                <button type="submit" className='backButton'>Next</button>
                            </div>
                        </form>
                    </div>
                </Container>
                {/* //  onClick={()=>customSelectOptions('Other')} */}
              
            </Modal.Body>
        </>
    )
}

export default OtherSetting