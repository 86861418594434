import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import konsole from "./Network/konsole";
import Services from "./Network/Services";
import commonLib from './control/commonLib'
import config from './config.json';
import { AoLoginBaseUrl } from "./Network/url";
import { rolesOfParalegalAttorney } from "./control/Constant";

const Login = (props) => {
    const navigate = useNavigate();
    const tokenKey = useLocation().search;
    useEffect(() => {
        const tokenMapKey = commonLib.GetQueryValues(tokenKey, "token");
        konsole.log("tokenMapKey", tokenMapKey);
        const decodedKey = window.atob(tokenMapKey);
        const loggenInId = commonLib.GetQueryValues(decodedKey, "loggenInId") || "";
        const userId = commonLib.GetQueryValues(decodedKey, "userId") || "";
        const roleId = commonLib.GetQueryValues(decodedKey, "roleId") || "";
        const appState = commonLib.GetQueryValues(decodedKey, "appState") || "";
        const agentUserId = commonLib.GetQueryValues(decodedKey, "agentUserId") || "";
        const openPage = commonLib.GetQueryValues(decodedKey, "openPage") || "";
        const selectedRole = commonLib.GetQueryValues(decodedKey, "selectedRole") || "";
        const selectedModule = commonLib.GetQueryValues(decodedKey, "selectedModule") || "";
        const memberUserId = commonLib.GetQueryValues(decodedKey, "memberUserId") || "";

        if (selectedModule !== "") commonLib.setSessionStoarge("openPage", openPage)
        if (selectedRole !== "") commonLib.setSessionStoarge("selectedRole", selectedRole)
        if (selectedModule !== "") commonLib.setSessionStoarge("selectedModule", selectedModule)
        if (memberUserId !== "") commonLib.setSessionStoarge("memberUserId", memberUserId)
        if (openPage !== "") commonLib.setSessionStoarge("openPage", openPage);


        if (loggenInId !== "" && userId !== "" && roleId !== "null" && appState !== "null") {
            getLoggedInUser(userId, appState, loggenInId, roleId, agentUserId);
        }
        else {
            window.location.replace(AoLoginBaseUrl + 'Account/UnauthorizedAccess');
        }
    }, [])


    const getLoggedInUser = (userId, appState, loggenInId, roleId, agentUserId) => {
        const promises = Services.getLoggedInUser(userId, appState, loggenInId, roleId)
        promises.then(response => {
            if (response) {
                let loginDetail = {};
                if (response.data !== null || response.data !== "") {
                    const stateObj = {
                        loggenInId: loggenInId,
                        userId: userId,
                        roleId: roleId,
                        appState: appState
                    }
                    commonLib.setSessionStoarge("stateObj", JSON.stringify(stateObj));
                    sessionStorage.setItem("subtenantId", response.data.data.subtenant_Id);
                    sessionStorage.setItem("SubtenantName", response.data.data.subtenantName);
                    sessionStorage.setItem("AuthToken", response.data.data.accessToken);
                    getContactByUserId(userId);
                    loginDetail = {
                        loggedUserId: userId,
                        roleId: response.data.data.roleId,
                        memberName: response.data && response.data.data.memberName,
                        primaryEmailId: response.data && response.data.data.primaryEmailId,
                        loginUserEmailId : response.data && response.data.data.loginUserEmailId,
                        userName: response.data && response.data.data.userName,
                        spouseUserId: response.data && response.data.data.spouseUserId,
                        loginUserName: response.data && response.data.data.loginUserName,
                        primaryPhoneNumber: response.data && response.data.data.primaryPhoneNumber

                    }
                    if (agentUserId !== "") {
                        loginDetail.loggedUserId = agentUserId;
                        getUserAgent(agentUserId, loginDetail, roleId,response.data.data);
                    } else {
                        if (appState != null && roleId == 5) {
                            const tokenMapKey = commonLib.GetQueryValues(tokenKey, "aorg");
                            navigate('/index', { state: tokenMapKey });
                        };
                    }
                    
                    sessionStorage.setItem("userLoggedInDetail", JSON.stringify(loginDetail));
                }
            }
        }).catch(err => {
            konsole.log("err", err);
            if(roleId ==5){
                window.location.replace(AoLoginBaseUrl + 'Account/UnauthorizedAccess');
            }else{
                commonLib.closeParentModal()
            }
        })
            .finally(() => {
                konsole.log("finish");
            })
    }



    const getUserAgent = async (agentUserId, loginUserDetail, roleId,json) => {
        let result = Services.getUserDetailsByEmailId(agentUserId)
        result.then((res) => {
           
            if(res.data.length > 0){
            // loginUserDetail.loginUserName = commonLib.showFullName(res.data.data.member);
            loginUserDetail.loginUserName = res.data[0]?.userFullName ;
            loginUserDetail.primaryEmailId = res.data[0]?.emailId;
            loginUserDetail.primaryPhoneNumber = res.data[0]?.mobileNo;
            loginUserDetail.loggedUserId = res.data[0]?.userId;
            loginUserDetail.loginUserEmailId = res.data[0]?.emailId;
            // loginUserDetail.feedBackUserId 

          
            sessionStorage.setItem("userLoggedInDetail", JSON.stringify(loginUserDetail));
            // if (roleId == 9 || roleId == 3 || roleId ==13 || roleId ==14 || roleId ==15 ) {
                if(rolesOfParalegalAttorney.includes(Number(roleId))){
                const tokenMapKey = commonLib.GetQueryValues(tokenKey, "aorg");
                navigate('/index', { state: tokenMapKey });
            }
            }
            else{
            let result = Services.getMember(agentUserId)
            result.then((res) => { 
            loginUserDetail.loginUserName = commonLib.showFullName(res.data.data.member);
            loginUserDetail.loggedUserId = agentUserId;
            getContactByUserId(agentUserId, loginUserDetail, roleId);
            
        }).catch((err) => {
            konsole.log("User", err)
        })
            }
        }).catch((err) => {
            konsole.log("User", err)
        })
    }

    const getContactByUserId = (userId, loginUserDetail, roleId) => {
        const response = Services.getContactbyuserId(userId);
        response.then((res) => {
            const emails = res.data.data.contact.emails.filter(d => (d.contactTypeId === 1));
            const mobiles = res.data.data.contact.mobiles.filter(d => (d.contactTypeId === 1));

            loginUserDetail.primaryEmailId = (emails.length > 0) ? emails[0].emailId : "";
            loginUserDetail.loginUserEmailId = (emails.length > 0) ? emails[0].emailId : "";
            loginUserDetail.primaryPhoneNumber = (mobiles.length > 0) ? mobiles[0].mobileNo : ""
            sessionStorage.setItem("userLoggedInDetail", JSON.stringify(loginUserDetail));
            konsole.log('sfsadgfsdgdfgdfgh', loginUserDetail);
            // if (roleId == 9 || roleId == 3) {
            if (rolesOfParalegalAttorney.includes(Number(roleId))) {
                const tokenMapKey = commonLib.GetQueryValues(tokenKey, "aorg");
                navigate('/index', { state: tokenMapKey });
            }
        }).catch((error) => {

        })
    }


    // const getSubtenantDetail = (subtenantId, isActive) => {
    //   setLoader(true);
    //   const promises = Services.getSubtenantDetail(subtenantId, isActive);
    //   promises.then(res => {
    //     setLoader(false);
    //     konsole.log('subtenantResObj', res);
    //     const subtenantRes = res.data.data[0];
    //     setSubtenantLogo(subtenantRes.subtenantLogoUrl);
    //     const seminarEventObj = commonLib.getEventListSubtenantObj(subtenantRes);
    //     getSeminarPublisherDetail(subtenantId, true, seminarId, seminarEventObj);
    //   }).catch(err => {
    //     konsole.log("error", err.response);
    //     setLoader(false);
    //   })
    // }



    return (
        <div>
            {
                <>
                </>
            }
        </div>
    )
};


export default Login;

