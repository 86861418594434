import React, { useState, useContext, useEffect, useRef } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Modal from "react-bootstrap/Modal";
import { globalData } from "../CommonModal";
import '../Modal.css'
import OccuranceCom2 from "../../OccuranceCom2";
import commonLib from "../../control/commonLib";
import { returnFname ,loginUserEmail} from "../../control/Constant";

// import "../Execute/Illness/Warning.css";

const EOLEmailforElderAttorney = ({selectedFinacePage, goBack,...props}) => {
    const {lenderemailtexttype, elderlawAttorney,handleClose,allProfessionals,setmodalcontantshow, steptwomodaldata, NotifyContinueCallApi, setSelectoptionmessage,sendEmailOrText,gcmDetails,getGCMprofessionalbyUserid,getGCMprofessionalbyUseridforLocate,setloader,primaryAgents,certifyAgent} = useContext(globalData)
    const [textareavalue, settextareavalue] = useState()
    const [sendbuttondisabled, setbuttondesabled] = useState()
    const stateObj = commonLib.getObjFromStorage("stateObj");
    let userLoggedInDetails =JSON.parse(sessionStorage.getItem('userLoggedInDetail'))
    let primaryAgentname = primaryAgents.length == 0 ? "" : primaryAgents.map((ele,index)=>{return (ele.length == 1 ? ele.fullName : `${" "+ele.fullName}${(primaryAgents.length - 1 === index) ? '' : ' '}`)})


    let elderlawAttorneyDetails=(elderlawAttorney !=='') && elderlawAttorney[0]
    let elderlawAttorneycheck = (elderlawAttorney !== '' && elderlawAttorney.length !== 0) ? true : false

    let occurranceRef = useRef(null)
    const userLoggedInDetail = commonLib.getObjFromStorage("userLoggedInDetail");


useEffect(()=>{ 
  const textareavalue = "We are sorry to report that {{userName}}'s health condition is not well currently. {{loginuserName}} requires your help to handle the situation."
  const textvalueNonEla = "My name is {{agentName}}. I need assistance with helping {{CLINTNAME}}, discharged from the hospital back home instead of a care facility. I understand you help with these types of issues. Or help me locate a good housing option so {{CLINTNAMES}} does not experience repeated moves. If you are able to help please call or text me at {{agentnumber}} or email me at {{agentEmail}}. I look forward to hearing back from you." 

  elderlawAttorneycheck == true ? callfunction(textareavalue) : callfunction(textvalueNonEla)
  ;
  

},[])
const callfunction = (textareavalue) => {
  let newstr = textareavalue.replaceAll('{{userName}}',steptwomodaldata.memberFullName).replaceAll("{{agentName}}",userLoggedInDetails.loginUserName).replaceAll("{{CLINTNAME}}",steptwomodaldata.memberFullName).replaceAll("{{agentnumber}}",userLoggedInDetail?.primaryPhoneNumber ? (numberFormate(userLoggedInDetail?.primaryPhoneNumber)):"not available").replaceAll("{{agentEmail}}",loginUserEmail()).replaceAll("{{CLINTNAMES}}",returnFname(steptwomodaldata.memberFullName)).
  replaceAll('{{loginuserName}}', userLoggedInDetail.loginUserName)
  settextareavalue(newstr)
  setSelectoptionmessage(newstr)

}



    const handleChange = (e) => {
        settextareavalue(e.target.value)
        setSelectoptionmessage(e.target.value)
    }
    const textarea = () => {
        return (
            <textarea className="form-control pt-0 ps-3 fs-4  text-dark  shadow-none border-0" rows="3" value={textareavalue} onChange={handleChange}></textarea>
        )
    }





    const clicknotifysend = () => {
        const stateObj = commonLib.getObjFromStorage("stateObj");
        let typecall=lenderemailtexttype
        let subtenantId = sessionStorage.getItem('subtenantId')
        let userLoggedInDetail =JSON.parse(sessionStorage.getItem('userLoggedInDetail'))
        const elderlawAttorney = {
                userPhoneNo : elderlawAttorneyDetails?.mobileNumbers ?? elderlawAttorneyDetails?.proPhoneNo,
                userEmail : elderlawAttorneyDetails?.emaidIds ?? elderlawAttorneyDetails?.proEmailId,
                type : "elderlawattorney",
                contentForMail : textareavalue,
                agentName : userLoggedInDetail?.loginUserName,
                agentEmail : loginUserEmail(),
                agentMobileNo : userLoggedInDetail?.primaryPhoneNumber
            }
           
            if(stateObj.roleId == "5"){
               if(elderlawAttorneycheck == true){
                    setloader(true)
                    occurranceRef?.current?.calloccuranceIdfun(24, subtenantId,elderlawAttorney,typecall);
                }else{
                    for(let i = 0;i < allProfessionals.length; i++){
                        setloader(true)
                        let professUserData = {
                          contentForMail: textareavalue,
                          agentName: userLoggedInDetail?.loginUserName,
                          agentEmail: loginUserEmail(),
                          agentMobileNo: userLoggedInDetail?.primaryPhoneNumber,
                          userEmail: allProfessionals[i]?.proPrimaryEmail,
                          userPhoneNo: allProfessionals[i]?.proPrimaryContact,
                          memberFullName: allProfessionals[i]?.proFullName,
                          type: "elderlawattorney",
                        }
                        occurranceRef?.current?.calloccuranceIdfun(24, subtenantId,professUserData,typecall);
                       }
                }
                 setmodalcontantshow('eolstep10')
            }else{
                setmodalcontantshow('eolstep10') 
            }

 }
    const clicksendlater = async() =>{
        setloader(true)
      await getGCMprofessionalbyUserid(7, true);
      await getGCMprofessionalbyUseridforLocate(13, true);
        setTimeout(()=>{
            setmodalcontantshow('eolstep10')
        },2000)
    }

 const numberFormate = (number) => {
    const lastTenDigitArray = array => array.slice(-10);
    return commonLib.convertToUSFormat(lastTenDigitArray(number), "1")
      }

    return (
        <>
       

            <Modal.Header style={{ backgroundColor: "#871a27" }}>
                <Row style={{ height: "30px" }}>
                    <Col sm={11}>
                        <h3 className="heading">Successor Agent Preview</h3>
                    </Col>
                    <Col sm={1}>
                        <button
                            className="closeButton"
                            style={{ border: "1px solid #981b29" }}
                            onClick={handleClose}
                        >
                            x
                        </button>
                    </Col>
                </Row>
            </Modal.Header>
            <Modal.Body>
                <div className="form-outline border border-2 rounded fs-4">
                    <label className="mb-0 ps-3  text-secondary">Dear User</label>
                    {textarea()}
                    {/* <h6 className="ps-3 textData">Regards</h6> */}
                    <h6 className="ps-3 textData mb-1">{certifyAgent === "illnessNo" ? returnFname(userLoggedInDetails.loginUserName) : primaryAgentname}</h6>
                    {/* <h6 className="ps-3 textData">{userLoggedInDetails?.primaryEmailId}</h6>
                    <h6 className="ps-3 textData mb-1">{numberFormate(userLoggedInDetails?.primaryPhoneNumber)}</h6> */}
                </div>
                <div className='stepsevenbuttonsDiv'>
                    <button className="backButton" onClick={() => setmodalcontantshow('contactToelderlawattorney')} >
                        Back
                    </button>
                    {stateObj.roleId !== "5" && 
                    <button className='laterButton' onClick={clicksendlater} disabled={sendbuttondisabled} >Send Later & Proceed</button>}
                    <button className='backButton' onClick={()=>clicknotifysend()} disabled={sendbuttondisabled} >
                        Send</button>
                </div>
            </Modal.Body>

            <OccuranceCom2 ref={occurranceRef}/>
        </>
    )
}
export default EOLEmailforElderAttorney;