import konsole from "../Network/konsole";
import { aorgLink } from "../Network/url";
import commonLib from "./commonLib";
import Services from "../Network/Services";
import JSZip from 'jszip';
import { Buffer } from 'buffer';
export const mapApi = "https://maps.googleapis.com/maps/api/js";

export const mapKey = 'AIzaSyBaUn22pwovCzOxH7Uthivbd8_ScMkaEAI';

export const aorgLinkFunc = (selectedModule, openPage, selectedRole, memberData) => {
  const stateObj = commonLib.getObjFromStorage('stateObj');
  let forPreview = stateObj?.roleId == "5" ? "" : `&agentUserId=${memberData.agentUserId}`
  const token = returnOjString(stateObj) + `selectedModule=${selectedModule}&openPage=${openPage}&selectedRole=${selectedRole}&memberUserId=${memberData.memberUserId}${forPreview}`
  return `${aorgLink}?token=${window.btoa(token)}`
}

const returnOjString = (object) => {
  let string = "";
  for (const [key, value] of Object.entries(object)) {
    string += `${key}=${value}&`
  }
  return string;
}
export const returnFname = (name) => {
  const nameParts = name?.split(" ");
  const suffixes = ["Dr.", "Mr.", "Mrs.", "Ms.", "Jr.", "Sr.", "II", "III"]; // Add more if needed
  const isFirstPartSuffix = suffixes.includes(nameParts[0]);
  if (isFirstPartSuffix) {
    return nameParts.slice(0, 2).join(" "); // Combine suffix and first name
  } else {
    return nameParts[0]; // Return only the first name
  }
}

export const loginUserEmail = () => {
  let userLoggedInDetails = JSON.parse(sessionStorage.getItem('userLoggedInDetail'))
  let string = userLoggedInDetails?.loginUserEmailId
  return string;
}
export const replaceDocumentFile = async (data) => {
  const fetchedDocx = await fetch('AgentCertification.docx');
  const zip = new JSZip();
  const buffer = await fetchedDocx.arrayBuffer();
  const loadedZip = await zip.loadAsync(buffer);

  for (const [relativePath, file] of Object.entries(loadedZip.files)) {
    if (relativePath.endsWith('.xml')) {
      let content = await file.async('text');
      data.forEach(({ textToReplace, replacementText }) => {
        content = content.replace(new RegExp(textToReplace, 'g'), replacementText);
      });
      loadedZip.file(relativePath, content);
    }
  }

  const updatedContent = await loadedZip.generateAsync({ type: 'uint8array' });
  const modifiedBlob = new Blob([Buffer.from(updatedContent)], {
    type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
  }); 

  const originalFilename = 'AgentCertification.docx'; // Original filename

  return new File([modifiedBlob], originalFilename, {
    type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    lastModified: Date.now() // Current timestamp for last modified
  });
};


export const returnGcm = (memberUserId, subId) => {
  return new Promise((resolve, reject) => {
    Services.getSubjectResponseDataGcm(memberUserId, subId).then((res) => {
      let responseId = res?.data?.data?.userSubjects[0]?.responseId
      resolve(responseId)
    }).catch((err) => {
      konsole.log('err of client insurance', err)
      resolve('err')
    })
  })

}
export const returnHealthPolicyCount = (id) => {
  return new Promise((resolve, reject) => {
    Services.getClintInsurenceInfo(id).then((res) => {
      let responseId = res?.data?.data?.longTermIns
      resolve(responseId)
    }).catch((err) => {
      konsole.log('err of client insurance', err)
      resolve('err')
    })
  })

}
export const returnMemberEmail = (fileId) => {
  return new Promise((resolve, reject) => {
    Services.getContactbyuserId(fileId).then((res) => {
      let responseData = res?.data?.data?.contact?.emails.filter((ele) => {
        return ele?.contactTypeId == 1
      })
      resolve(responseData)
    }).catch((err) => {
      konsole.log('err of client insurance', err)
      resolve('err')
    })
  })

}
const returnClintAddress = (userId,setloader) => {
  return new Promise((resolve, reject) => {
    setloader(true)
    Services.getClintAddress(userId).then((res) => {
      let address = res.data.data?.addresses.length > 0 ? res.data.data?.addresses[0]: "";
      resolve(address)
      setloader(false)
    }).catch((err) => {
      konsole.log('err of client insurance', err)
      resolve('err')
    })
  })

}
export const returnBase64File = (fileId,setloader) => {
  return new Promise((resolve, reject) => {
    setloader(true)
    Services.getBase64FileInfo(fileId).then((res) => {

      let fileDocObj = res.data.data;
      let data = "data:application/pdf;base64," + fileDocObj?.fileInfo?.fileDataToByteArray;
      resolve(data)
      setloader(false)
    }).catch((err) => {
      konsole.log('err of client insurance', err)
      resolve('err')
    })
  })

}
export const returnFileFormFileCabinate = (memberUserId,setloader) => {
  // debugger;
  return new Promise((resolve, reject) => {
    setloader(true)
    Services.getSharedFileStatus({ primaryMemberUserId: memberUserId}).then((res) => {
        let shareFileList = res?.data?.data.filter(({ isRead }) => isRead == true)
  
        setloader(false)
      if (shareFileList.length > 0) {
        setloader(true)
        Services.getFileFromCabinet(shareFileList[0].primaryUserId).then((res) => {
          setloader(false)
          konsole.log('res of file from cabinetaa',res)
          let responseData = res?.data?.data?.filter((item) => item?.fileStatusName != "Archived")
          konsole.log('responseDataresponseData', responseData)
          let newArr = []

          for (let [index, item] of shareFileList.entries()) {

              for (let i = 0; i < responseData?.length; i++) {
                 if (shareFileList[index].fileId == responseData[i].fileId) {
                      const newArrayinfo=({fileId:shareFileList[index].fileId,belongsTo:responseData[i].fileBelongsTo,belongsToRelationShipName:responseData[i].belongsToRelationShipName,belongsToMemberName:responseData[i].belongsToMemberName,fileTypeName:responseData[i].fileTypeName,fileTypeId:responseData[i].fileTypeId,fileStatusId:responseData[i].fileStatusId})
                      newArr.push(newArrayinfo)
                    }
              }
          }

          resolve(newArr)
      }).catch((err) => {
          setloader(false)
          konsole.log('err in getting file ', err, err?.response)
          resolve('err')
      })
      }
      
  }).catch((err) => {
      setloader(false)
      // console.log('err in getting file ', err, err?.response)
  })
  })

}
function mergeArraysById(arr1, arr2) {
  let mergedArray = [];
  arr1.forEach(obj1 => {
      let matchingObj = arr2.find(obj2 => obj2.fileId === obj1.fileId);
      if (matchingObj) {
          let mergedObject = { ...obj1, ...matchingObj };
          mergedArray.push(mergedObject);
      } else {
          mergedArray.push(obj1);
      }
  });

  return mergedArray;
}
export const getBase64FromFile = async (file) => {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.onload = () => {
      resolve("data:application/pdf;base64," +  reader.result.split(',')[1]); // Extracting base64 content
    };
    reader.readAsDataURL(file);
  });
};
export const returnAttorny = (subtenantId, isActive, roleId) => {
  return new Promise((resolve, reject) => {
    Services.getUserListByRoleId(subtenantId, isActive, roleId).then((res) => {
      let fileDocObj = res.data.data;
      let newJson = fileDocObj.length > 0 && fileDocObj.map((ele) => {
        return ele?.primaryEmail
      })
      resolve(newJson)
    }).catch((err) => {
      konsole.log('err of client insurance', err)
      resolve('err')
    })
  })

}
export const returnRoleChangeOfAgents = (json) => {
  return new Promise((resolve, reject) => {
    Services.upsertUserAgent(json).then((res) => {
      resolve(res)
    }).catch((err) => {
      konsole.log('err of client insurance', err)
      resolve('err')
    })
  })

}
export const returnMembersEmail = (agentRoleId, memberUserId, notifyId, notifyMsg, agentUserId) => {
  return new Promise((resolve, reject) => {
    Services.postsendnotifyemailtomemberrelativeList(agentRoleId, memberUserId, notifyId, notifyMsg, agentUserId).then((res) => {
      let responseData = res.data?.data?.memberDetails
      let subtenantId = sessionStorage.getItem('subtenantId')
      let userLoggedInDetail = JSON.parse(sessionStorage.getItem('userLoggedInDetail'))
      let filteredData = responseData?.filter((ele) => {
        return ele?.relativeUserId != userLoggedInDetail?.loggedUserId && ele?.relativeUserId != memberUserId

      })
      resolve(filteredData)
    }).catch((err) => {
      konsole.log('err of client insurance', err)
      resolve('err')
    })
  })

}
export const returnProfessionals = async (ProSerDescId, ProTypeId, PageNumber, RowsOfPage, setloader, id, step) => {
  try {
    setloader(true);
    let addresses = await returnClintAddress(id, setloader);
    let searchText = step === 2 ? addresses?.county?.split(" ")[0] : addresses?.city?.split(" ")[0];

    const response = await Services.getProfessionalUsers(ProSerDescId, ProTypeId, PageNumber, RowsOfPage, searchText);
    
    setloader(false);
    return response.data?.data;
  } catch (err) {
    if (step !== 2) {
      return returnProfessionals(ProSerDescId, ProTypeId, PageNumber, RowsOfPage, setloader, id, 2);
    } else {
      setloader(false);
      return 'err';
    }
  }
};
export const returnPostProfessional = (userProId, proUserId, proCatId, userId, lpoStatus, isActive, upsertedBy,setloader) => {
  let json = [{
    'userProId': userProId,
    'proUserId': proUserId,
    'proCatId': proCatId,
    'userId': userId,
    'lpoStatus': lpoStatus,
    'isActive': isActive,
    'upsertedBy': upsertedBy
  }]
  return new Promise((resolve, reject) => {
    setloader(true)
    Services.postProfessionalUsers(json).then((res) => {
      let response = res.data?.data
      setloader(false)
      resolve(response)
     
    }).catch((err) => {
      setloader(false)
      konsole.log('err of client insurance', err)
      resolve('err')
    })
  })

}
export const rolesOfParalegalAttorney = [3, 9, 13, 14, 15]
export const isNotValidNullUndefinedfile = (value) => {
  if (value !== undefined && value !== null && value !== '') {
    return true;
  }
  return false
}
export const removeDuplicateValue = (array) => {
  return array.filter((obj, index, self) =>
      index === self.findIndex((o) => o.agentId === obj.agentId)
  );
}


export const imagePath = [
  "icons/personalInfoHeader.svg",
  "icons/FamilyInfoHeader.svg",
  "icons/healthInfoHeader.svg",
  "icons/housingInfoHeader.svg",
  "icons/financialInfoHeader.svg",
  "icons/legalHeader.svg",
]  