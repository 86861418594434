import React, { useContext, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import '../Modal.css';
import { globalData } from '../CommonModal';
import {returnFname} from '../../control/Constant'

const IllnessNotifySuccessor = () => {

    const { NotifyContinueCallApi, handleClose, setmodalcontantshow, steptwomodaldata, getGCMprofessionalbyUserid,agentRoleid,setGoBack,goNext} = useContext(globalData)
    const warningBackbtn = () => {
         if(agentRoleid == 2){
            setmodalcontantshow('financeWarning')
         }else{
              if(goNext == "Home" || goNext == "Other"){
                setmodalcontantshow("illnesshomemedicalhelp")
              }
              else if(goNext == "Hospital"){
                setmodalcontantshow('hospitalDetails')
              }
              
             }
       
    }
    const continueBtn = ()=>{
        setmodalcontantshow('illnessemailtextpreview')
        // getGCMprofessionalbyUserid(7,true)
        setGoBack("illnessnotifysuccessor")   
    }
    return (
        <>





            <Modal.Header style={{ backgroundColor: "#871a27" }}>

                <Modal.Title className='mt-2' id="example-modal-sizes-title-lg">
                    <h3 className='heading text-center' >
                        Step {goNext !== "Hospital" ? "6" : "5" }: Notify Agents
                    </h3>
                </Modal.Title>
                {/* closeButton */}

                <button className='closeButton' style={{ border: "1px solid #981b29" }} onClick={handleClose}>x</button>

            </Modal.Header>
            <Modal.Body>
                <h3 className='SelectOptionsh4Tag mb-2 mt-3'>
                    You are required to notify all successor agents that you are taking over&nbsp; 
                    management of {returnFname(steptwomodaldata.memberFullName)}'s {agentRoleid == 1 ?  "healthcare affairs" :"financial affairs"}.
                </h3>
                <h3 className='SelectOptionsh4Tag mb-2 mt-1'>
                    By clicking continue, you can send the default notification provided  or create your own.
                </h3>


                <div className='buttonDiv '>
                    {/* <button className='understandButton fw-bold' onClick={() => setmodalcontantshow('illnessemailtextpreview')}>Continue</button> */}
                    <button className='understandButton fw-bold' onClick={() => continueBtn()}>Continue</button>

                </div>
                <div className='backbuttonDiv'>
                    <button className='backButton' onClick={() => warningBackbtn()}>Back</button>
                </div>
            </Modal.Body>

        </>
    );
}

export default IllnessNotifySuccessor;