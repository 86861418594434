import React, { useContext, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import "./Modal.css"
import { globalData } from './CommonModal';
import commonLib from '../control/commonLib';
import {returnFname} from '../control/Constant'



const Step3SelectOption = ({ setSelectoptionId }) => {
  const { setSelectoptionmessage,setStepName, handleClose, setmodalcontantshow, setillushospitalstep6help, memberRankIdforPrimary, selectedAgentRolesList, getGCMprofessionalbyUserid, addManageStepToFollow,steptwomodaldata} = useContext(globalData);

  const userloginId = commonLib.getObjFromStorage("userLoggedInDetail").loggedUserId
  const selectoption = (data) => {
    setStepName(data)
    let id = memberRankIdforPrimary
    if (data == 'Illness') {
      setillushospitalstep6help(data)
      setSelectoptionId(1)
      addManageStepToFollow("setSelectoptionId", 1);
      if (id == 1 || id == 2 || id == 3 || id == 4 || id == 5 || id == 6) {
        // setmodalcontantshow('illnessnotifysuccessor')
        setmodalcontantshow('step4illness')
      } else {
        setmodalcontantshow('illnesswarning')

      }
      // setSelectoptionmessage('Illness')
    } else if (data == 'EoL') {
      setSelectoptionId(2)
      addManageStepToFollow("setSelectoptionId", 2);
      // setSelectoptionmessage('End Of Life')
      setillushospitalstep6help("EndOfLife")
      addManageStepToFollow("setillushospitalstep6help", "EndOfLife");
      setmodalcontantshow('eolyesno')
    } else if (data == 'Death') {
      setSelectoptionId(3)
      addManageStepToFollow("setSelectoptionId", 3);
      // setSelectoptionmessage('Death')
      if (id == 1 || id == 2 || id == 3 || id == 4 || id == 5 || id == 6) {
        setmodalcontantshow('deathnotifysuccessorstep4')
      } else {
        setmodalcontantshow('deathwarning')
      }
    }
    else if (data == 'MentalHealth') {
      setSelectoptionId(4);
      setillushospitalstep6help("MentalHealth")
      addManageStepToFollow("setSelectoptionId", 4);

      if (id == 1 || id == 2 || id == 3 || id == 4 || id == 5 || id == 6) {
        setmodalcontantshow('mentalHealthNotifySuccessor')
      } else {
        setmodalcontantshow('mentalHealthWarning')
      }
      getGCMprofessionalbyUserid(7, true);
      // setSelectoptionmessage('Death')
      // if (memberRankIdforPrimary == 1) {
      //   setmodalcontantshow('deathnotifysuccessorstep4')
      // } else {
      //   setmodalcontantshow('deathwarning')
      // }
    }
  }

  const backBtn = () => {
    if (selectedAgentRolesList.length == 2) {
      setmodalcontantshow('')
    } else {
      setmodalcontantshow('step2selectone')
    }
  }



  return (
    <>
      <Modal.Header style={{ backgroundColor: "#871a27" }}>
        <Row >
          <Col sm={11}>
            <h3 className='heading'>
              Step 3: What is {returnFname(steptwomodaldata?.memberFullName)} dealing with ?
            </h3>
          </Col>
          <Col sm={1} >
            <button className='closeButton' style={{ border: "1px solid #981b29" }} onClick={handleClose}>x</button>
          </Col>
        </Row>


      </Modal.Header>
      <Modal.Body>
        {/* <Container> */}

        <h4 className='SelectOptionsh4Tag  mt-2'>Select Option </h4>

        {/* <div className='container'> */}
        <div className='row p-3' >
          <div className='col-md-4  col-xs-12 col-md-12 col-lg-3' style={{ display: "flex", justifyContent: "center" }}>
            <Card className='w-100' onClick={() => selectoption("Illness")}>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Card.Img className='mt-3' src="/Illness.png" style={{ height: 100, width: 120 }} ></Card.Img>
              </div>
              <Card.Body className='text-center mt-2  mt-2 mb-0 pb-0'>
                <Card.Title style={{ opacity: "85%", fontSize: "20px", fontFamily: "serif", fontWeight: 600 }}>Illness</Card.Title>

              </Card.Body>


            </Card>
          </div>
          <div className='col-md-4 col-xs-12 col-md-12 col-lg-3' style={{ display: "flex", justifyContent: "center" }}>
            <Card className='w-100' onClick={() => selectoption("MentalHealth")}>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Card.Img className='mt-3 ' variant="top" src="/mentailhealth.svg" style={{ height: 100, width: 120, }} />
              </div>
              <Card.Body className='text-center mt-2 mb-0 pb-0'>
                <Card.Title style={{ opacity: "85%", fontSize: "20px", fontFamily: "serif", fontWeight: 600 }}>Mental Health</Card.Title>

              </Card.Body>

            </Card>
          </div>
          <div className='col-md-4  col-xs-12 col-md-12 col-lg-3' style={{ display: "flex", justifyContent: "center" }}>
            <Card className='w-100' onClick={() => selectoption("EoL")} >
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Card.Img className='mt-3' variant="top" src="/EndOfLife.png" style={{ height: 100, width: 120, }} />
              </div>
              <Card.Body className='text-center mt-2 mt-2 mb-0 pb-0'>
                <Card.Title style={{ opacity: "85%", fontSize: "20px", fontFamily: "serif", fontWeight: 600 }}>End of Life</Card.Title>

              </Card.Body>

            </Card>
          </div>
         

          <div className='col-md-4 col-xs-12 col-md-12 col-lg-3' style={{ display: "flex", justifyContent: "center" }}>
            <Card className='w-100' onClick={() => selectoption("Death")}>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Card.Img className='mt-3 ' variant="top" src="/RIP.png" style={{ height: 100, width: 120, }} />
              </div>
              <Card.Body className='text-center mt-2 mb-0 pb-0'>
                <Card.Title style={{ opacity: "85%", fontSize: "20px", fontFamily: "serif", fontWeight: 600 }}>Death</Card.Title>

              </Card.Body>

            </Card>
          </div>
        </div>
        {/* </div>
        </Container> */}

        <div className='mt-2 mx-3'>
          <button className='backButton' onClick={backBtn}>Back</button>
        </div>
      </Modal.Body>
    </>
  );
}

export default Step3SelectOption 