import React, { useState, useContext } from 'react';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { globalData } from '../Execute/CommonModal';
import {returnFname} from '../control/Constant'

const MentalHealthSafe = () => {
    const { handleClose, setmodalcontantshow, steptwomodaldata,memberRankIdforPrimary, isGCM } = useContext(globalData)

    const btnCLickYes = () => {
        // if (memberRankIdforPrimary == 1) {
        //     setmodalcontantshow('eolnotifysuccessorstep4')
        // } else {
        //    setmodalcontantshow('eolwarning')
        // }
        if(isGCM == "true"){
            setmodalcontantshow("mentalHealthGCM")
        }
        else{
            setmodalcontantshow("noMentalGcm")
        }

    }
    return (
        <>

            <Modal.Header style={{ backgroundColor: "#871a27" }}>
                <Row >
                    <Col sm={11}>
                        <h3 className='heading'>
                            <span style={{ color: "#871a27" }}>S</span>
                        </h3>
                    </Col>
                    <Col sm={1} >
                        <button className='closeButton' style={{ border: "1px solid #981b29" }} onClick={handleClose}>x</button>
                    </Col>
                </Row>



            </Modal.Header>
            <Modal.Body>

                <h4 className='SelectOptionsh4Tag text-center'>
                    In your opinion, is {returnFname(steptwomodaldata.memberFullName)} safe at the moment?
                </h4>

                <div className='buttonDiv'>
                    <button className='yesButton' onClick={() => btnCLickYes()} >Yes</button>
                    <button className='noButton' onClick={() => setmodalcontantshow('mentalHealthCallNine')} >No</button>
                </div>
                <div className='backbuttonDiv'>
                    <button className='backButton' onClick={() => setmodalcontantshow('mentalHealthYesOrNo')}>Back</button>
                </div>
            </Modal.Body>



        </>
    );
}

export default MentalHealthSafe