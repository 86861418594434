import React, { useEffect, useContext } from "react";
import Accordion from "react-bootstrap/Accordion";
import { Breadcrumb, Col, Row } from "react-bootstrap";
import "../FileCabinetModal/Accodifile.css";
import Services from "../../Network/Services";
import konsole from "../../Network/konsole";
// import { UserContext } from "../../../App";

const Acordianfile = (props) => {
  
  const data = props.pdfdata;


 

  return (
    <div>
     
      {data != [] && data !== undefined && data != "" && data.lenght != 0 ? (
        <Row className="d-flex justify-content-start ">
          {/* {setLoader(false)} */}
          <Col>
            <Accordion defaultActiveKey="0">
              <Accordion.Item
                eventKey="0"
                className="AcodiItem"
                style={{ border: "none" }}
              >
                <Accordion.Header>
                  <div style={{ fontWeight: 600, fontSize: "17px" }}>
                    {data[0].belongsToMemberName}
                  </div>
                  <div style={{ width: "70%" }} className="px-1">
                    <hr className="w-100" />
                  </div>
                </Accordion.Header>
                <Accordion.Body
                  className="d-flex justify-content-between m-0 p-0 mt-1 mb-0 "
                  style={{ overflow: "scroll", overflowY: "hidden" }}
                >
                  {data.map((filemap) => (
                    <div
                      className="d-flex justify-content-center align-items-center flex-column mx-1 mb-2"
                      onClick={() => props.handleClose(filemap)}
                    >
                      <div className="img-Div" style={{ width: "50px" }}>
                        <img
                          src="images/file-folder.png"
                          className="img-fluid"
                        />
                      </div>
                      <div className=" " style={{ height: "36px" }}>
                        <h6
                          className=" text-center mt-1 fw-bold  "
                          style={{ fontSize: 10 }}
                        >
                          {filemap.fileTypeName}
                        </h6>
                      </div>
                    </div>
                  ))}
                  {/* <div
                    className="d-flex justify-content-center align-items-center flex-column"
                    onClick={() => props.handleClose("Will")}
                  >
                    <div className="img-Div" style={{ width: "52px" }}>
                      <img src="images/file-folder.png" className="img-fluid" />
                    </div>
                    <div className=" " style={{ height: "36px" }}>
                      <h6
                        className=" text-center mt-1 fw-bold  "
                        style={{ fontSize: 10 }}
                      >
                        Will
                      </h6>
                    </div>
                  </div> */}
                  {/* <div
                  className="d-flex justify-content-center align-items-center flex-column "
                  onClick={() =>
                    props.handleClose("Financial Power of Attorny")
                  }
                >
                  <div className="img-Div" style={{ width: "52px" }}>
                    <img src="images/file-folder.png" className="img-fluid" />
                  </div>
                  <div className=" " style={{ height: "36px" }}>
                    <h6
                      className=" text-center mt-1 fw-bold  "
                      style={{ fontSize: 10 }}
                    >
                      Financial Power of Attorny
                    </h6>
                  </div>
                </div>
                <div
                  className="d-flex justify-content-center align-items-center flex-column "
                  onClick={() =>
                    props.handleClose("Healthcare Power of Attorney")
                  }
                >
                  <div className="img-Div" style={{ width: "52px" }}>
                    <img src="images/file-folder.png" className="img-fluid" />
                  </div>
                  <div className=" " style={{ height: "36px" }}>
                    <h6
                      className=" text-center mt-1 fw-bold  "
                      style={{ fontSize: 10 }}
                    >
                      Healthcare Power of Attorney
                    </h6>
                  </div>
                </div>
                <div className="d-flex justify-content-center align-items-center flex-column ">
                  <div className="img-Div" style={{ width: "52px" }}>
                    <img src="images/file-folder.png" className="img-fluid" />
                  </div>
                  <div className=" " style={{ height: "36px" }}>
                    <h6
                      className=" text-center mt-1 fw-bold  "
                      style={{ fontSize: 10 }}
                    >
                      Will
                    </h6>
                  </div>
                </div>
                <div className="d-flex justify-content-center align-items-center flex-column ">
                  <div className="img-Div" style={{ width: "52px" }}>
                    <img src="images/file-folder.png" className="img-fluid" />
                  </div>
                  <div className=" " style={{ height: "36px" }}>
                    <h6
                      className=" text-center mt-1 fw-bold  "
                      style={{ fontSize: 10 }}
                    >
                      Healthcare Power of Attorney
                    </h6>
                  </div>
                </div>
                <div className="d-flex justify-content-center align-items-center flex-column ">
                  <div className="img-Div" style={{ width: "52px" }}>
                    <img src="images/file-folder.png" className="img-fluid" />
                  </div>
                  <div className="text-Div" style={{ height: "36px" }}>
                    <h6
                      className=" text-center mt-1 fw-bold  "
                      style={{ fontSize: 10 }}
                    >
                      Financial Power of Attorny
                    </h6>
                  </div>
                </div> */}

                  {/* <div className='d-flex justify-content-center align-items-center flex-column mt-2'  >
                                    <div style={{ width: "52px" }}>
                                        <img src="images/file-folder.png" className='img-fluid' />
                                    </div>
                                    <div  >
                                        <h6 className=' mt-1 fw-bold' style={{ fontSize: 10 }}>Financial Power of Attorny</h6>
                                    </div>
                                </div>
                                <div className='d-flex justify-content-center align-items-center flex-column'  >
                                    <div style={{ width: "52px" }}>
                                        <img src="images/file-folder.png" className='img-fluid' />
                                    </div>
                                    <div className='mt-1' >
                                        <h6 className=' mt-1 fw-bold' style={{ fontSize: 10 }}>Healthcare Power of Attorney</h6>
                                    </div>
                                </div>
                                <div className='d-flex justify-content-center align-items-center flex-column' >
                                    <div style={{ width: "52px" }}>
                                        <img src="images/file-folder.png" className='img-fluid' />
                                    </div>
                                    <div className='mt-1' >
                                        <h6 className=' mt-1 fw-bold' style={{ fontSize: 10 }}>Will</h6>
                                    </div>
                                </div>
                                <div className='d-flex justify-content-center align-items-center flex-column' >
                                    <div style={{ width: "52px" }}>
                                        <img src="images/file-folder.png" className='img-fluid' />
                                    </div>
                                    <div className='mt-1' >
                                        <h6 className=' mt-1 fw-bold' style={{ fontSize: 10 }}>Financial Power of Attorny</h6>
                                    </div>
                                </div>
                                <div className='d-flex justify-content-center align-items-center flex-column'  >
                                    <div style={{ width: "52px" }}>
                                        <img src="images/file-folder.png" className='img-fluid' />
                                    </div>
                                    <div className='mt-1' >
                                        <h6 className=' mt-1 fw-bold' style={{ fontSize: 10 }}>Healthcare Power of Attorney</h6>
                                    </div>
                                </div> */}
                </Accordion.Body>
              </Accordion.Item>
              {/* <Accordion.Item eventKey="1" style={{ border: "none" }}>
                <Accordion.Header>
                  <div style={{ fontWeight: 600, fontSize: "17px" }}>
                    Spouse
                  </div>
                  <div style={{ width: "95%" }} className="px-1">
                    <hr />
                  </div>
                </Accordion.Header>
                <Accordion.Body className="d-flex m-0 p-0"></Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2" style={{ border: "none" }}>
                <Accordion.Header>
                  <div style={{ fontWeight: 600, fontSize: "17px" }}>Joint</div>
                  <div style={{ width: "95%" }} className="px-1">
                    <hr />
                  </div>
                </Accordion.Header>
                <Accordion.Body className="d-flex m-0 p-0"></Accordion.Body>
              </Accordion.Item> */}
            </Accordion>
          </Col>
        </Row>
      ) : (
        <Row className="d-flex justify-content-start ">
          {/* {setLoader(false)} */}
          <p>File is not available.</p>
        </Row>
      )}
    </div>
  );
};

export default Acordianfile;
