import React, { useState, useContext, useEffect } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Modal from "react-bootstrap/Modal";
import { globalData } from "../Execute/CommonModal";
import Services from "../Network/Services";
import '../Execute/Modal.css'
import commonLib from "../control/commonLib";
import {returnFname} from '../control/Constant'
const MentalHealthSuccessorContact = (props) => {
    const { handleClose, setmodalcontantshow, steptwomodaldata, NotifyContinueCallApi, setSelectoptionmessage,getGCMprofessionalbyUserid,getGCMprofessionalbyUseridforLocate,illushospitalstep6help,selectoptionId,setloader} = useContext(globalData)
    const [textareavalue, settextareavalue] = useState()
    const [sendbuttondisabled, setbuttondesabled] = useState()
    let userLoggedInDetails =JSON.parse(sessionStorage.getItem('userLoggedInDetail'))


    useEffect(() => {
        // const string = "We are writing to let you know that {{userName}} is dealing with a mental illness, hence unable to make a rational decision. We are praying for a quick recovery. We will keep you updated."
        const string = "I'm writing to let you know that {{userName}} might be struggling with a mental illness. As a result, I want to talk to you about the situation so that {{userName}} can receive timely medical care. Please get in touch with me as soon as you can."
        if (illushospitalstep6help == "MentalHealth") {
            firstApi(string)
            
          }
          else{
            callfunction(string)
      
          }


    }, [])

    async function firstApi(string){
    
        const responseEmail =  await getNotifyMessageapi(1, 1, selectoptionId);
        const responseText = await getNotifyMessageapi(2, 1, selectoptionId);
        if (responseText !== "resolve" && responseEmail !== "resolve") {
          callfunction(string)
        }
      }
      const getNotifyMessageapi = (notifyTypeId, contactNatureId, notifyConditionId) => {
        setloader(true)
        return new Promise((resolve, reject) => {
          const userId = steptwomodaldata.memberUserId;
          Services.getNotifyMessageapi(userId, notifyTypeId, contactNatureId, notifyConditionId)
            .then((res) => {
                setloader(false)
              if (res.data.data.length > 0) {
                const name = res.data.data[0].notifyText
                if(illushospitalstep6help != "Discharge" && illushospitalstep6help != "Care" && illushospitalstep6help != "Home" && illushospitalstep6help != "Other"){
                    settextareavalue(name)
                   setSelectoptionmessage(name)
                  }
                
                // }
    
                resolve("resolve")
              }
            }).catch((err) => {
              resolve("reject");
              setloader(false)
            })
        })
    
      }
    const callfunction = (string) => {

        let newstr = string.replaceAll('{{userName}}', returnFname(steptwomodaldata.memberFullName));
        settextareavalue(newstr);
        setSelectoptionmessage(newstr);

    }




    const handleChange = (e) => {
        settextareavalue(e.target.value)
        setSelectoptionmessage(e.target.value)
    }
    const textarea = () => {
        return (
            <textarea className="form-control pt-0 ps-3 fs-4  text-dark  shadow-none border-0" rows="4" value={textareavalue} onChange={handleChange}></textarea>
        )
    }





    const clicksend = () => {
        // getGCMprofessionalbyUserid()
        NotifyContinueCallApi();
        setmodalcontantshow("step3selectoption")
        setbuttondesabled(true)
        setTimeout(() => {
            setbuttondesabled(false)
        }, 5000)
    }

    const clicksendlater =() =>{
        getGCMprofessionalbyUserid(7,true)
        getGCMprofessionalbyUseridforLocate(13, true);
        setmodalcontantshow("step3selectoption")
    }
    const numberFormate = (number) => {
        const lastTenDigitArray = array => array.slice(-10);
        return commonLib.convertToUSFormat(lastTenDigitArray(number), "1")
          }


    return (
        <>
            <Modal.Header style={{ backgroundColor: "#871a27" }}>
                <Row style={{ height: "30px" }}>
                    <Col sm={11}>
                        <h3 className="heading">Contact other Agents</h3>
                    </Col>
                    <Col sm={1}>
                        <button
                            className="closeButton"
                            style={{ border: "1px solid #981b29" }}
                            onClick={handleClose}
                        >
                            x
                        </button>
                    </Col>
                </Row>
            </Modal.Header>
            <Modal.Body>
                <div className="form-outline border border-2 rounded fs-4">
                    <label className="mb-0 ps-3  text-secondary">Dear User</label>
                    {textarea()}
                    {/* <h6 className="ps-3 textData">Regards</h6> */}
          <h6 className="ps-3 textData mb-1">{userLoggedInDetails.loginUserName}</h6>
          {/* <h6 className="ps-3 textData">{userLoggedInDetails?.primaryEmailId}</h6>
          <h6 className="ps-3 textData mb-1">{numberFormate(userLoggedInDetails?.primaryPhoneNumber)}</h6> */}

                </div>

                <div className='stepsevenbuttonsDiv'>
                    <button className="backButton" onClick={() => setmodalcontantshow('mentalHealthDiscuss')} >
                        Back
                    </button>
                    <button className='laterButton' onClick={clicksendlater} disabled={sendbuttondisabled} >
                Send Later & Proceed</button>
                    <button className='backButton' onClick={clicksend} disabled={sendbuttondisabled} >
                        Send</button> </div>

            </Modal.Body>
        </>
    )
}
export default MentalHealthSuccessorContact;
