import React, { useState, useContext, useEffect, useRef } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Modal from "react-bootstrap/Modal";
import { globalData } from "../Execute/CommonModal";
import '../Execute/Modal.css'
import OccuranceCom from "../OccuranceCom";

import "../Execute/Illness/Warning.css";
import commonLib from "../control/commonLib";
import Services from "../Network/Services";
import konsole from "../Network/konsole";
import {returnFname,loginUserEmail,isNotValidNullUndefinedfile} from '../control/Constant'
import EOLstep9Call from "../Execute/EndOfLife/EOLstep9Call";
const FinanceEmailNText = ({selectedFinacePage, goBack,...props}) => {
    const { illushospitalstep6help, postsendnotifytoGCMApi, isGCM, handleClose, setmodalcontantshow, steptwomodaldata, NotifyContinueCallApi, setSelectoptionmessage,sendEmailOrText,gcmDetails,getGCMprofessionalbyUserid,getGCMprofessionalbyUseridforLocate,rolesName,setIsGCM,setloader} = useContext(globalData);
    const [textareavalue, settextareavalue] = useState();
    const [sendbuttondisabled, setbuttondesabled] = useState();
    const stateObj = commonLib.getObjFromStorage("stateObj");
    let userLoggedInDetails =JSON.parse(sessionStorage.getItem('userLoggedInDetail'))
    let occurranceRef = useRef(null)

      useEffect(() => {
        const paybills = "We are writing to let you know that {{userName}} is not in a condition to pay {{HIM}} bills. {{loginuserName}} requires your help to handle the situation.";
        const filetaxe = "We are writing to let you know that {{userName}} is not in a condition to file his/her taxes. {{loginuserName}} requires your help to handle the situation.";
        const manageInverstment = "We are writing to let you know that {{userName}} is not in a condition to manage {{HIM}} investment. {{loginuserName}} requires your help to handle the situation.";
        const managerealproperty = "We are writing to let you know that {{userName}} is not in a condition to manage {{HIM}} real property. {{loginuserName}} requires your help to handle the situation.";
        const manageHealth = "We are writing to let you know that {{userName}} is dealing with a mental illness, hence unable to make a rational decision. We are praying for a quick recovery. We will keep you updated.";
        konsole.log("selectedFinacePage", selectedFinacePage);
        if(selectedFinacePage === "MentalHealth"){
            firstApi(paybills, filetaxe, manageInverstment, managerealproperty, manageHealth)
        }
        else{
            callfunction(paybills, filetaxe, manageInverstment, managerealproperty, manageHealth);
        }

    }, [])
       const sendBacks =()=>{
        setmodalcontantshow(goBack)
        // if(selectedFinacePage === "MentalHealth"){
        // setIsGCM("false")
        // }
        


       }

    const callfunction = (paybills, filetaxe, manageInverstment, managerealproperty, manageHealth) => {
         let userLoggedInDetail =JSON.parse(sessionStorage.getItem('userLoggedInDetail'))
        if (selectedFinacePage == "paybills") {
            let newstr = paybills.replaceAll('{{userName}}',steptwomodaldata.memberFullName).replaceAll("{{loginuserName}}",userLoggedInDetail?.loginUserName).replaceAll("{{HIM}}",steptwomodaldata.memberFullName)
            settextareavalue(newstr)
            setSelectoptionmessage(newstr)
        }
        else if (selectedFinacePage == "filetaxes") {
            let newstr = filetaxe.replaceAll('{{userName}}',steptwomodaldata.memberFullName).replaceAll("{{loginuserName}}",userLoggedInDetail?.loginUserName).replaceAll("{{loginuserName}}",userLoggedInDetail?.loginUserName).replaceAll("{{HIM}}",steptwomodaldata.memberFullName)
            settextareavalue(newstr)
            setSelectoptionmessage(newstr)
        }
        else if (selectedFinacePage == "manageInverstment") {
            let newstr = manageInverstment.replaceAll('{{userName}}',steptwomodaldata.memberFullName).replaceAll("{{loginuserName}}",userLoggedInDetail?.loginUserName).replaceAll("{{loginuserName}}",userLoggedInDetail?.loginUserName).replaceAll("{{HIM}}",steptwomodaldata.memberFullName)
            settextareavalue(newstr)
            setSelectoptionmessage(newstr)
        }
        else if (selectedFinacePage == "managerealproperty") {
           
            let newstr = managerealproperty.replaceAll('{{userName}}',steptwomodaldata.memberFullName).replaceAll("{{loginuserName}}",userLoggedInDetail?.loginUserName).replaceAll("{{loginuserName}}",userLoggedInDetail?.loginUserName).replaceAll("{{HIM}}",steptwomodaldata.memberFullName)
            settextareavalue(newstr)
            setSelectoptionmessage(newstr)
        }else if (selectedFinacePage == "passwordsLoginId") {

        }
        else if (selectedFinacePage == "otherServices") {

        }
        else if (selectedFinacePage == "MentalHealth") {
            if(goBack == "mentalHealthGCM"){
             let userLoggedInDetail =JSON.parse(sessionStorage.getItem('userLoggedInDetail'))   
             let text = `We are writing to let you know that ${steptwomodaldata.memberFullName} is dealing with a mental illness. ${userLoggedInDetail?.loginUserName} requires your help to handle the situation.`
             settextareavalue(text)
             setSelectoptionmessage(text)   
            }else{
            let newstr = manageHealth.replaceAll('{{userName}}', returnFname(steptwomodaldata.memberFullName)).replaceAll("{{loginuserName}}",userLoggedInDetail?.loginUserName).replaceAll("{{HIM}}",returnFname(steptwomodaldata.memberFullName))
            settextareavalue(newstr)
            setSelectoptionmessage(newstr)
            }
            
        }

    }


    async function firstApi(paybills, filetaxe, manageInverstment, managerealproperty, manageHealth, selectedMod = 4, ){
    
        const responseEmail =  await getNotifyMessageapi(1, 1, selectedMod);
        const responseText = await getNotifyMessageapi(2, 1, selectedMod);
       
        if (responseText !== "resolve" && responseEmail !== "resolve") {
            callfunction(paybills, filetaxe, manageInverstment, managerealproperty, manageHealth);
        }
      }
    
    
      const getNotifyMessageapi = (notifyTypeId, contactNatureId, notifyConditionId) => {
        setloader(true)
        let userLoggedInDetail =JSON.parse(sessionStorage.getItem('userLoggedInDetail'))
         return new Promise((resolve, reject) => {
          const userId = steptwomodaldata.memberUserId;
          Services.getNotifyMessageapi(userId, notifyTypeId, contactNatureId, notifyConditionId)
            .then((res) => {
                setloader(false)
              if (res.data.data.length > 0) {
                const name = res.data.data[0].notifyText
                if(goBack == "mentalHealthGCM"){
                let text = `We are writing to let you know that ${steptwomodaldata.memberFullName} is dealing with a mental illness. ${userLoggedInDetail?.loginUserName} requires your help to handle the situation.`
                settextareavalue(text)
                setSelectoptionmessage(text)
                }else{
                 settextareavalue(name)
                 setSelectoptionmessage(name)
                }
                
                resolve("resolve")
              }
            }).catch((err) => {
                setloader(false)
                // if(illushospitalstep6help == "MentalHealth"){
                //     if(isGCM == true){
                //         let userLoggedInDetail =JSON.parse(sessionStorage.getItem('userLoggedInDetail'))   
                //         let text = `We are writing to let you know that ${returnFname(steptwomodaldata.memberFullName)} is dealing with a mental illness.${userLoggedInDetail?.loginUserName} requires your help to handle the situation.`
                //         settextareavalue(text)
                //         setSelectoptionmessage(text)   
                //        }else{
                //     const text = `We are writing to let you know that ${returnFname(steptwomodaldata.memberFullName)} is dealing with a mental illness, hence unable to make a rational decision. We are praying for a quick recovery. We will keep you updated.`
                //     settextareavalue(text)
                //     setSelectoptionmessage(text)
                //   }}
              resolve("reject");
            })
        })
    
      }

   


    const handleChange = (e) => {
        settextareavalue(e.target.value)
        setSelectoptionmessage(e.target.value)
    }
    const textarea = () => {
        return (
            <textarea className="form-control pt-0 ps-3 fs-4  text-dark  shadow-none border-0" rows="3" value={textareavalue} onChange={handleChange}></textarea>
        )
    }





    const clicksend = () => {
        // getGCMprofessionalbyUserid()
        NotifyContinueCallApi()
        setbuttondesabled(true)
        setTimeout(() => {
            setbuttondesabled(false)
        }, 5000)
        setmodalcontantshow('mentalHealthYesOrNo')


    }
    const clicknotifysend = () => {
        let subtenantId = sessionStorage.getItem('subtenantId')
        let userLoggedInDetail =JSON.parse(sessionStorage.getItem('userLoggedInDetail'))
        const professUserData = {
                agentName : userLoggedInDetail?.loginUserName,
                agentEmail : loginUserEmail(),
                agentMobileNo : userLoggedInDetail?.primaryPhoneNumber,
                userPhoneNo : gcmDetails?.proPhoneNo ?? gcmDetails?.mobileNumbers,
                usersUserId : gcmDetails?.professionalUserId,
                userEmail : gcmDetails?.proEmailId ?? gcmDetails?.emaidIds,
                type : "financeUser",
                contentForMail : textareavalue
            }
            if(stateObj.roleId == "5"){
                setloader(true)
                occurranceRef?.current?.calloccuranceIdfunOnlyMailOrText(24, subtenantId,professUserData,sendEmailOrText);
            }
          
        if(selectedFinacePage === "MentalHealth") return setmodalcontantshow('step3selectoption');
        setmodalcontantshow('financeOptions')
        // setmodalcontantshow('eolneedhelpstep9')
    }
    const clicksendlater =()=>{
    getGCMprofessionalbyUseridforLocate(13, true);
        if(!isNotValidNullUndefinedfile(props?.isSuccessor)){
            if(selectedFinacePage == "MentalHealth"){
                getGCMprofessionalbyUserid(7, true);
                setmodalcontantshow('step3selectoption');
            }else{
               setmodalcontantshow('financeOptions')
            }

        }else{
            getGCMprofessionalbyUserid(7, true);
            setmodalcontantshow('mentalHealthYesOrNo')
        }
}
const numberFormate = (number) => {
    const lastTenDigitArray = array => array.slice(-10);
    return commonLib.convertToUSFormat(lastTenDigitArray(number), "1")
      }

    return (
        <>
            <Modal.Header style={{ backgroundColor: "#871a27" }}>
                <Row style={{ height: "30px" }}>
                    <Col sm={11}>
                        <h3 className="heading">{sendEmailOrText == "Email" ? "Email Preview":sendEmailOrText == "Text" ? "Text Preview" :"Email and Text Preview"}</h3>
                    </Col>
                    <Col sm={1}>
                        <button
                            className="closeButton"
                            style={{ border: "1px solid #981b29" }}
                            onClick={handleClose}
                        >
                            x
                        </button>
                    </Col>
                </Row>
            </Modal.Header>
            <Modal.Body>
                <div className="form-outline border border-2 rounded fs-4">
                    <label className="mb-0 ps-3  text-secondary">Dear User</label>
                    {textarea()}
                    {/* <h6 className="ps-3 textData">Regards</h6> */}
                    <h6 className="ps-3 textData mb-1">{userLoggedInDetails.loginUserName}</h6>
                    {/* <h6 className="ps-3 textData">{userLoggedInDetails?.primaryEmailId}</h6>
                    <h6 className="ps-3 textData mb-1">{numberFormate(userLoggedInDetails?.primaryPhoneNumber)}</h6> */}
                </div>
                <div className='stepsevenbuttonsDiv'>
                    <button className="backButton" onClick={() =>sendBacks()} >
                        Back
                    </button>
                    <button className='laterButton' onClick={clicksendlater} disabled={sendbuttondisabled} >
                    Send Later & Proceed</button>
                    <button className='backButton' onClick={()=> {(!isNotValidNullUndefinedfile(props?.isSuccessor)) ? clicknotifysend() : clicksend()}} disabled={sendbuttondisabled} >
                        Send</button>
                </div>
            </Modal.Body>

            <OccuranceCom ref={occurranceRef}/>
        </>
    )
}
export default FinanceEmailNText;