import React, { useContext, useState } from 'react';

// import Modal from 'react-bootstrap/Modal';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Modal from 'react-bootstrap/Modal';
import { globalData } from '../CommonModal';
import '../Modal.css';
import {returnFname} from '../../control/Constant'

const DeathWarning = () => {
    const { handleClose, setmodalcontantshow, steptwomodaldata,primaryAgents } = useContext(globalData)
    // let primaryAgentname = primaryAgents != null &&  (primaryAgents.length == 0 || primaryAgents?.[0].fullName == null || primaryAgents?.[0].fullName == undefined || primaryAgents?.[0].fullName == "" ? "" : primaryAgents?.[0].fullName   + " is the primary agent.")
    // let primaryAgentnames = primaryAgents != null &&  (primaryAgents.length == 0 || primaryAgents?.[0].fullName == null || primaryAgents?.[0].fullName == undefined || primaryAgents?.[0].fullName == "" ? "" : primaryAgents?.[0].fullName  + " as the primary agent,")
    let loggeduserData = JSON.parse(sessionStorage.getItem('userLoggedInDetail'))
    let primaryAgentnames = primaryAgents.length == 0 ? "" : primaryAgents.map((ele,index)=>{return (ele.length == 1 ? ele.fullName : `${" "+ ele.fullName}${(primaryAgents.length - 1 === index) ? '' : ' '}`)})

    return (
        <>

<Modal.Header style={{ backgroundColor: "#871a27" }}>
<Modal.Title className='mt-2' id="example-modal-sizes-title-lg">
                    <h3 className='heading text-center' >
                        Warning
                    </h3>
                </Modal.Title>
                {/* closeButton */}

                <button className='closeButton' style={{ border: "1px solid #981b29" }} onClick={handleClose}>x</button>

        </Modal.Header>
            <Modal.Body>
                <h3 className='SelectOptionsh4Tag mb-2 mt-3'>
                It appears you are NOT the primary agent to act on behalf of {returnFname(steptwomodaldata?.memberFullName)}. This means that you,
                    do not have the authority to act on {returnFname(steptwomodaldata?.memberFullName)}'s behalf.<br/><br/> {primaryAgentnames != "" ? primaryAgentnames + " is the primary agent. ":""} You can see
                     what {primaryAgentnames != "" ? primaryAgentnames + " as the primary agent, ":""} will need to do in order to fulfill {primaryAgentnames != "" ? primaryAgentnames:""}'s role.<br/><br/> Click continue to acknowledge that you understand that you do not
                    have the authority to act.
                </h3>


                <div className='buttonDiv'>
                    <button className='understandButton fw-bold' onClick={()=>setmodalcontantshow('deathnotifysuccessorstep4')}>
                        Click here to confirm you understand
                    </button>

                </div>
                <div className='warnigbackbuttonDiv '>
                    <button className='backButton' onClick={()=>setmodalcontantshow('step3selectoption')}>Back</button>
                </div>
            </Modal.Body>
        </>
    );
}

export default DeathWarning;