import React, { useContext, useEffect, useState } from 'react';
import { Modal, Button, Form, Row, Col } from 'react-bootstrap';
import { globalData } from "../Execute/CommonModal";
import Services from '../Network/Services';
import konsole from '../Network/konsole';
import html2canvas from '@nidi/html2canvas';
import './feedback.css';
import { isNotValidNullUndefinedfile } from '../control/Constant';

const Feedback = () => {
    const currentTime = new Date().toLocaleString()
    const [image, setImage] = useState()
    const [show, setshow] = useState(false);
    const [feedbackQuery, setfeedbackQuery] = useState("");
    const { setloader } = useContext(globalData);
    const [notification, setNotification] = useState(null)
    const [priorityList, setPriorityList] = useState([])
    const [priorityValue, setpriorityValue] = useState(2);
    const [disabled,setDisabled] = useState("")
    const rolesofLegal=[3,13,14,15]
    // {
    //     msg: "",
    //     type: 0 // 0 - success & 1 - warning
    // });


    useEffect(() => {
        getFeedbackPriorityfunc()
    }, [])
    const getFeedbackPriorityfunc = () => {
        Services.getFeedbackPriority().then((res) => {
            konsole.log('res of feedback priority', res)
            setPriorityList(res?.data?.data)
        }).catch(err => konsole.log('err of get feedback priority', err))
    }


    const setTimeOutNotification = (msgObject) => {
        setNotification(msgObject)
        setTimeout(() => {
            setNotification(null);
        }, [3000]);
    }

    let stateObj = JSON.parse(sessionStorage.getItem("stateObj"))
    let previewAgentId = stateObj.userId || "";
    let agentUserId = JSON.parse(sessionStorage.getItem("userLoggedInDetail")).loggedUserId || "";
    const roleId = stateObj?.roleId
    const loggedInUser = roleId == 1 || roleId == 3 || roleId == 9 || roleId == 10 || roleId == 13 || roleId == 14 || roleId == 15 ? previewAgentId : agentUserId 
    

    konsole.log("feedback loggedUserId:", loggedInUser)

    const handleClose = () => {setshow(false);setDisabled("")};

    const takeScreenShot = () => {
        html2canvas(document.body, {
            ignoreElements: (ele) => {
                if (ele.tagName == 'NOSCRIPT') return true
                if (ele.classList.contains("modal-backdrop")) return true
                return false;
            }
        })
            .then((canvas) => {
                setImage(canvas.toDataURL());
                setshow(true);
                setfeedbackQuery("");
            })
            .catch((err) => {
                setTimeOutNotification({ type: 1, msg: "Sorry unable to take screenshot" })
                konsole.log("feedback error in takeScreenShot", err);
            });
    }

    const getImage = () => {
        if (show) return setshow(false);
        window.scrollTo(0, 0);
        setTimeout(takeScreenShot, [500]);
        setDisabled("disabled")
    }

    const handleQueryChange = (e) => {
        setfeedbackQuery(e.target.value);
    }

    const handleImageSubmit = async () => {

      
        const fileTypeId = 3; //for feedback
        const fileCategoryId = 7; //for feedback
        const fileStatusId = 2; //for feedback
        const imageSrc = image;
        const blob = await fetch(imageSrc).then((res) => res.blob());
        const file = new File([blob], 'FEEDBACK.jpg', { type: "image/png" });

        if (feedbackQuery == "" || !imageSrc) {
            setTimeOutNotification({ type: 1, msg: "Feedback query cannot be blank!" });
            return;
        }

        setloader(true)
        Services.postFileUpload(file, loggedInUser, loggedInUser, fileTypeId, fileCategoryId, fileStatusId).then((res) => {
            const imageUrl = res.data.data.fileURL;
            const fileId = res.data.data.fileId;
            konsole.log("feedback imageUrl:", imageUrl, "\nfileId", fileId, "\nblob", blob, "\nimage", image);
            handleFeedbackSubmit(imageUrl, fileId, blob);
        }).catch((err) => {
            setloader(false);
            setTimeOutNotification({ type: 1, msg: "Sorry unable to send feedback!" });
            konsole.log("feedback postFileUpload err:", err);
        }).finally(() => {
            konsole.log("feedback postFileUpload completed");
        });
    }

    const handleFeedbackSubmit = (imageUrl, fileId, blob) => {
        let totalinuptdata = {
            userId: loggedInUser,
            feedbackQuery: feedbackQuery,
            feedbackType: "string",
            applicationURL: window.location.href,
            imageURL: imageUrl,
            fileId: fileId,
            moduleTypeId: 3, 
            createdBy: loggedInUser,
            feedbackPriorityId:(rolesofLegal.includes(Number(roleId)))?priorityValue:null
        };

        Services.postFeedback(totalinuptdata).then((res) => {
            let subtenantId = sessionStorage.getItem('subtenantId');
            konsole.log("feedback postFeedback before sendEmail", blob, subtenantId);
            if (subtenantId == 2 || subtenantId == 742) {
                sendFeedBackEmail(blob, subtenantId,res);
            } else {
                setloader(false);
                setTimeOutNotification({ type: 0, msg: "Feedback submitted Successfully!" })
                handleClose();
            }
        }).catch((err) => {
            setloader(false);
            setTimeOutNotification({ type: 1, msg: "Sorry unable to send feedback!" });
            konsole.log("feedback postFeedback err:", err);
        }).finally(() => {
            konsole.log("feedback postFeedback completed");
        })

    }

    const sendFeedBackEmail = (blob, subtenantId,response) => {
        const file = new File([blob], 'FEEDBACK.jpg', { type: "image/png" });
        let clientName = JSON.parse(sessionStorage.getItem('userLoggedInDetail'))
        sendFeedBackEmailToUser(response, clientName);
        let userType = roleId == 1 ? "Intake Member" : roleId == 3 ? "Paralegal" : roleId == 9 ? "LPO" : roleId == 10 ? "Direct Intake Membar" : roleId == 13 ? "Attorney" : roleId == 14 ? "Legal Assistant" : roleId == 15 ? "Law Office Staff" : 'Agent'
   
        let emailto = subtenantId == 742 ? 'TechSupport@agingoptions.com' : 'shreyasinha@agingoptions.com';
        // let emailto = subtenantId == 742 ? 'TechSupport@agingoptions.com' : 'nitin.kumar@agingoptions.com';
                let emailContent = `
        <p style="font-size: 16px; color: #333; background-color: #f5f5f5; padding: 10px; margin: 0; box-sizing: border-box;">
          <span style="display: block; padding-bottom: 10px;"><b>Ticket Number:</b> @@ticketno<br></span>
          <span style="display: block; padding-bottom: 10px;"><b>Name:</b> @@CLIENT_NAME<br></span>
          <span style="display: block; padding-bottom: 10px;"><b>Email:</b> @@CLIENT_EMAIL<br></span>
          <span style="display: block; padding-bottom: 10px;"><b>Sender Email:</b> @@SENDER_EMAIL<br></span>
          <span style="display: block; padding-bottom: 10px;"><b>Application URL:</b> @@APPLICATION_URL<br></span>
          <span style="display: block; padding-bottom: 10px;"><b>Query:</b> @@FEEDBACK_QUERY<br></span>
          ${rolesofLegal.includes(Number(roleId)) ? `<span style="display: block; padding-bottom: 10px;"><b>Priority:</b> @@priority<br></span>` : ''}
          <span style="display: block; padding-bottom: 10px;"><b>Time:</b> @@time</span><br>
        </p>
        <p>(<b>Note:</b> Sent from @@USERTYPE)</p>
      `;
      emailContent = emailContent.replace('@@CLIENT_NAME', clientName?.loginUserName);
    //   emailContent = emailContent.replace('@@CLIENT_EMAIL', clientName?.primaryEmailId);
      emailContent = emailContent.replace('@@CLIENT_EMAIL', clientName?.loginUserEmailId);
      emailContent = emailContent.replace('@@SENDER_EMAIL',roleId == 1 || roleId == 3 || roleId == 9 || roleId == 10 || roleId == 13 || roleId == 14 || roleId == 15  ? clientName?.userName : clientName?.loginUserEmailId);
      emailContent = emailContent.replace('@@FEEDBACK_QUERY', feedbackQuery);
      emailContent = emailContent.replace('@@APPLICATION_URL', window.location.href);
      if (rolesofLegal.includes(Number(roleId))) {
        emailContent = emailContent.replace('@@priority',priorityList?.find(e => e.value == priorityValue).label )
      }
      emailContent = emailContent.replace('@@time', currentTime)
      emailContent = emailContent.replace('@@USERTYPE', userType)
      emailContent = emailContent.replace('@@ticketno', response?.data?.data?.feedbackId)

  
        let formdata = new FormData();
        formdata.append('File', file)
        formdata.append('EmailType', 'Client_Feedback_Details')
        formdata.append('EmailTo', emailto)
        formdata.append('EmailSubject', 'Feedback Details')
        formdata.append('EmailContent', emailContent)
        formdata.append('EmailMappingTable', 'tblMember')
        formdata.append('EmailStatusId', '1')
        // formdata.append('Emailcc', clientName?.userName)
        formdata.append('EmailMappingTablePKId', loggedInUser)
        formdata.append('CreatedBy', loggedInUser)
        Services.sendMailFeedBack(formdata).then((res) => {
            konsole.log("feedback sendMailFeedBack res:", res);
            setTimeOutNotification({ type: 0, msg: "Feedback submitted successfully! " });
            handleClose();
        }).catch((err) => {
            setTimeOutNotification({ type: 1, msg: "Feedback submitted successfully but unable to send feedback mail!" });
            konsole.log("feedback sendMailFeedBack err:", err);
        }).finally(() => {
            setloader(false);
            konsole.log("feedback sendMailFeedBack completed");
        })
    }

    const sendFeedBackEmailToUser = (response, clientName) => {    

        let emailContent = `
          <!DOCTYPE html>
          <html>
            <head>
              <title>Feedback Acknowledgement | New Ticket @@ticketno</title>
            </head>
            <body>
              <p>Hi @@CLIENT_NAME,</p>
              <p>We would like to acknowledge that we have received your request and a ticket @@ticketno has been created.</p>
              <p><b>Ticket Details</b></p>
              <p>Ticket No.: @@ticketno</p>
              <p>Ticket Title: @@FEEDBACK_QUERY</p>
              <p>Rest assured, our tech support team has initiated an investigation based on your feedback.</p>
              <p>Warm regards,</p>
              <p>Tech Support Team<br>Aging Options</p>
            </body>
          </html>
      `;
    
    
        emailContent = emailContent.replace('@@CLIENT_NAME', clientName?.loginUserName);
        emailContent = emailContent.replace('@@FEEDBACK_QUERY', feedbackQuery);
        emailContent = emailContent.replace(/@@ticketno/g,response?.data?.data?.feedbackId);
    
        const jsonObj = {
          emailType: "Client_Feedback_Details",
          emailTo: clientName?.userName,
          emailSubject: `Feedback Acknowledgement | New Ticket ${response?.data?.data?.feedbackId}`,
          emailContent: emailContent,
          // emailFrom: "string",
          // emailFromDisplayName: "string",
          // emailTemplateId: 0,
          emailStatusId: 1,
          emailMappingTable: "tblMember",
          emailMappingTablePKId: loggedInUser,
          createdBy: loggedInUser,
          // emailcc: ""
        }
    
        Services.sendMailFeedBackToUser(jsonObj).then((res) => {
            konsole.log("feedback sendMailFeedBack forUser res:", res);
        }).catch((err) => {
            konsole.log("feedback sendMailFeedBack forUser err:", err);
        }).finally(() => {
            konsole.log("feedback sendMailFeedBack forUser completed");
        })
    }

    let clientName = JSON.parse(sessionStorage.getItem('userLoggedInDetail'))

    return (
        <>
            {isNotValidNullUndefinedfile(notification) ?
                <div className='position-fixed top-0 end-0 p-3' style={{ zIndex: '999999' }}>
                    <div className={`alert ${notification.type == 0 ? "alert-success" : "alert-warning"} alert-dismissible fade show`} >
                        <strong>{notification.msg}</strong>
                        <button type="button" className="btn-close" onClick={() => setNotification(null)}></button>
                    </div>
                </div> : ""}

            <button className={`btn feedbackbtn text-white ${disabled}`} onClick={getImage}> Feedback</button>

            <Modal
                show={show}
                size="lg"
                centered
                onHide={handleClose}
                animation="false"
                id="feedbackModalId"
                backdrop="static"
            >
                <Modal.Header>
                    <Modal.Title id='modal'>Feedback</Modal.Title><button className='closeButton' onClick={handleClose}>&#x2715;</button>
                </Modal.Header>
                <Modal.Body className="pt-4">
             
                    <Row>
                        <Col className="d-flex mt-2">
                     <p className="ps-1"><b>Name: </b>{clientName.memberName}</p></Col>
                        <Col className="d-flex mt-2"> <b>Time:</b><p className="ps-1"> {currentTime}</p></Col>
                        {rolesofLegal.includes(Number(roleId)) && 
                        <Col className="d-flex justify-content-center align-items-center ">
                            <h6>Priority: </h6> <select className="ms-2 " value={priorityValue} onChange={(e) => setpriorityValue(e.target.value)}>
                            <option value={null}>Select Priority</option>
                                {priorityList.length > 0 && priorityList?.map((e) => (
                                    <option value={e.value}>{e?.label}</option>
                                ))}
                            </select>
                        </Col>}
                    </Row>
                    <Row>
                        <Col className="ms-2">
                            <h3>Describe</h3>
                        </Col>
                    </Row>
                    <Form.Control className="mt-4 ms-2" as="textarea" value={feedbackQuery} rows={3} onChange={(e) => handleQueryChange(e)} />
                    <div className='mt-4 ms-2'>
                        <img width="100%" src={image} alt={"Screenshot"} />
                    </div>
                </Modal.Body>
                <Modal.Footer className="border-0">
                    <Button onClick={handleImageSubmit}>
                        Submit
                    </Button>
                    <Button onClick={handleClose}>
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default Feedback;