import React, { useState, useContext,useEffect } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Modal from "react-bootstrap/Modal";
import { globalData } from "../CommonModal";
import '../Modal.css'
import commonLib from "../../control/commonLib";

import "../Illness/Warning.css";
import Services from "../../Network/Services";
const DeathEmailTextPreview = () => {
  const { handleClose, setmodalcontantshow, steptwomodaldata,NotifyContinueCallApi ,setSelectoptionmessage, selectoptionId,getGCMprofessionalbyUserid,getGCMprofessionalbyUseridforLocate,setloader} = useContext(globalData)
 const [textareavalue, settextareavalue] = useState()
 let userLoggedInDetails =JSON.parse(sessionStorage.getItem('userLoggedInDetail'))
 
const[sendbuttondisabled,setbuttondesabled]=useState()


  useEffect(() => {
    const string = "We are sorry to be the bearers of sad news, but {{userName}} passed away. This is an intimation mail and we wish you all the strength to cope with this tragic loss"
    let newstr=string.replaceAll('{{userName}}',steptwomodaldata.memberFullName)
    firstApi(newstr)
  }, [])



  async function firstApi(newstr){
    
    const responseEmail =  await getNotifyMessageapi(1, 1, selectoptionId);
    const responseText = await getNotifyMessageapi(2, 1, selectoptionId);
    
    if (responseText !== "resolve" && responseEmail !== "resolve") {
      settextareavalue(newstr)
      setSelectoptionmessage(newstr)
    }
  }


  const getNotifyMessageapi = (notifyTypeId, contactNatureId, notifyConditionId) => {
    setloader(true)
    return new Promise((resolve, reject) => {
      const userId = steptwomodaldata.memberUserId;
      Services.getNotifyMessageapi(userId, notifyTypeId, contactNatureId, notifyConditionId)
        .then((res) => {
          setloader(false)
          if (res.data.data.length > 0) {
            const name = res.data.data[0].notifyText
            // let text = `We are sorry to be the bearers of sad news, but ${steptwomodaldata.memberFullName} passed away. This is an intimation mail and we wish you all the strength to cope with this tragic loss.`
            setSelectoptionmessage(name)
            settextareavalue(name)
            resolve("resolve")
          }
        }).catch((err) => {
          resolve("reject");
          setloader(false)
        })
    })

  }
  

  const handleChange=(e)=>{
    settextareavalue(e.target.value)
    setSelectoptionmessage(e.target.value)
  }
  const textarea = () => {

    return(
      <textarea className="form-control pt-0 ps-3 fs-4  text-dark  shadow-none border-0" rows="4" value={textareavalue} onChange={handleChange}></textarea>
    )
  }


  const clicksend=()=>{
    NotifyContinueCallApi()
    setbuttondesabled(true)
    setTimeout(()=>{
      setbuttondesabled(false)
    },5000)
    // setmodalcontantshow('deathstep5')

}
const clicksendlater = () =>{
  getGCMprofessionalbyUserid(7, true);
  getGCMprofessionalbyUseridforLocate(13, true);
  setTimeout(()=>{
    setmodalcontantshow('deathstep5')
  },2000)
}
const numberFormate = (number) => {
  const lastTenDigitArray = array => array.slice(-10);
  return commonLib.convertToUSFormat(lastTenDigitArray(number), "1")
    }

  return (
    <>
      <Modal.Header style={{ backgroundColor: "#871a27" }}>
        <Row   style={{height:"30px"}}>
          <Col sm={11}>
            <h3 className="heading">Email and Text Preview</h3>
          </Col>
          <Col sm={1}>
            <button
              className="closeButton"
              style={{ border: "1px solid #981b29" }}
              onClick={handleClose}
            >
              x
            </button>
          </Col>
        </Row>
      </Modal.Header>
      <Modal.Body>
   <div className="form-outline border border-2 rounded fs-4">
   <label className="mb-0 ps-3  text-secondary">Dear User</label>
{textarea()}
          {/* <h6 className="ps-3 textData">Regards</h6> */}
          <h6 className="ps-3 textData mb-1">{userLoggedInDetails.loginUserName}</h6>
          {/* <h6 className="ps-3 textData">{userLoggedInDetails?.primaryEmailId}</h6>
          <h6 className="ps-3 textData mb-1">{numberFormate(userLoggedInDetails?.primaryPhoneNumber)}</h6> */}
</div>

<div className='stepsevenbuttonsDiv'>
        <button className="backButton" onClick={()=>setmodalcontantshow('deathnotifysuccessorstep4')} >
            Back
          </button>
          <button className='laterButton' onClick={clicksendlater} disabled={sendbuttondisabled} >Send Later & Proceed</button>
          <button className='backButton'onClick={clicksend} disabled={sendbuttondisabled} >
            Send</button>
          </div>
      </Modal.Body>
    </>
  )
}
export default DeathEmailTextPreview;