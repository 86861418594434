import React, { useState, useContext } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Modal from "react-bootstrap/Modal";
import { globalData } from "../CommonModal";
import {returnFname} from '../../control/Constant'
import '../Modal.css'

import "../Illness/Warning.css";
const DeathGoalStep7 = () => {
  const { handleClose, setmodalcontantshow, steptwomodaldata } = useContext(globalData)


  return (
    <>
      <Modal.Header style={{ backgroundColor: "#871a27" }}>
        <Row>
          <Col sm={11}>
            <h3 className="heading">Step 7:Goal and Steps</h3>
          </Col>
          <Col sm={1}>
            <button
              className="closeButton"
              style={{ border: "1px solid #981b29" }}
              onClick={handleClose}
            >
              x
            </button>
          </Col>
        </Row>
      </Modal.Header>
      <Modal.Body>
      
<h4 className="SelectOptionsh4Tag mb-4 mt-2">
Please click below to send a notification to {returnFname(steptwomodaldata.memberFullName)}'s Close Friends, Family and Geriatric care Manager. </h4>
      
              <div className="d-flex justify-content-center">
              <button  className="healthButton" onClick={()=>setmodalcontantshow('deathaskagent')} >
               Notify
              </button>
            
              </div>

    
        <div className='buttonsDiv'>
             <button className='backButton' onClick={()=>setmodalcontantshow('deathhome')}>Back</button>
               </div>
      </Modal.Body>
    </>
  )
}
export default DeathGoalStep7;