import React, { useContext, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { globalData } from '../CommonModal';
import {returnFname} from '../../control/Constant'

import '../Modal.css';


const EOLAccessDocument = () => {
    const { handleClose, setmodalcontantshow, steptwomodaldata, handleShowDocumentModal } = useContext(globalData)
  


    return (
        <>

            <Modal.Header style={{ backgroundColor: "#871a27" }}>

                <Modal.Title className='heading' id="example-modal-sizes-title-lg" style={{ color: 'white', fontFamily: "Inter"}}>
                    <span style={{ color: "#871a27" }}>S</span>
                </Modal.Title>
                {/* closeButton */}

                <button className='closeButton' onClick={handleClose}>x</button>

            </Modal.Header>
            <Modal.Body>
                <h4 className='SelectOptionsh4Tag mt-3'>
                Here is what {returnFname(steptwomodaldata.memberFullName)} wanted done.
                </h4>

          <div className='d-flex justify-content-center'>
              <button  className="healthButton" onClick={handleShowDocumentModal}>
                Access documents
              </button>
              </div>
        <div className='backbuttonDiv'>
                    <button className='backButton' onClick={()=>setmodalcontantshow('eolneedyesunanimous')}>Back</button>
                </div>
            </Modal.Body>

            {/* </Modal> */}
        </>
    );
}

export default EOLAccessDocument;