import React, { useContext, useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";
import { globalData } from "../CommonModal";
import '../Modal.css'
import {returnFname} from '../../control/Constant'

import { useNavigate } from "react-router-dom";

const EOLStep10 = (props) => {
    //console.log("propsprops",props)
    const { handleClose, setmodalcontantshow, steptwomodaldata, handleShowDocumentModal } = useContext(globalData)

    const navigate = useNavigate();
    const ClickButtonfile = () => {
      if (steptwomodaldata != "") {
        sessionStorage.setItem(
          "userPrimaryInDetail",
          JSON.stringify(steptwomodaldata)
        );
        //console.log("hyy", JSON.stringify(steptwomodaldata));
      }
      navigate("/filecabinet");
    };

  return (
    <>
      <Modal.Header style={{ backgroundColor: "#871a27" }}>
        <Row>
          <Col sm={11} md={10} lg={11}>
            <h3 className="heading">Step 10: Next Steps</h3>
          </Col>
          <Col sm={1} md={2} lg={1}>
            <button
              className="closeButton"
              style={{ border: "1px solid #981b29" }}
              onClick={handleClose}
            >
              x
            </button>
          </Col>
        </Row>
      </Modal.Header>
      <Modal.Body>
        <h4 className="SelectOptionsh4Tag">
          While you are waiting on the GCM’s response, please review&nbsp;
          {returnFname(steptwomodaldata.memberFullName)}'s guidance and legal documents in
          the file cabinet.
        </h4>
        <div className="buttonDiv pt-0 d-flex justify-content-center">
          {/* <button className="emailButton" onClick={()=>{window.location.href= "https://beta.lifeplanorganizer.com/pdf/WhatToExpectFromA_GCM.pdf/"}}>What a expect from a GCM</button> */}
       <a className="emailButton link " href="https://beta.lifeplanorganizer.com/pdf/WhatToExpectFromA_GCM.pdf" target="_blank">What to expect from a GCM</a>
        </div>
        <div className="pt-3 d-flex justify-content-center">
          <a  className="callButton link" href="https://beta.lifeplanorganizer.com/pdf/DocumentElderLaw.pdf" target="_blank">
            What to expect from Elder Law Attorney
          </a>
        </div>
        <div className="pt-3 d-flex justify-content-center">
          <button className="callButton" onClick={handleShowDocumentModal}>
            Access {returnFname(steptwomodaldata.memberFullName)}'s file cabinet
          </button>
        </div>
        {/* <div className="pt-3 d-flex justify-content-center">
          <button className="callButton">
            View Guidance about {returnFname(steptwomodaldata.memberFullName)}'s care
          </button>
        </div> */}
    
          <div className="backButtonDiv">

            {
                (props.Eolstep10=='Email') ?  <button   className="backButton" onClick={()=>setmodalcontantshow('eolneedhelpstep9')}> Back</button>:
                 (props.Eolstep10=='ok') ?
                 <button   className="backButton" onClick={()=>setmodalcontantshow('eolstep9call')}> Back</button>:
                <button   className="backButton" onClick={()=>setmodalcontantshow('eolmailforelderattorney')} > Back</button>
            }

            </div>
      
      </Modal.Body>
    </>
  );
};

export default EOLStep10;
