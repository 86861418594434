import React, { useState, useEffect, forwardRef, useImperativeHandle, useContext } from 'react'
import commonLib from "./control/commonLib";
import { globalData } from './Execute/CommonModal';
import konsole from "./Network/konsole";
import Services from "./Network/Services";

const OccuranceCom2 = forwardRef((props, ref) => {
    const {steptwomodaldata, fullNameofGcm,elderlawAttorney, setsuccessalert,setWarningText,setwarninngMsg,setloader} = useContext(globalData)

    useImperativeHandle(ref, () => ({
    calloccuranceIdfun
    }));
    //-----------------------------------------------------------------------------------------
    let subtenantId = sessionStorage.getItem('subtenantId')
    let loggedUserId = JSON.parse(sessionStorage.getItem('userLoggedInDetail'))
    let memberName = JSON.parse(sessionStorage.getItem('userLoggedInDetail')).loginUserName
    //-----------------------------------------------------------------------------------------
    

//    To Send Only Mail or Text


const calloccuranceIdfun=(occurrenceId, subtenantId,allData,typecall)=>{
    
    let jsonobj = {
                "occurrenceId": occurrenceId,
                "isActive": true,
                "subtenantId": subtenantId
            }
      
            let result = Services.getCommMediumPath(jsonobj)
        result.then((res) => {

            const commChannelIdId = res.data?.data[0]?.commChannelId
            const applicableEmailTempIdId = res.data?.data[0]?.applicableEmailTempId
            const applicableTextTempIdId = res.data?.data[0]?.applicableTextTempId
            if (commChannelIdId == 3) {
                // callBothTextAndEmailApiFunc(applicableEmailTempIdId, applicableTextTempIdId, occurrenceId, allData)
                callTextApiFunc(applicableTextTempIdId, allData ,typecall)
                callEmailApiFunc(applicableEmailTempIdId, allData ,typecall)
            } else if (commChannelIdId == 2) {
                callTextApiFunc(applicableTextTempIdId, allData ,typecall)
            } else if (commChannelIdId == 1) {
                callEmailApiFunc(applicableEmailTempIdId, allData ,typecall)
            }
            // callTextApiFunc(applicableTextTempIdId, allData)
            // callEmailApiFunc(applicableEmailTempIdId, allData)
        }).catch((err) => {
            setloader(false)
            konsole.log("getCommMediumPath", err)
            if (err.response.status == 404) {
                konsole.log("getCommMediumPathab")
                let subtenantId = commonLib.DEFAULT_SUBTENANTID_FOR_OCCURRENCE;
                calloccuranceIdfun(occurrenceId, subtenantId, allData)
            }
        }).finally(() => {
            setloader(false)
            konsole.log("getCommMediumPathfinally")
        })
}


//call both

    //------------------------------Text Call & Send ---------------------------------------

    const callTextApiFunc = (TempalteId, allData,typecall) => {
      
        let isActive = true;
        konsole.log("TempalteIdisActiv", TempalteId, isActive)
        let result = Services.getTextTemplate(TempalteId, isActive)
        result.then((res) => {
            konsole.log("getTextTemplate", res)
            let responseData = res.data.data[0]
            if(typecall=='Text'){
                sendOnlyTextAPI(responseData, allData)
            }
           
        }).catch((err) => {
            setloader(false)
            konsole.log("getTextTemplate", err)
        }).finally(() => {
            setloader(false)
            konsole.log("getTextTemplatefinally")
        })
    }

    const sendOnlyTextAPI = (responseData, allData) => {
        let templateData = templateReplacer(responseData?.textTemplateContent, allData, 'TEXT')
        konsole.log("sendTextAPIFunc",responseData, allData,templateData)
        let jsonobj = {
            "smsType": responseData?.textTemplateType,
            "textTo": allData?.userPhoneNo,
            "textContent": templateData,
            "smsTemplateId": responseData?.textTemplateId,
            "smsStatusId": 1,
            "smsMappingTable": "tblUserLinks",
            "smsMappingTablePKId": allData?.usersUserId,
            "createdBy": loggedUserId.loggedUserId
        }

        konsole.log("jsobobjTe", jsonobj)
            sendTextApi(jsonobj)
    }

    

    const sendTextApi = (jsonobj) => {
        konsole.log("textTotextToTT")

        if(jsonobj?.textTo != null && jsonobj?.textTo != undefined && jsonobj?.textTo != ""){
            let result = Services.postSendTextPath(jsonobj)
            result.then((res) => {
                setloader(false)
                konsole.log("postSendTextPath", res)
                setsuccessalert(true);
                setTimeout(() => {
                    setsuccessalert(false)
                  }, 3000)
            }).catch((err) => {
                setloader(false)
                konsole.log("postSendTextPath".err)
            }).finally(() => {
                setloader(false)
                konsole.log("postSendTextPathfinally")
            })
        }else{
            setwarninngMsg(true)
            setWarningText("Contact number not available")
            setTimeout(() => {
                setwarninngMsg(false)
              }, 3000)
        }
       
    }
    //------------------------------EMail Call & Send ---------------------------------------
    const callEmailApiFunc = (TempalteId, allData,typecall) => {
        konsole.log("callEmailApiFunc", TempalteId,allData)
        let isActive = true;
        let result = Services.getEmailTemplate(TempalteId, isActive)
        result.then((res) => {
            konsole.log("getEmailTemplate", res)
            let responseData = res.data?.data[0]
            if(typecall=='Email'){
                   sendOnlyEmailAPI(responseData,allData)
            }

        }).catch((err) => {
            setloader(false)
            konsole.log("getEmailTemplate", err)
        }).finally(() => {
            setloader(false)
            konsole.log("getEmailTemplatefinally")
        })
    }

    const sendOnlyEmailAPI = (responseData, allData,) => {
        konsole.log("responseData", responseData, allData)
        let paralegalDetails = allData.paralegalDetails
        let templateData = templateReplacer(responseData?.templateContent, allData, 'EMAIL')
        let emailto = allData?.primaryclientData?.contact?.emails
        const userLoggedInDetail = commonLib.getObjFromStorage("userLoggedInDetail");
        let jsonobj = {
            "emailType": responseData?.templateType,
            "emailTo": allData.userEmail,
            "emailSubject": responseData?.emailSubject.replace("@@MEMBERNAME",userLoggedInDetail?.loginUserName),
            "emailContent": templateData,
            "emailTemplateId": responseData?.templateId,
            "emailStatusId": 1,
            "emailMappingTable": "tblUserLinks",
            "emailMappingTablePKId": allData?.usersUserId,
            "createdBy": loggedUserId?.loggedUserId

        }
        konsole.log("jsonobjemail",allData, JSON.stringify(jsonobj))
        sendEmailApi(jsonobj)
    }

    const sendEmailApi = (jsonobj) => {
        if(jsonobj?.emailTo != null && jsonobj?.emailTo != undefined && jsonobj?.emailTo != ""){
            let result = Services.PostEmailCom(jsonobj)
            result.then((res) => {
                setloader(false)
                setsuccessalert(true);
                setTimeout(() => {
                    setsuccessalert(false)
                  }, 3000)
                konsole.log("PostEmailCom", res)
                setloader(false)
            }).catch((err) => {
                konsole.log("PostEmailCom", err)
                setloader(false)
            }).finally(() => {
                konsole.log("PostEmailCom")
            })
        }else{
            setloader(false)
            setwarninngMsg(true)
            setWarningText("Email not available")
            setTimeout(() => {
                setwarninngMsg(false)
              }, 3000)
        }
       
    }
    const numberFormate = (number) => {
        const lastTenDigitArray = array => array.slice(-10);
        return commonLib.convertToUSFormat(lastTenDigitArray(number), "1")
          }
    const templateReplacer = (templateData, allData, type) => {

        let elderlawAttorneycheck = (elderlawAttorney !=='' && elderlawAttorney.length !==0)?true:false
        let fullNameelderlawAttorney=(elderlawAttorneycheck==true)? commonLib.showFullName(elderlawAttorney[0]) : allData?.memberFullName
        const userLoggedInDetail = commonLib.getObjFromStorage("userLoggedInDetail");

        let clientdetails = allData?.clientData

        let updatedTemplateData = templateData;
        
        const nameOfSubtenant = sessionStorage.getItem("SubtenantName")
        
        updatedTemplateData = updatedTemplateData?.replace('@@SUBTENANTNAME',nameOfSubtenant).replaceAll("@@AGENTFULLNAME",allData?.agentName).replaceAll("@@AGENTEMAIL",allData?.agentEmail).replaceAll("@@AGENTNUMBER",allData?.agentMobileNo != undefined && allData?.agentMobileNo != null && allData?.agentMobileNo != ""? numberFormate(allData?.agentMobileNo):"not available").
        replace("@@EMAILTEXT", allData?.contentForMail).replace("@@MEMBERNAME ", userLoggedInDetail.loginUserName).replace("@USERNAME", fullNameelderlawAttorney).replace("@@SMSTEXT", allData?.contentForMail)
      
        return updatedTemplateData



    }

    //---------------------------------COMPONENENT--------------------------------------------------------
    return <></>
});
export default OccuranceCom2