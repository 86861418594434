import React, { useState, useContext } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Modal from "react-bootstrap/Modal";
import { globalData } from "../CommonModal";
import '../Modal.css'
import {returnFname} from '../../control/Constant'
import { aorgLink, aorgLinkFunc } from "../../control/Constant";

import "../Illness/Warning.css";
const EOLGoalStep7 = (props) => {
  const { handleClose, setmodalcontantshow, steptwomodaldata, isGCM, fullNameofGcm, setstep7illnesshomenext, modalcontantshow, stepsToFollow } = useContext(globalData)

  const click = () => {
    setstep7illnesshomenext('eolstep7')
    setmodalcontantshow('eolgcmstep8')

  }


  const openWindow = () => {
    localStorage.setItem("keypoints", JSON.stringify(stepsToFollow));
    window.location.replace(aorgLinkFunc("health", modalcontantshow, 7, steptwomodaldata));
  }

  return (
    <>
      <Modal.Header style={{ backgroundColor: "#871a27" }}>
        <Row>
          <Col sm={11}>
            <h3 className="heading">Step 7:Goal and Steps</h3>
          </Col>
          <Col sm={1}>
            <button
              className="closeButton"
              style={{ border: "1px solid #981b29" }}
              onClick={handleClose}
            >
              x
            </button>
          </Col>
        </Row>
      </Modal.Header>
      <Modal.Body>

        <h4 className="SelectOptionsh4Tag mb-4 mt-2">
          Please contact {returnFname(steptwomodaldata.memberFullName)}'s GCM. He/She will be able to direct you to get a second opinion. A decision can be made after that.
        </h4>


        <div className="d-flex justify-content-center">
          {
            (isGCM == 'true') ?
              <button className="healthButton" onClick={click}>
                Contact {fullNameofGcm}
              </button>
              : <a onClick={openWindow} target="_blank">
                <button className="healthButton">
                  Find a GCM
                </button>
              </a>

          }

        </div>


        <div className='buttonsDiv'>
          <button className='backButton' onClick={() => setmodalcontantshow('eolneedyesunanimous')}>Back</button>
        </div>
      </Modal.Body>
    </>
  )
}
export default EOLGoalStep7;