import React from "react";
import commonLib from "../control/commonLib";
import "./AboutSection.css"


function AboutSection() {
    const userLoggedInDetail = commonLib.getObjFromStorage("userLoggedInDetail");
    return (
        <>

            <div className="container1">
                <div className="title">
                    <div className="centered">
                        <h1 className="Minion-Pro-Bold text-white" >Welcome, {userLoggedInDetail.loginUserName} !</h1>
                    </div>
                </div>
            </div>

            <section className="content1 container">
                <div>
                    <h6 >
                        YOUR ROLE AS A FIDUCIARY
                    </h6>
                    <p>
                        You have been appointed to act as a Personal Representative (also referred to as the
                        Executor); and/or the Attorney in Fact(agent) or successor Attorney in Fact under the
                        Power of Attorney for finances and/or Power of Attorney for healthcare created by a
                        friend or family member who has faith and trust in your abilities to act in their best
                        interest when they are not able to do so themself. Since you will have an active role to
                        play, please find enclosed copies of the documents you will need to fulfill your
                        appointed obligations. You will also find a copy of the Health Care Directive and
                        Supplement more commonly known as a Living Will, which sets the wishes of the
                        maker of the Living Will as regards to administration of artificial means of life support
                        to keep him or her alive should he or she be found to be either in a persistive vegetative
                        state or terminally ill and unable to communicate at the time.
                        Just because you have been named to act as a fiduciary does not mean that you have to
                        accept this assignment.
                        <br />
                        The following information is to provide you guidance in regard to your duties as
                        Personal Representative or Attorney in Fact, the limitations on your powers, and other
                        information you may find useful in assessing whether or not to accept the
                        responsibilities. If you choose not to accept the responsibility, we ask that you provide the
                        Principal (the person who named you as the fiduciary) and/or this office written notification
                        of such decision.
                    </p>
                </div>
                <div className="mt-5">
                    <h6>
                        WHAT ARE THE RESPONSIBILITIES OF A PERSONAL REPRESENTATIVE?
                    </h6>
                    <p>
                        Personal Representative you will be called upon to identify assets that belong to the
                        estate of the deceased individual at the time of death take charge of the assets; contact
                        an attorney to review whether or not a probate process is necessary, and if a probate
                        is needed then execute the legal documents necessary to start the process. You will be
                        required to identify the beneficiaries of the estate and distribute the assets according
                        to the LastWillandTestament of the deceased person. As Personal Representative, you
                        will act as an officer of the court and quickly and selflessly close the estate. The task is
                        not difficult but can be time-consuming. You can ask to be compensated for the time
                        you spend on administrative or other types of duties of a Personal Representative for
                        the benefit of the estate, which will be in addition to your share of receiving from the
                        estate, or you can forego any payment. Usually, competent attorney assistance can keep
                        your involvement to a minimum. Assets within the estate will be used to bear the cost
                        of the probate or estate administration (if a full probate is not necessary).
                        For most family members this is one of the last physical tasks they will undertake that
                        is connected to their loved one, and it is important in bringing a sense of closure. It is
                        also a very stressful time for all family members and unless approached with care, it
                        can lead to discourse within the family. It is very important that you be sensitive to
                        what can go wrong and seek competent legal assistance to navigate through this
                        process. With proper counsel, you will find your involvement very fulfilling. As with
                        everything else, just because you have been named as a Personal Representative does
                        not obligate you to accept the responsibility. Should you decide that you would rather
                        not accept this responsibility, please do not hesitate to contact this office in order that
                        we may amend the Will and have a suitable alternative named.
                    </p>
                </div>
                <div className="mt-5">
                    <h6>
                        WHEN WILL YOU BE CALLED UPON AS A PERSONALREPRESENTATIVE?
                    </h6>
                    <p>
                        When the
                        maker of the Will (Testator or Testatrix) passes away you will then be called upon to
                        fulfill your duty as a Personal Representative.
                    </p>
                </div>
                <div className="mt-5">
                    <h6>
                        WHERE WILL YOU FIND THE WILL?
                    </h6>
                    <p>
                        Although our office will have a copy of the Will,
                        the original Will should be found with the personal belongings of the Testator or
                        Testatrix. You may wish to make sure you know where to find the Will by having a
                        discussion with the Testator or Testatrix as to the location of this legal instrument as
                        well as any other important paperwork.
                    </p>
                </div>
                <div className="mt-5">
                    <h6>
                        WHAT IS A POWER OF ATTORNEY?
                    </h6>
                    <p>
                        Similar to a Will, a Power of Attorney is a
                        document that nominates a person to manage the financial and/or healthcare affairs
                        of another should he or she find himself or herself unable to manage those affairs on
                        his or her own. As a loved one or friend to the maker of the Power of Attorney
                        (Principal), you will need to stay in touch with the Principal so you know when he or
                        she might be in need of assistance. As I have set out below, one of the hallmarks of the
                        Powers of Attorney executed by the Principal is that they are focused as much on the
                        Principal's and your quality of life as on the preservation of assets. Incapacity usually
                        brings with it unique challenges that few of us are ready to deal with; the biggest
                        challenge being how to best address physical incapacity: “Should one look to nursing
                        homes, assisted living facilities or other institutions as a way to keep the Principal at
                        home while assuring his or her safety and happiness?” This issue has been discussed
                        in some detail with the Principal who does not wish to unduly burden you with this
                        awesome responsibility without giving you resources that will allow you to seek
                        guidance as to this very important issue. Much more on this subject is explained below.
                    </p>
                </div>
                <div className="mt-5 mb-5">
                    <h6>
                        WHEN DOES YOUR POWER COME INTO BEING IF YOU ARE APPOINTED AS
                        PRIMARY ATTORNEY IN FACT WHEN AN IMMEDIATE POWER OF ATTORNEY IS
                        ENACTED.
                    </h6>
                    <p>
                        If the Power of Attorney executed by the Principal is an immediate Power
                        of Attorney (you will find this information under the header to the Power of Attorney),
                        it gives the first named attorney in fact the right to act on the Principal's behalf
                        immediately. Successor attorneys in fact will have the right to act once the primary
                        attorney in fact named ahead of them is unable or unwilling to act.
                    </p>
                </div>
                <div className="mt-5 mb-5">
                    <h6>
                        WHEN DOES YOUR POWER COME INTO BEING IF YOU ARE APPOINTED AS
                        PRIMARY ATTORNEY IN FACT TO THAT CONTINGENT POWER OF ATTORNEY.
                    </h6>
                    <p> If
                        the Power of Attorney executed by the Principal is a contingent Power of Attorney (also
                        referred to as a springing Power of Attorney), it requires you to provide some proof of
                        the Principal's inability to manage his or her own affairs without placing himself or
                        herself in risk of imminent financial or physical harm.
                    </p>
                </div>
                <div className="mt-5 mb-5">
                    <h6>
                        WHEN DOES YOURPOWERCOME INTO BEING IF YOU HAVEBEEN APPOINTED AS
                        A SUCCESSOR ATTORNEY IN FACT?
                    </h6>
                    <p>
                        If the Power of Attorney executed by the
                        Principal names you as the successor attorney in fact (you are not the first named
                        person or primary), this means that your power to act will come into being only after
                        all the persons named ahead of you die or become incapacitated to a point that they
                        cannot fulfill the obligations of an attorney in fact or resign their position. This means
                        that you will need to provide proof showing that all agents named ahead of you are
                        unable to fulfill the role triggering your authority to act.
                    </p>
                </div>
                <div className="mt-5 mb-5">
                    <h6>
                        HOW DO YOU PROVE YOUR AUTHORITY.
                    </h6>
                    <p>
                        Most institutions will accept a copy of the
                        Power of Attorney along with verification of your identity as proof of your ability to act.
                        However, this is not always the case. Banks and financial institutions may sometimes
                        require you to provide them with an Affidavit in Support of the Power of Attorney. If you are
                        named as the first Attorney in Fact you will be able to execute the Affidavit by signing
                        the document in front of a notary public and presenting both the Power of Attorney and
                        the Affidavit to the institution requesting the Affidavit. If you are the successor
                        Attorney in Fact you may also need to provide a letter of resignation by the Attorney
                        in Fact named ahead of you. If you are unable to obtain a letter of resignation you
                        should be able to use the Affidavit so long as it includes a statement that the first named
                        attorney in fact is unable or unwilling to act along with an explanation of the
                        circumstances that lead you to this conclusion.
                    </p>
                </div>
                <div className="mt-5 mb-5">
                    <h6>
                        THE COPY OF THE POWER OF ATTORNEY IS THE SAME AS AN ORIGINAL.
                    </h6>
                    <p>
                        A copy
                        of the original Power of Attorney is as good as the original, though certain institutions
                        and organizations insist on the original Power of Attorney or a certified copy of the
                        Power of Attorney. For example, you may not be able to utilize a copy of the Power of
                        Attorney to prepare a deed without first recording the original Power of Attorney with
                        the County Recorder's office. You may also obtain a certified copy of this recorded
                        original from the Recorder's office.
                    </p>
                </div>
                <div className="mt-5 mb-5">
                    <h6>
                        DOES THE POWER OF ATTORNEY EVER EXPIRE?
                    </h6>
                    <p>
                        Technically, a Power of Attorney
                        never expires. However, many institutions do question the validity of the Powers of
                        Attorney that are more than five years old. Though this does not meet any legal
                        standard, the institutions find some protection in the argument that they are only
                        trying to protect the interest of their client, the Principal who executed the Power of
                        Attorney. In such cases, the only alternative is to obtain a court order to enforce the
                        Power of Attorney which the institutions cannot ignore.
                        Should the Principal be subjected to the protections of a guardianship proceeding in
                        which he or she has his or her right to make financial and or healthcare decisions taken
                        away from them by operation of law, your powers will come to an end unless the court
                        specifically affirms the Power of Attorney and recognizes your power to continue acting
                        under the Power of Attorney.
                    </p>
                </div>
                <div className="mt-5 mb-5">
                    <h6>
                        DO ALL INSTITUTIONS HAVE TO ACCEPT THE POWER OF ATTORNEY?
                    </h6>
                    <p>
                        Powers of Attorney are created under state statutes and therefore are enforceable within the state
                        with all institutions, agencies, and organizations EXCEPT federal agencies, such as the
                        Veterans Administration, Social Security Administration, etc. These agencies require
                        the Principal to sign other forms. In the case of the Veteran's Administration, they are
                        known to accept any document with the purported signatures of the Principal without
                        looking into the mental status or capacity of the Principal. The Social Security the
                        Administration has a process whereby they can appoint an interested person in the life
                        of the Principal as the Representative Payee who then is given the authority to act on
                        behalf of the Principal.
                        Outside of the federal agencies, sometimes financial institutions may require you to file
                        forms thats call for Medallion signatures. Unlike a notarized signature, a Medallion
                        signature is provided only by banks and supposedly provides the financial institutions
                        better assurance of the identity of the individual signing the document. Though this is
                        seen by many as a smoke screen masking the real intent to keep control over the
                        financial assets of the Principal, it is a practice that is prevalent and generally accepted.
                        The financial institutions argue that they are not challenging the validity of the Power
                        of Attorney, they are only making you follow their regulations as regards transacting
                        business with that particular institution.
                        Finally, you may not be able to use a copy of the Power of Attorney to execute real
                        property transactions, specifically deeds. If you are called upon to sign a deed on behalf
                        of the Principal, then you will need to take the original Power of Attorney to the County
                        Recorder's Office and record it before filing the deed bearing your signature in the
                        capacity of Attorney in Fact.
                    </p>
                </div>
                <div className="mt-5 mb-5">
                    <h6>
                        WHAT IF THE ORIGINAL POWER OF ATTORNEY IS LOST?
                    </h6>
                    <p>Generally, most
                        institutions will accept the copy of the Power of Attorney as proof of your authority to
                        act on behalf of the Principal. However, if they do not and you do not have the original
                        Power of Attorney, the only resolution may be to obtain a court order accepting the
                        copy as the original. Since court actions are expensive and time-consuming it is
                        advisable for you to try and negotiate a lesser demanding alternative to a court order.
                    </p>
                </div>
                <div className="mt-5 mb-5">
                    <h6>
                        LIMITATIONS ON YOUR POWERS.
                    </h6>
                    <p>Please be aware of the following limitation on your authority to act:</p>
                    <ol>
                        <li>
                            <h6 className="my-2"> ALL YOUR ACTIONS MUST BE IN THE BEST INTEREST OF PRINCIPAL
                                TO THE EXCLUSION OF ALL OTHERS.
                            </h6>
                            <p>
                                The Power of Attorney is a durable power of attorney and will survive the Principal's incapacity. The Power of Attorney only accords you the authority to act on behalf of the Principal and it does not give you the right to use any of the assets for your own benefit or the benefit of any person other than the Principal.
                            </p>
                        </li>
                        <li>
                            <h6 className="my-2">YOU MUST DEFER TO THE DECISIONS OR KNOWN WISHES OF THE PRINCIPAL.</h6>
                            <p>
                                The Principal never gives up the right to make his or her own decisions or to veto your decisions. As such, your decisions will always be subordinate to the decisions of the Principal. Even if the Principal is incapacitated you must not substitute your own judgment over the known wishes of the Principal. For example, if the Principal was opposed to making charitable gifts, you may not apply your own morals and decide to make charitable gifts from the Principal's estate. Another example if the Principal had been making gifts to a family member which you thought were unwise, you may not use your own judgment and stop making those gifts.
                            </p>
                        </li>
                        <li>
                            <h6 className="my-2">YOU MUST ACCOUNT TO THE PRINCIPAL OR ANY OTHER INTERESTED PARTY.</h6>
                            <p>
                                The Principal and all other interested persons have the right to demand an accounting from you of all your actions undertaken in reliance of the Power of Attorney. If you are asked by the Principal or other interested party for an accounting (in writing or otherwise) you must provide such an accounting within a reasonable amount of time. The accounting must be in a format that is easily understandable by a reasonable person.
                            </p>
                        </li>
                        <li>
                            <h6 className="my-2">YOU MUST CEASE USING THE POWER OF ATTORNEY IF SO DIRECTED.</h6>
                            <p>
                                The Principal also retains the right to discharge you from your assigned role as Attorney in Fact at will, unless the Principal has been found to be
                                incapacitated under the Guardianship statutes by a court of competent jurisdiction. It is not necessary for the Principal to notify you in writing
                                of his or her decision to discharge you. Once discharged, you must refrain from using the Power for any purpose.
                            </p>
                        </li>
                        <li>
                            <h6 className="my-2">YOU DO NOT HAVE AUTHORITY TO ENTER INTO VOLUNTARY ARBITRATION AGREEMENTS.</h6>
                            <p>A specific limitation on your powers is a prohibition for you to enter into any arbitration agreements on behalf of the Principal.</p>
                        </li>
                        <li>
                            <h6 className="my-2">AFFIRMATIVE OBLIGATIONS, RESPONSIBILITIES, AND OTHER CONSIDERATIONS IN THE ROLE OF FIDUCIARY.</h6>
                            <ol type="A">
                                <li>
                                    <h6 className="my-2">YOU ARE CALLED UPON TO ENGAGE THE SERVICES OF A GERIATRIC CARE MANAGER. </h6>
                                    <p>
                                        As mentioned above, a significant goal in drafting the Power of Attorney in the manner in which it has been drafted is to recognize that the burdens placed on you are heavy. Given that you may be quite busy in your own life and may not have the time necessary to devote to identifying all healthcare-related issues without sacrificing your own quality of life, the Principal has included provisions that require you to utilize the resources within the estate to obtain the services of a Geriatric Care Manager with the intent of obtaining an initial assessment at to the Principal's care needs. Questions such as whether or not the Principal can continue to reside at home should not be made without professional input. It is the hope that with this input from an outside objective professional knowledgeable in geriatric and disability issues, you will be able to make better-informed decisions as regards maximizing the Principal's quality of life under the circumstances without sacrificing your own. You will have the right to disregard the input so long as you obtain a second opinion. If the two opinions concur, then you will have to follow the advice. If the two opinions differ, you will be free to adopt either of the two choices. You will also have the right to discharge the services of a care manager for ongoing care management so long as you obtain the services of another independent care manager.
                                    </p>
                                </li>
                                <li>
                                    <h6 className="my-2">YOU MUST DEFER TO THE ATTORNEY IN FACT NAMED IN THE HEALTHCARE POWER OF ATTORNEY FOR HEALTH CARE AND QUALITY OF LIFE RELATED DECISIONS. </h6>
                                    <p>
                                        If the opinions of the agent named in the Power of Attorney for finances and the agent named in the Power of Attorney for healthcare are different, then
                                        The decisions made by the attorney in fact acting under the provisions of the Power of Attorney for finances will be subordinate to the agent acting under the provisions of the Power of Attorney for healthcare for health and quality of life-related matters.
                                    </p>
                                </li>
                            </ol>
                        </li>
                    </ol>
                </div>
                <div className="mt-5 mb-5">
                    <h6>
                        RESIGNATION
                    </h6>
                    <p>You may resign by giving written notice and an accounting of funds
                        under your control to the successor attorney in fact. If no successor attorney in fact
                        exists you may resign by providing a court of competent jurisdiction notice of your
                        intent to resign and provide the court with an accounting of the funds that were under your
                        control.
                    </p>
                </div>
                <div className="mt-5 mb-5">
                    <h6>
                        ASSISTANCE.
                    </h6>
                    <p>
                        Though the duties you may be called upon to fulfill may not be complex
                        for a variety of reasons, you may find yourself needing assistance. In those times you
                        would be well advised to turn to Aging Options or the Law Firm of Johnson & Nagaich,
                        P.S., its successors and or assigns for assistance. You may also be able to find assistance
                        with other care management companies or law firms.
                    </p>
                </div>
                <div className="mt-5 mb-5">
                    <h6>
                        COMPENSATION
                    </h6>
                    <p>You may reimburse yourself all out-of-pocket expenses you incur
                        in the course of executing your duties as an attorney in fact. You may also pay yourself a
                        reasonable amount of compensation for the time you spend executing your
                        responsibility as an attorney in fact.
                    </p>
                </div>
                <div className="mt-5 mb-5">
                    <h6>
                        LIVING WILL ISSUES.
                    </h6>
                    <p>
                        If the Principal has elected to forego any artificial means of life
                        support should he or she be found to be in a persistent vegetative state or terminally
                        ill and unable to communicate his or her wishes at the time, then you must adhere to
                        those wishes.
                    </p>
                </div>
                <div className="mt-5 mb-5">
                    <h6>
                        DISPOSITION OF REMAINS.
                    </h6>
                    <p>If the Principal has elected for cremation, please find
                        enclosed the executed copies evidencing their wishes. You do not have the right to
                        override these wishes and will need to follow them regardless of your own feelings and
                        values on the issue.
                        We have provided you with a great deal of information. Should you have questions or
                        concerns, please feel free to call.
                    </p>
                </div>
            </section>
        </>
    )

}

export default AboutSection;