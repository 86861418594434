import React, { useState, useContext } from 'react';
import Modal from 'react-bootstrap/Modal';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { globalData } from '../Execute/CommonModal';
const MentailHealthCallNine = (props) => {
    const { handleClose, setmodalcontantshow, steptwomodaldata } = useContext(globalData)


    return (
        <>

            <Modal.Header style={{ backgroundColor: "#871a27" }}>

                <Modal.Title className='heading' id="example-modal-sizes-title-lg" style={{ color: 'white', fontFamily: "Inter",height: 30 }}>

                </Modal.Title>
                {/* closeButton */}

                <button className='closeButton' style={{ border: "1px solid #981b29" }} onClick={handleClose}>x</button>

            </Modal.Header>
            <Modal.Body>
                <h4 className='SelectOptionsh4Tag text-center mb-4'>

                     Please call 911.
                </h4>

                <div className='stepsevenbuttonsDiv'>
                    <button className='backButton' onClick={() => setmodalcontantshow('MentalHealthSafe')}>
                        Back</button>
                </div>



            </Modal.Body>


        </>
    );
}

export default MentailHealthCallNine;