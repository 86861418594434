import React, { useContext, useEffect, useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";
import { globalData } from "../CommonModal";
import '../Modal.css'
import { click } from "@testing-library/user-event/dist/click";

const ContactToelderlawattorney = (props) => {

    const { handleClose, setmodalcontantshow,elderlawAttorney,steptwomodaldata,isGCM,setEOlStep8Toallcall, setNotifyTypeId ,fullnameofAlderlawattorny,setlenderemailtexttype} = useContext(globalData)
    let elderlawAttorneycheck = (elderlawAttorney !== '' && elderlawAttorney.length !== 0) ? true : false
  useEffect(()=>{
    setEOlStep8Toallcall('Email')
  },[])
  
 
  const clickemailtext=(type)=>{
    if(type==1){
        setlenderemailtexttype('Email')
    }else{
        setlenderemailtexttype('Text')
    }
    setmodalcontantshow('eolmailforelderattorney')
 
}
  
  return (
    <>
      <Modal.Header style={{ backgroundColor: "#871a27" }}>
        <Row>
          <Col sm={11} md={10} lg={11}>
            <h3 className="heading">How would you like to contact {fullnameofAlderlawattorny}, Elder law attorney? </h3>
          </Col>
          <Col sm={1} md={2} lg={1}>
            <button
              className="closeButton"
              style={{ border: "1px solid #981b29" }}
              onClick={handleClose}
            >
              x
            </button>
          </Col>
        </Row>
      </Modal.Header>
      <Modal.Body>
        <h4 className="SelectOptionsh4Tag">Select option</h4>

        <div className="buttonDiv mt-4">
        <button className='emailButton' onClick={()=>clickemailtext(1)}>Email</button>
        <button className='callButton' onClick={()=>clickemailtext(2)} >Text</button>
        {elderlawAttorneycheck == true && 
        <button className='callButton' onClick={()=> setmodalcontantshow('eolattornerCall')} >Call</button>}
                        
          
        </div>
       
        <div className="backButtonDiv">

          {
            (elderlawAttorneycheck == true) ?<>
            {
            (props.step7illnesshomenext=='IllnessHome') ?

            <button className="backButton"  onClick={()=>setmodalcontantshow('illnesshomegoalstep7')}>Back</button>
            :(props.step7illnesshomenext=='eolstep7')?
            <button className="backButton"  onClick={()=>setmodalcontantshow('eolgoalstep7')}>Back</button>
            :(props.step7illnesshomenext=='NeedHelp')?
            <button className="backButton"  onClick={()=>setmodalcontantshow('eolneedhelpstep7')}>Back</button>
            :<button className="backButton"  onClick={()=>setmodalcontantshow('illnesshospitalstep7')}>
            Back
          </button>
}
          </>:(elderlawAttorneycheck == false) ?
          <>
          {(props.withOutGCMNeedHelpNext=='NeedHelp')?
          <button className="backButton"  onClick={()=>setmodalcontantshow('eolneedhelpstep7')}>Back</button>
          :<button className="backButton"  onClick={()=>setmodalcontantshow('eolneedhelpstep9')}>Back</button>
        }
          
          </> : "" }
        
        </div>
      </Modal.Body>
     
    </>
  );
};

export default ContactToelderlawattorney;
