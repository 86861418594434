import React, { useContext, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import '../Modal.css';
import { globalData } from '../CommonModal';

const DeathNotifySuccessorStep4 = () => {
   
    const {NotifyContinueCallApi, handleClose, setmodalcontantshow, steptwomodaldata,getGCMprofessionalbyUserid,memberRankIdforPrimary } = useContext(globalData)
 
    
    const backBtnClick=()=>{
        let id = memberRankIdforPrimary
        if(id == 1 || id == 2 || id == 3 || id == 4 || id == 5 || id == 6){
            setmodalcontantshow('step3selectoption')
        }else{
            setmodalcontantshow('deathwarning')
        }
    }
    return (
        <>
        




            <Modal.Header style={{ backgroundColor: "#871a27" }}>

                <Modal.Title className='mt-2' id="example-modal-sizes-title-lg">
                    <h3 className='heading text-center' >
                        Step 4: Notify Agents
                    </h3>
                </Modal.Title>
                {/* closeButton */}

                <button className='closeButton' style={{ border: "1px solid #981b29" }} onClick={handleClose}>x</button>

            </Modal.Header>
            <Modal.Body>
                
                <h3 className='SelectOptionsh4Tag mb-2 mt-1'>As a first step, we would like to inform you that all agents should be notified. Please click continue, and a notification will be sent to them.
                </h3>


                <div className='buttonDiv '>
                    <button className='understandButton fw-bold' onClick={()=>setmodalcontantshow('deathemailtextpreview')}>Continue</button>

                </div>
                <div className='backbuttonDiv'>
                    <button className='backButton'onClick={()=>backBtnClick()}  >Back</button>
                </div>
            </Modal.Body>
       
        </>
    );
}

export default DeathNotifySuccessorStep4;