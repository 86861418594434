import React, { useContext, useState, useEffect } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import { globalData } from "../CommonModal";
import konsole from '../../Network/konsole';
import '../Modal.css'
import ShowPreofessionlDetails from '../../ShowPreofessionlDetails';
import Confirmation from '../../AgentInvitation/Confirmation';
import { aorgLink, aorgLinkFunc, returnHealthPolicyCount, returnPostProfessional, returnProfessionals } from "../../control/Constant";
import commonLib from '../../control/commonLib';
import { returnFname,isNotValidNullUndefinedfile} from '../../control/Constant'

const EOLNeedHelpStep9 = (props) => {
    const { handleClose, setlenderemailtexttype, setmodalcontantshow,setloader, steptwomodaldata, setEOLstep10, elderlawAttorney, modalcontantshow, stepsToFollow, setFullnameofalderlawattorney, illushospitalstep6help, goNext, setInsurenceJosn, getGCMprofessionalbyUseridforLocate } = useContext(globalData)
    konsole.log("elderlawAttorneyelderlawAttorney", elderlawAttorney)

    let elderlawAttorneycheck = (elderlawAttorney !== '' && elderlawAttorney.length !== 0) ? true : false
    let fullNameelderlawAttorney = (elderlawAttorneycheck == true) ? commonLib.showFullName(elderlawAttorney[0]) : "";
    let fNameelderlawAttorney = (elderlawAttorneycheck == true) ? elderlawAttorney[0]?.fName : "";
    const [elaData, setWlaData] = useState()
    const [policyData, setPolicyData] = useState()
    const [showGcmModal, setShowGcmModal] = useState(false)
    
    useEffect(() => {
        if (goNext == "Home" || goNext == "Hospital" || goNext == "Other") {
            healthPolicyCount(steptwomodaldata?.memberUserId)
        }

    }, [])

    const clickemailtext = (type) => {
        setmodalcontantshow('contactToelderlawattorney')
        setFullnameofalderlawattorney((elderlawAttorneycheck == true) ? `${fullNameelderlawAttorney}` : "Elder law attorney")
    }
    useEffect(() => {
        // if (elderlawAttorneycheck !== true) {
            ProfessionalUsers(4, 13, 1, 100)// ela
        // }

    }, [])
    const ProfessionalUsers = async (ProSerDescId, ProTypeId, PageNumber, RowsOfPage) => {
        let userId = steptwomodaldata?.memberUserId
        const result = await returnProfessionals(ProSerDescId, ProTypeId, PageNumber, RowsOfPage,setloader,userId);
        const shuffledUsers =  result.length > 0 && result.sort(() => Math.random() - 0.5);
        const usersToShow = shuffledUsers.slice(0, 6);
        setWlaData(usersToShow)
       if (result == 'err') return;
    };

    
    const healthPolicyCount = async (id) => {
        setloader(true)
        const result = await returnHealthPolicyCount(id);
        setPolicyData(result)
        setloader(false)
        setInsurenceJosn(result)
        if (result == 'err') return;
    };
    
   const Confirmations = async(text,setshowConfrimation)=>{
    setmodalcontantshow('contactToelderlawattorney')
        // let data = userData
        // const userLoggedInDetail = commonLib.getObjFromStorage("userLoggedInDetail");
        // const result = await returnPostProfessional(0, data?.proUserId, data?.proCatId, steptwomodaldata?.memberUserId, true, true, userLoggedInDetail?.loggedUserId,setloader)
        //  setshowConfrimation(false)
        //  setShowGcmModal(false)
        //  getGCMprofessionalbyUseridforLocate(13, true)
        // if (result == 'err') return;
    
   } 
    return (
        <>

            <Modal.Header style={{ backgroundColor: "#871a27" }}>
                <Row >
                    <Col sm={11} md={10} lg={11}>
                        <h3 className='heading'>
                            Step 9: Contact Elder Law Attorney
                        </h3>
                    </Col>
                    <Col sm={1} md={2} lg={1}>
                        <button className='closeButton' style={{ border: "1px solid #981b29" }} onClick={handleClose}>x</button>
                    </Col>
                </Row>

            </Modal.Header>
            <Modal.Body>
                {/* <h4 className='SelectOptionsh4Tag'> */}
                {elderlawAttorneycheck == true ? <>

                    {policyData != undefined && policyData != null && policyData.length > 0 && illushospitalstep6help !== "EndOfLife" ? <>
                        <h4 className='SelectOptionsh4Tag'>
                            Now that you have contacted the care manager to organize {returnFname(steptwomodaldata.memberFullName)}'s care, know that Medicare
                            may only cover some limited care costs.<br /><br /> {returnFname(steptwomodaldata.memberFullName)} would like you to contact an elder law attorney to see whether {returnFname(steptwomodaldata.memberFullName)}'s Long term Care insurance of
                            {policyData.length > 0 && policyData?.map((ele) => {
                                return ` ${isNotValidNullUndefinedfile(ele?.insCompany) ? ele?.insCompany : ""} policy number${!isNotValidNullUndefinedfile(ele?.additionalDetails)? "" :ele?.additionalDetails}, `
                            })}
                            could be accessed for help to cover some or all of these costs left uncovered by Medicare
                        </h4>
                        <span className='SelectOptionsh4Tag text-primary' style={{ cursor: "pointer", textDecoration: "underline" }} onClick={() => setmodalcontantshow("modalInsurence")}>Click here to view the long term care insurance policy</span>
                    </> :

                        <>
                            <h4 className='SelectOptionsh4Tag'>
                                Now that you have contacted the care manager to organize {returnFname(steptwomodaldata.memberFullName)}'s care,
                                know that Medicare may only cover some limited care costs.<br /><br /> {returnFname(steptwomodaldata.memberFullName)}  would like you to contact an elder law attorney to see whether VA or Medicaid programs can help cover some or all of these costs left uncovered by Medicare.<br />
                            </h4>
                        </>}

                </> : <>
                <h4 className='SelectOptionsh4Tag'>
                    Now that you have contacted the care manager to organize {returnFname(steptwomodaldata.memberFullName)}'s care, know that Medicare
                    may only cover some limited care costs.<br /><br /> {returnFname(steptwomodaldata.memberFullName)} does not have any Elder law attorney.<br /><br /> Please click below to search
                    for elder law attorney, Elder law attorney will help you assess whether it is time to apply for VA or Medicaid benefits for help with the care
                    </h4>
                </>}

                {/* </h4> */}
                {(elderlawAttorneycheck == true) ?
                    <>
                        <div className='buttonDiv mb-4'>
                            <button className='emailButton' onClick={() => clickemailtext(1)}>{` Contact ${((elderlawAttorneycheck == true) ? `${fullNameelderlawAttorney}` : "Elder law attorney")}`} </button>
                            {/* <button className="emailButton" onClick={()=>setShowGcmModal(true)}>ELA Details</button> */}
                        </div>
                        {/* <div className="d-flex justify-content-center">
                            {totalPages > 1 && currentPage > 0 && (
                                <button className="nextButton me-2" onClick={prevPage}>{"<"}</button>
                            )}
                            {displayedUsers.map(ele => (
                                <button className="profesionalBtn" onClick={() => addEla(ele)} key={ele.id}>
                                    {ele.proFullName}
                                </button>
                            ))}
                            {totalPages > 1 && currentPage < totalPages - 1 ? (
                                <button className="nextButton" onClick={nextPage}>{">"}</button>
                            ) : (
                                <button className="nextButton" style={{ width: "auto", padding: "7px" }} onClick={openWindow}>Show more</button>
                            )}
                        </div> */}
                         {/* <div className='buttonDiv mb-4'><button className="emailButton" onClick={()=>setShowGcmModal(true)}> Find ELA</button></div> */}

                    </> : <>
                        
                        {/* <div className="d-flex justify-content-center">
                            {totalPages > 1 && currentPage > 0 && (
                                <button className="nextButton me-2" onClick={prevPage}>{"<"}</button>
                            )}
                            {displayedUsers.length > 0 && displayedUsers.map(ele => (
                                <button className="profesionalBtn" onClick={() => addEla(ele)} key={ele.id}>
                                    {ele.proFullName}
                                </button>
                            ))}
                            {totalPages > 1 && currentPage < totalPages - 1 ? (
                                <button className="nextButton" onClick={nextPage}>{">"}</button>
                            ) : (
                                <button className="nextButton" style={{ width: "auto", padding: "7px" }} onClick={openWindow}>Show more</button>
                            )}
                        </div> */}
                        <div className="d-flex justify-content-center">
                            <button className="emailButton" onClick={()=>setShowGcmModal(true)}> Find ELA</button>
                        </div>

                    </>
                }
               <div className='backButtonDiv'>
                    {
                        (props.EOlStep8Toallcall == 'ok') ?
                            <button className='backButton' onClick={() => setmodalcontantshow('eolgcmcontact')}>Back</button>
                            :<button className='backButton' onClick={() => setmodalcontantshow('illnessemailtextpreview')}>Back</button>
                    }
                </div>
                {showGcmModal == true && 
       <ShowPreofessionlDetails showGcmModal={showGcmModal} setShowGcmModal={setShowGcmModal} professData={elaData} Confirmations={Confirmations} professType="Elder law attorney"isProfess={elderlawAttorneycheck}singleUserData={elderlawAttorney[0]}/>}
      
            </Modal.Body>
            </>
    );
}

export default EOLNeedHelpStep9