import React, { useState, useContext } from 'react';
import Modal from 'react-bootstrap/Modal';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { globalData } from '../CommonModal';
import {returnFname} from '../../control/Constant'

import '../Modal.css'
import "../Illness/Warning.css";
const DeathCrematedViewGuidance = (props) => {
    const { handleClose, setmodalcontantshow, steptwomodaldata } = useContext(globalData)

    const clickviewguidance=()=>{
        // alert("Click View Guidance")
    }


  return (
    <>
    
      <Modal.Header style={{ backgroundColor: "#871a27" }}>

        <Modal.Title className='heading' id="example-modal-sizes-title-lg" style={{ color: 'white', fontFamily: "Inter", height: 30 }}>

        </Modal.Title>
        {/* closeButton */}

        <button className='closeButton' style={{ border: "1px solid #981b29" }} onClick={handleClose}>x</button>

      </Modal.Header>
      <Modal.Body>

        <h4 className='SelectOptionsh4Tag text-center mb-4'>
          {
            (props.deathcrematedburied=='cremated') ?
            <>
            Please review {returnFname(steptwomodaldata.memberFullName)}'s guidance to find out how to handle this situation.
            </>
          : (props.deathcrematedburied=='buried') ?
          <>
          Please review {returnFname(steptwomodaldata.memberFullName)}’s guidance to make sure that all {returnFname(steptwomodaldata.memberFullName)}'s wishes are followed.
          </>
          :""
}
         
        </h4>
     <div className='d-flex justify-content-center'>    
               <button  className="healthButton" onClick={clickviewguidance} >
              View Guidance
              </button>
              </div>

        <div className='stepsevenbuttonsDiv'>
          <button className='backButton' onClick={()=>setmodalcontantshow('deathcrematedyesno')}>
            Back</button>
         
        </div>  
      </Modal.Body>

   
    </>
  );
}

export default DeathCrematedViewGuidance;