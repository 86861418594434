import axios from 'axios';
import config from '../config.json';
// import { mapApi, mapKey } from '../control/Constant'
import { mapApi, mapKey } from '../control/Constant'

import konsole from "./konsole";
import AoUrl, { BASE_URL } from './url';

axios.defaults.timeout = 150000;
axios.defaults.baseURL = BASE_URL;
// axios.defaults.headers.post['Content-Type'] = 'application/json;text/html;charset=utf-8';

const invoke = (url, method, body, cancel) => {

    if (cancel) {
        cancel();
    }

    try {
        const token = sessionStorage.getItem('AuthToken');
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        konsole.log('URL: ' + url);
        konsole.log('method:' + method);
        konsole.log(((method == "POST") ? "body" : 'params') + JSON.stringify(body));

        return axios({
            cancelToken: new axios.CancelToken(function executor(c) {
                cancel = c;
            }),
            method: method,
            url: url,
            params: (method === "GET") ? body : null,
            data: (method === "POST" || method === "PUT" || method === "DELETE") ? body : null
        });
    } catch (error) {
        if (axios.isCancel(error)) {
            konsole.error('Request canceled', error.message);
        } else {
            konsole.error('Something went wrong: ', error.message)
        }
    }
};

const Services = {
    postgetUserAgentUserByAgentId: async (data) => {
        let cancel;
        let url = AoUrl.postgetUserByAgentId
        let body = data

        return invoke(url, "POST", body, cancel);
    },
    getsubjectForFormLabelIds: async (data) => {
        let cancel;
        let url = AoUrl.getsubjectForFormLabelId
        let body = data

        return invoke(url, "POST", body, cancel);
    },
    getUserDetailsByEmailId: async (userId) => {
        let cancel;
        let url = AoUrl.getUserDetailByEmailId + `?UserId=${userId}`

        return invoke(url, "GET", '', cancel);
    },
    getMember: async (userId) => {
        let cancel;
        let url = AoUrl.getMember + `/${userId}`

        return invoke(url, "GET", '', cancel);
    },
    getLoggedInUser: async (userId, appState, loggedInUserId, roleId) => {
        let cancel;
        let url = AoUrl.getAthenticatePath + `${userId}/${appState}/${loggedInUserId}/${roleId}/`;
        let bookingSlot = {};
        // bookingSlot['refreshId'] = parseInt(refreshId);
        // bookingSlot['roleId'] = roleId;
        // bookingSlot['refreshToken'] = refreshToken;
        // bookingSlot['userid'] = userid;

        return invoke(url, "GET", bookingSlot, cancel);
    },

    postsendnotifyemailtomemberrelative: async (agentRoleId, memberUserId, notifyId, notifyMsg, agentUserId) => {
        let cancel;
        let url = AoUrl.postsendNotifyemail
        let body = {}
        body['agentUserId'] = agentUserId;
        body['agentRoleId'] = agentRoleId
        body['memberUserId'] = memberUserId
        body['notifyId'] = notifyId
        body['notifyMsg'] = notifyMsg

        return invoke(url, "POST", body, cancel)
    },
    postsendnotifyemailtomemberrelativeList: async (agentRoleId, memberUserId, notifyId, notifyMsg, agentUserId) => {
        let cancel;
        let url = AoUrl.postsendNotifyemaillist
        let body = {}
        body['agentUserId'] = agentUserId;
        body['agentRoleId'] = agentRoleId
        body['memberUserId'] = memberUserId
        body['notifyId'] = notifyId
        body['notifyMsg'] = notifyMsg

        return invoke(url, "POST", body, cancel)
    },


    getprofessionalusergetmembergcmbyuserid: async (userId, ProTypeId, LPOStatus) => {
        let cancel;
        let url = AoUrl.getprofessionalusergetmembergcm + userId + '/' + ProTypeId + '/' + LPOStatus
        let body = {}

        return invoke(url, "GET", body, cancel)
    },
    getSubjectResponseData: async (userId, topicId ,subjectId ) => {
        let cancel;
        let url = AoUrl.getSubjectResponse+'/'+ userId + `/0/0/${subjectId}` ;
        let body = {topicId}
       return invoke(url, "GET", body, cancel)
    },
    getSubjectResponseDataGcm: async (userId, topicId) => {
        let cancel;
        let url = AoUrl.getSubjectResponse+'/'+ userId + `/0/0/${topicId}` ;
        // let body = {topicId}
       return invoke(url, "GET", cancel)
    },
    getClintInsurenceInfo: async (userId) => {
        let cancel;
        let url = AoUrl.getClintHealthInfo +'/'+ userId;
        // let body = {topicId}
       return invoke(url, "GET", cancel)
    },
    getBase64FileInfo: async (fileId) => {
        let cancel;
        let url = AoUrl.getBase64File +'/'+ fileId +"/1";
        // let body = {topicId}
       return invoke(url, "GET", cancel)
    },
    getProfessionalUsers: async (ProSerDescId,ProTypeId,PageNumber,RowsOfPage,SearchText) => {
        let cancel;
        let url = AoUrl.getProfessionaluser +'?ProSerDescId='+ ProSerDescId + "&ProTypeId=" + ProTypeId + "&PageNumber=" + PageNumber + "&RowsOfPage=" + RowsOfPage + "&SearchText=" + SearchText;
       return invoke(url, "GET", cancel)
    },
    getClintAddress: async (userId) => {
        let cancel;
        let url = AoUrl.getAddressByUserId+ userId;
       return invoke(url, "GET", cancel)
    },
    getSubjectResponseDataother: async (userId,subjectId ) => {
        let cancel;
        let url = AoUrl.getSubjectResponse+'/'+ userId + `/0/0/${subjectId}` ;
        let body = {}
        
     

        return invoke(url, "GET", body, cancel)
    },
    getContactbyuserId: async (gcmidforcontactno) => {
        let cancel;
        let url = AoUrl.getcontactbyuserid + gcmidforcontactno
        let body = {}

        return invoke(url, "GET", body, cancel)
    },
    postgetUserListByRoleId: async (data) => {
        let cancel;
        let url = AoUrl.getUserListByRoleId

        return invoke(url, "POST", data, cancel)
    },

    getactivitytype: async () => {
        let cancel;
        let url = AoUrl.getactivitytype;
        let body = {}

        return invoke(url, 'GET', body, cancel)

    },
    postsendNotifyemailToGCM: async (agentUserId, agentRoleId, memberUserId, notifyId, notifyMsg, gcmUserId, notifyTypeId) => {
        let cancel;
        let url = AoUrl.postsendNotifyemailGCM;
        let body = {}
        body['agentUserId'] = agentUserId;
        body['agentRoleId'] = agentRoleId;
        body['memberUserId'] = memberUserId
        body['notifyId'] = notifyId;
        body['notifyTypeId'] = notifyTypeId;
        body['notifyMsg'] = notifyMsg;
        body['gcmUserId'] = gcmUserId;

        return invoke(url, "POST", body, cancel)
    },
    postProfessionalUsers: async (data) => {
        let cancel;
        let url = AoUrl.postProfessionaluser;
        let body = data
       
         return invoke(url, "POST", body, cancel)
    },


    // getFiduciaryMemberList: async (userId) => {
    //     let cancel;
    //     let url = AoUrl.getFiduciariesName + userId
    //     let body = {};
    //     // body[''] = subtenantId;

    //     return invoke(url, "GET", body, cancel);
    // },
    getFileCabinet: async () => {
        let cancel;
        let url = AoUrl.getFileCabinetType;
        let body = {}
        return invoke(url, "GET", body, cancel)
    },
    postFileDocumentCabinet: async (postData) => {
        let cancel;
        let url = AoUrl.postFileDocumentCabinetById;
        let body = {}
        body['postData'] = postData

        body['userId'] = postData.userId;
        body['fileCabinetId'] = postData.fileCabinetId;
        // body['fileTypeId']=fileTypeId;
        return invoke(url, "POST", body, cancel)
    },
    postDownloadUserDocumentdata: async (getPost, loginUserId) => {
        let cancel;
        let url = AoUrl.postDownloadUserDocument + getPost.userId + '/' + getPost.fileId + '/' + getPost.fileTypeId + '/' + getPost.fileCategoryId + '/' + loginUserId
        let body = {}

        return invoke(url, "GET", body, cancel)
    },
    getFileCabinetRemarksp: async (postData) => {
        let cancel;
        let url = AoUrl.getFileCabinetRemarksPath;
        return invoke(url, "POST", postData, cancel)

    },
    postFileCabinetRemarksPath: async (method, postData) => {
        let cancel;
        let url = (method === "POST") ? AoUrl.postFileCabinetRemarksPath : AoUrl.putFileCabinetRemarksPath;

        return invoke(url, method, postData, cancel)
    },
    filecabinetfilestatusapi: async (filestatus) => {
        let cancel;
        let url = AoUrl.filecabinetfilestatus + "?IsActive=" + filestatus;
        let body = {};
        // body[''] = subtenantId;

        return invoke(url, "GET", body, cancel);
    },
    filecabinetdocumentapi: async (userId, FileCategoryId, PreparedByIndex, FileStatusId) => {
        let cancel;
        let url = AoUrl.filecabinetdocumenturl + "/" + userId + "?FileCategoryId=" + FileCategoryId + "&PreparedByIndex=" + PreparedByIndex + "&FileStatusId=" + FileStatusId;
        let body = {};
        // body[''] = subtenantId;

        return invoke(url, "GET", body, cancel);
    },
    filecabinetPreparebyapi: async (userId, fileCabinetId) => {
        let cancel;
        let url = AoUrl.filecabinetPreparebyUrl + userId + " / " + fileCabinetId;
        let body = {};
        // body[''] = subtenantId;

        return invoke(url, "GET", body, cancel);
    },
    postDownloadUserDocument: async (dataObj) => {
        let cancel;
        let url = AoUrl.postfileuploaddownloadurl + dataObj;
        let body = dataObj;

        return invoke(url, "GET", body, cancel);
    },

    getUserAgent: async (data) => {
        let cancel;
        let url = AoUrl.getUserAgentByAgentId;
        let body = data
        return invoke(url, "POST", body, cancel);
    },
    getUserListByRoleId: async (subtenantId, isActive, roleId) => {
        let cancel;
        let url = AoUrl.getUserListByRoleId;
        let body = {};
    
        body['subtenantId'] = subtenantId;
        body['isActive'] = isActive;
        body['roleId'] = roleId;
        return invoke(url, "POST", body, cancel)
    
    
      },
    upsertUserAgent: async (data) => {
        let cancel;
        let url = AoUrl.upsertUserAgent;
        let body = data
        return invoke(url, "POST", body, cancel);
    },
    getSubtenantDetail: async (subtenantId, isActive) => {
        let cancel;
        let url = AoUrl.getSubtenantDetailPath
        let body = {};
        body['subtenantId'] = subtenantId;
        // body['subtenantLogoUrl'] = subtenantLogoUrl;
        body['isActive'] = isActive;

        return invoke(url, "POST", body, cancel);
    },
    agentActivationUserByUserId: async (dataobj) => {
        let cancel;
        let url = AoUrl.agentActivationUserByUserId
        return invoke(url, "POST", dataobj, cancel)
    },
    GetUserDetailByEmailId: async (userId) => {
        let cancel;
        let url = AoUrl.getUserDetailsByEmailId
        let body = {};
        body["userId"] = userId
        return invoke(url, "GET", body, cancel)
    },
    getProfessionalService:async(userId)=>{
        let cancel;
        let url = AoUrl.getProfessionalurl+'?MemberUserId='+userId
        let body={}
        return invoke(url,"GET",body,cancel)
    },


    //-Occurance URL-----------------------------

    getCommMediumPath: async (bodyData) => {
        let cancel;
        let url = AoUrl.getPostCommMediumPath
        return invoke(url, "POST", bodyData, cancel)
    },
    getTextTemplate: async (TempalteId, isActive) => {
        let cancel;
        let url = AoUrl.getTextTemplate + `?TextTemplateId=${TempalteId}&IsActive=${isActive}`
        let body = {}
        return invoke(url, "GET", body, cancel)
    },
    postSendTextPath: async (data) => {
        let cancel;
        let url = AoUrl.postSendTextPath
        return invoke(url, "POST", data, cancel)
    },
    PostEmailCom: async (data) => {
        let cancel;
        let url = AoUrl.PostEmailCom
        return invoke(url, "POST", data, cancel)
    },
    PostEmail: async (data) => {
        let cancel;
        let url = AoUrl.postEmail
        return invoke(url, "POST", data, cancel)
    },
    PostEmailMultiFiles: async (data) => {
        let cancel;
        let url = AoUrl.postEmailMultiFile
        return invoke(url, "POST", data, cancel)
    },
    getEmailTemplate: async (TempalteId, isActive) => {
        let cancel;
        let url = AoUrl.GetEmailTemplate + `?TemplateId=${TempalteId}&IsActive=${isActive}`
        let body = {}
        return invoke(url, "GET", body, cancel)
    },
    getsubdetails: async (data) => {
        let cancel;
        let url = AoUrl.getsubdetails
        return invoke(url, "POST", data, cancel)
    },

    userValidateLinks: async (LinkStatusId, UniqueLinkKey, LinkTypeId) => {
        let cancel;
        let url = AoUrl.validateUserLinks + `?LinkStatusId=${LinkStatusId}&UniqueLinkKey=${UniqueLinkKey}&LinkTypeId=${LinkTypeId}`

        return invoke(url, "GET", cancel)
    }, getProfssionalbyuserId: async (userId) => {
        let cancel;
        let url = AoUrl.getProfessionalByUserIdPath + userId
        let body = {}
        return invoke(url, "GET", body, cancel)
    },
    getNotifyMessageapi: async (userId, notifyTypeId, contactNatureId, notifyConditionId) => {

        let cancel;
        let url = AoUrl.getNotifyMessage + userId + "/" + notifyTypeId + "/" + contactNatureId + "/" + notifyConditionId;

        let body = userId;

        return invoke(url, "GET",body, cancel);
    },
    getUseragentsapi: async (isActive,userId) => {
        let cancel;
        let url = AoUrl.getUseragents + "?IsActive="+ isActive + "&UserId=" + userId;
        let body = userId;
        return invoke(url, "GET",body, cancel);
    },
    getSharedFileStatus:async({primaryMemberUserId})=>{
        let cancel;
        let url=AoUrl.getSharedFileStatus+`?sharedUserId=${primaryMemberUserId}`
        let body={}
        return invoke(url,'GET',body,cancel)
    },
    getFileFromCabinet: async (primaryUserId) => {
        konsole.log('primaryuseriud',primaryUserId)
        let cancel;
        let url = AoUrl.getUserFileCabinetFile + primaryUserId
        let body = {}
        return invoke(url, 'GET', body, cancel)
    },
    postDownloadShareUserDocument:async(jsonObj)=>{
        let cancel;
        let url=AoUrl.postDownloadUserDocument
        return invoke(url, 'POST', jsonObj, cancel)
    },
    postFileUpload: async (file,userId,loggedInUser,fileTypeId,fileCategoryId,fileStatusId) => {
        let cancel;
        let url = AoUrl.fileUploadPath;
        let formdata = new FormData();
        formdata.append('file', file)
        formdata.append('UserId', userId)
        formdata.append('UploadedBy', loggedInUser)
        formdata.append('FileTypeId', fileTypeId)
        formdata.append('FileCategoryId', fileCategoryId);
        formdata.append('FileStatusId', fileStatusId)
        return invoke(url, 'POST', formdata, cancel);
    },
    postFeedback: async ( totalinuptdata ) => {
        let cancel;
        let url = AoUrl.postFeedback;
        return invoke(url, 'POST', totalinuptdata, cancel);
    },
    sendMailFeedBack: async ( formdata ) => {
        let cancel;
        let url = AoUrl.sendMailFeedBack;
        return invoke(url, 'POST', formdata, cancel);
    },
    sendMailFeedBackToUser: async ( formdata ) => {
        let cancel;
        let url = AoUrl.sendPostEmailCC;
        return invoke(url, 'POST', formdata, cancel);
    },
    getFeedbackPriority: async () => {
        let cancel;
        let url = AoUrl.getFeedbackPriority;
        return invoke(url, "GET", '', cancel)

    },

}


const loadAsyncScript = async (src) => {
    return new Promise((resolve) => {
        const script = document.createElement('script');
        Object.assign(script, {
            type: "text/javascript",
            async: true,
            src
        })
        script.addEventListener("load", () => resolve(script));
        document.head.appendChild(script);
    })
}


export default Services;



