import React, { useState, useContext } from 'react';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { globalData } from '../Execute/CommonModal';

const MentalHealthDiscuss = () => {
    const { handleClose, setmodalcontantshow, steptwomodaldata,memberRankIdforPrimary, handleShowDocumentModal } = useContext(globalData)

    // const btnCLickYes = () => {
    //     // if (memberRankIdforPrimary == 1) {
    //     //     setmodalcontantshow('eolnotifysuccessorstep4')
    //     // } else {
    //     //    setmodalcontantshow('eolwarning')
    //     // }
    //     setmodalcontantshow("MentalHealthSafe");
    // }
    
    return (
        <>

            <Modal.Header style={{ backgroundColor: "#871a27" }}>
                <Row >
                    <Col sm={11}>
                        <h3 className='heading'>
                            <span style={{ color: "#871a27" }}>S</span>
                        </h3>
                    </Col>
                    <Col sm={1} >
                        <button className='closeButton' style={{ border: "1px solid #981b29" }} onClick={handleClose}>x</button>
                    </Col>
                </Row>



            </Modal.Header>
            <Modal.Body>

                <h4 className='SelectOptionsh4Tag text-center'>
                    Discuss the situation you are concerned about with agents / family members and decide whether to contact a geriatric care manager or not. 
                </h4>

                <div className='buttonDiv'>
                    <button className='yesButton' onClick={handleShowDocumentModal} >Access Documents</button>
                    <button className='noButton' onClick={() => setmodalcontantshow('mentalHealthSuccessorContact')}>Contact Agents</button>
                </div>
                <div className='backbuttonDiv'>
                    <button className='backButton' onClick={() => setmodalcontantshow('step3selectoption')}>Back</button>
                </div>
            </Modal.Body>



        </>
    );
}

export default MentalHealthDiscuss