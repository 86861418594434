import React, { useEffect,useContext, useState,useRef } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import Remarks from "./Remarks";
import { isNotValidNullUndefinedfile } from "../control/Constant";
import konsole from "../Network/konsole";
import Services from "../Network/Services";

import "./Filecabinet.css";
import { globalData } from "../Execute/CommonModal";

const PdfViwer = ({ setDownLoadSend,documentViewObj, fileCabinetId, documentUploadedViewtype,handelChange,setHandelChange,print,setbaseFile,downLoadSend,base64File}) => {
  const primaryUserId = JSON.parse(sessionStorage.getItem("stateObj")).userId;
  const {setloader}=useContext(globalData)

  const [numPages, setNumPages] = useState(null);
  const [show, setshow] = useState(false);
  const [base64data, setBase64Data] = useState(false);
  const [showFilePreview, setShowFilePreview] = useState(false);
  const [downloadUrl, setDownloadUrl] = useState("");
  const pdfRef = useRef(null);
  
  useEffect(() => {
    if (isNotValidNullUndefinedfile(documentViewObj) && isNotValidNullUndefinedfile(documentViewObj.fileId)  && documentViewObj?.isLongTerm != true ) {
      if (documentUploadedViewtype == 'UserShare') {
        getBinaryFilesFromUploadUserShare()
      } else {
        getBinaryFilesFromUpload();
      }
      
    }
  }, [documentViewObj?.fileId, documentUploadedViewtype]);


    useEffect(() => {
      if(handelChange == true && showFilePreview == true && downLoadSend == true){
     
        setloader(true)
        setDownLoadSend(false)
        const printWindow = window.open('', '_blank');
        printWindow.document.write('<iframe src="' + downloadUrl + '" width="100%" height="100%"></iframe>');
        printWindow.document.close();
        setTimeout(() => {
          setloader(false)
          setHandelChange(false)
        }, 2000); // Adjust the delay as needed
      
      }
}, [downloadUrl])
  
useEffect(() => {
  setBase64Data(base64File)
  if(documentViewObj?.isLongTerm == true){
    setDownloadUrl(base64File)

  }
 
  setShowFilePreview(true)
}, [base64File])

   
  

 
  const getBinaryFilesFromUpload = () => {
    setloader(true)

    const { memberUserId, fileId, fileTypeId } = documentViewObj
    const string = `/${memberUserId}/${fileId}/${fileTypeId}/${fileCabinetId}/${primaryUserId}`
    let promises = Services.postDownloadUserDocument(string);
    promises
      .then((response) => {
        if (response) {
          setloader(false)
          let fileDocObj = response.data.data;
          let data ="data:application/pdf;base64," + fileDocObj.fileInfo.fileDataToByteArray;
          setBase64Data(data);
          setbaseFile(data)
          setDownloadUrl(data)
          setShowFilePreview(true);
          konsole.log("response ar pdfView", fileDocObj);
        }
      })
      .catch((err) => {
        setloader(false)
      });
  };
  const getBinaryFilesFromUploadUserShare = () => {
    const { primaryUserId, fileStatusId, fileCategoryId, sharedUserId, fileId, fileTypeId } = documentViewObj
    konsole.log('josnObjjosnObj', documentViewObj)
    let josnObj = { "userId": primaryUserId, "fileId": fileId, "fileTypeId": fileTypeId, "fileCategoryId": fileCategoryId, "fileStatusId": fileStatusId, "requestedBy": sharedUserId }
    setloader(true)
    Services.postDownloadShareUserDocument(josnObj).then((response) => {
      setloader(false)
      let fileDocObj = response.data.data;
      let data = "data:application/pdf;base64," + fileDocObj.fileInfo.fileDataToByteArray;
    
      setBase64Data(data);
     
      setbaseFile(data)
      setDownloadUrl(data)
      setBase64Data(data)
      setShowFilePreview(true)
      setShowFilePreview(true);
      konsole.log("response ar pdfView", fileDocObj);


    }).catch((err) => {
      setloader(false)
      konsole.log(' err in gettig file base64', err, err?.response)
    })

  }
  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

  const addWidth = (e) => {
    e.preventDefault();
    let menuBar = document.getElementById("fileCabinetList");
    let currentWidth = document.getElementById("fileCabinetPdfView");
    let ZoomOut = document.getElementById("zoomOut");
    let zoonIn = document.getElementById("zoom-In");
    currentWidth.classList.add("col-sm-12");
    currentWidth.classList.remove("col-sm-8");
    menuBar.classList.add("d-none");
    ZoomOut.classList.remove("d-none");
    zoonIn.classList.add("d-none");
  };
  const removeWidth = (e) => {
    e.preventDefault();
    let menuBar = document.getElementById("fileCabinetList");
    let currentWidth = document.getElementById("fileCabinetPdfView");
    let ZoomOut = document.getElementById("zoomOut");
    let zoonIn = document.getElementById("zoom-In");

    currentWidth.classList.remove("col-sm-12");
    currentWidth.classList.add("col-sm-9");
    menuBar.classList.remove("d-none");
    ZoomOut.classList.add("d-none");
    zoonIn.classList.remove("d-none");
  };

  const handleClose = () => {
    setshow(!show);
  };


  return (
     <>{downLoadSend == true ? <></>:
      <>
       <div className="container-fluid pdf" id="pdfId">
      
      <div id="logo" className="" style={{ maxHeight: "80vh", height: "80vh", overflowY: "auto" }}ref={pdfRef}>
         {
          showFilePreview === true ?
            <div className="mt-2">
              <Document
                className=""
                file={base64data}
                onLoadSuccess={onDocumentLoadSuccess}
                onContextMenu={(e) => e.preventDefault()}
              >
                {Array.from(new Array(numPages), (el, index) => (
                  <Page key={`page_${index + 1}`} pageNumber={index + 1} />
                ))}
              </Document>
            </div> :
            <p className="text-center">Please wait....</p>
        }
      </div>
       </div>
      </>

     }</>
   
  );
};

export default PdfViwer;
