import React, { useContext, useState, useEffect, useRef } from "react";
import Header from "./Header";
import "./Main.css";
import commonLib from "./control/commonLib";
import { useNavigate } from "react-router-dom";
import { globalData } from "./Execute/CommonModal";
import FileCabinetModal from "./FileCabinet/FileCabinetModal";
import Services from "./Network/Services";
import konsole from "./Network/konsole";
import { Modal, Button } from 'react-bootstrap';
import CustomeIdleTimer from './CustomeIdleTimer'
import OccuranceCom from './OccuranceCom'
import AccessDocuments from "./AccessDocument/AccessDocuments";


const timeout = 300_000 //in seconds
const promptBeforeIdle = 60_000 //in seconds

const Main = ({ selectedRoleJson }) => {

  let occurranceRef = useRef(null)

  const [show, setShow] = useState(false);
  const [openmodalagent, setopenmodalagent] = useState(false)
  const [userAgentData, setuserAgentData] = useState([])

  konsole.log("userAgentDatauserAgentData", userAgentData)
  const navigate = useNavigate();
  const { handleShow, setmodalcontantshow, setsteptwomodaldata,setloader,setStepName } = useContext(globalData);
  const userLoggedInDetail = commonLib.getObjFromStorage("userLoggedInDetail");
  const stateObj = commonLib.getObjFromStorage("stateObj")
  const loggedInUserId = userLoggedInDetail?.loggedUserId

  const [successalert, setsuccessalert] = useState(false)
  const [successalertmsg, setsuccessalertmsg] = useState("Hello")
  const [notificationlength, setnotificationlength] = useState(0)
  const [switchExecutiveRole, setSwitchExecutiveRole] = useState(false);
  const [showDocument, hideDocument] = useState(false);
  const [msgType, setmsgType] = useState('success')
  //-----------------------------------------------------------------------------------------------------------------------------------------------------------------
  const [subtenantId, setsubtenantId] = useState('')
  const [subtenantName, setsubtenantName] = useState('')

  const [waringAlert, setWaringAlert] = useState(false)
  const [waringAlerttMsg, setWaringAlertmsg] = useState("");

  useEffect(() => {

    let subtenantId = sessionStorage.getItem('subtenantId')
    setsubtenantId(subtenantId)
    getSubtenantDetails(subtenantId)
    getUserAgent();
    if (stateObj.roleId !== "5") getUserAgentByAsync();

  }, [])
  const getSubtenantDetails = (subtenantId) => {
    let jsonobj = {
      "subtenantId": subtenantId,
      "isActive": true,
    }
    setloader(true)
    let result = Services.getsubdetails(jsonobj)
    result.then((res) => {
      setloader(false)
      konsole.log('error', res)
      setsubtenantName(res.data?.data[0]?.subtenantName)
    }).catch((err) => {
      konsole.log('error', err)
      setloader(false)
    })
  }

  const getUserAgent = () => {
    let jsonobj = {
      "agentUserId": loggedInUserId,
      "agentRoleId": null,
      "agentActiveStatus": true,
    };
    konsole.log("jsonobj", JSON.stringify(jsonobj))
    setloader(true)
    let result = Services.getUserAgent(jsonobj)
    result.then((res) => {
      setloader(false)
      konsole.log("UserUser", res)
      let responseData = res.data.data
      let arryData = []
      let agentRoleAccept = [];
      for (let item of responseData) {
        if (item.statusId !== 2 && item.statusId !== 3) {
          arryData.push(item)
        }
      }
     

      if (stateObj.roleId !== 9) {
        const responseData = res.data.data;
        const agentDocumentIndex = responseData.findIndex((d) => ((d.agentRoleId == 1 || d.agentRoleId == 2) && d.statusId === 2));
        if (agentDocumentIndex >= 0) {
          setSwitchExecutiveRole(true);
          const agentData = responseData.find((d) => ((d.agentRoleId == 1 || d.agentRoleId == 2) && d.statusId === 2 && d.memberUserId === commonLib.getSessionStoarge("memberUserId"))) ?? {};
       
          if(Object.keys(agentData).length > 0){
            setsteptwomodaldata(agentData);
          }
          
        }
        else {
          setWaringAlertmsg("You are not assigned as an agent for either Healthcare or Finance role, therefore please click on Access document to proceed further.");
        }
      }
      konsole.log("arryData JOSN caheck", arryData, responseData)
      setnotificationlength(arryData.length)
      setuserAgentData(arryData)
      // setuserAgentData([])
    }).catch((err) => {
      konsole.log("User", err)
      setloader(false)
    })
  }


  const getUserAgentByAsync = () => {
    let jsonobj = {
      "agentUserId": loggedInUserId,
      "agentRoleId": null,
      "agentActiveStatus": true,
      "isDummyAgent": (stateObj.roleId !== "5") ? true : undefined,
    };
    konsole.log("jsonobj", JSON.stringify(jsonobj))
    setloader(true)
    let result = Services.getUserAgent(jsonobj)
    result.then((res) => {
      setloader(false)
      const responseData = res.data.data;
      const agentDocumentIndex = responseData.findIndex((d) => ((d.agentRoleId == 1 || d.agentRoleId == 2) && d.statusId === 2));
      konsole.log("agentDocumentIndex", agentDocumentIndex);
      if (agentDocumentIndex >= 0) {
        setSwitchExecutiveRole(true);
      }
    }).catch((err) => {
      konsole.log("User", err)
      setloader(false)
    })
  }


  //-----------------------------------------------------------------------------------------------------------------------------------------------------------------
  const handleClose = () => {
    setShow(false);
  };

  const handleShowModal = () => {
    setStepName("")
    setmodalcontantshow("accessDocuments");
    hideDocument(true);
  };

  konsole.log('switchExecutiveRole', switchExecutiveRole);
  const handleExecutiveRole = () => {
    if (switchExecutiveRole === false) return getWarningMess();
    setmodalcontantshow("step2selectone");
    handleShow();
  };

  //-----------------------------------------------------------------------------------------------------------------------------------------------------------------

  const acceptStatusBtn = (type, item) => {
    let msg = (type == "Accept") ? `You have sucessfully accepted the role of ${item?.agentRole}  (${item?.agentRank}) for ${item?.legalDocName}.` : `You have declined the role of ${item?.agentRole} (${item?.agentRank}) for ${item?.legalDocName}.`
    setsuccessalertmsg(msg)
    let typemsg = (type == 'Accept') ? 'success' : 'decline'
    setmsgType(typemsg)
    konsole.log("itemitemitem", item)
    let agentEffectDate = new Date()
    let jsonobj = [
      {
        "agentId": item.agentId,
        "agentUserId": item?.agentUserId,
        "memberUserId": item?.memberUserId,
        "agentRoleId": item?.agentRoleId,
        "agentRankId": item?.agentRankId,
        "agentAcceptanceStatus": (type == 'Accept') ? true : false,
        "agentActiveStatus": true,
        "agentEffectiveDate": agentEffectDate,
        "fileId": item?.fileId,
        "legalDocId": item?.legalDocId,
        "testDocId": item?.testDocId,
        "testSupportDocId": item?.testSupportDocId,
        "statusId": (type == 'Accept') ? 2 : 3,
        "upsertedBy": loggedInUserId
      }
    ]
    
    if (stateObj.roleId !== "5") {
      setsuccessalert(false)
      return getsuccessmsg();
    }
    setloader(true)
    let result = Services.upsertUserAgent(jsonobj)
    result.then((res) => {
      konsole.log("UserUsers", res)
      setloader(false)
      setsuccessalert(false)
      getUserAgent()
      getsuccessmsg()
      if (type !== "Accept") {
        acceptStatusBtnmail(item)
      }
    }).catch((err) => {
      konsole.log("User", err)
      setloader(false)
    })
  }

  const acceptStatusBtnmail = (item) => {
    getContactDetails(item)
  }
  const getContactDetails = (item) => {
    setloader(true)
    let result = Services.getContactbyuserId(item?.memberUserId)
    result.then((res) => {
      setloader(false)
      konsole.log("getContactbyuserIdres", res)
      konsole.log("result", res)
      let primarycontactdetails = res.data.data
      getUserListByRoleId(item, primarycontactdetails)
    }).catch((err) => {
      setloader(false)
      konsole.log("getContactbyuserIderr", err)
    }).finally(() => {
      setloader(false)
      konsole.log("getContactbyuserIdfinally")
    })
  }
  const getUserListByRoleId = (item, primarycontactdetails) => {
    let subtenantId = sessionStorage.getItem("subtenantId")
    let jsonobj = {
      "subtenantId": subtenantId,
      "isActive": true,
      "roleId": 3
    }
    konsole.log("jsonobj", jsonobj)
    setloader(true)
    let result = Services.postgetUserListByRoleId(jsonobj)
    result.then((res) => {
      konsole.log("postgetUserListByRoleId", res)
      let paralegalDetails = res.data.data
      setloader(false)
      let allData = {
        clientData: item,
        primaryclientData: primarycontactdetails,
        paralegalDetails: paralegalDetails,
        subtenantName: subtenantName,
      }
      konsole.log("allData", allData)
      occurranceRef.current.calloccuranceIdfun(21, subtenantId, allData);
    }).catch((err) => {
      konsole.log("postgetUserListByRoleId", err)
      setloader(false)
    }).finally(() => {
      setloader(false)
      konsole.log("postgetUserListByRoleIdfinally")
    })
  }


  //-----------------------------------------------------------------------------------------------------------------------------------------------------------------
  const getsuccessmsg = () => {
    setsuccessalert(true)
    setTimeout(() => {
      setsuccessalert(false)
    }, 3000)
  }

  const getWarningMess = () => {
    setWaringAlert(true)
    setTimeout(() => {
      setWaringAlert(false)
    }, 3000)
  }

  const openmodalagentfun = () => {
    setopenmodalagent(!openmodalagent)
  }

  if (userAgentData.length == 0) {
    setTimeout(() => {
      return setopenmodalagent(false)
    }, 2000)
  }

  //-----------------------------------------------------------------------------------------------------------------------------------------------------------------
  return (
    <>
      <OccuranceCom ref={occurranceRef} />
      {
        successalert ?
          <div className={`position-fixed top-0 start-0 end-0 bottom-0 d-flex justify-content-end`} style={{ zIndex: '999999', backgroundColor: "rgba(0,0,0,.5)" }}>
            <div className='position-sticky top-0 end-0 p-3 w-50 '>
              <div className="alert alert-dismissible fade show border" style={{ backgroundColor: "white" }} >
                <strong className={`${(msgType !== 'success') ? "notificationmsgtypedecline" : "notificationmsgtypesuccess"}`}>{successalertmsg}</strong>
                <button type="button" className="btn-close" onClick={() => setsuccessalert(!successalert)}></button>
              </div>
            </div>
          </div>
          : ""
      }

      {
        waringAlert ?
          <div className={`position-fixed top-0 start-0 end-0 bottom-0 d-flex justify-content-end`} style={{ zIndex: '999999', backgroundColor: "rgba(0,0,0,.5)" }}>
            <div className='position-sticky top-0 end-0 p-3 w-50 '>
              <div clasName="alert alert-dismissible fade show border" style={{ backgroundColor: "white" }} >
                <strong className="notificationmsgtypedecline">{waringAlerttMsg}</strong>
                <button type="button" className="btn-close" onClick={() => setWaringAlert(!waringAlert)}></button>
              </div>
            </div>
          </div>
          : ""
      }
      <Header openmodalagentfun={openmodalagentfun} notificationlength={notificationlength} />
      <CustomeIdleTimer timeogitgitut={timeout} promptBeforeIdle={promptBeforeIdle} />

      {
        showDocument === true && <AccessDocuments showDocument={showDocument} hideView={hideDocument} />
      }


      <div className="container-fluid">
        <div className="d-flex justify-content-end">

          <Modal show={openmodalagent}
            onHide={() => openmodalagentfun()}
            // className="modalDialog mt-4"
            className={successalert == true ? "modalDialog1 mt-4" : "modalDialog mt-4"}
            style={{zIndex: `${successalert == true ? "999999" : ""}`}}

          >
            <Modal.Body className={`${(userAgentData.length == 0) ? "" : "modalDialog_body"}`}>
              <div className="justify-content-center">
                {(userAgentData.length > 0) ?
                  userAgentData.map((item, index) => {
                    konsole.log("userAgentDataitem", item)

                    return <>
                      <div className="m-3 ">
                        <p style={{ fontWeight: "500" }}> {item?.memberFullName} has chosen you as
                          <span style={{ color: "#720C20" }}> {item?.agentRole}  {`${item?.agentRank ? `(${item?.agentRank})` : ""}`} </span> for the<span style={{ color: "#720C20" }}> {item?.legalDocName}
                          </span> would you like to accept this role ?
                        </p>
                        <div className="d-flex">
                          <button type="button" className="btn me-2" style={{ backgroundColor: "#720C20", color: "white" }} onClick={() => acceptStatusBtn('Accept', item)}>Accept</button>
                          <button type="button" className="btn " style={{ color: "#720C20", border: "2px solid #720C20" }} onClick={() => acceptStatusBtn('Decline', item)}>Decline</button>

                          <button className="border-0 mt-3 ms-3" style={{ backgroundColor: "white" }}>{commonLib.getFormattedDate(item.createdOn)} , {commonLib.getFormattedTime(item.createdOn)} </button>
                        </div>
                        {(index !== userAgentData.langth) ? <hr /> : null}
                      </div>


                    </>
                  }) :
                  <>
                    <div className="fw-bold mt-1" style={{ border: "none" }}>
                      No requests available
                    </div>
                  </>
                }
              </div>
            </Modal.Body>


          </Modal>


        </div>

        {/* <div className="row text-center position-relative" >

          <div className="col-md-12 col-xs-12 pt-5">
            <h2>Hi, {userLoggedInDetail.memberName}</h2>
          </div>
          <br></br>
          <br></br>
          <br></br>
          <br></br> <br></br>
          <div className="col-md-12 col-xs-12">
            <h2>Welcome to Agent Portal </h2>

          </div>
          <div className="col-md-12 col-xs-12">
            <h2>Please Pick a reason for today's visit</h2>
          </div>
        </div> */}
        <div className='row no-gutters m-0 p-0 justify-content-center align-items-center flex-column'>
          <div className='col-5 mt-5 text-center' style={{ padding: "10px" }} >
            <h4 style={{ fontFamily: "Inter", color: "#7B7D7D" }}>Hi, <b>{userLoggedInDetail.loginUserName} </b> </h4>
            <h4 style={{ fontFamily: "Inter", color: "#7B7D7D" }} className="mt-3">Welcome to Agent Portal  </h4>
            <h4 style={{ fontFamily: "Inter", color: "#7B7D7D" }}>Please Pick a reason for today's visit </h4>
          </div>
        </div>
      </div>

      <br></br>

      <div className="container justify-content-around ">
        <div className="row">
          <div
            className="col-md-4 col-xs-12 pt-5"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              className="card shadow-lg main-card "
              style={{
                width: "230px",
                borderRadius: "50px",
                borderTopRightRadius: "50px",
                backgroundColor: "#CD9A27",
              }}
            >
              <div
                className="card-body"
                style={{ height: "12rem", left: "33px" }}
                onClick={() => navigate("/myroles")}
              >
                <img
                  src="Rectangle (5).png"
                  className="img-fluid cardimg"
                  style={{
                    position: "absolute",
                    left: "40px",
                    bottom: "132px",
                    width: "140px",
                  }}
                ></img>

                <h5
                  className="card-title text-center"
                  style={{
                    marginTop: "130px",
                    marginLeft: "",
                    color: "white",
                    fontSize: "23px",
                  }}
                >
                  LEARN MY ROLE
                </h5>
              </div>
              <div
                className="text-center pt-2"
                onClick={() => navigate("/myroles")}
                style={{
                  backgroundColor: "white",
                  height: "50px",
                  borderBottomLeftRadius: "50px",
                  borderBottomRightRadius: "50px",
                  fontSize: "20px",
                  fontWeight: "bold",
                  color: "#62011D",
                }}
              >
                LEARN
              </div>
            </div>
          </div>
          <div
            className="col-md-4 col-xs-12 pt-5"
            onClick={handleExecutiveRole}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              className="card shadow-lg "
              style={{
                width: "230px",
                borderRadius: "50px",
                borderTopRightRadius: "50px",
                backgroundColor: "#62011D",
              }}
            >
              <div
                className="card-body"
                style={{ height: "12rem", left: "33px", width: "" }}
              >
                <img
                  src="Rectangle (3).png"
                  className="img-fluid cardimg"
                  style={{
                    position: "absolute",
                    left: "40px",
                    bottom: "132px",
                    width: "140px",
                  }}
                ></img>

                <h5
                  className="card-title text-center"
                  style={{
                    marginTop: "130px",
                    marginLeft: "",
                    color: "white",
                    fontSize: "23px",
                  }}
                >
                  EXECUTE ROLE
                </h5>
              </div>
              <div
                className="text-center pt-2"
                style={{
                  backgroundColor: "white",
                  height: "50px",
                  borderBottomLeftRadius: "50px",
                  borderBottomRightRadius: "50px",
                  fontSize: "20px",
                  fontWeight: "bold",
                  color: "#62011D",
                }}
              >
                EXECUTE
              </div>
            </div>
          </div>

          <div
            className="col-md-4 col-xs-12 pt-5"
            onClick={handleShowModal}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              className="card shadow-lg "
              style={{
                maxWidth: "230px",
                borderRadius: "50px",
                borderTopRightRadius: "50px",
                backgroundColor: "#CD9A27",
              }}
            >
              <div
                className="card-body"
                style={{ height: "12rem", left: "33px", width: "" }}
              >
                <img
                  src="Rectangle (4).png"
                  className="img-fluid cardimg"
                  style={{
                    position: "absolute",
                    left: "40px",
                    bottom: "132px",
                    width: "140px",
                  }}
                ></img>

                <h5
                  className="card-title text-center"
                  style={{
                    marginTop: "120px",
                    marginLeft: "",
                    color: "white",
                    fontSize: "23px",
                  }}
                >
                  ACCESS DOCUMENTS
                </h5>
              </div>
              <div
                className="text-center pt-2"
                style={{
                  backgroundColor: "white",
                  height: "50px",
                  borderBottomLeftRadius: "50px",
                  borderBottomRightRadius: "50px",
                  fontSize: "20px",
                  fontWeight: "bold",
                  color: "#62011D",
                }}
              >
                DOCUMENTS
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <FileCabinetModal show={show} handleClose={handleClose} /> */}
    </>
  );
};

export default Main;
