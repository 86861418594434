import React, { useState, useContext } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Modal from "react-bootstrap/Modal";
import { globalData } from "../Execute/CommonModal";
import '../Execute/Modal.css'
import {returnFname} from '../control/Constant'

import "../Execute/Illness/Warning.css";
const Filetaxes = () => {
    const { handleClose, setmodalcontantshow, steptwomodaldata, isGCM, fullNameofGcm } = useContext(globalData)


    return (
        <>
            <Modal.Header style={{ backgroundColor: "#871a27" }}>
                <Row>
                    <Col sm={11}>
                        <h3 className="heading">Goal and Steps</h3>
                    </Col>
                    <Col sm={1}>
                        <button
                            className="closeButton"
                            style={{ border: "1px solid #981b29" }}
                            onClick={handleClose}
                        >
                            x
                        </button>
                    </Col>
                </Row>
            </Modal.Header>
            <Modal.Body>

                {
                    (isGCM == "true") ?
                        <>
                            <h4 className="SelectOptionsh4Tag mb-4 mt-2">
                                {returnFname(steptwomodaldata.memberFullName)} did not want to burden you to be sorting it with the bill payments. Please contact accountant and they will be able to help you with it.<br />
                                Please contact {returnFname(steptwomodaldata.memberFullName)}-Accountant
                            </h4>
                            <div className="d-flex justify-content-center">
                                <button className="healthButton" onClick={() => setmodalcontantshow("filetaxesContactpage")} >
                                    Contact {fullNameofGcm}
                                </button>

                            </div>
                        </>
                        :(isGCM == "false")?
                        <>
                                <h4 className="SelectOptionsh4Tag mb-4 mt-2">
                                    {returnFname(steptwomodaldata.memberFullName)} did not choose any accountant for filing the taxes
                                    Please choose an option below to proceed further.
                                </h4>

                            <div className="d-flex justify-content-around">
                                <button className="healthButton" onClick={() => setmodalcontantshow('filetaxesGoals')} >
                                    Choose a professional
                                </button>
                                <button className="healthButton" onClick={() => setmodalcontantshow('fileTaxesByYourself')} >
                                    Pay by yourself
                                </button>

                            </div>
                        </>
                        :<></>
                }


                <div className='buttonsDiv'>
                    <button className='backButton' onClick={() => setmodalcontantshow('financeOptions')}>Back</button>
                </div>
            </Modal.Body>
        </>
    )
}
export default Filetaxes;