import React, { useState, useContext } from 'react';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { globalData } from '../CommonModal';
import '../Modal.css'

const DeathCrematedYesNo = (props) => {
    const { handleClose, setmodalcontantshow, steptwomodaldata } = useContext(globalData)
    const clickno=()=>{

    }
return (
        <>

            <Modal.Header style={{ backgroundColor: "#871a27" }}>
                <Row >
                    <Col sm={11}>
                        <h3 className='heading'>
                            <span style={{ color: "#871a27" }}>S</span>
                        </h3>
                    </Col>
                    <Col sm={1} >
                        <button className='closeButton' style={{ border: "1px solid #981b29" }} onClick={handleClose}>x</button>
                    </Col>
                </Row>



            </Modal.Header>
            <Modal.Body>


{
    (props.deathcrematedburied=='cremated') ?
<>
<h4 className='SelectOptionsh4Tag text-center'>
                Have you selected a cremation facility?
                </h4>
                

                <div className='buttonDiv'>
                    <button className='yesButton' onClick={()=>setmodalcontantshow('deathcrematedviewguidance')} >Yes</button>
                    <button className='noButton' onClick={()=>setmodalcontantshow('step3selectoption')}>No</button>
                </div>
                <div className='backbuttonDiv'>
                    <button className='backButton'  onClick={()=>setmodalcontantshow('deathcrematedburied')}>Back</button>
                </div>

</>
: (props.deathcrematedburied=='buried') ?
<>
<h4 className='SelectOptionsh4Tag text-center'>
Have you chosen a funeral home?
                </h4>
                

                <div className='buttonDiv'>
                    <button className='yesButton' onClick={()=>setmodalcontantshow('deathcrematedviewguidance')} >Yes</button>
                    {/* <button className='noButton' onClick={()=>setmodalcontantshow('deathburiedno')} >No</button> */}
                    <button className='noButton'onClick={()=>setmodalcontantshow('step3selectoption')}>No</button>
                </div>
                <div className='backbuttonDiv'>
                    <button className='backButton'  onClick={()=>setmodalcontantshow('deathcrematedburied')}>Back</button>
                </div>

</>
:""
}
               
            </Modal.Body>



        </>
    );
}

export default DeathCrematedYesNo