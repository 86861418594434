import React, { useState, useContext,useEffect} from 'react';
import Modal from 'react-bootstrap/Modal';
import { globalData } from '../CommonModal';
import {returnFname} from '../../control/Constant'

import '../Modal.css';

const Informationnot = () => {
    const { handleClose, setmodalcontantshow, steptwomodaldata ,isGCM,fullNameofGcm} = useContext(globalData)
  

    return (
        <>

            <Modal.Header style={{ backgroundColor: "#871a27" }}>

                <Modal.Title className='heading' id="example-modal-sizes-title-lg" style={{ color: 'white', fontFamily: "Inter"}}>
                    <span style={{ color: "#871a27" }}>S</span>
                </Modal.Title>
                {/* closeButton */} 

                <button className='closeButton' style={{ border: "1px solid #981b29" }} onClick={handleClose}>x</button>

            </Modal.Header>
            <Modal.Body>
                <h4 className='SelectOptionsh4Tag mb-2 mt-3'>
               

                </h4>
                <h3 className='SelectOptionsh4Tag mb-2 mt-1'>
                    {
                        (isGCM=='true')?
                        <>
                        {returnFname(steptwomodaldata.memberFullName)} would like to receive all possible treatments to prolong life.<br /></>:
                        <> {returnFname(steptwomodaldata.memberFullName)}  not provided any Information <br /></>
                        
                    }
                    
                    At this point, you need to connect with all family members and successor agents to 
                    get their consent to fulfill {returnFname(steptwomodaldata.memberFullName)}'s wishes.
                </h3>

                <div className='buttonsDiv'>
                    <button className='backButton' onClick={()=>setmodalcontantshow('eolyesneedno')} >Back</button>
                    <button className='continueButton' onClick={()=>setmodalcontantshow('eolneedyesunanimous')}>Continue</button>
                </div>
            </Modal.Body>

        </>
    );
}

export default Informationnot