import React, { useState, useContext } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Modal from "react-bootstrap/Modal";
import { globalData } from "../Execute/CommonModal";
import '../Execute/Modal.css'
import {returnFname} from '../control/Constant'

import "../Execute/Illness/Warning.css";
const PaybiilsByYourself = () => {
    const { handleClose, setmodalcontantshow, steptwomodaldata } = useContext(globalData)


    return (
        <>
            <Modal.Header style={{ backgroundColor: "#871a27" }}>
                <Row>
                    <Col sm={11}>
                        <h3 className="heading">Goal and Steps</h3>
                    </Col>
                    <Col sm={1}>
                        <button
                            className="closeButton"
                            style={{ border: "1px solid #981b29" }}
                            onClick={handleClose}
                        >
                            x
                        </button>
                    </Col>
                </Row>
            </Modal.Header>
            <Modal.Body>

                <h4 className="SelectOptionsh4Tag mb-4 mt-2">
                As you are paying the bills on behalf of {returnFname(steptwomodaldata.memberFullName)} please pay the bills and charge the amount similarly charged by the professional/service provider.
                    
                </h4>
                   <div className="d-flex justify-content-around">
                    <button className="healthButton" >
                        Pay by yourself
                    </button>

                </div>


                <div className='buttonsDiv'>
                    <button className='backButton' onClick={() => setmodalcontantshow('paybills')}>Back</button>
                </div>
            </Modal.Body>
        </>
    )
}
export default PaybiilsByYourself;