import React, { useState, useContext, useEffect } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Modal from "react-bootstrap/Modal";
import { globalData } from "../CommonModal";
import commonLib from "../../control/commonLib";
import ShowPreofessionlDetails from "../../ShowPreofessionlDetails";
import '../Modal.css';
import { aorgLink } from "../../Network/url";
import {aorgLinkFunc, returnFname, returnProfessionals, returnPostProfessional } from "../../control/Constant";

import "./Warning.css";
const IllnessHomeGoalStep7 = () => {
  const { handleClose,gcmDetails,setmodalcontantshow,steptwomodaldata,setFlowWithoutProff,isGCM, fullNameofGcm, setstep7illnesshomenext, addManageStepToFollow, stepsToFollow, modalcontantshow, goNext, setillushospitalstep6help, setGoNext, setIsGCM, getGCMprofessionalbyUserid, setloader } = useContext(globalData)
  const [gcmData, setGcmData] = useState()
  const [showGcmModal, setShowGcmModal] = useState(false)
  const clicknextcontactbutton = () => {
    setstep7illnesshomenext('IllnessHome')
    addManageStepToFollow("setstep7illnesshomenext", "IllnessHome");
    setmodalcontantshow('eolgcmstep8')
  }


  
  useEffect(() => {
    if(isGCM == 'false'){
    ProfessionalUsers(1, 7, 1, 30)// gcm
    }

  }, [])
  const ProfessionalUsers = async (ProSerDescId, ProTypeId, PageNumber, RowsOfPage) => {
    let userId = steptwomodaldata?.memberUserId
    setloader(true)
    const result = await returnProfessionals(ProSerDescId, ProTypeId, PageNumber, RowsOfPage, setloader,userId);
    setloader(false)
    const shuffledUsers = result.length > 0 && result.length > 0 && result.sort(() => Math.random() - 0.5);
    const usersToShow = shuffledUsers.slice(0, 6);
    setGcmData(usersToShow)
    if (result == 'err') return;
  };

  const Confirmations = async(text,setshowConfrimation)=>{
    setFlowWithoutProff(text)
    setmodalcontantshow('eolgcmstep8')
    setstep7illnesshomenext('IllnessHome')
    // let data = userData
    //   const userLoggedInDetail = commonLib.getObjFromStorage("userLoggedInDetail");
    //   const result = await returnPostProfessional(0, data?.proUserId,data?.proCatId,steptwomodaldata?.memberUserId,true,true,userLoggedInDetail?.loggedUserId,setloader)
    //   getGCMprofessionalbyUserid(7,true)
    //   setshowConfrimation(false)
    //   setShowGcmModal(false)
    //   if (result == 'err')return;
  
   
  }




  const sendBack = () => {
    if (goNext == "Hospital") {
      setGoNext(goNext)
      setmodalcontantshow('illnesshospitalstep6')
    } else if (goNext == "Home" || goNext == "Other") {
      setGoNext(goNext)
      setIsGCM("false")
      setmodalcontantshow('illnessemailtextpreview')
      setillushospitalstep6help('Illness')
    } else {
      setmodalcontantshow('illnesshomemedicalhelp')
    }

  }


  return (
    <>
      <Modal.Header style={{ backgroundColor: "#871a27" }}>
        <Row>
          <Col sm={11}>
            <h3 className="heading">Step 7 : Goal and Steps </h3>
          </Col>
          <Col sm={1}>
            <button
              className="closeButton"
              style={{ border: "1px solid #981b29" }}
              onClick={handleClose}
            >
              x
            </button>
          </Col>
        </Row>
      </Modal.Header>
      <Modal.Body>


        {
          (isGCM == 'true') ?
            <>
              {goNext == "Other" ? <>
                <h3 className="SelectOptionsh4Tag mb-4 mt-2">{returnFname(steptwomodaldata.memberFullName)} selected you to work with {fullNameofGcm} in the role of a Geriatric Care Manager,
                  who will assist you in providing {returnFname(steptwomodaldata.memberFullName)} the required care and help.<br />
                  Your job is to work to ensure that no one is taking advantage of {returnFname(steptwomodaldata.memberFullName)}
                  or leaving {returnFname(steptwomodaldata.memberFullName)} neglected.<br />
                  Please contact {fullNameofGcm}-Geriatric Care Manager (GCM).</h3>
              </> : <>
                <h3 className="SelectOptionsh4Tag mb-4 mt-2">{returnFname(steptwomodaldata.memberFullName)} selected you to work with {fullNameofGcm} in the role of a Geriatric Care Manager, who will work with the hospital to organize {returnFname(steptwomodaldata.memberFullName)}'s care at home if at all possible.<br />
                  {/* If coming home is not possible, then {fullNameofGcm} will help you select the facility best able to help {returnFname(steptwomodaldata.memberFullName)}, so {returnFname(steptwomodaldata.memberFullName)} does not have to endure repeated moves.<br /> */}
                  Your job is to work to ensure that no one is taking advantage of {returnFname(steptwomodaldata.memberFullName)} or leaving {returnFname(steptwomodaldata.memberFullName)} neglected.<br />
                  Please contact {fullNameofGcm}-Geriatric Care Manager (GCM).</h3>
              </>}





              <div className="d-flex justify-content-center mb-3">
                <button className="healthButton" onClick={clicknextcontactbutton}> Contact {fullNameofGcm}</button>
                {/* <button className="healthButton" onClick={()=>setShowGcmModal(true)}>GCM Details</button> */}
                </div>
             

            </>

            : (isGCM == 'false') ?
              <>
                <h4 className="SelectOptionsh4Tag mb-4 mt-2">{returnFname(steptwomodaldata.memberFullName)} would like you to advocate for {steptwomodaldata?.memberGenderId == 1 ? "him":"her"} to be able to access any rehabilitation {goNext == "Other" ? "in other setting" : `of care in ${steptwomodaldata?.memberGenderId == 1 ? "his":"her"} own home`}. To help you accomplish this objective {returnFname(steptwomodaldata.memberFullName)} would like you to work with a Geriatric Care Manager, professionals who can help you with this task. <br/><br/>{returnFname(steptwomodaldata.memberFullName)} left it to you to select the Geriatric Care Manager you would like to work with. Here are three names (you can look for more) of Geriatric Care Managers who may be able to assist you.<br/><br/> You can call them or email them with your request for assistance. Under the Power of Attorney you must seek their assistance at least once.
                 {/* does not have a geriatric care manager. Please click below to search for geriatric care manager. */}
                 </h4>
              
                 <div className="d-flex justify-content-center">
                  <button className="healthButton" onClick={()=>setShowGcmModal(true)}> Find GCM</button>

                 </div>
              </>
              : ''
        }

        <div className='stepsevenbuttonsDiv'>
          <button className="backButton" onClick={() => sendBack()}>
            Back
          </button>
          {
            (isGCM == 'true') ? <>
              <button className='backButton' style={{ backgroundColor: "#d79b48" }} onClick={clicknextcontactbutton}>
                Next</button>
            </>

              : ''
          }

        </div>
        {showGcmModal == true && 
       <ShowPreofessionlDetails showGcmModal={showGcmModal} setShowGcmModal={setShowGcmModal} professData={gcmData} Confirmations={Confirmations} professType="Geriatric Care Manager"isProfess={isGCM}singleUserData={gcmDetails}/>}
      
      </Modal.Body>
    </>
  )
}
export default IllnessHomeGoalStep7;