import React, { useState, useEffect } from 'react';
const HeaderInvitation = ({logourl}) => {

    return (
        <div className='container-fluid   border-0 border-bottom'>
 
        <div className='row'>
            <div className='col-sm-4 col-md-9 col-lg-9   ' >
                {/* <img src='/AO-Logo.png' ></img> */}
                <img src={logourl} style={{width:"140px"}}  />
            </div>
           
        </div>
    </div>
    )
}

export default HeaderInvitation