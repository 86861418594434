import React, { useContext, useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import konsole from '../../Network/konsole';

import { globalData } from "../CommonModal";
import '../Modal.css'
import { isNotValidNullUndefinedfile } from '../../control/Constant';

const Gcmcaremanagar = (props) => {
    
    const {isGCM, handleClose, setmodalcontantshow, steptwomodaldata,setEOlStep8Toallcall,GCmcontactNo,getGCMContactCallNo,fullNameofGcm } = useContext(globalData)


    konsole.log("gcmMobileNo", GCmcontactNo);
    const clickok=()=>{
        setEOlStep8Toallcall('ok')
        setmodalcontantshow('eolneedhelpstep9')
    }

    useEffect(()=>{
        getGCMContactCallNo()
    },[]);

    const formatPhoneNumber = (str) => {
        let phoneNum = str.slice(-10);
        let countryCode = str.slice(0,-10)
        let cleaned = ("" + phoneNum).replace(/\D/g, "");

        let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

        if (match) {
          return countryCode + " " +  "(" + match[1] + ") " + match[2] + "-" + match[3];
        }

        return null;
      };

    return (
        <>
            
                <Modal.Header style={{ backgroundColor: "#871a27" }}>

                    <Modal.Title className='heading' id="example-modal-sizes-title-lg" style={{ color: 'white', fontFamily: "Inter"}}>
                    <span style={{color:"#871a27"}}>S</span>
                    </Modal.Title>
                    {/* closeButton */}

                    <button className=' closeButton' style={{ border: "1px solid #981b29" }} onClick={handleClose}>x</button>

                </Modal.Header>
                <Modal.Body>

                    <div>
                     <h4 className='SelectOptionsh4Tag'>
                        GCM {(isGCM=='true')?<> {fullNameofGcm} </>:"Care Managar "}
                   
                    </h4>
                    <h4 className='SelectOptionsh4Tag pt-4'>
                        {(isNotValidNullUndefinedfile(GCmcontactNo) && GCmcontactNo !== "No Cell Number") ? formatPhoneNumber(GCmcontactNo) : 'Contact no. not available'}
                    </h4>
                    <div className='buttonDiv pt-3'>
                        <button className='yesButton' onClick={clickok}>OK</button>

                    </div>
                    <div className='stepsevenbuttonsDiv'>
                        <button className='backButton' onClick={()=>setmodalcontantshow('eolgcmstep8')}>Back</button>
                        
                    </div>
                    </div>
                   
                    
                </Modal.Body>
                
            {/* </Modal> */}
        </>
    );
}

export default Gcmcaremanagar