import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom';
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import konsole from '../Network/konsole';
import Services from '../Network/Services';
import HeaderInvitation from './HeaderInvitation';
import Confirmation from './Confirmation';
import config from '../config.json';
import { Button } from 'react-bootstrap';
import { AoLoginBaseUrl } from '../Network/url';
import { isNotValidNullUndefinedfile } from '../control/Constant';


const messageForRoles = 'Your account is already associated with us accepted role/roles is being added, please click Sign In below to access you account.'
const messageForRoles2 = 'Your account is already associated with us accepted role/roles is being added, please Login In to access your account.'

const AgentAllList = () => {
    const { state } = useLocation();
    const logoUrl = state?.response[0]?.fileURL
    const responseData = state?.response[0]
    const subtenant = state?.response[0]


    // State----------------------------------------------------------------------------
    const [isLoading, setisLoading] = useState(false)
    const [subtenantId, setSubtenantId] = useState('')
    const [userAgentData, setuserAgentData] = useState([])
    const [checkAllCheckSelect, setcheckAllCheckSelect] = useState(false)
    const [renderpage, setranderpage] = useState(false)
    const [showmessage, setShowmessage] = useState('Thank you for accepting the role so that you have access to the documents and the information you will need to fulfill your responsibilities as the agent you need to set up your account. Please Activate your account using the Activate Button.')
    const [displaynone, setDisplaynone] = useState(false)
    const [memberName, setMemberName] = useState('')
    const [buttonText, setbuttontext] = useState('Activate Account')

    // UseEffect & function-------------------------------------------------------------

    useEffect(() => {
        if (isNotValidNullUndefinedfile(subtenant?.subtenantId)) {
            setSubtenantId(subtenant?.subtenantId)
            getAgentDetails(subtenant?.subtenantId)
        }
    }, [subtenant])

    const getAgentDetails = () => {
        setisLoading(true)
        let jsonobj = { "agentUserId": responseData?.userId, "agentActiveStatus": true }
        let result = Services.getUserAgent(jsonobj)
        result.then((res) => {
            setisLoading(false)
            let responseResultData = res.data?.data.filter(item => item.statusId !== 2)
            let responseData = responseResultData
            konsole.log("getUserAgent", responseData)
            if(responseData.length === 0){
                GetUserDetailByEmailId(jsonobj.agentUserId);
            }
            for (let [index, item] of responseData.entries()) {
                responseData[index]['isSelected'] = true
            }
            setuserAgentData(responseData)
            let checkAllCheckSelect = responseData?.every(obj => obj.isSelected === true);
            setcheckAllCheckSelect(checkAllCheckSelect)
            const filteredArrays = {};
            const lookup = {};

            for (const obj of responseData) {
                if (lookup[obj.memberUserId]) {
                    filteredArrays[obj.memberUserId].push(obj);
                } else {
                    lookup[obj.memberUserId] = true;
                    filteredArrays[obj.memberUserId] = [obj];
                }
            }
            let value = Object.values(filteredArrays)
            setMemberName(value)

        }).catch((err) => {
            setisLoading(false)
            konsole.log("getUserAgent", err)
        })
    }



    const GetUserDetailByEmailId = (userId) => {
        let response = Services.GetUserDetailByEmailId(userId);
        konsole.log("userInfo asdadasdas", userId);

        response.then((res) => {
            konsole.log("userInfo", res);
            const responseJson = res.data;
            if(responseJson.length === 0 ) return;
            if(responseJson[0].isActive === false ) return;
            


            const responseRolesJson = responseJson[0].roleDetails.findIndex(d => d.roleId === 5)
            if(responseRolesJson >= 0){
                setShowmessage(messageForRoles);
                setbuttontext("Sign In")
            }

        }).catch((err)=>{
            konsole.log("userInfo error", err);
        }).finally(()=>setisLoading(false))
    }


    const isSelectAdd = (value, type, index) => {
        let data = userAgentData
        if (type === 'ALLSELECT') {
            for (let [index, item] of data.entries()) {
                data[index].isSelected = value
            }
        } else if (type === 'SEPRATE') { data[index].isSelected = value }

        setuserAgentData(data)
        let checkAllCheckSelect = data?.every(obj => obj.isSelected === true);
        setcheckAllCheckSelect(checkAllCheckSelect)
        setranderpage(!renderpage)
    }


    //------------------------------------------------------------------------------------------------------------------------------

    const createjsonforupsert = (item, type) => {
        let { agentId, agentUserId, memberUserId, agentRoleId, agentRankId, fileId, legalDocId, testDocId, testSupportDocId, isSelected } = item;
        let agentEffectDate = new Date();
        let agentAcceptanceStatus = (type == 'ACCEPTALL' || type == 'ACCEPTSOME') ? isSelected : isSelected;
        let statusId = (type == 'ACCEPTALL' || type == 'ACCEPTSOME') ? (isSelected === true)? 2: 3: 3;
        return { "agentId": agentId, "agentUserId": agentUserId, "memberUserId": memberUserId, "agentRoleId": agentRoleId, "agentRankId": agentRankId, "agentAcceptanceStatus": agentAcceptanceStatus, "agentActiveStatus": true, "agentEffectiveDate": agentEffectDate, "fileId": fileId, "legalDocId": legalDocId, "testDocId": testDocId, "testSupportDocId": testSupportDocId, "statusId": statusId, "upsertedBy": responseData?.userId }
    }

    let arrayselectedBtn = { 'ACCEPTALL': userAgentData, 'DECLINEALL': userAgentData, 'ACCEPTSOME': userAgentData 
        // .filter(item => item.isSelected === true)
    }

    const AccptDeclineSomefun = (btntype) => {
        setisLoading(true)
        let info_btn_selection = arrayselectedBtn[btntype]
        let agentSaveObj = [];
        for (let [index, item] of info_btn_selection.entries()) {
            agentSaveObj.push(createjsonforupsert(item, btntype))
        }
        konsole.log("agentSaveObjagentSaveObj", agentSaveObj)
        let result = Services.upsertUserAgent(agentSaveObj)
        result.then((res) => {
            konsole.log("upsertUserAgentupsertUserAgent", res)
            setisLoading(false)
            getAgentDetails(state?.response[0]?.subtenant)
            setDisplaynone(true)
            setisLoading(true)

            const isEveryAgentRoleDecline = info_btn_selection.every((d) => d.isSelected === false);

            if (btntype === 'DECLINEALL' || ( btntype == "ACCEPTSOME" && isEveryAgentRoleDecline === true)) {
                setShowmessage(`Thank you for letting us know you will not be able to perform the role. we will notify ${userAgentData[0]?.memberFullName} Thank you for your consideration.`)
            } else  if( btntype == "ACCEPTSOME" && userAgentData[0].isAgentActiveUser === true ){
                setShowmessage('Your account is already associated with us accepted role/roles is being added, please click Sign In below to access your account.');
            } else {
                setShowmessage('You will now receive one time password(OTP) on your cellphone. Please enter the OTP(one time password) on the following screen.')
            }
            
            if ((btntype === 'ACCEPTALL' || btntype === 'ACCEPTSOME') && isEveryAgentRoleDecline !== true && userAgentData[0].isAgentActiveUser === false) {
                setTimeout(() => {
                    setisLoading(true)
                    agentActivationUserByfun()
                }, 3000)
            }
            else if ((btntype === 'ACCEPTALL' || btntype === 'ACCEPTSOME') && isEveryAgentRoleDecline !== true && userAgentData[0].isAgentActiveUser === true) {
                agentActivationUserByfun()
            }
        }).catch((err) => {
            konsole.log("upsertUserAgentupsertUserAgent", err)
            setisLoading(false)
        })
    }
    const agentActivationUserByfun = () => {
        setisLoading(true)
        let jsonobj = { "subtenantId": subtenantId, "signUpPlateform": 10, "userId": responseData?.userId, "roleId": 5, "activateUser": false, "createdBy": responseData?.userId }
        let results = Services.agentActivationUserByUserId(jsonobj)
        results.then((res) => {
            konsole.log("agentActivationUserByUserId", res)
            let responseData = res.data?.data
            setisLoading(true)
            window.location.replace(responseData.activationLink);
        }).catch((err) => {
            err = err.response;
            if(err.data.statusCode === 400 && err.data?.errorFlag === "USER_ALREADY_ACTIVATED"){
                konsole.log("agentActivationUserByUserId", err)
                setTimeout(()=>{
                    window.location.replace(`${AoLoginBaseUrl}account/Signin?subtenantId=${subtenantId}`);
                },2000)
            }
            setisLoading(false)
        }).finally(() => {
            konsole.log("agentActivationUserByUserId")
        })
    }

    const membername = () => {
        let name = ''
        if (memberName.length > 0) {
            if (memberName.length == 2) {
                name = `${memberName[0][0]?.memberFullName},${memberName[1][0]?.memberFullName}`
            } else {
                name = `${memberName[0][0]?.memberFullName}`
            }
            return name;
        }
    }
        return (
            <>
                <div>{isLoading === true ? <div className="w-100 h-100 d-flex align-items-center justify-content-center position-absolute overLayLoader" style={{ backgroundColor: "rgba(0,0,0,0.5)", zIndex: '99999999999999999999' }}>   <div className="spinner-border" role="status">  </div> </div> : null}</div>
                <HeaderInvitation logourl={logoUrl} />
                <div className={`${(displaynone === true || userAgentData.length === 0 || isLoading === true) && "d-none"} container mt-5`}>
                    <h3>Hi, <b>{responseData?.memberName}</b></h3>
                    <p><b>{membername(membername)}</b> has named you as an agent in more than one position, please make your selection for role/roles below.</p>
                    {(userAgentData.length > 0) && <>
                        <Table bordered responsive="sm md lg xl" className="text-center" >
                            <thead className=''>
                                <tr>
                                    <th><Form.Check inline type='checkbox' checked={checkAllCheckSelect} value="checked" onChange={(e) => isSelectAdd(e.target.checked, "ALLSELECT")}></Form.Check>Select All</th>
                                    <th>Legal Document</th>
                                    <th>Role</th>
                                    <th>Order</th>
                                    {(memberName.length === 2)&&<th>Assigned By</th>}
                                    
                                </tr>
                            </thead>
                            <tbody>
                                {(userAgentData.length > 0 && userAgentData.map((item, index) => {
                                    let { agentRank, agentRole, legalDocName, testDocName, testSupportDocName, isSelected } = item;
                                    let documentName = (testDocName == null && testSupportDocName == null) ? legalDocName : (testDocName == null && testSupportDocName !== null) ? testSupportDocName : testDocName;
                                    konsole.log("isSelectisSelect", isSelected)
                                    return (
                                        <tr>
                                            <td><Form.Check inline type='checkbox' checked={isSelected} onChange={(e) => isSelectAdd(e.target.checked, "SEPRATE", index)} /></td>
                                            <td>{documentName}</td>
                                            <td>{agentRole}</td>
                                            <td>{agentRank}</td>
                                            {(memberName.length === 2)&&<td>{item?.memberFullName}</td>}
                                        </tr>
                                    )
                                }))}
                            </tbody>
                        </Table>
                        <div className='d-flex justify-content-end mt-2 mb-5'>
                            {/* <button type="button" className="btn me-2" style={{ backgroundColor: "#720C20", color: "white" }} onClick={() => AccptDeclineSomefun('ACCEPTALL')} >Accept All </button> */}
                            <button type="button" className="btn me-2" style={{ backgroundColor: "#720C20", color: "white" }} onClick={() => AccptDeclineSomefun('ACCEPTSOME')} >Submit</button>
                            {/* <button type="button" className="btn " style={{ color: "#720C20", border: "2px solid #720C20" }} onClick={() => AccptDeclineSomefun('DECLINEALL')}>Decline All </button> */}
                        </div>

                    </>}
                </div>

                {(userAgentData.length === 0 || displaynone === true) &&
                    <div className={`${(isLoading === true) && "d-none"} row no-gutters m-0 p-0 justify-content-center align-items-center flex-column`}
                        style={{ height: "85vh" }}  >
                        <div className='col-5' style={{ padding: "10px", boxShadow: "0 10px 15px -3px rgba(0,0,0,0.1), 0 4px 6px -4px rgba(0,0,0,0.1)", border: "2px solid #ECF0F1" }}>
                            <h4 style={{ fontFamily: "Inter", color: "#7B7D7D" }}>{showmessage}
                                {/* Thank you for  accepting the role so that you have access to the documents and the information you  will need to fulfill your responsibilities as   the agent you need to set up your account. Please Activate your account using the Activate Button. */}
                            </h4>

                        </div>
                        {(userAgentData.length === 0 && displaynone == false) && <div className='col-5 mt-3 d-flex justify-content-center align-items-center'>
                            <button style={{ backgroundColor: "#720c20", color: "white", border: "none", padding: "10px", borderRadius: "5px", fontWeight: "500" }} onClick={() => agentActivationUserByfun()}>{buttonText}</button>
                        </div>}

                    </div>
                }


            </>
        )
    }

    export default AgentAllList