import React, { useContext, useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import commonLib from '../../control/commonLib';
import { isNotValidNullUndefinedfile } from '../../control/Constant';

import { globalData } from "../CommonModal";
import '../Modal.css'

const EOlAttornerCall = () => {
    
    const { handleClose, setmodalcontantshow, steptwomodaldata,setEOLstep10 ,elderlawAttorney} = useContext(globalData)
    let elderlawAttorneycheck = (elderlawAttorney !=='' && elderlawAttorney.length !==0)?true:false
    let elderlawAttorneycheckName=(elderlawAttorneycheck==true)? elderlawAttorney[0] :""
    let elderno= elderlawAttorneycheckName?.mobileNumbers ?? elderlawAttorneycheckName?.proPhoneNo;
    
    const formatPhoneNumber = (str) => {
        let phoneNum = str.slice(-10);
        let countryCode = str.slice(0,-10)
        let cleaned = ("" + phoneNum).replace(/\D/g, "");

        let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

        if (match) {
          return countryCode + " " +  "(" + match[1] + ") " + match[2] + "-" + match[3];
        }

        return null;
      };
  

    return (
        <>
            
                <Modal.Header style={{ backgroundColor: "#871a27" }}>

                    <Modal.Title className='heading' id="example-modal-sizes-title-lg" style={{ color: 'white', fontFamily: "Inter"}}>
                    <span style={{color:"#871a27"}}>S</span>
                    </Modal.Title>
                    {/* closeButton */}

                    <button className=' closeButton' style={{ border: "1px solid #981b29" }} onClick={handleClose}>x</button>

                </Modal.Header>
                <Modal.Body>

                    <div>
                    <h4 className='SelectOptionsh4Tag'>
                        {commonLib.showFullName(elderlawAttorney[0]) +" Elder law attorney"}
                   
                    </h4>
                    <h4 className='SelectOptionsh4Tag pt-4'>
                        {(isNotValidNullUndefinedfile(elderno)) ? formatPhoneNumber(elderno) : 'Contact no. not available.'}
                    </h4>
                    <div className='buttonDiv pt-3'>
                    
                        <button className='yesButton'onClick={()=>setmodalcontantshow('eolstep10')}>OK</button>

                    </div>
                    {}
                    <div className='stepsevenbuttonsDiv'>
                        <button className='backButton' onClick={()=>setmodalcontantshow('eolneedhelpstep9')}>Back</button>
                        
                    </div>
                    </div>
                   
                    
                </Modal.Body>
                
            {/* </Modal> */}
        </>
    );
}

export default EOlAttornerCall