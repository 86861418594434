import React, { useState, useContext, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { globalData } from '../CommonModal';
import Services from '../../Network/Services';
import { returnFname } from '../../control/Constant';
import '../Modal.css'
import "../Illness/Warning.css";
const DeathAskAgent = () => {
  const [textMesaage, setTextMesaage] = useState(null)

    const { handleClose, setmodalcontantshow, steptwomodaldata,isGCM,setDeathText,deathText,setloader} = useContext(globalData)
    useEffect(() => {
      getQuestionsByID(905)
       },[])

const clickshowinfo=()=>{
}

 
const getQuestionsByID =(questionId)=>{
  setloader(true)
  Services.getsubjectForFormLabelIds([questionId]).then((res) => {
    
    let responseQuestion = res?.data?.data
    Services.getSubjectResponseData(steptwomodaldata?.memberUserId, questionId ,responseQuestion[0]?.subjectId).then((res) => {
      let responseAns = res?.data?.data
      setloader(false)
        let value = responseAns?.userSubjects[0]?.responseId 
         let text = value == "222" ? "Cremated" : value == "223" ? "Buried" : value == "224" ? "other" : "" 
         setDeathText(text)
       let id = value == "222" ? 906 : value == "223" ? 940 : value == "224" ? 224 : ""
         if(id == 906 || id == 940){
          setloader(true)
          Services.getsubjectForFormLabelIds([id]).then((res)=>{
            let responseQuestion = res?.data?.data 
            Services.getSubjectResponseData(steptwomodaldata?.memberUserId, id ,responseQuestion[0]?.subjectId).then((res)=>{
              setloader(false)
              let responseId = res?.data?.data?.userSubjects[0]?.responseId
              let yesNotext = responseId == "225" || responseId == "274" ? `${returnFname(steptwomodaldata.memberFullName)} would like the body to be ${text}.
                 ${returnFname(steptwomodaldata.memberFullName)} has already made arrangements for their funeral, so please make sure it is followed as directed.` : responseId == "226" || responseId == "275"?
                  ` ${returnFname(steptwomodaldata.memberFullName)} would like the body to be ${text}.
                  ${returnFname(steptwomodaldata.memberFullName)} has not made any arrangements for their funeral, so you will have to make arrangements.` : ""
                  setTextMesaage(yesNotext)
            }).catch((err)=>{setloader(false)})  
          }).catch((err)=>{setloader(false)})
            

         }else if(id == 224){
              setloader(true)
             Services.getSubjectResponseDataother(steptwomodaldata?.memberUserId,"134").then((res)=>{
              setloader(false)
              let responseAns = res?.data?.data
              let text =`${returnFname(steptwomodaldata.memberFullName)} has choosen below action to be taken after death, please make sure that it is followed, which is as mentioned. ${responseAns?.userSubjects[0]?.response}`
              setTextMesaage(text) }).catch((err)=>{setloader(false)})
            }
      
        
     }).catch((err) => {setloader(false)})

   }).catch((err) => {setloader(false)})

}

  return (
    <>
    
      <Modal.Header style={{ backgroundColor: "#871a27" }}>

        <Modal.Title className='heading' id="example-modal-sizes-title-lg" style={{ color: 'white', fontFamily: "Inter", height: 30 }}>

        </Modal.Title><button className='closeButton' style={{ border: "1px solid #981b29" }} onClick={handleClose}>x</button></Modal.Header>
      <Modal.Body>

        <h4 className='SelectOptionsh4Tag text-center mb-4'>
          {
            (deathText !='')?
            <>
            {textMesaage ? textMesaage : "Loading..."}
            </>
            :<>
            {returnFname(steptwomodaldata.memberFullName)} has not made any decision. Please click below to choose how you want to proceed with the situation.</>
          }
        </h4>
       <div className='d-flex justify-content-center'>
            {
              (deathText !='')?
              <button  className="healthButton" onClick={clickshowinfo} >
            Show Info
             </button>
             :
          
             <button  className="healthButton" onClick={()=>setmodalcontantshow('deathcrematedburied')} >
             click to proceed
            </button>
            }
</div>

        <div className='stepsevenbuttonsDiv'>
          {/* <button className='backButton' onClick={()=>setmodalcontantshow('deathgoalstep7')}> */}
          <button className='backButton' onClick={()=>setmodalcontantshow('deathhome')}>
            Back</button>
         
        </div>  
      </Modal.Body>

   
    </>
  );
}

export default DeathAskAgent;