import React, { useContext, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import '../Modal.css';
import { returnFname } from '../../control/Constant';
import { globalData } from '../CommonModal';

import Container from "react-bootstrap/Container"

const DeathStep5= ({setdeathhome}) => {
    const {setIsGCM, handleClose, setmodalcontantshow, steptwomodaldata, addManageStepToFollow } = useContext(globalData)

    const click=(e)=>{
      setdeathhome(e)
      setmodalcontantshow('deathhome')
      addManageStepToFollow("setdeathhome", e);

    }
    
    const   clickbackbutton=()=>{
      setIsGCM('false')
      setmodalcontantshow('deathemailtextpreview')
    }


  return (
    <>
      
      <Modal.Header style={{ backgroundColor: "#871a27" }}>
        <Row >
          <Col sm={11}>
            <h3 className='heading locateTitle'>
              Step 5: Where is {returnFname(steptwomodaldata.memberFullName)} located at this time?
            </h3>
          </Col>
          <Col sm={1} >
            <button className='closeButton' style={{ border: "1px solid #981b29" }} onClick={handleClose}>x</button>
          </Col>
        </Row>

      </Modal.Header>
      <Modal.Body>

        <Container>
          <h4 className='SelectOptionsh4Tag mb-4 mt-2'>
            Select option
          </h4>

          <div className='container'>
            <div className='row '>
              <div className='col-md-4 col-xs-12 col-md-12 col-lg-4 '>
                <Card className="card" onClick={()=>click('deathhome')} >
                  <div style={{ display: "flex", justifyContent: "center" }}>
                  <Card.Img className='mt-2' src="/home.png" style={{ height: 90, width: 100, }} ></Card.Img>
                  </div>
                 
                  <Card.Body className='text-center mt-2 mt-2 mb-2 pb-0'>
                    <Card.Title  style={{ opacity: "85%", fontSize: "24px", fontFamily: "Inter"}}>Home</Card.Title>

                  </Card.Body>


                </Card>
              </div><br></br>
              <div className='col-md-4 col-xs-12 col-md-12 col-lg-4 justify-content-around'>
                <Card onClick={()=>click('carefacility')} >
                  <div style={{ display: "flex", justifyContent: "center" }}>
                  <Card.Img className='mt-2' variant="top" src="/hospital.png" style={{ height: 90, width: 100, }} />
                  </div>
                 
                  <Card.Body className='text-center mt-2 mt-2 mb-2 pb-0'>
                    <Card.Title  style={{ opacity: "85%", fontSize: "24px", fontFamily: "Inter"}}>Care Facility</Card.Title>

                  </Card.Body>

                </Card>
              </div><br></br>
              <div className='col-md-4 col-xs-12 col-md-12 col-lg-4 justify-content-around'>
                <Card onClick={()=>click('deathhome')}>
                <div style={{ display: "flex", justifyContent: "center" }}>
                <Card.Img className='mt-2' variant="top" src="/Others.png" style={{ height: 90, width: 100, }} />
                  </div>
                 
                  <Card.Body  className='text-center mt-2 mt-2 mb-2 pb-0'>
                    <Card.Title style={{ opacity: "85%", fontSize: "24px", fontFamily: "Inter"}}>Other Setting</Card.Title>

                  </Card.Body>

                </Card>
              </div>
            </div>
          </div>
        </Container>

        <div className='backbuttonDiv'>
          <button className='backButton' onClick={clickbackbutton}>Back</button>
        </div>
      </Modal.Body>
   
    </>
  );
}

export default DeathStep5