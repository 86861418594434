import React, { useContext, useState, useEffect, useRef } from 'react';
import Modal from 'react-bootstrap/Modal';
import { Table } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Container from "react-bootstrap/Container"
import { globalData } from './CommonModal';
import commonLib from '../control/commonLib';
import { returnBase64File,isNotValidNullUndefinedfile} from '../control/Constant';
import PdfViwer from '../FileCabinet/PdfViwer';
import OccuranceCom from '../OccuranceCom'
import Services from '../Network/Services';
const ModalInsurence = () => {

    const { setillushospitalstep6help, setIsGCM, handleClose, setmodalcontantshow, steptwomodaldata, addManageStepToFollow, setHospitalDetails, setGoBack, insurenceJosn,setloader,setnewAlert} = useContext(globalData)
    // console.log("insurenceJosn", insurenceJosn[1]?.insuranceDocs[0]?.docFileId)
    const [showDocumentView, setDocumentView] = useState(false)
    const [downLoadSend, setDownLoadSend] = useState(false)
    const [base64File, setbase64File] = useState()
    const stateObj = commonLib.getObjFromStorage("stateObj")
    const [errors, setErrors] = useState({});
    const userLoggedInDetail = commonLib.getObjFromStorage("userLoggedInDetail");
    const [showField, setShowField] = useState(false)
    const [showMail, setShowMail] = useState(false)
    const [formData, setFormData] = useState({
        to: '',
        Subject: '',
        Compose: '',
        bcc: '',
        cc: '',
      });
      
    let occurranceRef = useRef(null)

    useEffect(() => {
        // base64Json(insurenceJosn[1]?.insuranceDocs[0]?.docFileId)
        // console.log("gsaminhhhh",base64Json)
    }, [])

    const base64Json = async (id) => {
        const result = await returnBase64File(id);
        
        setbase64File(result)
        // console.log("reasulthh11", result)
        if (result == 'err')return;
    };
    const handleDocumnets = async(type,data) => {
        let  id = data?.longTermInsDocs[0]?.fileId
        let result;
        if(!isNotValidNullUndefinedfile(id)){
           result = {}
        }else{
            result = await returnBase64File(id,setloader);
            
        }
       setbase64File(result)

       if(type == "view"){
      setDocumentView(true)
       }else if(type == "print"){
        setloader(true)
        const printWindow = window.open('', '_blank');
        printWindow.document.write('<iframe src="' + base64File + '" width="100%" height="100%"></iframe>');
        printWindow.document.close();
        setTimeout(() => {
          setloader(false)
        }, 2000); // Adjust the delay as needed
       }else{
        setShowMail(true)
       }
       if (result == 'err')return; 
    }
    const handleSubmit = async(e)=>{
        e.preventDefault();
    // Basic validations
    const newErrors = {};
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!formData.to) {
        newErrors.to = 'To field is mandatory';
      }
    if(showField) {
        if (formData.to) {
            const toEmails = formData.to.split(',').map((email) => email.trim());
            for (const toEmail of toEmails){
                if (!emailRegex.test(toEmail)) {
                      newErrors.to = 'Please enter a valid email';
                      // return;
                      break;
                }
            }
        }
        if (formData.cc) {
            const ccEmails = formData.cc.split(',').map((email) => email.trim());
            for (const ccEmail of ccEmails){
                if (!emailRegex.test(ccEmail)) {
                    newErrors.cc = 'Please enter a valid email';
                    break;
                }
            }
        }
        if (formData.bcc) {
            const bccEmails = formData.bcc.split(',').map((email) => email.trim());
            for (const bccEmail of bccEmails){
                if (!emailRegex.test(bccEmail)) {
                    newErrors.bcc = 'Please enter a valid email';
                    break;
                }
            }
        }
    }
    

    // Check if there are any errors
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }
    if(stateObj.roleId == "5"){
        const blob = await fetch(base64File).then((res) => res.blob());
        const file = new File([blob], `${"Long Term Care Policy"}.pdf`, { type: "pdf" });
        let formdata = new FormData();
       
        formdata.append("CreatedBy",userLoggedInDetail?.loggedUserId)
        formdata.append("Emailcc", formData?.cc)
        formdata.append("Emailbcc" , formData?.bcc)
        formdata.append("File" , file)
        formdata.append("EmailType" , "SavePdfData")
        formdata.append("EmailTo" , formData?.to)
        formdata.append("EmailSubject" , !isNotValidNullUndefinedfile(formData?.Subject) ? (`${"Long Term Care Policy"} - ${userLoggedInDetail.loginUserName}`)  :formData?.Subject)
        formdata.append("EmailContent", !isNotValidNullUndefinedfile(formData?.Compose) ? "At your required find the "+ "Long Term Care Policy" + " for " + steptwomodaldata.memberFullName+".<br />Do email me " + userLoggedInDetail.primaryEmailId + " or call me at " + (userLoggedInDetail?.primaryPhoneNumber ? numberFormatee(userLoggedInDetail.primaryPhoneNumber) : "not available" )+ ".<br />If you have questions.<br /> " + userLoggedInDetail.loginUserName : formData?.Compose)
        formdata.append("EmailTemplateId" , 1)
        formdata.append("EmailStatusId" , 1)
        formdata.append("EmailMappingTable" , "EmailMappingTable")
        formdata.append("EmailMappingTablePKId" , stateObj?.loggenInId)
        setloader(true) 
      let result = Services.PostEmail(formdata)
       result.then((res) => {
        setloader(false)
        setShowMail(false)

        setFormData({
            ...formData,
            to: "",Subject:"",Compose:"", cc:"",bcc:""
          });
 
        setnewAlert({type: "success", text: "Email sent successfully"});
        setTimeout(() => {
            setnewAlert({type: "", text: ""})
          }, 3000)
    }).catch((err) => {
        setloader(false)
    }).finally(() => {
        setloader(false)
    })
    }else{
        setShowMail(false)
    }
    
       
    }
    const numberFormatee = (number) => {
        const lastTenDigitArray = array => array.slice(-10);
        let countryCode = getCountryCode(number)
         if(countryCode == '1'){
          return commonLib.convertToUSFormat(lastTenDigitArray(number), countryCode)
         }else{
          return commonLib.convertToIndiaFormat(lastTenDigitArray(number), countryCode);
         }
       
          }
    function getCountryCode(mobileNumber) {
            const digitsOnly = mobileNumber.replace(/\D/g, '');
          
           if (digitsOnly.startsWith('1')) {
             
               return '1';
           }
           if (digitsOnly.startsWith('91')) {
               return '91';
           }
           return null;
       }
    const handleChanges =(e)=>{
        const { name, value } = e.target;
        setFormData({
          ...formData,
          [name]: value
        });
        setErrors({
            ...errors,
            [name]: ''
        });
    }

    const toggleCc = () =>{
        setShowField(!showField);
    }   
          

    return (
        <>
            <Modal.Header style={{ backgroundColor: "#871a27" }}>
                <Modal.Title className='mt-2' id="example-modal-sizes-title-lg">
                    <h3 className='heading text-center' >
                        Documents
                    </h3>
                </Modal.Title>

                <button className='closeButton' style={{ border: "1px solid #981b29" }} onClick={() => setmodalcontantshow('eolneedhelpstep9')}>x</button>

            </Modal.Header>
            <Modal.Body>
                <div style={{ maxHeight: "50vh", height: "50vh", overflowY: "auto" }} className='p-2 pt-0 mt-0'>
                    <Table striped bordered hover >
                        <thead className='sticky-top bg-white' style={{ top: "-2px" }}>
                            <tr>

                                <th>Insurance Company</th>
                                <th>Policy Number</th>
                                <th>Policy Start</th>
                                <th>Elimination Period</th>
                                <th>Premium</th>
                                <th>Max Life Benefits</th>
                                {/* <th>Death Benefits</th> */}
                                {/* <th>Beneficiary</th> */}
                                <th>View/Download/Forward</th>
                            </tr>
                        </thead>
                        <tbody>

                            {insurenceJosn.length > 0 &&  insurenceJosn?.map((user, index) => {

                                return (
                                    <tr>

                                        <td>{user?.insCompany}</td>
                                        <td>{user?.additionalDetails}</td>
                                        <td>{isNotValidNullUndefinedfile(user?.policyStartDate) ? commonLib?.getFormattedDate(user?.policyStartDate) : ""}</td>
                                        <td>{user?.eliminationPeriod}</td>
                                        <td>{user?.insType}</td>
                                        <td>{user?.maxLifeBenefits}</td>
                                        {/* <td>{user?.cashValue}</td> */}
                                        {/* <td>{user?.deathBenefits}</td> */}
                                        {/* <td>{user?.beneficiary.map((element, idx) => (
                                                <span key={idx}>{element?.beneficiaryName}</span>
                                                   ))}
                                                </td> */}
                                        <td className='colWidth'>
                                            <div className='d-flex justify-content-center'>
                                                {user?.longTermInsDocs.length > 0 ? <>
                                                    <a className='me-3' style={{ cursor: "pointer" }} onClick={() => handleDocumnets("view",user)}>
                                                        <img
                                                            title='View'
                                                            src="/icons/viewIcon.svg"
                                                            className='smallIconSize'
                                                            alt="View"
                                                        />
                                                    </a>
                                                      
                                                <a className='me-3' style={{ cursor: "pointer" }}onClick={()=>handleDocumnets("print",user)} >
                                                    <img
                                                        title='Download & Print'
                                                        src="/icons/printIcon.svg"
                                                        className='printIconSize'
                                                        alt="Download & Print"
                                                    />
                                                </a>
                                                <a style={{ cursor: "pointer" }}onClick={()=>handleDocumnets("mail",user)} >
                                                    <img
                                                        title='Forward'
                                                        src="/icons/forwardIcon.svg"
                                                        className='smallIconSize'
                                                        alt="Forward"
                                                    />
                                                </a>
                                                </> : <img
                                                    title='Document not available'
                                                    src="/icons/warning.svg"
                                                    className='smallIconSize'
                                                    alt="View"
                                                />}
                                               
                                            </div></td>

                                    </tr>
                                )
                            })}



                        </tbody>
                    </Table>

                </div>
                <div className='d-flex justify-content-between'>
                    <button className='backButton' onClick={() => setmodalcontantshow('eolneedhelpstep9')}>Back</button>
                </div>

            </Modal.Body>
            <Modal centered={true} size="lg" show={showDocumentView} backdrop="static" >
                <Modal.Header style={{ backgroundColor: "#871a27" }}>
                    <Row>
                        <Col sm={11}>
                            <h3 className='heading'>
                                Document View
                            </h3>
                        </Col>
                        <Col sm={1} >
                            <button className='closeButton' style={{ border: "1px solid #981b29" }} onClick={() => setDocumentView(false)}>x</button>
                        </Col>
                    </Row>
                </Modal.Header>
                <Modal.Body>
                    <PdfViwer base64File={base64File} />
                </Modal.Body>
                <OccuranceCom ref={occurranceRef} />
            </Modal>
            <Modal centered={true} size="md" show={showMail} backdrop="static" style={{opacity:"0.1 !importtant", zIndex: "9999",background:"rgba(0,0,0,0.5)"}}>
                <Modal.Header style={{ backgroundColor: "#871a27" }}>
                    <div>
                        <h5 style={{ color: "aliceblue", marginBottom: "auto" }}><span>Forward Document</span></h5>
                        <button className='closeButton' style={{ border: "1px solid #981b29" }} onClick={() => setShowMail(false)}>x</button>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <>
                        <form className="compose-container"onSubmit={handleSubmit}>
                        <div className="form-group">
                            <div className='position-relative'>
                            <input type="text"id="to" name="to" className="recipient-input" placeholder="To" onChange={handleChanges} />
                            <button className='position-absolute top-0 end-0 mt-2 me-4 border-0 bg-white' onClick={toggleCc}>Cc / Bcc</button>
                            {errors.to && <p className='text-danger'> <div className="error">{errors.to}
                            </div></p>}
                            </div>
                           { showField && <input type="text"id="cc" name="cc" className="recipient-input" placeholder="Cc" defaultValue={(`${userLoggedInDetail.primaryEmailId},`)} onChange={handleChanges} />}
                           {errors.cc && <p className='text-danger'> <div className="error">{errors.cc}
                            </div></p>}
                            { showField && <input type="text"id="bcc" name="bcc" className="recipient-input" placeholder="Bcc" onChange={handleChanges} />}
                            {errors.bcc && <p className='text-danger'> <div className="error">{errors.bcc}
                            </div></p>}
                            
                            </div>
                            <input type="text"id="Subject" name="Subject" className="subject-input" defaultValue={(`${"Long Term Care Policy"} - ${steptwomodaldata.memberFullName}`)} onChange={handleChanges}  />
                            <textarea className="message-input" id="Compose"name="Compose" placeholder="Compose your message..." defaultValue={`At your required find the ${"Long Term Care Policy"} for ${steptwomodaldata.memberFullName}.\nDo email me ${(userLoggedInDetail.primaryEmailId)} or call me at ${userLoggedInDetail?.primaryPhoneNumber ? (numberFormatee(userLoggedInDetail.primaryPhoneNumber)):"not available"}.\nIf you have question.\n${userLoggedInDetail.loginUserName}`} onChange={handleChanges}  />
                            <div className='d-flex' style={{ width: "fit-content" }}>
                                <button type="submit" style={{ height: "46px" }} className="send-button me-2">Send</button>
                            </div>
                        </form>
                    </>
                </Modal.Body >

            </Modal >


        </>
    )
}

export default ModalInsurence