import React, { useState, useContext, useEffect, useRef } from "react";
import { globalData } from "../CommonModal";
import { returnMembersEmail,isNotValidNullUndefinedfile } from "../../control/Constant";
import commonLib from "../../control/commonLib";



const Emailcreate = () => {
    const {steptwomodaldata,selectoptionmessage,setInputValue,inputValue,setEmails,emails,selectoptionId} = useContext(globalData)

    useEffect(() => {
        getMembers()
    }, [])
    const getMembers = async () => {
        const agentuserId = commonLib.getObjFromStorage("userLoggedInDetail").loggedUserId;
        const result = await returnMembersEmail(1, steptwomodaldata.memberUserId, selectoptionId, selectoptionmessage, agentuserId);
        
        setEmails(result)
        if (result == 'err')return;
    };
    const handleInputChange = (event) => {
        setInputValue(event.target.value);
      };
    
      const handleKeyDown = (event) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (event.key === ',' || event.key === 'Enter' ) {
          event.preventDefault();
          const trimmedValue = inputValue.trim();
          if (trimmedValue !== '' || trimmedValue !== 'Enter') {
            if (!emailRegex.test(trimmedValue)) {
              // newErrors.to = 'Please enter a valid email';
              alert("Please enter a valid email")
          }else{
            setEmails([...emails, trimmedValue]);
            setInputValue('');
          }
           
          }
        }
      };


//       max-height: 12rem;
//       overflow-y: auto;
//       height: auto;
//       height: 6rem;
//   }
  return (
    <>
       {emails.length > 0  ? <>
        <div className="d-flex" style={{ maxHeight: "12rem", overflowY: "auto", height: "6rem" }}>
    <div style={{ display: 'flex', flexWrap: 'wrap', width: "100%" }}>
      {emails?.map((email, index) => (
        <div key={index} style={{ display: 'flex', alignItems: 'center', margin: '5px', padding: '2px', background: '#f0f0f0', borderRadius: '5px', height: "max-content", fontFamily: "initial" }}>
          <span className="ms-1 me-2">{email?.relativeEmailId}</span>
          {emails.length > 1 && (
            <span className="align-self-center">
              <img src='close.png' className='' style={{ cursor: "pointer", height: "0.9rem", marginTop: "-29px" }} onClick={() => setEmails(emails.filter((_, i) => i !== index))}></img>
            </span>
          )}
        </div>
      
        ))}
        {/* <textarea className="form-control pt-0 ps-3 fs-4  text-dark  shadow-none border-0" rows="4"
              type="text"
              value={inputValue}
              onChange={handleInputChange}
              onKeyDown={handleKeyDown}
              placeholder={emails.length == 0 ? "Enter an email separated by commas" : ""}
              style={{ width: '100%', padding: '8px', border: '1px solid #ccc', borderRadius: '4px', fontSize: '16px' }}
            /> */}
      </div>
            
          </div>
       </>:""}
    
    
    </>
  )
}

export default Emailcreate