import React, { useState, useContext } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Modal from "react-bootstrap/Modal";
import { globalData } from "../CommonModal";
import Services from "../../Network/Services";
import { returnHealthPolicyCount ,returnGcm} from "../../control/Constant";
import '../Modal.css'

import "./Warning.css";
const  IllnessHospitalStep6 = ({setillushospitalstep6help}) => {
    const { handleClose, setmodalcontantshow, steptwomodaldata, addManageStepToFollow,setIsGCM} = useContext(globalData)
  //console.log("memberUserId", steptwomodaldata.memberUserId)

  const selectoption=(e)=>{
    setillushospitalstep6help(e)
    setmodalcontantshow('illnesshospitalstep7')
    addManageStepToFollow("setillushospitalstep6help", e);
    // question(steptwomodaldata?.memberUserId,"98")/// for illness
  
  }
  const question =(memberUserId,subId)=>{
    // console.log("datass",returnHealthPolicyCount(memberUserId))
    
  //   Services.getSubjectResponseDataGcm(memberUserId,subId).then((res)=>{
  //     let responseId = res?.data?.data?.userSubjects[0]?.responseId
  //     console.log("steptwomodaldata",responseId)
  //   }).catch((err)=>{})  
  // }
     
  // healthPolicyCount(memberUserId)
  gcmIsactive(memberUserId,subId)
  }
//   const healthPolicyCount = async (id) => {
//        const result = await returnHealthPolicyCount(id);
//        if(result=='err') return;
// };
  const gcmIsactive = async (memberUserId,subId) => {
       const result = await returnGcm(memberUserId,subId);
       if(result=='err') return;
      //  console.log('resultresult1',result)
};
const backButoon =()=>{
  setmodalcontantshow('illnessemailtextpreview')
  setillushospitalstep6help("Illness")
  setIsGCM('false')
}
    return (
      <>
        <Modal.Header style={{ backgroundColor: "#871a27" }}>
          <Row>
            <Col sm={11}>
              <h3 className="heading">Step 6: What do you need help with?</h3>
            </Col>
            <Col sm={1}>
              <button
                className="closeButton"
                style={{ border: "1px solid #981b29" }}
                onClick={handleClose}
              >
                x
              </button>
            </Col>
          </Row>
        </Modal.Header>
        <Modal.Body>
          <h4 className="SelectOptionsh4Tag mb-4 mt-2">Select option </h4>

          <Row className="pt-2">
            <Col
              xs={12}
              md={12}
              lg={6}
              className="healthButtonCol DischargeButton m-0 p-0"
            >
              <button className="healthButton"onClick={()=>selectoption('Discharge')} >
                Help with Discharge 
              </button>
            </Col>
            <Col
              xs={12}
              md={12}
              lg={6}
              className="finacialButtonCol HospitalButton  m-0 p-0"
            >
               <button
                className="finacialButton needhelp"
                onClick={()=>selectoption('Care')}
              >
                Help with Care in the Hospital
              </button>
            </Col>
          </Row>

        
          <div className="backButtonDiv">
            <button className="backButton" onClick={()=>backButoon()}>
              Back
            </button>
          </div>
        </Modal.Body>
      </>
    )
  }
export default IllnessHospitalStep6;