import React, { useContext, useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';

import { globalData } from "../CommonModal";
import '../Modal.css'

const EOLstep9Call = () => {
    
    const { handleClose, setmodalcontantshow, steptwomodaldata,setEOLstep10 } = useContext(globalData)

    const clickok=()=>{
        setEOLstep10('ok')
        setmodalcontantshow('eolstep10')
    }

  

    return (
        <>
            
                <Modal.Header style={{ backgroundColor: "#871a27" }}>

                    <Modal.Title className='heading' id="example-modal-sizes-title-lg" style={{ color: 'white', fontFamily: "Inter"}}>
                    <span style={{color:"#871a27"}}>S</span>
                    </Modal.Title>
                    {/* closeButton */}

                    <button className=' closeButton' style={{ border: "1px solid #981b29" }} onClick={handleClose}>x</button>

                </Modal.Header>
                <Modal.Body>

                    <div>
                    
                    <div className='buttonDiv pt-3'>
                        <button className='yesButton' onClick={clickok}>OK</button>

                    </div>
                    <div className='stepsevenbuttonsDiv'>
                        <button className='backButton' onClick={()=>setmodalcontantshow('eolneedhelpstep9')}>Back</button>
                        
                    </div>
                    </div>
                   
                    
                </Modal.Body>
                
            {/* </Modal> */}
        </>
    );
}

export default EOLstep9Call