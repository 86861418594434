import React, { useState, useContext } from 'react';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import { globalData } from '../CommonModal';
import {returnFname} from '../../control/Constant'
import '../Modal.css'
import "../Illness/Warning.css";
const DeathCrematedBuried = ({setcrematedburied}) => {
    const { handleClose, setmodalcontantshow, steptwomodaldata } = useContext(globalData)

    const clickbutton=(e)=>{
        setcrematedburied(e)
        setmodalcontantshow('deathcrematedyesno')
        

    }


    return (
        <>
           
            <Modal.Header style={{ backgroundColor: "#871a27" }}>

                <Modal.Title className='heading' id="example-modal-sizes-title-lg" style={{ color: 'white', fontFamily: "Inter"}}>
                    <span style={{ color: "#871a27" }}>S</span>
                </Modal.Title>
                {/* closeButton */}

                <button className='closeButton' onClick={handleClose}>x</button>

            </Modal.Header>
            <Modal.Body>
                <h4 className='SelectOptionsh4Tag text-center mt-2 px-4' >
                {returnFname(steptwomodaldata.memberFullName)} has not specified how remains should be handled.
                <br />
                    How would you like to handle {returnFname(steptwomodaldata.memberFullName)} remains?
                </h4>
                <Row className='pt-2'>
                    <Col xs={12} md={6} lg={6} sm={6} className='healthButtonCol m-0 p-0'>
                        <button className='healthButton' onClick={()=>clickbutton('cremated')} >Cremated</button>
                    </Col>
                    <Col xs={12} md={6} lg={6} sm={6} className='healthButtonCol m-0 p-0'>
                        <button className='healthButton' onClick={()=>clickbutton('buried')} >Buried</button>
                    </Col>
                </Row>
             

                <div className='backbuttonDiv'>
                    <button className='backButton' onClick={() => setmodalcontantshow('deathaskagent')}>Back</button>
                </div>
            </Modal.Body>
            {/* <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button>
        </Modal.Footer> */}
            {/* </Modal> */}
        </>
    );
}

export default DeathCrematedBuried;